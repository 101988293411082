

import React from 'react';
import moment from 'moment';

import Switch from './ui/Switch';
import Calendar from './Calendar';
import { __ } from './../i18n';

export default class CalendarInterval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: props.toggled || false,
      startDate: props.startDate,
      endDate: props.endDate,
      minEndDate: props.minEndDate,
      maxEndDate: props.maxEndDate,
      minStartDate: props.minStartDate,
      maxStartDate: props.maxStartDate,
    }
  }

  render() {
    const {
      onUpdateStartDate,
      onUpdateEndDate,
      maxIntervalInMonths = 1
    } = this.props;
    const { toggled, startDate, endDate, minStartDate, maxStartDate, minEndDate, maxEndDate } = this.state;

    return (
      <div>
        <Switch
          ref={c => this.toggle = c}
          toggled={toggled}
          onToggle={(res) => this.setState({ toggled: res })}
          left={__('Begin')}
          right={__('End')}
          id="calendar-toggle"
        />
        <p style={{ textAlign: 'center', margin: '10px 0px' }}>{__('When you select the intial date, automatcally end date is defined to %s months ago from the selected date.', maxIntervalInMonths)}</p>
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          {!toggled && <Calendar
            withBorder
            calendarStyles
            calendarPadding
            headerAlwaysOn
            selectedDt={startDate}
            minDate={minStartDate}
            maxDate={maxStartDate}
            onSelect={(dateSelected) => {
              this.setState({
                startDate: dateSelected.selectedDt,
                endDate: new Date(moment(dateSelected.selectedDt).add(maxIntervalInMonths, 'month').subtract(1, 'days').format('YYYY-MM-DDT23:59:59')),
                minEndDate: moment(dateSelected.selectedDt).format('YYYY-MM-DDT23:59:59'),
                maxEndDate: moment(dateSelected.selectedDt).add(maxIntervalInMonths, 'month').subtract(1, 'days').format('YYYY-MM-DDT23:59:59')
              }, () => this.setState({ toggled: true }, this.toggle.change));

              if (onUpdateStartDate) onUpdateStartDate(dateSelected);
              // setTimeout(, 1000);
            }}
          />
          }
          {toggled && (
            <Calendar
              withBorder
              calendarStyles
              calendarPadding
              headerAlwaysOn
              selectedDt={endDate}
              minDate={minEndDate}
              maxDate={maxEndDate}
              onSelect={(dateSelected) => {
                this.setState({ endDate: new Date(moment(dateSelected.selectedDt).format('YYYY-MM-DDT23:59:59')) });
                if (onUpdateEndDate) onUpdateEndDate(dateSelected);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
