import React from 'react';
import { render, screen, fireEvent } from '../../../../../../../../newRender';
import { ChannelName } from '.';

import { __ } from '../../../../../../../../i18n';

describe('<ChannelName />', () => {
  it('should to be in the document', () => {
    const { getByPlaceholderText, getByText } = render(<ChannelName value="" error="" onChange={() => { }} />);

    const input = getByPlaceholderText(__('Ex: Secretary'));
    const label = getByText(`${__('Channel`s name')} *`);

    expect(input).toBeInTheDocument();
    expect(label).toBeInTheDocument();
  });

  it('should be able to get channel error', () => {
    render(<ChannelName error="Channel error" />);

    const error = screen.getByText('Channel error');
    expect(error).toBeInTheDocument();
  });

  it('should be able to change the input value', async () => {
    const mockOnChange = jest.fn();
    const { getByPlaceholderText } = render(
      <ChannelName value="" error="" onChange={mockOnChange} />
    );

    const input = getByPlaceholderText(__('Ex: Secretary'));

    fireEvent.change(input, { target: { value: 'New Channel' } });

    expect(mockOnChange).toHaveBeenCalled();
  });
});
