import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  width: 100%;

  span {
    font-size: 0.75rem;
    text-align: right;
    color: ${({ theme }) => theme.colors.pencil.main};
  }

  @media (max-width: 768px) {
    display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  min-width: 10rem;
  height: 7.5rem;
  font-family: Circular, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  resize: none;
  word-break: break-word !important;

  &::placeholder {
    color: ${({ theme }) => theme.colors.pencil.main};
    font-family: Circular, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 450;

    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  }

  &.channelConfig {
    border: 1px solid ${({ theme }) => theme.colors.pencil.clear} !important;

    &:disabled {
      color: ${({ theme }) => theme.colors.pencil.main} !important;
      background: transparent !important;
    }
  }

  @media (max-width: 768px) {
    height: 11rem;

    &:disabled {
      display: none;
    }
  }
`;
