import React from 'react';
import { render } from '../../../../newRender';

import { ArrivedSwitch } from '../ArrivedSwitch';

describe('<ArrivedSwitch />', () => {
  let props;

  beforeEach(() => {
    props = {
      isArrivedEnabled: true,
      onChange: jest.fn(),
      hasStudentRepliesFeature: true,
    };
  });

  it('should render the component when props is provided', () => {
    const container = render(<ArrivedSwitch {...props} />);
    expect(container).toBeDefined();
  });

  it('should render the disabled label when isArrivedEnabled is false', () => {
    props.isArrivedEnabled = false;
    const { getByText } = render(<ArrivedSwitch {...props} />);
    expect(getByText('Disabled')).toBeInTheDocument();
  });

  it('should render the margin when hasStudentRepliesFeature is true', () => {
    props.hasStudentRepliesFeature = true;
    const { getByTestId } = render(<ArrivedSwitch {...props} />);
    expect(getByTestId('arrived-switch-container')).toHaveStyle({
      marginTop: '',
    });
  });

  it('should render the margin when hasStudentRepliesFeature is false', () => {
    props.hasStudentRepliesFeature = false;
    const { getByTestId } = render(<ArrivedSwitch {...props} />);
    expect(getByTestId('arrived-switch-container')).toHaveStyle({
      marginTop: '1rem',
    });
  });
});
