import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';

import Loading from '../components/ui/Loading';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '170px',
    flex: 1,
    border: '1px solid #E8E8E8',
    borderRadius: '20px',
    backgroundColor: '#FFFFFF'
  }
};

const DashHeader = ({ title, filter, info, icon, iconColor }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
    <div className="dashboard-title-container" style={{ textAlign: 'left' }}>
      {icon && <Icon className="dashboard-title-icon" style={{ marginTop: '2px' }} data-value="error" name={icon} color={iconColor || ''} />}
      <span className="dashboard-title" style={{ fontSize: '18px', fontWeight: 'bold' }}>{title}</span>
      {info && <Popup
        trigger={<Icon className="dashboard-info" style={{ opacity: '.6', marginLeft: '5px', fontSize: 18 }} name="info circle" color="grey" />}
        content={info}
        hideOnScroll
        position="bottom right"
        basic
      />}
    </div>
    <div>
      {filter}
    </div>
  </div>
);

const DashHeaderMobile = ({ title, filter, info }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className="dashboard-title-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
      {title.length > 20 ?
        <Popup
          trigger={<span className="dashboard-title" style={{ fontSize: '18px', fontWeight: 'bold' }}>{title}</span>}
          content={title}
          hideOnScroll
          basic
        /> : <span className="dashboard-title" style={{ fontWeight: 'bold', fontSize: 18 }}>{title}</span>}

      {info && <Popup
        trigger={(
          <Icon
            className="dashboard-info"
            style={{ opacity: '.6', marginLeft: '5px', fontSize: 18 }}
            name="info circle"
            color="grey"
          />
        )}
        content={info}
        hideOnScroll
        basic
      />}
    </div>
    <div>
      {filter}
    </div>
  </div>
);

export default class DashOrganizationCard extends React.Component {
  render() {
    const { loading, key, id, isMobile } = this.props;

    if (loading) {
      return (
        <div id={id} key={key} style={styles.container}>
          <div style={{ padding: isMobile ? '25px 20px' : '30px' }}>
            {isMobile ? <DashHeaderMobile {...this.props} /> : <DashHeader {...this.props} />}
          </div>
          <div className="loading-container">
            <Loading style={{ marginTop: '0' }} width={30} height={30} />
          </div>
        </div>
      );
    }

    return (
      <div style={{ ...styles.container, padding: isMobile ? '25px 20px' : '30px' }} id={id} key={key}>
        {isMobile ? <DashHeaderMobile {...this.props} /> : <DashHeader {...this.props} />}
        <div style={isMobile ? { display: 'flex', flex: 1, justifyContent: 'center' } : {}}>
          {this.props.children}
        </div>
      </div>);
  }
}
