import React from 'react';
import { inject, observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import {
  Button,
  Modal,
  Icon,
  Image,
  Loader,
  Dropdown
} from 'semantic-ui-react';
import Cropper from 'react-cropper';

import 'cropperjs/dist/cropper.css';

import { __ } from '../i18n';
import * as utils from '../utils';

const ATTACHMENT_SIZE_MB = 25;
const BYTES_IN_ONE_MB = 1e+6;

@inject('store', 'api') @observer
export default class ImageEditor extends React.Component {
  state = {
    image: null,
    deleteDialog: false,
    loading: false
  }

  onSubmit = () => {
    const { store, submitRequest, onSubmit, submitTarget, api } = this.props;

    this.setState({ loading: true });

    const dataUrl = this.cropper.getCroppedCanvas().toDataURL();

    const block = dataUrl.split(';');
    const contentType = block[0].split(':')[1];
    const realData = block[1].split(',')[1];
    const blob = utils.b64toBlob(realData, contentType);

    api.upload(submitTarget, { file: blob, query: submitRequest })
      .then((data) => {
        store.snackbar = { active: true, message: __('Image uploaded'), success: true };
        this.setState({ loading: false });
        onSubmit(data);
      })
      .catch((err) => {
        store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
        this.setState({ loading: false });
      });
  }

  onDelete = () => {
    const { store, deleteRequest, onDelete, deleteParams } = this.props;

    this.setState({ loading: true });

    deleteRequest({
      variables: { ...deleteParams }
    }).then((data) => {
      store.snackbar = { active: true, message: __('Image deleted'), success: true };
      this.setState({ loading: false });
      onDelete(data);
    })
      .catch((err) => {
        store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
        this.setState({ loading: false });
      });
  }

  onDrop = (accepted, rejected) => {
    const { store, circular } = this.props;
    if (rejected.length > 0) {
      if (rejected[0].size > ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB) {
        store.snackbar = { active: true, message: __('File too big, maximum size is %s MB.', ATTACHMENT_SIZE_MB), success: false };
      } else {
        store.snackbar = { active: true, message: __('File format not accepted, please provide an image.'), success: false };
      }
    }

    this.setState({ image: accepted[0] });
  }

  renderImage = () => {
    const { image, loading } = this.state;
    const { circular } = this.props;
    const height = this.props.height || 400;
    const width = this.props.width || 400;
    const params = {};

    if (circular) {
      params.className = 'clsp-circular';
    }

    return (<Cropper
      ref={c => this.cropper = c}
      src={image && image.preview}
      aspectRatio={width / height}
      guides={false}
      style={{ maxHeight: 400 }}
      {...params}
    />);
  }

  render() {
    const { header, backAndRefresh, hasPicture, dimensions } = this.props;
    const height = this.props.height || 400;
    const width = this.props.width || 400;

    return (
      <div>
        <Modal id={this.props.id} size="small" open={!this.state.deleteDialog}>
          <Modal.Header>{header}</Modal.Header>
          <Modal.Content>
            <div>
              {
                !this.state.image ?
                  <Dropzone
                    ref={(node) => { this.dropzone = node; }}
                    onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                    multiple={false}
                    accept="image/jpeg,image/png"
                    maxSize={ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB}
                    className="ui button fluid large dropzone"
                    style={{ borderStyle: 'dashed', borderWidth: 1 }}
                  >
                    <Icon name="arrow down" size="large" circular />
                    <div>{__('Drop file here or click to browse')}</div>
                  </Dropzone>
                  :
                  [
                    <p>{__('Drag to reposition')}</p>,
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      {this.renderImage()}
                    </div>
                  ]
              }
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              data-action="cancel"
              basic
              floated="left"
              content={__('Cancel')}
              onClick={backAndRefresh}
              disabled={this.state.loading}
            />
            {
              hasPicture !== null && !this.state.image &&
              <Button
                data-action="delete"
                color="red"
                basic
                onClick={() => this.setState({ deleteDialog: true })}
              >
                {__('Remove Photo')}
              </Button>
            }
            <Button
              data-action="submit"
              primary
              content={__('Save Changes')}
              onClick={this.onSubmit}
              loading={this.state.loading}
              disabled={!this.state.image || this.state.loading}
            />
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.deleteDialog} >
          <Modal.Header>{__('Delete Image')}</Modal.Header>
          <Modal.Content>
            <p>{__('Are you sure you want to delete?')}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              data-action="cancel"
              floated="left"
              onClick={() => this.setState({ deleteDialog: false })}
              content={__('Cancel')}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <Button
              data-action="delete"
              onClick={this.onDelete}
              color="red"
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              <Icon name="trash outline" />
              {__('Delete')}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
