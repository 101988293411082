import React from 'react';
import { ResponsiveMessage } from '.';
import { render } from '../../../../../../../newRender';

describe('given ResponsiveMessage component', () => {
  const mockedProps = {
    columns: [
      {
        id: '1',
        content: <div>Test content</div>,
      },
    ],
  };

  it('should be able to render responsive message without props', () => {
    const { getByTestId } = render(<ResponsiveMessage />);

    const container = getByTestId('RESPONSIVE_MESSAGE_CONTAINER');

    expect(container).toBeTruthy();
  });

  it('should be able to find some content column', () => {
    const { getByText } = render(<ResponsiveMessage {...mockedProps} />);

    const content = getByText('Test content');

    expect(content).toBeTruthy();
  });
});
