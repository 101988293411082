import React from 'react';
import {
  Icon
} from 'semantic-ui-react';
import { __ } from '../i18n';

export default class EntityInformationText extends React.Component{
  constructor(props){
    super(props);
  }
  render() {
    const { entity, isChannel } = this.props;

    const canSeeAllUsers = isChannel ? !!(entity?.visibility === 'public') : !!(entity?.seeAll);
    const hasOneGroup = isChannel ? !!(entity?.selectedGroups?.length) : !!(entity?.groups?.length);

    const infoText = canSeeAllUsers ? __('This employee has access to all users in the institution and can approve all messages') :
      hasOneGroup ? __('This employee can only approve messages sent to users within the Groups he/she belongs to') :
      __('Allow the employee to communicate with other users or add him to Groups so that he can approve messages'); 

    if (!entity) return <div></div>
    return (
      <div id='entity-information'>
        <div id='icon-container' className='entity-information-icon'>
          <Icon
            name='exclamation circle'
            size='large'
            className='icon'
          />
        </div>
        <p className='info-text'>{infoText}</p>
      </div>
    );
  };
};