import React from 'react';
import { __ } from '../../../../i18n';
import { Loading } from './Loading';

/**
 * @typedef {{
 *  statusSend: string,
 *  hasCommunicationStatusFeedback: boolean
 * }} Props
 * @param {Props} props
 * @returns {React.JSX.Element}
 */

export function LoaderMessage({ statusSend, hasCommunicationStatusFeedback }) {
  if (!statusSend || !hasCommunicationStatusFeedback || statusSend === 'DONE') {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: (255, 255, 255, 0.7),
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
      }}
    >
      <Loading />
      <h3
        style={{
          color: '#999999',
          fontSize: '12px',
          marginLeft: '5px',
          fontWeight: 'normal',
          marginTop: '2px',
        }}
      >
        {__('Sending')}
      </h3>
    </div>
  );
}
