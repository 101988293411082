import styled from 'styled-components';
import { tokens } from '@classapp-tech/edna-styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastContainer } from 'react-toastify';

export const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast-theme--light.Toastify__toast--info {
    background-color: #FAFAFA;
  }
  .Toastify__toast-theme--light.Toastify__toast--success {
    background-color: ${tokens.colors.approved.clear};
  }
  .Toastify__toast-theme--light.Toastify__toast--warning {
    background-color: #FFF9E3;
  }
  .Toastify__toast-theme--light.Toastify__toast--error {
    background-color: ${tokens.colors.warning.clear};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar-theme.Toastify__progress-bar--info {
    background-color: ${tokens.colors.pencil.bold};
  }
  .Toastify__progress-bar-theme.Toastify__progress-bar--success {
    background-color: ${tokens.colors.approved.main};
  }
  .Toastify__progress-bar-theme.Toastify__progress-bar--warning {
    background-color: #FBB13C;
  }
  .Toastify__progress-bar-theme.Toastify__progress-bar--error {
    background-color: ${tokens.colors.warning.main};
  }
  .Toastify__toast-body > div:last-child {
    color: ${tokens.colors.blackboard.bold};
  }
`;
// ${({ type }) => getSnackbarColor(type)};
