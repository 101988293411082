import React from 'react';
import {
  render, screen, fireEvent, act
} from '@testing-library/react';
import { SearchInput } from './index';

const onChange = jest.fn();
jest.useFakeTimers();

describe('SearchInput', () => {
  it('should render Search Input', () => {
    const { baseElement } = render(<SearchInput onChangeValue={onChange} />);
    expect(baseElement).toBeInTheDocument();
  });

  it('updates search value when input changes', () => {
    render(<SearchInput onChangeValue={onChange} />);
    const inputElement = screen.getByTestId('search-input');
    fireEvent.change(inputElement, { target: { value: 'Test Input' } });
    expect(inputElement).toHaveValue('Test Input');
  });

  it('triggers onBlur event and removes outline style', () => {
    render(<SearchInput onChangeValue={onChange} />);
    const containerElement = screen.getByTestId('search-container');
    fireEvent.focus(containerElement);
    expect(containerElement).toHaveStyle('outline: 1px solid #000;');

    fireEvent.blur(containerElement);
    expect(containerElement).not.toHaveAttribute('style');
  });

  it('calls onChangeValue with the correct input value after debounce time', () => {
    render(<SearchInput onChangeValue={onChange} debounceTime={500} />);
    const inputElement = screen.getByTestId('search-input');
    act(() => {
      fireEvent.change(inputElement, { target: { value: 'Test Input' } });
    });

    act(() => {
      jest.advanceTimersByTime(500);
    });

    expect(onChange).toHaveBeenCalledWith('Test Input');
  });
});
