import React from 'react';
import { Label } from 'semantic-ui-react';

import { __ } from '../i18n';
import * as utils from '../utils';

/**
 * @typedef {import('../containers/Entity/Messages/MultipleAutosuggest').EntityItem} EntityItem
 * @typedef {EntityItem['value']} Value
 */

/**
 * @typedef {{
 *  values: Value[],
 *  style: import('../containers/Entity/Messages/MultipleAutosuggest').Props['style'][],
 *  apiExtract: import('../containers/Entity/Messages/MultipleAutosuggest').Props['apiExtract'][],
 *  selected: import('../containers/Entity/Messages/MultipleAutosuggest').State['selectedItems'][],
 *  onValueChange: (arg: import('../containers/Entity/Messages/MultipleAutosuggest').State['tempSelectedItems']) => void,
 * }} Props
 * @extends {React.Component<Props>}
 */
export default class MultipleAutosuggestForm extends React.Component {
  /**
   * @param {Props} props
   */
  constructor(props) {
    super(props);
    this.state = {
      tempValues: [...props.values],
    };
  }

  render() {
    const {
      style, apiExtract, selected, onValueChange, values,
    } = this.props;
    const { tempValues } = this.state;
    return (
      <div style={{ minHeight: '7rem' }}>
        <div className="custom-label" style={{ minHeight: tempValues.length > 0 ? 'unset' : '7rem' }}>
          {tempValues.length > 0
            ? tempValues.map((val) => {
              const label = selected[0].find((l) => l.value === val) || {
                value: '', text: '', 'data-id': 0, icon: ''
              };
              // @ts-ignore
              const [id, key] = label.value.split(' ').map(/** @param {Value} v */(v) => parseInt(v, 10));

              return (
                <Label
                  key={id}
                  color={style[key].color}
                  content={utils.renderLongText(`${label.text}`, 14)}
                  image={style[key].image && {
                    avatar: true,
                    size: 'medium',
                    // @ts-ignore
                    src: label.image.src
                      // @ts-ignore
                      || utils.asset(`/avatars/${utils.normalize(label[apiExtract[key].name][0].toLowerCase())}.svg`, '')
                  }}
                  icon={!style[key].image && { name: style[key].icon, size: 'large' }}
                  onRemove={() => {
                    this.setState({
                      tempValues: tempValues.filter((item) => parseInt(item.replace(/ .$/, ''), 10) !== id),
                    });
                    onValueChange(tempValues.filter((item) => parseInt(item.replace(/ .$/, ''), 10) !== id));
                  }}
                />
              );
            })
            : [
              <span key={0}>{__('No person selected.') + ' '}</span>,
              // eslint-disable-next-line react/button-has-type
              <button
                key={1}
                onClick={() => {
                  this.setState({ tempValues: [...values] });
                  onValueChange([...values]);
                }}
              >
                {__('Undo')}
              </button>
            ]}
        </div>
      </div>
    );
  }
}
