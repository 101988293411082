import getButtonPadding from '../getButtonPadding';

describe('getButtonPadding function', () => {
  it('should return padding for medium size by default', () => {
    const spacings = {
      micro: 0.5,
      xSmall: 1,
      small: 1.5,
      medium: 2,
    };

    const result = getButtonPadding(spacings);

    expect(result).toBe('1.5rem 2rem');
  });

  it('should return padding for small size', () => {
    const spacings = {
      micro: 0.5,
      xSmall: 1,
      small: 1.5,
      medium: 2,
    };

    const result = getButtonPadding(spacings, 'small');

    expect(result).toBe('0.5rem 1rem');
  });

  // Add more test cases as needed
});
