import React from 'react';

import { DirectNotifications } from '.';
import { render } from '../../../../../../../newRender';
import { directNotificationsMock } from './__mocks__/direct-notifications.mock';

describe('given DirectNotifications component', () => {
  const mockedProps = {
    notifications: directNotificationsMock,
    pagination: {
      graphql: false,
      hasNextPage: false,
      loading: false,
    },
    onLoadMore: jest.fn(),
    onClose: jest.fn(),
    isLoading: false,
  };

  it('should be able to see the list', async () => {
    const { findByTestId } = render(<DirectNotifications {...mockedProps} />);

    const list = await findByTestId('list');

    expect(list).toBeVisible();
  });

  it('should be able to see the loading state', async () => {
    const { findByTestId } = render(
      <DirectNotifications {...mockedProps} isLoading />,
    );

    const loading = await findByTestId('loading-ui');

    expect(loading).toBeVisible();
  });
});
