import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Button, Image, Header } from 'semantic-ui-react';
import cookie from 'react-cookie';

import Modal from './Modal';

import * as utils from '../utils';
import { __ } from '../i18n';

const announcements = [
  {
    announcement: 'hasSeenMomentsAnnouncement',
    privacy: ['ADMIN', 'STAFF'],
    content: <div>
      <Image src={utils.asset('/images/ilustracoes-40.png')} style={{ maxHeight: 375 }} centered />
      <Header as="h2" icon style={{ letterSpacing: '-0.2px' }}>
        {__('Compartilhe momentos incríveis da sua instituição de forma fácil e divertida!')}
        <Header.Subheader style={{ marginTop: 16 }}>
          {__('Já disponível para versão Web e App (iOS/Android).')}
        </Header.Subheader>
      </Header>
    </div>
  }
];

@inject('store') @observer
export default class Announcements extends React.Component {
  state = {
    position: 0
  }

  closeAnnouncements = (newAnnouncements) => {
    const { store } = this.props;

    const announcements = store.announcements;

    newAnnouncements.forEach((announcement) => {
      cookie.save(announcement.announcement, 'yes', { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 30 });
      announcements.push(announcement.announcement);
    });

    store.announcements = announcements;
  };

  render() {
    if (!announcements.length) return null;

    const { store } = this.props;
    const { position } = this.state;

    const userEntityTypes = [];

    store.currentUser.entities.forEach((entity) => {
      if (userEntityTypes.indexOf(entity.type) === -1) userEntityTypes.push(entity.type);
    });

    const newAnnouncements = announcements
      .filter(announcement => store.announcements.indexOf(announcement.announcement) === -1 && utils.intersection(announcement.privacy, userEntityTypes).length);

    if (!newAnnouncements.length) return null;

    return (<Modal
      closeIcon={newAnnouncements.length > 1 && 'times close'}
      onClose={() => this.closeAnnouncements(newAnnouncements)}
      closeOnRootNodeClick={false}
    >
      <Modal.Content style={{ backgroundColor: '#fff', padding: 40 }}>
        <Container textAlign="center">
          {newAnnouncements[position].content}
          <div>
            {newAnnouncements.length > 1 && <Button
              data-action="prev"
              onClick={() => this.setState({ position: position - 1 })}
              icon="angle left"
              disabled={!position}
              size="big"
              circular
            />}
            <Button
              data-action="next"
              onClick={position === newAnnouncements.length - 1 ? () => this.closeAnnouncements(newAnnouncements) : () => this.setState({ position: position + 1 })}
              icon={position === newAnnouncements.length - 1 ? 'check' : 'angle right'}
              size="big"
              circular
              primary
            />
          </div>
        </Container>
      </Modal.Content>
    </Modal>);
  }
}
