import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { __ } from '../i18n';
import * as utils from '../utils';
import Modal from '../components/Modal';

const styles = {
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '30px'
  },
  confirmButton: {
    backgroundColor: '#0069FF',
    height: '43px',
    color: '#FFF',
    borderRadius: '20px'
  },
  cancelButton: {
    height: '43px',
    borderRadius: '20px'
  },
  content: {
    textAlign: 'left'
  }
};

@inject('store')
@observer
export default class KnowMoreModal extends React.Component {
  render() {
    const { store, isBanner } = this.props;

    return (
      <Modal
        closeIcon={'times close inside'}
        header={__('Message limit reached')}
        onClose={() => this.props.store.appends.pop()}
        content={
          <div>
            <span style={styles.content}>
              {__('The message limit has been reached and you will not be able to send this message now, but it can be saved as a draft to be sent later on the future.')}
              {' '}
              {utils.isEntityType(store, 'STAFF') ?
                __('Contact the admin for more information.') :
                __('Consult your dashboard')
              }
            </span>
            <div style={styles.footer}>
              <Button
                basic
                style={styles.cancelButton}
                content={__('Back')}
                onClick={() => this.props.store.appends.pop()}
              />
            </div>
          </div>}
      />
    );
  }
}
