import { themes } from '@classapp-tech/edna-styles';
import * as StyledComponents from 'styled-components';

import { act, renderHook } from '@testing-library/react-hooks/dom';
import { useRadioButton } from '../useRadioButton';

jest.useFakeTimers();

describe('given useRadioButton hook', () => {
  beforeEach(() => {
    jest.spyOn(StyledComponents, 'useTheme').mockReturnValue(themes.classapp.classappLight);
  });

  const mocks = {
    selected: false,
    theme: themes.classapp.classappLight,
    onChange: jest.fn((e) => e),
  };

  it('should be able to active the checkbox', () => {
    const { result } = renderHook(() => useRadioButton(mocks));

    act(() => {
      result.current.handleClick();
    });

    expect(result.current.isActive).toBeTruthy();
  });

  it('should be able to deactive the checkbox', () => {
    const { result } = renderHook(() => useRadioButton(mocks));

    act(() => {
      result.current.handleClick();
    });

    act(() => {
      result.current.handleClick();
    });

    expect(result.current.isActive).toBeTruthy();
  });

  it('should be able to call onChange', () => {
    const { result } = renderHook(() => useRadioButton(mocks));

    act(() => {
      result.current.handleClick();
    });

    expect(mocks.onChange).toHaveBeenCalled();
  });

  it('should be able to call onChange with false', () => {
    const { result } = renderHook(() => useRadioButton(mocks));

    act(() => {
      result.current.handleClick();
    });

    expect(mocks.onChange).toHaveBeenCalledWith(false);
  });

  it('should be able to change the active using toggle', () => {
    const { result } = renderHook(() => useRadioButton(mocks));

    act(() => {
      result.current.handleToogle();
    });

    expect(result.current.isActive).toBeTruthy();
  });
});
