import { useCallback, useEffect, useState } from 'react';

export function useRadioList(props) {
  const { onSelect, selectedFilter = '' } = props;
  const [selectedOption, setSelectedOption] = useState(undefined);

  useEffect(() => {
    setSelectedOption(selectedFilter);
  }, [JSON.stringify(selectedFilter)]);

  const handleSelectOption = useCallback((value, id) => {
    setSelectedOption(value);

    return onSelect && onSelect(value, id);
  }, [selectedOption, onSelect, selectedFilter]);

  const verifySelectedOption = useCallback(
    (value) => selectedOption === value,
    [selectedOption],
  );

  return {
    handleSelectOption,
    selectedOption,
    verifySelectedOption,
  };
}
