import React from 'react';
import { Label, Popup } from 'semantic-ui-react';

/**
 * @param {{
 *  user: {
 *   fullname: string;
 *  },
 * tags: {
 *   nodes: {
 *    id: number | string;
 *    name: string;
 *   }[];
 * }
 * }} options
 */
export function UserNameHeader({ tags, user }) {
  const fullname = user && user?.fullname;
  const hasTags = tags && tags?.nodes && tags?.nodes?.length > 0;

  if (!fullname) {
    return null;
  }

  if (hasTags) {
    return (
      <Popup
        data-testid="header-popup-container"
        trigger={
          <Label
            basic
            size="tiny"
            className="uppercase"
            content={fullname}
            style={{ alignSelf: 'center', marginLeft: '8px', fontSize: '10px' }}
          />
        }
        content={tags.nodes.map((item) => (
          <div key={item.id} style={{ display: 'block' }}>
            <span
              key={item.name}
              id={item.id}
              style={{ fontSize: '12px', fontWeight: 'medium' }}
            >
              {item.name}
            </span>
          </div>
        ))}
      />
    );
  }

  return (
    <Label
      basic
      size="tiny"
      className="uppercase"
      content={fullname}
      style={{ alignSelf: 'center', marginLeft: '8px', fontSize: '10px' }}
    />
  );
}
