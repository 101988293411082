import React from 'react';
import { Icon, Popup, Button, Form } from 'semantic-ui-react';

import Responsive from './Responsive';

const styles = {
  action: {
    padding: '.78571429em .78571429em .78571429em',
    border: '1px solid #e0b4b4',
    borderLeft: '0px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: '#fff7f6'
  }
};
export default class TextInput extends Responsive {
  render() {
    const newProps = { ...this.props };
    let errorText = null;

    if (newProps.error && this.isMobile()) {
      newProps.error = true;
      newProps.action = this.props.action ? (<span style={{ ...styles.action, padding: '' }}><Icon name="exclamation triangle" color="red" />{this.props.action}</span>) : <span style={styles.action}><Icon name="exclamation triangle" color="red" /></span>;
      errorText = <div className="clsp-errorText">{this.props.error}</div>;
    } else if (newProps.error) {
      const action = (<Popup
        trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
        content={this.props.error}
      />);
      newProps.error = true;
      newProps.action = this.props.action ? (<div>
        {this.props.action}
        {action}
      </div>) : action;
    }

    return (<Form.Field className={newProps.error ? 'clsp-error' : null} style={{ display: 'flex', flexFlow: 'column' }}>
      <Form.Input {...newProps} />
      {errorText}
    </Form.Field>);
  }
}
