import React from 'react';
import { fireEvent, render } from '../../../../newRender';

import { FormContent } from '../FormContent';

describe('<FormContent />', () => {
  let props;

  beforeEach(() => {
    props = {
      values: {
        name: 'tag',
        checked: false,
        isNetworkTag: false,
      },
      errors: {
        name: '',
      },
      edit: false,
      isNetwork: false,
      onInputChange: jest.fn(),
    };
  });

  it('should render the component when props is provided', () => {
    const container = render(<FormContent {...props} />);
    expect(container).toBeDefined();
  });

  it('should call onInputChange when Initially checkbox is clicked', () => {
    const { getByText } = render(<FormContent {...props} />);
    fireEvent.click(getByText('Initially checked'));
    expect(props.onInputChange).toHaveBeenCalled();
  });

  it('should call onInputChange when Network checkbox is clicked', () => {
    props.isNetwork = true;
    const { getByText } = render(<FormContent {...props} />);
    fireEvent.click(getByText('I want it to reflect on the units'));
    expect(props.onInputChange).toHaveBeenCalled();
  });
});
