import React from 'react';
import { render, screen, fireEvent } from '../../../../../../../../newRender';
import { ExternalID } from '.';

import { __ } from '../../../../../../../../i18n';

describe('<ExternalID />', () => {
  it('should to be in the document', () => {
    const { getByPlaceholderText, getByText } = render(<ExternalID value="" error="" onChange={() => { }} />);

    const input = getByPlaceholderText(__('Optional'));
    const label = getByText(__('External ID'));

    expect(input).toBeInTheDocument();
    expect(label).toBeInTheDocument();
  });

  it('should be able to get channel error', () => {
    render(<ExternalID value="" error="Channel description error" onChange={() => { }} />);

    const error = screen.getByText('Channel description error');
    expect(error).toBeInTheDocument();
  });

  it('should be able to change the input value', async () => {
    const mockOnChange = jest.fn();
    const { getByPlaceholderText } = render(
      <ExternalID value="" error="" onChange={mockOnChange} />
    );

    const input = getByPlaceholderText(__('Optional'));

    fireEvent.change(input, { target: { value: 'New Channel description' } });

    expect(mockOnChange).toHaveBeenCalled();
  });
});
