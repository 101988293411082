import React, { useEffect, useState } from 'react';

/**
 * This hook is used to manage the tabs
 * @param index It's the active tab index
 * @param onTabChange It's the callback executed when the tab is clicked
 * @returns It returns the active tab and the function to change the active tab
 */

export function useTabs(props) {
  const [activeTab, setActiveTab] = useState(props.value ?? '');

  const changeTab = (value) => {
    setActiveTab(value);
    if (props.onTabChange) props.onTabChange(value);
  };

  useEffect(() => {
    if (!props.value) {
      const firstTab = props.childrenToArray.find((child) =>
        React.isValidElement(child),
      );

      if (firstTab) {
        const firstTabValue = React.isValidElement(firstTab)
          ? firstTab.props?.value
          : null;
        changeTab(firstTabValue);
      }
    }
  }, [props.value]);

  return {
    activeTab,
    changeTab,
  };
}
