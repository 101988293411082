import React from 'react';
import { fireEvent, render, waitFor } from '../../../../newRender';

import { MarkersDropdown } from '.';

describe('MarkersDropdown', () => {
  let props;

  beforeEach(() => {
    props = {
      value: [],
      onChange: jest.fn(),
      onLoadMore: jest.fn(),
      options: [
        {
          text: '9º ano',
          value: 1,
        },
        {
          text: '8º ano',
          value: 2,
        },
      ],
      loading: false,
    };
  });

  it('should render when props is provided', () => {
    const wrapper = render(<MarkersDropdown {...props} />);
    expect(wrapper).toBeDefined();
  });

  it('should render info icon', () => {
    const { getByTestId, getByText } = render(<MarkersDropdown {...props} />);
    const icon = getByTestId('info-icon');

    fireEvent.mouseOver(icon);

    waitFor(() =>
      expect(
        getByText(
          /Markers make it possible to organize and categorize groups to facilitate sending communications/,
        ),
      ).toBeInTheDocument(),
    );
  });

  it('should call onLoadMore function when scroll dropdown', async () => {
    const { getByTestId } = render(
      <MarkersDropdown data-testid="markers-dropdown-container" {...props} />,
    );
    const dropdown = getByTestId('markers-dropdown-container');

    await fireEvent.click(dropdown);

    await fireEvent.scroll(dropdown, { target: { scrollY: 100 } });

    waitFor(() => expect(props.onLoadMore).toBeCalledTimes(1));
  });
});
