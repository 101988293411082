import React from 'react';
import {
  Container,
} from './RadioList.styles';
import { RadioListItem } from './components/RadioListItem';
import { useRadioList } from './hooks/useRadioList';

/**
 * This is the RadioList component, the radio list can be used to do a single
 * selection of options.
 *
 * @example
 * <RadioList
 *   options={[
 *     { label: { text: 'Option 1'}, value: '1' },
 *     { label: {text: 'Option 2'}, value: '2' },
 *     { label: {text: 'Option 3'}, value: '3' },
 *   ]}
 *   onSelect={(option) => console.log(option)}
 * />
 *
 */
export function RadioList(props) {
  const {
    options,
    onSelect,
    selectedFilter,
    ContainerProps,
  } = props;

  const {
    handleSelectOption,
    verifySelectedOption,
  } = useRadioList({
    onSelect,
    selectedFilter,
  });

  return (
    <Container
      {...ContainerProps}
    >
      {options.map((item) => (
        <RadioListItem
          key={item.value}
          id={item.id}
          option={item}
          onSelect={handleSelectOption}
          isSelected={verifySelectedOption(item.value)}
        />
      ))}
    </Container>
  );
}
