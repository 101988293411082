import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as zod from 'zod';

import { ChannelName } from './components/ChannelName';
import { ExternalID } from './components/ExternalID';
import { Description } from './components/Description';

import { PictureInput } from './components/PictureInput';
import { SelectHours } from './components/SelectHours';
import { SelectResponsible } from './components/SelectResponsible';
import { SelectVisibility } from './components/SelectVisibility';
import { ManagePermissions } from './components/ManagePermissions';
import { ChannelType } from './components/ChannelType';
import { Status } from './components/Status';

import Loading from '../../../../../../components/ui/Loading';

import { ViewModel as useViewModel } from '../../Add/ViewModel';

import { validateRangeTime } from './helpers/validateRangeTime';
import { validateVisibility } from './helpers/validateVisibility';
import { validateSelectedUsers } from './helpers/validateSelectedUsers';

import { __ } from '../../../../../../i18n';

import {
  Subtitle,
  Container,
  SubtitleContainer,
  FirstRow,
  Row,
  AdvancedLabelContainer,
  AdvancedLabelText,
} from './Form.styles';
import { AutomaticMessages } from '../AutomaticMessages';
import { canSeeAutomaticMessagesUseCase } from '../../../../../Domain/UseCases/AutomaticMessage/CanSeeAutomaticMessagesUseCase';
import { getDefaultAutomaticMessages } from '../../../../../helpers/getDefaultAutomaticMessages';

const schema = zod.object({
  fullname: zod
    .string()
    .max(255, { message: __('Name must contain at most 255 characters') })
    .min(1, { message: __('Enter the channel name') }),
  externalId: zod
    .string()
    .max(255, {
      message: __('External ID must contain at most 255 characters'),
    })
    .optional(),
  description: zod
    .string()
    .max(500, {
      message: __('Description must contain at most 500 characters'),
    })
    .optional(),
  picture: zod.any().optional(),
  type: zod.string().optional(),
  status: zod.string().optional(),
  hours: zod.custom(validateRangeTime, __('Select a valid time range')),
  selectedUsers: zod.custom(
    validateSelectedUsers,
    __('Select at least one user'),
  ),
  visibility: zod.custom(
    validateVisibility,
    __('Custom visibility requires at least one group'),
  ),
  permissions: zod.object({
    scope: zod.any(),
    policy: zod.any(),
  }),
});

function FormComponent(
  {
    currentEntityId,
    isLoading,
    isDisabled,
    hasMessageApprove,
    hasMenuConversations,
    entity,
    onSubmit,
    canAddPicture,
    isEditable,
    footer,
    defaultValues,
  },
  ref,
) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      fullname: '',
      externalId: '',
      description: '',
      picture: null,
      type: 'WITH_STATUS',
      status: 'ENABLED',
      hours: {
        open: '',
        close: '',
      },
      selectedUsers: [],
      visibility: {
        status: 'public',
        selectedGroups: [],
      },
      permissions: {
        scope: {
          messages: {
            read: false,
            delete: false,
          },
          reports: {
            read: false,
            createUpdate: false,
            delete: false,
          },
          entities: {
            read: false,
            createUpdate: false,
            delete: false,
            contentApprover: false,
          },
          dashboard: {
            read: false,
          },
          integration: {
            read: false,
          },
          payments: {
            read: false,
          },
          accounts: {
            read: false,
            createUpdate: false,
            delete: false,
          },
          organization: {
            update: false,
          },
          forms: {
            read: false,
            createUpdate: false,
            delete: false,
          },
          links: {
            read: false,
            createUpdate: false,
            delete: false,
          },
        },
        policy: [],
      },
    },
  });

  const { convertImageToBlob } = useViewModel();
  const canSeeAutomaticMessages = canSeeAutomaticMessagesUseCase();
  const entityAutomaticMessages = entity?.automaticMessages?.nodes;
  const [defaultAutomaticMessages, setDefaultAutomaticMessages] = useState(
    getDefaultAutomaticMessages(entityAutomaticMessages),
  );
  const automaticMessagesRef = useRef(null);
  const attendanceEnabled = watch('type') === 'WITH_STATUS';

  useImperativeHandle(ref, () => ({
    getAutomaticMessages: () => automaticMessagesRef.current?.getMessages(),
  }));

  const updateLocalAutomaticMessages = () => {
    setDefaultAutomaticMessages((state) => {
      const messages = automaticMessagesRef.current?.getMessages();

      for (const [index, message] of state.entries()) {
        if (messages[message.type]) {
          state[index].enabled = messages[message.type].enabled;
          state[index].message = messages[message.type].message;
        }
      }

      return state;
    });
  };

  const onSetDefaultPicture = useCallback(async (__pictureName) => {
    const blob = await convertImageToBlob(__pictureName);
    setValue('picture', blob);
  });

  useEffect(() => {
    if (!isLoading && defaultValues) {
      reset(defaultValues);
    }
  }, [isLoading, defaultValues]);

  useEffect(() => {
    if (entity?.automaticMessages?.nodes.length) {
      setDefaultAutomaticMessages(
        getDefaultAutomaticMessages(entityAutomaticMessages),
      );
    }
  }, [entity?.automaticMessages]);

  if (isLoading) {
    return (
      <form id="ChannelForm">
        <Loading data-testid="loading-component" width={35} height={35} />
      </form>
    );
  }

  return (
    <form
      data-testid="ChannelForm"
      id="ChannelForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Container>
        <SubtitleContainer>
          <Subtitle>{__('Details')}</Subtitle>
        </SubtitleContainer>
        <FirstRow>
          <ChannelName
            error={errors?.fullname?.message}
            {...register('fullname')}
          />
          <ExternalID
            error={errors?.externalId?.message}
            inputStyle={{ width: '100%' }}
            style={{ width: '50%' }}
            {...register('externalId')}
          />
        </FirstRow>
        <Controller
          name="description"
          control={control}
          render={({ field: { value } }) => (
            <Description
              label={__('Description')}
              placeholder={__('Write a description, it`s optional')}
              length={watch('description')?.length}
              value={value}
              onChange={(__value) => setValue('description', __value)}
            />
          )}
        />
        <Controller
          name="picture"
          control={control}
          render={({ field: { value } }) => (
            <PictureInput
              value={value}
              fullname={watch('fullname')}
              onSubmit={(__picture) => setValue('picture', __picture)}
              onDelete={() => setValue('picture', null)}
              onSetDefault={(__picture) => onSetDefaultPicture(__picture)}
              canAddPicture={canAddPicture}
            />
          )}
        />
        <Row>
          <Controller
            name="hours"
            control={control}
            render={({ field: { value } }) => (
              <SelectHours
                data-testid="hours-controller"
                value={value}
                error={errors?.hours?.message}
                onTimeChange={(time) => setValue('hours', time)}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="selectedUsers"
            data-testId="selected-users-controller"
            control={control}
            render={({ field: { value } }) => (
              <SelectResponsible
                selectedUsers={value}
                currentEntityId={currentEntityId}
                error={errors?.selectedUsers?.message}
                onSubmit={(__selectedUsers) =>
                  setValue('selectedUsers', __selectedUsers)
                }
                onDelete={(__selectedUsers) =>
                  setValue('selectedUsers', __selectedUsers)
                }
              />
            )}
          />
        </Row>
        <AdvancedLabelContainer>
          <AdvancedLabelText>{__('Advanced settings')}</AdvancedLabelText>
        </AdvancedLabelContainer>

        {isEditable && (
          <Controller
            name="status"
            control={control}
            render={({ field: { value } }) => (
              <Status
                value={value}
                onChange={(__value) => {
                  setValue('status', __value);
                }}
              />
            )}
          />
        )}
        {hasMenuConversations && (
          <Row>
            <Controller
              name="type"
              control={control}
              render={({ field: { value } }) => (
                <ChannelType
                  value={value}
                  onChange={(__type) => {
                    canSeeAutomaticMessages && updateLocalAutomaticMessages();
                    setValue('type', __type);
                  }}
                />
              )}
            />
          </Row>
        )}
        <Row>
          <Controller
            name="visibility"
            control={control}
            render={({ field: { value } }) => (
              <SelectVisibility
                selectedGroups={value?.selectedGroups}
                visibility={value?.status}
                error={errors?.visibility?.message}
                disabled={isDisabled}
                onChange={(_visibility) =>
                  setValue('visibility', { ...value, status: _visibility })
                }
                onSubmit={(_selectedGroups) =>
                  setValue('visibility', {
                    ...value,
                    selectedGroups: _selectedGroups,
                  })
                }
                onDelete={(_selectedGroups) =>
                  setValue('visibility', {
                    ...value,
                    selectedGroups: _selectedGroups,
                  })
                }
              />
            )}
          />
        </Row>
        {canSeeAutomaticMessages && hasMenuConversations && (
          <Row>
            <AutomaticMessages
              ref={automaticMessagesRef}
              messages={defaultAutomaticMessages}
              attendanceEnabled={attendanceEnabled}
              onResponsivityChange={updateLocalAutomaticMessages}
            />
          </Row>
        )}
        <Row>
          <Controller
            name="permissions"
            control={control}
            render={({ field: { value } }) => (
              <ManagePermissions
                scope={value?.scope}
                policy={value?.policy}
                error={errors?.permissions?.message}
                entity={entity}
                onSubmit={(_scope, _policy) => {
                  setValue('permissions', { scope: _scope, policy: _policy });
                }}
                hasMessageApprove={hasMessageApprove}
                hasMenuConversations={hasMenuConversations}
              />
            )}
          />
        </Row>
      </Container>
      {footer}
    </form>
  );
}

export const Form = forwardRef(FormComponent);
