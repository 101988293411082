/* eslint-disable no-shadow */
/* eslint-disable no-useless-constructor */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import BetaModal from './ui/Modal';
import BetaButton from './ui/Button';
import { inject } from 'mobx-react';
import { cloneDeep } from 'lodash';
import { __ } from '../i18n';
import EntityInformationText from './EntityInformationText';
import RadioButtonList from './RadioButtonList';
import '../assets/css/ui/ApprovalPermissionsModal.module.scss';

/**
* @typedef {{
*  onClose: () => void,
*  onCancel: () => void,
*  onConfirm: () => void,
*  cancelText?: string,
*  confirmText?: string,
*  header?: string,
*  style?: React.CSSProperties,
*  listTitle?: string,
*  scope: import('../utils').ScopeForm,
*  policy: "NEED_APPROVAL_MESSAGE" | "NEED_APPROVAL_REPLY"[],
*  radioList: {
*    value: string,
*    label: string,
*  },
*  entity?: {
*   id: number,
*   fullname: string,
*   type: STUDENT | STAFF | ADMIN,
*   eid: string,
*   seeAll: boolean,
*   invisible: boolean,
*   disabled: boolean,
*   pictued: string,
*   groups: {
*     name: string,
*     id: number,
*   }[],
*   addresses: {
*     address: string,
*     type: PHONE | EMAIL,
*     tags: {
*       id: number,
*       name: string,
*     }[]
*     user: {id: number},
*   }[]
*   scope: import('../utils').ScopeForm,
*   policy: "NEED_APPROVAL_MESSAGE" | "NEED_APPROVAL_REPLY"[]
*}
* }} Props
* @extends {React.Component<Props>}
*/

export class ApprovalPermissionsModal extends React.Component {
  constructor(props) {
    super(props);
    const scope = (props && props.scope) || {
      messages: {
        read: false,
        delete: false
      },
      reports: {
        read: false,
        createUpdate: false,
        delete: false
      },
      entities: {
        read: false,
        createUpdate: false,
        delete: false,
        contentApprover: false,
      },
      dashboard: {
        read: false
      },
      integration: {
        read: false
      },
      payments: {
        read: false
      },
      accounts: {
        read: false,
        createUpdate: false,
        delete: false
      },
      organization: {
        update: false
      },
      forms: {
        read: false,
        createUpdate: false,
        delete: false
      },
    };
    const policy = (props && props.policy) || []

    this.state = {
      policy,
      scope,
      showEntityInformationText: this.getDefaultCheck(props.entity && props.entity.scope, props.policy) === 'approveAndReprove',
      selected: this.getDefaultCheck(props.entity && props.entity.scope, props.entity && props.policy),
    };
  }

  /**
   * @param {import('../utils').ScopeForm} scope
   * @param {"NEED_APPROVAL_MESSAGE" | "NEED_APPROVAL_REPLY"[]} policy
   * @param {"notApprove" | "approveAndReprove" | "submitToApprove"} selected
   * @returns {{scope: {import('../utils').ScopeForm}}, policy: "NEED_APPROVAL_MESSAGE" | "NEED_APPROVAL_REPLY"[]}
   */
  getScopeAndPolicy = (scope, policy, selected) => {
    const updatedPolicy = policy || []
    const updatedScope = scope;
    if (selected === 'notApprove') {
      updatedScope.entities.contentApprover = false
      let index = updatedPolicy.indexOf('NEED_APPROVAL_MESSAGE')
      if (index !== -1) updatedPolicy.splice(index, 1)

    } else if (selected === 'approveAndReprove') {
      updatedScope.entities.contentApprover = true
      updatedScope.entities.read = true;
      updatedScope.messages.read = true;
      let index = updatedPolicy.indexOf('NEED_APPROVAL_MESSAGE')
      if (index !== -1) updatedPolicy.splice(index, 1)
    } else {
      updatedScope.entities.contentApprover = false;
      updatedPolicy.push('NEED_APPROVAL_MESSAGE')
    }
    return { scope: updatedScope, policy: updatedPolicy }
  }

  handleClick = async () => {
    const { store, onSubmit } = this.props;
    const { scope, policy } = this.getScopeAndPolicy(this.state.scope, this.props.policy, this.state.selected)
    onSubmit(scope, policy);
    store.appends.pop();
  }

  /**
   *
   * @param {import('../utils').ScopeForm} scope
   * @param {"NEED_APPROVAL_MESSAGE" | "NEED_APPROVAL_REPLY"[]} policy
   * @returns {string}
   */
  getDefaultCheck = (scope, policy) => {
    return scope && scope.entities && scope.entities.contentApprover ? 'approveAndReprove' :
      (policy && policy.includes('NEED_APPROVAL_MESSAGE')) ? 'submitToApprove' : 'notApprove';
  }

  render() {
    const {
      onClose,
      onCancel,
      onConfirm,
      cancelText,
      confirmText,
      header,
      style,
      isChannel,
      entity,
      radioList,
      listTitle,
      policy,
      scope,
      hasMenuConversations
    } = this.props;
    const defaultCheck = this.getDefaultCheck(scope, policy);
    const { showEntityInformationText } = this.state;
    return (
      <BetaModal
        id="PermissionModal"
        onClose={onClose}
        size="tiny"
        style={style}
        header={header ?? (hasMenuConversations ? __('Communication approval permissions') : __('Message approval permissions'))}
        content={(
          <div>
            {
              <RadioButtonList
                listTitle={listTitle ?? __('This employee will')}
                formName="MessageApproval"
                defaultValueCheck={defaultCheck}
                list={radioList}
                onChange={(e) => {
                  const showEntityInformationText = e.target.value === 'approveAndReprove';
                  this.setState({ showEntityInformationText, selected: e.target.value });
                }}
              />
            }
            {
              showEntityInformationText
              && (
                <EntityInformationText
                  entity={entity}
                  isChannel={isChannel}
                />
              )
            }
            <footer className="modal-footer">
              <BetaButton
                data-action="cancel"
                className=""
                floated="left"
                id="approval-permissions-cancel-button"
                round
                transparent
                text={cancelText ?? __('Cancel')}
                onClick={onCancel}
              />

              <BetaButton
                data-action="confirm"
                className=""
                floated="left"
                id="approval-permissions-confirm-button"
                round
                transparent
                icon={{
                  name: 'check icon'
                }}
                text={confirmText ?? __('Confirm')}
                onClick={this.handleClick}
              />
            </footer>
          </div>
        )}
      />
    );
  }
}
@inject('store')
export default class ApprovalPermissions extends ApprovalPermissionsModal {
  constructor(props) {
    super(props);
  }
}
