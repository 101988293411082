import React from 'react';
import { Trigger } from '.';
import { act, fireEvent, render } from '../../../../../../../newRender';

describe('given automatic message Trigger component', () => {
  const mockedProps = {
    enabled: false,
    onChange: jest.fn(),
    title: 'Test title',
    description: 'Test description',
    ref: React.createRef(),
  };

  it('should be able to render trigger without props', () => {
    const { getByTestId } = render(<Trigger />);

    const trigger = getByTestId('CHANNEL_CONFIG_SWITCH_UNCHECKED');

    expect(trigger).toBeTruthy();
  });

  it('should be able to render some trigger', () => {
    const { getByText } = render(<Trigger {...mockedProps} />);

    const title = getByText('Test title');
    const description = getByText('Test description');

    expect(title).toBeTruthy();
    expect(description).toBeTruthy();
  });

  it('should be able to change the trigger', () => {
    const { getByTestId } = render(<Trigger {...mockedProps} />);

    const checkbox = getByTestId('CHANNEL_CONFIG_SWITCH_UNCHECKED');
    act(() => {
      fireEvent.click(checkbox);
    });

    expect(mockedProps.onChange).toHaveBeenCalled();
  });

  it('should be able to get checked using ref', () => {
    render(<Trigger {...mockedProps} />);

    const checked = mockedProps.ref.current.getChecked();

    expect(checked).toEqual(false);
  });
});
