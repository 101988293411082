import React from 'react';
import { Container } from 'semantic-ui-react';
import ListView from '../../../../../../../components/ListView';
import { NotificationEmptyState } from '../NotificationEmptyState';

export const OtherNotifications = ({
  notifications,
  renderRow,
  pagination,
  onLoadMore,
  ...rest
}) => {
  return (
    <Container
      style={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        ...(notifications.length ? {} : { alignItems: 'center' }),
      }}
      {...rest}
    >
      {notifications.length ? (
        <ListView
          segment={false}
          renderRow={renderRow}
          source={notifications}
          pagination={pagination}
          onLoadMore={() => onLoadMore('notifications')}
        />
      ) : (
        <NotificationEmptyState />
      )}
    </Container>
  );
};
