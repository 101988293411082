import React from 'react';
import { render, fireEvent } from '../../../../../../../../newRender';
import { ChannelType } from '.';

import { __ } from '../../../../../../../../i18n';

describe('<ChannelType />', () => {
  it('should to be in the document', () => {
    const { getByText } = render(<ChannelType value="" onChange={() => { }} />);

    const input = getByText(__('With attendance status'));
    const label = getByText(__('Channel Type'));

    expect(input).toBeInTheDocument();
    expect(label).toBeInTheDocument();
  });

  it('should be able to change the input value', async () => {
    const mockOnChange = jest.fn();
    const { getByTestId } = render(
      <ChannelType value="" onChange={mockOnChange} />
    );

    const withoutStatusOption = getByTestId('without-status-option');

    fireEvent.click(withoutStatusOption);

    expect(mockOnChange).toHaveBeenCalledWith('WITHOUT_STATUS');
  });
});
