import React from 'react';
import { Button } from 'semantic-ui-react';
import * as utils from '../utils';
/**
 * This component is a button that makes sure that the onClick function is called only once.
 *
 * The loading and disabled props of the button are changed internally.
 *
 * The button allows the click when it is constructed and it will not allow another click
 * unless it's constructor is called again or the function enableButton is called.
 */
export default class SafeButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingAction: false,
      disableAction: false
    };
  }

  enableButton() {
    this.setState({ disableAction: true });
  }

  render() {
    return (
      <Button
        {...this.props}
        loading={this.state.loadingAction || this.props.loading}
        disabled={this.state.disableAction || this.props.disabled}
        onClick={(event) => {
          event.persist();
          this.setState({ loadingAction: true, disableAction: true }, utils.debounce(() => {
            try {
              if (this.props.onClick) this.props.onClick(event);
            } catch (error) {
              console.error(error);
              this.setState({ disableAction: false });
              if (this.props.onError) this.props.onError(event);
            } finally {
              this.setState({ loadingAction: false });
            }
          }));
        }}
      >
        {this.props.children}
      </Button>
    );
  }
}
