import React, {
  useImperativeHandle,
} from 'react';
import {
  Container,
  RadioContainer,
  RadioCircle,
  Text,
} from './RadioButton.styles';
import { useRadioButton } from './hooks/useRadioButton';
import { getBackgroundColor } from './helpers/getBackgroundColor';

export function RadioButtonComponent(props, ref) {
  const {
    selected = false,
    onChange,
    dotProps,
    backgroundColor,
    radioContainer,
    dotColor,
    text,
    textProps,
    disabled,
    ...rest
  } = props;

  const {
    handleClick,
    handleToogle,
    isActive,
    radioBackground,
  } = useRadioButton({
    selected,
    onChange,
    backgroundColor,
  });

  useImperativeHandle(ref, () => ({
    ...ref,
    isActive,
    toggle: handleToogle,
  }));

  return (
    <Container
      onClick={() => (disabled ? null : handleClick())}
      {...rest}
    >
      <RadioContainer
        isActive={isActive}
        backgroundColor={radioBackground}
        style={getBackgroundColor({
          isActive,
          backgroundColor: radioBackground,
        })}
        data-testid={radioContainer?.testID}
        {...radioContainer}
      >
        {isActive && <RadioCircle dotColor={dotColor} data-testid={dotProps?.testID} {...dotProps} />}
      </RadioContainer>

      {text && <Text {...textProps}>{text}</Text>}
    </Container>
  );
}
