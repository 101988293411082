
import React from 'react';

import { Icon, Dropdown, Popup } from 'semantic-ui-react';
import { __ } from '../i18n';
import Responsive from './Responsive';

const baseColor = '#0069FF';
const barColor = '#E8E8E8';
const borderColor = '#D2D2D2';

const styles = {
  playButton: {
    display: 'flex',
    cursor: 'pointer',
    userSelect: 'none',
    borderRadius: '50%',
    backgroundColor: baseColor,
    width: 40,
    height: 40,
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '17px',
    fontSize: 16
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: '24px',
    border: `1px solid ${borderColor}`,
    borderRadius: '8px',
    padding: 13,
    margin: '14px 0'
  },
  containerSmall: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  circle: {
    position: 'absolute',
    width: 12,
    height: 12,
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    backgroundColor: baseColor,
    cursor: 'pointer'
  },
  bar: {
    position: 'absolute',
    height: 4,
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: barColor,
    borderRadius: '200px'
  },
  filledBar: {
    backgroundColor: baseColor
  },
  downloadButton: {
    borderRadius: '5px'
  }
};

function getFormattedTime(seconds) {
  return [
    `${Math.floor(seconds / 60)}`.padStart(2, '0'), // Minutes
    `${Math.floor(seconds) % 60}`.padStart(2, '0') // Seconds
  ].join(':');
}

class AudioPlayer extends Responsive {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      duration: null,
      currentTime: 0,
      dragging: false,
      currentX: 0,
      barWidth: 0,
      barX: 0
    };
    this.audio = new Audio(props.url);

    this.audio.addEventListener('loadedmetadata', () => {
      this.setState({ duration: this.audio.duration });
    }, false);

    this.audio.addEventListener('timeupdate', () => {
      if (!this.state.dragging) this.setState({ currentTime: this.audio.currentTime });
    }, false);

    AudioPlayer.audioPlayers.add(this);
  }

  componentDidMount() {
    this.audio.addEventListener('ended', () => this.setState({ playing: false }));
    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);
    this.audio.pause();
    this.audio.currentTime = 0;
    AudioPlayer.audioPlayers.delete(this);
    this.audio.removeEventListener('ended', () => this.setState({ playing: false }));
  }

  getProgress = () => {
    const {
      dragging, duration, currentTime, barWidth, barX, currentX
    } = this.state;

    let progress = duration ? currentTime / duration : 0;
    if (dragging) {
      progress = (currentX - barX) / barWidth;
      progress = Math.max(0, Math.min(1, progress));
    }
    return progress;
  }

  mouseMove = (event) => {
    const { dragging, duration } = this.state;
    if (!dragging) return;
    const currentTime = this.getProgress() * duration;
    this.setState({ currentX: event.clientX, currentTime });
  };

  mouseUp = () => {
    if (this.state.dragging) {
      const { duration } = this.state;
      const currentTime = this.getProgress() * duration;
      this.audio.currentTime = currentTime;
      this.setState({ dragging: false, currentTime });
    }
  };

  barMouseDown = (event) => {
    this.setState({
      dragging: true,
      currentX: event.clientX,
      barWidth: this.bar.getBoundingClientRect().width,
      barX: this.bar.getBoundingClientRect().left
    }, () => {
      const { duration } = this.state;
      const currentTime = this.getProgress() * duration;
      this.setState({ currentTime });
    });
  };

  stop = () => {
    this.audio.pause();
    this.audio.currentTime = 0;
    this.setState({ playing: false });
  };

  togglePlay = (resetToStart = false) => {
    this.setState({ playing: !this.state.playing }, () => {
      if (this.state.playing) {
        AudioPlayer.audioPlayers.forEach(player => player !== this && player.stop());
        this.audio.play();
      } else {
        this.audio.pause();
        if (resetToStart) this.audio.currentTime = 0;
      }
    });
  };

  render() {
    const { name, alias, small = false, url } = this.props;
    const {
      playing, duration, currentTime
    } = this.state;

    const progress = this.getProgress();

    if (small) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', border: `1px solid ${borderColor}`, borderRadius: '8px', padding: '5px', marginBottom: '10px' }}>
          <div style={styles.containerSmall}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ ...styles.playButton, paddingLeft: playing ? 3 : 5 }} onClick={() => this.togglePlay(false)}>
                {playing ? <Icon style={{ fontWeight: 900 }} name="pause" /> : <Icon style={{ fontWeight: 900 }} name="play" /> }
              </div>
              <div style={{ padding: '0 8px' }}>
                {
                  (alias || name).length > 8 ?
                    <Popup
                      trigger={<p>{(alias || name).replace(/^(.{8}).+/, '$1...')}</p>}
                      content={<span>{(alias || name)}</span>}
                    /> : (alias || name)
                }

              </div>
            </div>
            <div>
              <Dropdown icon={null} trigger={<Icon name="ellipsis v" title={__('Options')} />} upward>
                <Dropdown.Menu className="bold" style={this.isMobile() ? { ...styles.downloadButton, left: 'unset', right: 0 } : { ...styles.downloadButton }}>
                  <Dropdown.Item
                    data-action="download"
                    href={`/download_file?name=${encodeURIComponent(name)}&path=${encodeURIComponent(url)}`}
                    as="a"
                    target="_blank"
                  >
                    {__('Download')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ minWidth: 48, textAlign: 'left' }}>{getFormattedTime(currentTime)}</div>
            <div
              ref={(el) => { this.bar = el; }}
              style={{ flex: 1, position: 'relative', userSelect: 'none', cursor: 'pointer' }}
              onMouseDown={this.barMouseDown}
            >
              <div style={styles.bar} />
              <div style={{ ...styles.bar, ...styles.filledBar, width: `${progress * 100}%` }} />
              <div style={{ ...styles.circle, left: `${progress * 100}%` }} />
            </div>
            <div style={{ minWidth: 48, textAlign: 'right' }}>{getFormattedTime(duration)}</div>
          </div>
        </div>
      );
    }

    return (
      <div style={styles.container}>
        <div style={{ ...styles.playButton, paddingLeft: playing ? 3 : 5 }} onClick={() => this.togglePlay(false)}>
          {playing ? <Icon style={{ fontWeight: 900 }} name="pause" /> : <Icon style={{ fontWeight: 900 }} name="play" /> }
        </div>
        <div style={{ paddingLeft: 16, flex: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ textAlign: 'left' }}>{alias || name}</div>
            <div>
              <Dropdown icon={null} trigger={<Icon name="ellipsis v" title={__('Options')} />} upward>
                <Dropdown.Menu className="bold" style={this.isMobile() ? { ...styles.downloadButton, left: 'unset', right: 0 } : { ...styles.downloadButton }}>
                  <Dropdown.Item
                    data-action="download"
                    href={`/download_file?name=${encodeURIComponent(name)}&path=${encodeURIComponent(url)}`}
                    as="a"
                    target="_blank"
                  >
                    {__('Download')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ minWidth: 48, textAlign: 'left' }}>{getFormattedTime(currentTime)}</div>
            <div
              ref={(el) => { this.bar = el; }}
              style={{ flex: 1, position: 'relative', userSelect: 'none', cursor: 'pointer' }}
              onMouseDown={this.barMouseDown}
            >
              <div style={styles.bar} />
              <div style={{ ...styles.bar, ...styles.filledBar, width: `${progress * 100}%` }} />
              <div style={{ ...styles.circle, left: `${progress * 100}%` }} />
            </div>
            <div style={{ minWidth: 48, textAlign: 'right' }}>{getFormattedTime(duration)}</div>
          </div>
        </div>
      </div>
    );
  }
}

AudioPlayer.audioPlayers = new Set();

export default AudioPlayer;
