import React from 'react';

import {
  Container,
  Label,
  Footer,
  FooterText,
  TextArea
} from './Description.styles';

const MAX_TEXT_AREA_SIZE = 500;

const handleTextChange = (event, onChange) => {
  const newText = event?.target?.value;

  if (newText.length <= MAX_TEXT_AREA_SIZE) {
    onChange(newText);
  } else if (newText.length > MAX_TEXT_AREA_SIZE) {
    onChange(newText.substring(0, MAX_TEXT_AREA_SIZE));
  }
};

/**
* @typedef {{
*  value?: string;
*  onChange?: (event: Event) => void;
*  error?: TError | string;
* }} Props
*
* @param {Props} props
* @returns {JSX.Element}
*/
export const Description = React.forwardRef(({
  label, length = 0, style, value, onChange, ...rest
}, ref) => (
  <Container style={style}>
    <Label>{label}</Label>
    <TextArea
      onChange={(e) => handleTextChange(e, onChange)}
      ref={ref}
      {...rest}
      value={value}
    />
    <Footer>
      <FooterText>{`${length}`}</FooterText>
      <FooterText>{`/${MAX_TEXT_AREA_SIZE}`}</FooterText>
    </Footer>
  </Container>
));
