import React from 'react';
import { Container } from './ResponsiveMessage.styles';

/**
 * @param {{
 *  columns: {
 *    id: string;
 *    content: React.ReactNode;
 *  }[];
 * }} props
 * @returns {React.Component}
 */
export const ResponsiveMessage = ({ columns = [] }) => {
  return (
    <Container data-testid="RESPONSIVE_MESSAGE_CONTAINER">
      {columns?.map((column) => (
        <div key={column?.id}>{column?.content}</div>
      ))}
    </Container>
  );
};
