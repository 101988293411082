import React from 'react';
import { inject, observer } from 'mobx-react';
import { Menu, Container, Icon, Button } from 'semantic-ui-react';
import moment from 'moment';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../i18n';
import { isEntityType } from '../utils';
import Modal from '../components/Modal';

@inject('store', 'client')
@graphql(gql`mutation deleteDemonstration ($organizationId: ID!) {
  deleteDemonstration(input: {
    id: $organizationId
  }) {
    organization {
      id
    }
  }
}
`, { name: 'deleteDemonstration' })
@observer
export default class DemonstrationBanner extends React.Component {

  handleClick = () => {
    const { store } = this.props;
    const organization = store && store.currentOrganization;
    const userEntity = store && store.currentUser && store.currentUser.entities[1];
    
    this.props.deleteDemonstration({
      variables: {
        organizationId: organization.id
      }
    }).then(async () => {
      store.appends.pop();
      window.location.replace(`/entities/${userEntity.id}/messages`);
    }).catch((err) => {
      this.setState({ loading: false });
      console.error(err);
    });
  }

  renderDeleteDemoModal = () => {
    this.props.store.appends.push(<Modal
      closeIcon={'times close inside'}
      header={__('Do you really want to delete your demo?')}
      onClose={() => this.props.store.appends.pop()}
      content={
        <div>
          <span>{__('We added fictitious people and messages to demonstrate the use of ClassApp. When you confirm this action, the information will be deleted.')}</span>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '30px' }}>
            <Button
              basic
              style={{ height: '43px', borderRadius: '20px' }}
              content={__('Back')}
              onClick={() => this.props.store.appends.pop()}
            />
            <Button
              style={{ height: '43px', borderRadius: '20px', backgroundColor: '#0069FF', color: '#FFF' }}
              content={__('Delete Demo')}
              onClick={() => { this.handleClick(); }}
            />
          </div>
        </div>}
    />);
  }


  render() {
    const { store } = this.props;
    const organization = store && store.currentOrganization;
    const startShowBanner = moment([2020, 10, 11]);
    const todayDate = moment();

    const isShowBanner = todayDate.isAfter(startShowBanner);

    if (isEntityType(store, 'STAFF') || isEntityType(store, 'STUDENT')) return null;

    if (!organization || organization.plan !== 'FREE' || !organization.isDemonstration || !isShowBanner) return null;

    return (
      <div>
        <div style={{ height: 55 }} />
        <Menu
          fixed="top"
          borderless
          style={{
            backgroundColor: '#FFFFF',
            marginTop: 64,
            zIndex: 3,
            border: 0
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', cursor: 'pointer' }}>
            <div onClick={() => this.renderDeleteDemoModal()}>
              <span style={{ color: '#DB2828', textDecoration: 'underline' }}>{__('Delete messages from the demo')}</span>
              <Icon name="trash" style={{ color: '#DB2828', marginBottom: '3px', marginLeft: '3px' }} />
            </div>
          </div>
        </Menu>
      </div>);
  }
}
