// eslint-disable-next-line import/no-extraneous-dependencies

import { toast as toastify } from 'react-toastify';

import { UndoButton } from '../../components/UndoButton';

export function useSnackbar() {
  const toast = ({
    position = 'top-center', duration = 5000, type = 'INFO', undoProps, content
  }) => {
    const toastVariations = {
      INFO: toastify.info,
      WARNING: toastify.warning,
      ERROR: toastify.error,
      SUCCESS: toastify.success,
    };

    toastVariations[type](content, {
      position,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
      // eslint-disable-next-line new-cap
      closeButton: undoProps ? UndoButton(undoProps) : true,
    });
  };

  return {
    toast,
  };
}
