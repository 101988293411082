import React from 'react';

/**
 * @param {{
 *  title: string,
 *  iconDown?: string,
 *  iconUp?: string,
 *  customTag: React.ReactNode
 *  children: JSX.Element
 * }} param0
 * @returns {JSX.Element}
 */
export default class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: false
    };
  }

  render() {
    const { title, iconDown, iconUp, customTag, children } = this.props;
    const { showContent } = this.state;

    return (
      <div className="single-accordion">
        <div
          className="single-accordion-title"
          onClick={() => this.setState({ showContent: !showContent })}
          onKeyPress={() => this.setState({ showContent: !showContent })}
        >
          <span>{title}</span>

          <i
            className={`icon ${showContent ? (iconUp || 'caret right') : (iconDown || 'sort down')}`}
            style={{ fontWeight: 700 }}
          />

          <span style={{ fontWeight: 'normal' }}>
            {!showContent && customTag}
          </span>
        </div>

        {showContent && <div className="single-accordion-content">{children}</div>}
      </div>
    );
  }
}
