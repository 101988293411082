import React from 'react';
import { Icon } from 'semantic-ui-react';

import {
  AlertContainer,
  TitleContainer,
  DropdownContainer,
} from './Alert.styles';

/**
 * Alert component
 * How to use it:
 *
    <Alert
      title='Channels'
      icon={<Icon name='question' size='large'/>}
      onExpand={this.onExpand}
      isExpanded={this.state.isExpanded}
      backgroundColor='#F2F7FF'
      titleStyle={{
        marginLeft: '0.8rem',
      }}
      style={{
        padding: '1.5rem'
      }}
    >
      <div>
        Children Component
      </div>
    </Alert>
 */

/**
 * @param {{
 *  backgroundColor?: string;
 *  title: string;
 *  titleStyle?: React.CSSProperties;
 *  style?: React.CSSProperties;
 *  icon?: React.ReactNode;
 *  isExpanded: boolean;
 *  onExpand?: () => void;
 *  children?: React.ReactNode;
 *  dropdownIconColor?: string;
 * }} input
 * @returns {React.Component}
 */
export function Alert({
  style,
  backgroundColor,
  title,
  titleStyle,
  icon,
  isExpanded,
  onExpand,
  children,
  dropdownIconColor
}) {
  return (
    <AlertContainer
      style={style}
      backgroundColor={backgroundColor}
    >
      <TitleContainer>
        {!!icon && icon}
        <h5 style={titleStyle}>{title}</h5>
        <DropdownContainer
          onClick={onExpand}
        >
          <Icon
            data-testid="dropdownIcon"
            name={isExpanded ? 'angle down' : 'angle up'}
            fill={dropdownIconColor || '#000'}
            size="large"
          />
        </DropdownContainer>
      </TitleContainer>
      {isExpanded && children}
    </AlertContainer>
  );
}
