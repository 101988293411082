import React from 'react';
import { __ } from '../../../../../../../../i18n';
import SelectPermissions from '../../../../../../../../components/ui/SelectPermissions';

const styles = {
  subTitleText: {
    fontWeight: 'bold',
    fontSize: '1.286rem',
    color: '#000000'
  },
  subTitle: {
    marginBottom: '1.25rem'
  },
  manage: {
    marginBottom: '1.75rem'
  },
  label: {
    fontSize: '1.143rem',
    fontWeight: 'bold',

  },
};

/**
* @param {{
*   scope: import('../../../../../../utils').ScopeForm,
*   policy: "NEED_APPROVAL_MESSAGE" | "NEED_APPROVAL_REPLY"[]
*   entity: import('../../../../../../components/ApprovalPermissionsModal').Props['entity'],
*   onSubmit: (SelectedGroup[]) => void,
*   hasMessageApprove: boolean,
* }} param
* @returns {JSX.Element}
*/
export function ManagePermissions({
  scope,
  policy,
  entity,
  onSubmit,
  hasMessageApprove,
  hasMenuConversations
}) {
  if (!hasMessageApprove) {
    return null;
  }

  const radioListOptions = hasMenuConversations
    ? [
      { value: 'notApprove', label: __('Sending communications without approval') },
      { value: 'approveAndReprove', label: __('Approve and disapprove communications sent by other employees') },
      { value: 'submitToApprove', label: __('Have your press releases approved or disapproved before sending') },
    ]
    : [
      { value: 'notApprove', label: __('Sending messages without approval') },
      { value: 'approveAndReprove', label: __('Approve and disapprove messages sent by other employees') },
      { value: 'submitToApprove', label: __('Have your messages approved or disapproved before sending') },
    ];

  return (
    <div data-testid="manage-permissions-component" style={styles.manage}>
      <div style={styles.subTitle}>
        <span style={styles.subTitleText}>{__('Manage Permissions')}</span>
      </div>
      <div style={styles.manage}>
        <SelectPermissions
          listTitle={__('This channel will')}
          entity={entity}
          messageApproval
          hasMenuConversations={hasMenuConversations}
          isChannel
          scope={scope}
          policy={policy}
          onSubmit={onSubmit}
          radioList={radioListOptions}
        />
      </div>
      {hasMenuConversations
        && (
          <div>
            <p style={styles.label}>{__('Communication approval permissions only apply to communications sent to the inbox.')}</p>
            <p style={styles.label}>{__('They are not applied to messages within conversations.')}</p>
          </div>
        )}
    </div>
  );
}
