import React from 'react';
import { Icon, Button, Image } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';
import { DefaultPlayer as Video } from 'react-html5video';

import Responsive from './Responsive';

import { __ } from '../i18n';
import Modal from '../components/Modal';
import BetaModal from '../components/ui/Modal';
import BetaButton from '../components/ui/Button';

import { asset, isEntityType, hasEntityInCurrentOrganization } from '../utils';
import betaIds from '../lib/betaIds';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  button: {
    height: '43px',
    borderRadius: '20px',
    backgroundColor: '#0069FF',
    color: '#FFF',
    margin: 0
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px'
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: '15px',
    textAlign: 'center',
    marginTop: '8px'
  },
  mobileVideo: {
    width: '290px',
    height: '163px',
    marginBottom: '16px'
  },
  desktopVideo: {
    width: '1048px',
    height: '589.5px',
    marginBottom: '16px',
  }
};

const REFERENCE_SCHOOL_ID = 5413;

@inject('store')
@observer
export default class WelcomeModal extends Responsive {
  constructor(props) {
    super(props);
    this.messages = {
      demonstration: {
        title: __('Watch the video to find out everything you need to know to transform the comunication of your school.'),
        subtitle: __('If you prefer, open on YouTube to watch while you use it.'),
        videoTitle: __('ClassApp - First Steps'),
        img: '/demonstration/welcome_free.png',
        width: 400,
        height: 300
      },
      simulation: {
        title: __('This is a simulation school'),
        subtitle: __('You are accessing a ClassApp simulation, feel free to send messages, access moments and calendar.'),
        img: '/demonstration/welcome_simulation.png',
        width: 300,
        height: 300
      }
    };
  }

  componentDidUpdate = () => {
    const { store } = this.props;
    const organization = store && store.currentOrganization;
    const { currentUser, app } = store;
    const welcomeModalViewers = cookie.load('welcomeModalViewers') || [];
    const user = store && store.currentUser;

    if (!organization || !user) {
      return null;
    }
    if (!currentUser || !currentUser.organizations) return null;

    if (welcomeModalViewers.includes(user.id)) {
      this.props.store.appends.pop();
      return null;
    }

    if ((organization.plan !== 'FREE' || !isEntityType(store, 'ADMIN')) && organization.usage !== 'SIMULATION') {
      this.props.store.appends.pop();
      return null;
    }
  }

  handleClick = () => {
    const { store } = this.props;
    this.props.store.appends.pop();
    const welcomeModalViewers = cookie.load('welcomeModalViewers') || [];

    const user = store && store.currentUser;
    welcomeModalViewers.push(user.id);
    cookie.save('welcomeModalViewers', welcomeModalViewers, { path: '/', maxAge: 60 * 60 * 24 * 7 });
  }

  renderVideo = () => {
    const videoStyle = this.isMobile() ? styles.mobileVideo : styles.desktopVideo;

    return (
      <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
        <iframe
          style={{ ...videoStyle }}
          src="https://www.youtube.com/embed/VdmmUmhhxaw?rel=0"

          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  }

  openArticle = () => {
    window.open(
      'https://ajuda.classapp.com.br/hc/pt-br/articles/4402696175259',
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  renderPopUp = () => {
    const { store } = this.props;
    return (
      hasEntityInCurrentOrganization(store, ['ADMIN']) ?
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {this.isMobile() &&
            <div style={{ marginBottom: '16px', width: '100%', fontSize: '18px', textAlign: 'center' }}>{__('ClassApp has a new layout to further enhance your experience! 😍')}</div>
          }

          <div style={{ height: '260px', width: '275px', marginBottom: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Image src={asset('/popup/discussion.png')} size="medium" style={{ display: 'flex' }} />
          </div>

          {!this.isMobile() &&
            <div style={{ marginBottom: '16px', width: '80%', fontSize: '20px', textAlign: 'center' }}>{__('ClassApp has a new layout to further enhance your experience! 😍')}</div>
          }
          {!this.isMobile() ?
            <div style={{ width: '80%' }}>
              <div style={{ marginBottom: '16px', fontSize: '16px', textAlign: 'flex-start', color: 'rgba(0, 0, 0, 0.6)' }}>{__('Check what is new:')}</div>
              <div style={{ fontSize: '16px', textAlign: 'flex-start', color: 'rgba(0, 0, 0, 0.6)' }}>{__('→ Distinction between channels and people')}</div>
              <div style={{ fontSize: '16px', textAlign: 'flex-start', color: 'rgba(0, 0, 0, 0.6)' }}>{__('→ Retractable menus')}</div>
              <div style={{ fontSize: '16px', textAlign: 'flex-start', color: 'rgba(0, 0, 0, 0.6)' }}>{__('→ Buttons in top menu')}</div>
              <div style={{ marginBottom: '16px', fontSize: '16px', textAlign: 'flex-start', color: 'rgba(0, 0, 0, 0.6)' }}>{__('→ Friendlier layout')}</div>
            </div>
            :
            <div style={{ width: '100%', fontSize: '14px', marginBottom: '10px', textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)' }}>{__('We made a more user-friendly interface and some retractable menus.')}</div>
          }
          <div style={{ marginBottom: '16px', width: this.isMobile() ? '100%' : '80%', fontSize: this.isMobile() ? '14px' : '16px', textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)' }}>{<p style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{__('Check out all the details on our')}<p onClick={() => this.openArticle()} style={{ color: '#084FFF', cursor: 'pointer', textDecoration: 'underline' }}>{__('help page')}</p></p>}</div>
          <BetaButton
            data-action="download"
            className="blue"
            round
            text={__('Ok, I got it')}
            onClick={() => this.handleClick()}
            style={{ marginBottom: '23.5px', width: '60%', height: '47px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
        </div>
        :
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

          {this.isMobile() &&
            <div style={{ marginBottom: '16px', width: '80%', fontSize: '20px', textAlign: 'center' }}>{__('ClassApp has a new layout to further enhance your experience! 😍')}</div>
          }

          <div style={{ height: '260px', width: '275px', marginBottom: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Image src={asset('/popup/discussion.png')} size="medium" style={{ display: 'flex' }} />
          </div>
          {!this.isMobile() &&
            <div style={{ marginBottom: '16px', width: '80%', fontSize: '20px', textAlign: 'center' }}>{__('ClassApp has a new layout to further enhance your experience! 😍')}</div>
          }
          <div style={{ marginBottom: '16px', width: '80%', fontSize: '16px', textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)' }}>{__('We made a more user-friendly interface and some retractable menus.')}</div>
          <BetaButton
            data-action="download"
            className="blue"
            round
            text={__('Ok, I got it')}
            onClick={() => this.handleClick()}
            style={{ marginBottom: '23.5px', width: '60%', height: '47px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
        </div>
    );
  }

  render() {
    const { store } = this.props;
    const { currentUser, app } = store;
    const organization = store && store.currentOrganization;
    const welcomeModalViewers = cookie.load('welcomeModalViewers') || [];
    const user = store && store.currentUser;

    if (!organization || !user) return null;
    if (!currentUser || !currentUser.organizations) return null;

    if (welcomeModalViewers.includes(user.id)) return null;

    if ((organization.plan !== 'FREE' || !isEntityType(store, 'ADMIN')) && organization.usage !== 'SIMULATION') return null;

    const message = organization.usage === 'SIMULATION' ? this.messages.simulation : this.messages.demonstration;

    if (organization.usage === 'SIMULATION') {
      return (
        <Modal
          content={
            <div style={{ ...styles.container, padding: '32px' }}>
              <img style={{ width: message.width, height: message.height }} alt="welcome" src={asset(message.img)} />
              <span style={styles.title}>{message.title}</span>
              <span style={styles.subtitle}>{message.subtitle}</span>
              <div style={{ ...styles.footer, marginTop: '32px' }}>
                <Button
                  style={styles.button}
                  content={__('Come on!')}
                  onClick={() => this.handleClick()}
                />
              </div>
            </div>
          }
        />
      );
    }
    return (
      <Modal
        closeIcon="times close"
        onClose={() => this.handleClick()}
        content={
          <div style={{ ...styles.container, padding: '32px' }}>
            {this.renderVideo(store)}
            <span style={styles.title}>{message.title}</span>
            <span style={styles.subtitle}>{message.subtitle}</span>
            <a href="https://youtu.be/VdmmUmhhxaw" target="_blank" rel="noopener noreferrer">{message.videoTitle}</a>
            <div style={{ ...styles.footer, marginTop: '32px' }}>
              <Button
                style={styles.button}
                content={__('Come on!')}
                onClick={() => this.handleClick()}
              />
            </div>
          </div>
        }
        size="large"
      />
    );
  }
}
