import React from 'react';

// Libraries
import { Dropdown, Icon } from 'semantic-ui-react';
import qs from 'qs';

// Components
import TableActionBar from './TableActionBar';
import ColoredCheckbox from './ColoredCheckbox';
import Loading from './ui/Loading';
import EmptyImage from './ui/EmptyImage';

// Others
import { __ } from '../i18n';

const { compare } = new Intl.Collator();

/**
 * Table component
 * @colNames array of strings for the table header
 * @rows array of arrays of objects [[obj, obj, ...], [obj, obj, ...], ...], where obj is {
 *     value: string | element,
 *     link: string (optional),
 *     key: string to be used when filtering or ordering table. If none is provided, value
 *          will be used (optional)
 *   }
 * @search (optional) object to allow table search, where the objetc is {
 *     placeholder: string,
 *     columnIndex: (optional) number of the column index where the search will be apllied,
 *     api: object with settings for api search, where the object is {
 *       value: string with the search text,
 *       fn: callback function for the api search
 *     }
 *   }
 * @order (optional) boolean or object to allow table sorting. In case of an object, it may be {
 *     options: array of strings,
 *     rules: (optional) array of values for the options order,
 *     api: object with settings for api order, where the object is {
 *       value: string with the selected order option,
 *       fn: callback function for the api order
 *     }
 *   }
 * @simpleFilters (optional) boolean or array of objects to allow table filtering one by one. In case of an array, the object may be {
 *     key: string,
 *     placeholder: string,
 *     options: array of strings,
 *     fn: callback function for the api order
 *   }
 * @customAction (optional) React element to be passed to TableActionBar
 * @gridColumns (optional) CSS Grid columns template style. If none is provided, all columns
 *   will have the same width
 * @exportData (optional) object to allow table data export, where the object is {
 *     text: string with the button text,
 *     download: string with the downloaded file name
 *   }
 * @loading (optional) boolean to show loader
 *
 * @menu (optional) array of objects to put a right menu at Table In case of an array, the object may be
 *  {
 *    type: enum (dropdown, menu),
 *    params: object in case type = 'dropdown' object looks like {
 *        name: string,
 *        options: array of objects like { text: string, value: string },
 *        fn: callback funtion that has to return the option value
 *     } and in case type = 'button' object looks like {
 *        name: string,
 *        icon: string,
 *        fn: callback function
 *     }
 *  }
 */

/* Styles */
const styles = {
  buttonSize: {
    width: '100%',
    height: '43px',
  }
};
export default class Table extends React.Component {
  constructor(props) {
    super(props);
    const parameters = qs.parse(props.location && props.location.query);
    this.state = {
      searchText: /* (parameters.search && JSON.parse(parameters.search)) || */ '',
      entityIds: (parameters.entityIds && JSON.parse(parameters.entityIds)) || null,
      startDate: parameters.startDate || '',
      endDate: parameters.endDate || '',
      orderBy: '',
      loading: false,
    };
  }

  componentDidMount = () => {
    const { location } = this.props;
    if (location && location.query && location.query.search) {
      this.setState({ searchText: location.query.search });
    }
  }

  onChangeText = (value, search) => {
    if (search.api) {
      search.api.fn(value);
    }
    this.setState({ searchText: value });
  }

  onAutosuggestChange = (idArray, multipleAutosuggest) => {
    let value;
    if (idArray.length === 0) {
      value = null;
    } else {
      value = idArray;
    }

    multipleAutosuggest.api.fn(value);
  }

  filteredRows = () => {
    let { orderBy } = this.state;
    const {
      rows, colNames, order
    } = this.props;

    if (orderBy === 'Sem filtro') orderBy = '';

    let filtered = [...rows];

    if (orderBy) {
      const index = colNames.findIndex(val => val === orderBy);

      if (order.rules && order.rules[orderBy]) {
        filtered = filtered.map((row) => {
          row[index].order = order.rules[orderBy][row[index].key || row[index].value] || 0;
          return row;
        });
      }

      const filter = filtered.sort((a, b) => {
        let valueA = a[index].order || a[index].key || a[index].value;
        let valueB = b[index].order || b[index].key || b[index].value;
        const stringValues = typeof valueA === 'string' && typeof valueB === 'string';

        if (stringValues) {
          valueA = valueA.toLocaleLowerCase();
          valueB = valueB.toLocaleLowerCase();

          return compare(valueA, valueB);
        }

        if (valueA > valueB) return 1;
        if (valueA < valueB) return -1;
        return 0;
      });
      const invertOrder = ['Última atualização', 'Data', 'Recebido', 'Pendente'];
      if (invertOrder.indexOf(orderBy) >= 0) return filter.reverse();
      return filter;
    }

    return filtered;
  }

  formatNumberMoney = (
    number, maximumFractionDigits = 2
  ) => {
    const value = number.toFixed(maximumFractionDigits).split('.');
    value[0] = value[0].split(/(?=(?:...)*$)/).join('.');
    return value.join(',');
  };

  columnsTemplate = () => {
    const { colNames, gridColumns, gridGap = '24px' } = this.props;

    return {
      gridTemplateColumns: gridColumns || `repeat(${colNames.length}, 1fr)`,
      gridGap
    };
  }

  renderItem = (item, colName, index) => {
    if (typeof item.value === 'string') {
      //   return (item.link
      //     ? <Link href={item.link} key={colName + index}><a>{item.value}</a></Link>
      //     : <p key={colName + index} {...item}>{item.value}</p>
      //   );
      return <p key={colName + index} {...item}>{item.value}</p>;
    }
    return item.value;
  }

  renderSelectedMenu = (selectedMenu) => {
    const { selectedCount } = this.props;

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: -12, flexDirection: 'row', alignContent: 'center' }}>
        <div onClick={() => m.fn()} style={{ display: 'flex', alignContent: 'center', marginRight: '12px' }}>
          <div className="generic-button" style={{ marginTop: 12, cursor: 'default' }}>
            <div className="wrapper light" style={{ cursor: 'default' }}>
              <Icon name={'check circle'} style={{ color: '#084FFF', margin: null, fontWeight: 'bold', cursor: 'default' }} size="large" />
              <span
                style={{ marginLeft: '5px', color: '#084FFF' }}
              >
                {selectedCount}
              </span>
            </div>
          </div>
        </div>


        {selectedMenu.map((m, index) =>
          <div onClick={() => m.fn()} style={{ display: 'flex', alignContent: 'center', marginRight: index + 1 === selectedMenu.length ? null : '12px' }}>
            <div className="generic-button" style={{ marginTop: 12 }}>
              <div className="wrapper white">
                <Icon className={m.icon} style={{ margin: 0 }} />
                <span
                  style={{ marginLeft: '5px' }}
                >
                  {m.name}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderMenu = menu => (
    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignContent: 'center' }}>
      {menu.map((m, index) => {
        if (m.type === 'dropdown') {
          return (
            <div style={{ display: 'flex', marginRight: index + 1 === menu.length ? null : '12px' }}>
              <Dropdown
                text={`${m.params.name} ${m.params.activeValue || ''}`}
                icon={m.params.icon}
                className={'dropdownFilter'}
              >
                <Dropdown.Menu>
                  {m.params.options.map(o => (
                    <Dropdown.Item
                      text={o.text}
                      icon={o.icon}
                      onClick={() => m.params.fn(o.value, o)}
                      key={`filter-option-${o.text}`}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        }
        return (
          <div onClick={() => m.params.fn()} style={{ display: 'flex', alignContent: 'center', marginRight: index + 1 === menu.length ? null : '12px' }}>
            <div className="generic-button" style={{ backgroundColor: m.params.backgroundColor || '#084FFF', border: m.params.border || 'none' }}>
              <div className={`wrapper${m.params.name ? '' : ' icon'}`}>
                {m.params.icon && <Icon className={m.params.icon} style={{ margin: 0, color: m.params.fontColor || '#FFF' }} />}
                {m.params.name &&
                  <span style={{ marginLeft: m.params.icon ? '5px' : '0px', marginRight: m.params.rightIcon ? '5px' : '0px', color: m.params.fontColor || '#FFF' }}>
                    {m.params.name}
                  </span>}
                {m.params.rightIcon && <Icon className={m.params.rightIcon} style={{ margin: 0, color: m.params.fontColor || '#FFF' }} />}
              </div>
            </div>
          </div>
        );
      }
      )}
    </div>

  )

  render() {
    const {
      searchText, orderBy
    } = this.state;
    const {
      colNames, search, order, simpleFilters, emptyImage, loading, multipleAutosuggest, location, customFilter, withSelection, selectAll, onSelectAll, onEntitySelect, data, menu, selectedCount, selectedMenu, type, hideNotFoundImage, cellStyle = {}, stylePerCell = {}, externFilters, multiSelectFilters
    } = this.props;

    const parameters = qs.parse(location && location.query);
    const entityIds = (parameters.entityIds && JSON.parse(parameters.entityIds)) || null;

    const selectOptions = order && (typeof order === 'boolean' ? colNames : order.options)
      .filter(name => name)
      .map(name => ({ text: name, value: name, key: name }));

    if (selectOptions && typeof order === 'boolean') {
      selectOptions.push({ value: '', text: 'Sem filtro', key: `${colNames[0]}no-value` });
    }

    const filteredRows = this.filteredRows();
    const { invalidFilters } = this.props;
    return (
      <div className="global-table" style={{ ...this.props.style }}>
        {(simpleFilters || search || selectedMenu || menu || order) && (
          <TableActionBar
            selectedMenu={selectedMenu && !!selectedCount && this.renderSelectedMenu(selectedMenu)}
            externFilters={externFilters}
            multiSelectFilters={multiSelectFilters}
            search={search && {
              fillAllWidth: search.fillAllWidth,
              value: searchText,
              placeholder: search.placeholder,
              fn: value => this.onChangeText(value, search)
            }}
            multipleAutosuggest={multipleAutosuggest && {
              value: entityIds,
              fn: array => this.onAutosuggestChange(array[0], multipleAutosuggest),
              location,
              ...multipleAutosuggest.props
            }}
            order={order && {
              value: order.api ? order.api.value : orderBy,
              options: selectOptions,
              fn: value => (order.api ? order.api.fn(value) : this.setState({ orderBy: value }))
            }}
            simpleFilters={simpleFilters}
            selectedFilters={this.props.selectedFilters}
            customFilter={customFilter}
            menu={menu && this.renderMenu(menu)}
            loading={loading}
          />
        )}

        <div className="table-header" style={{ ...this.columnsTemplate(), backgroundColor: '#FAFAFA' }}>
          {withSelection &&
            <ColoredCheckbox radio color="#084FFF" lighter={!selectAll && !!selectedCount} radioChecked data-action="select-all" className="bold" onClick={() => onSelectAll()} checked={!!selectedCount} />
          }
          {colNames.map(name => <p key={name}>{name}</p>)}
        </div>

        {loading ?
          <Loading width={40} height={40} />
          :
          this.props && invalidFilters && invalidFilters.value ?
            !hideNotFoundImage && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '6rem' }}> <EmptyImage
              url="/images/request_not_found_empty_state.svg"
              title={(invalidFilters.title) || __('No results found')}
              subtitle={(invalidFilters.subtitle) || __('Try again using other search parameters')}
            />
            </div>
            :
            filteredRows.length === 0 ?
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '6rem' }}>
                {
                  location.query.search ?
                    !hideNotFoundImage && <EmptyImage
                      url="/images/pablita-page-not-found.png"
                      title={__('Search not found')}
                    />
                    :
                    !hideNotFoundImage && <EmptyImage
                      url={(emptyImage && emptyImage.url) || '/images/pablita-588.png'}
                      title={(emptyImage && emptyImage.title) || __('Nobody is here yet')}
                      subtitle={(emptyImage && emptyImage.subtitle) || __('How about adding someone?')}
                    />
                }
              </div>
              :
              filteredRows.map((row, index) => (
                <div
                  className="table-row"
                  style={{ ...this.columnsTemplate() }}
                  key={index}
                >
                  {withSelection &&
                    <ColoredCheckbox
                      color="#084FFF" radio radioChecked data-action="select-all" className="bold"
                      onClick={() => onEntitySelect(data[index].id)}
                      checked={data[index].selected}
                    />}

                  {row.map((item, ind) => <div style={{ minWidth: 0, margin: 0, ...cellStyle, ...stylePerCell[ind] }} key={`${index}-${ind}`}>{this.renderItem(item, colNames[ind], index)}</div>)}
                </div>
              ))}
      </div>
    );
  }
}
