import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import BetaModal from './ui/Modal';
import BetaButton from './ui/Button';
import Calendar from './Calendar';
import Form from './Form';
import Responsive from './Responsive';

import { __ } from '../i18n';

const timeOptions = {
  hour: ['07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06'].map(v => ({ text: v, value: parseInt(v, 10) })),
  minute: ['00', '30'].map(v => ({ text: v, value: parseInt(v, 10) }))
};

@inject('store') @observer
export default class DatePicker extends Responsive {
  constructor(props) {
    super(props);
    this.state = {
      date: props.selectedDt || null
    };
  }

  setTime = (where = 'hour', value) => {
    const { date } = this.state;

    if (where === 'hour') {
      date.setHours(value);
    } else if (where === 'minute') {
      date.setMinutes(value);
    }

    this.setState({ date });
  }

  formatTime = (value) => {
    const time = value.toString();

    if (time.length === 1) return '0' + time;

    return time;
  }

  render() {
    const { cancelButton, submitButton, minDate, maxDate, message } = this.props;
    const { date, errors } = this.state;

    return (<BetaModal
      toast={this.isMobile()}
      invertCloseButton={this.isMobile()}
      id="DatePicker"
      onClose={() => this.props.store.appends.pop()}
      actions={[
        <BetaButton
          data-action="cancel"
          transparent round
          text={__('Cancel')}
          style={{ marginLeft: 0, marginRight: 'auto' }}
          onClick={() => {
            this.props.onCancel();
            this.props.store.appends.pop();
          }}
          {...cancelButton}
        />,
        <BetaButton
          data-action="submit"
          id="ScheduleSubmitButton"
          round
          text={__('Submit')}
          style={{ marginRight: 0 }}
          onClick={() => {
            if (!moment(date).isAfter()) {
              this.setState({ errors: { date: __('You can\'t choose a date or time that has passed.') } });
              return;
            }

            this.props.onSubmit(date);
            this.props.store.appends.pop();
          }}
          primary
          {...submitButton}
        />
      ]}
      header={__('Pick Date & Time')}
      content={[
        <Calendar
          selectedDt={date}
          onSelect={({ selectedDt }) => {
            if (date) {
              selectedDt.setHours(date.getHours());
              selectedDt.setMinutes(date.getMinutes());
            }
            this.setState({ date: selectedDt });
          }}
          minDate={minDate}
          maxDate={maxDate}
        />,
        <span style={{ fontSize: '16px', fontWeight: '700', marginBottom: '12px', width: '280px', alignSelf: 'center' }}>
          {__('Hour')}
        </span>,
        <div className="time-selection">
          <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Dropdown
              upward={window.innerHeight < 860}
              name="hour"
              compact
              selection
              placeholder="--"
              icon="angle down"
              value={date && date.getHours()}
              options={timeOptions.hour}
              onChange={(e, { value }) => this.setTime('hour', value)}
              style={{ marginRight: '12px' }}
            />
            <Form.Dropdown
              upward={window.innerHeight < 860}
              name="minute"
              compact
              selection
              placeholder="--"
              icon="angle down"
              value={date && date.getMinutes()}
              options={timeOptions.minute}
              onChange={(e, { value }) => this.setTime('minute', value)}
            />
          </Form.Group>
        </div>,
        <div>
          {message}
          {errors && errors.date ?
            <div className="error-warning-red" style={{ marginTop: '12px' }}>
              <Icon data-value="error" name="exclamation circle" />
              {errors.date}
            </div>
            :
            null}
        </div>
      ]}
      size="tiny"
    />);
  }
}
