import React from 'react';

import { __ } from '../../../../../../i18n';
import {
  FooterContainer,
  ButtonContainer,
  ChannelInfoContainer,
  SubmitButton,
  CancelButton,
  SubmitButtonContainer,
} from './Footer.styles';

/**
 * How to use it:
    <Footer
      chatNumber={10}
      attendanceNumber={12}
      onCancel={() => console.log('cancel')}
      onSave={() => console.log('submit')}
    />
 */

/**
 * @param {{
 *  chatNumber?: number;
 *  attendanceNumber?: string;
 *  onSave?: () => void;
 *  onCancel?: () => void;
 * }} props
 * @returns {React.Component}
 */
export function Footer({
  onCancel,
  onSave,
  chatNumber,
  attendanceNumber,
  isLoading
}) {
  return (
    <FooterContainer>
      <ChannelInfoContainer>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: __(
              chatNumber !== 1
                ? '%s channels configured as simple talk'
                : '%s channel configured as simple talk',
              chatNumber ?? 0
            )
          }}
        />
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: __(
              attendanceNumber !== 1
                ? '%s channels configured with service status'
                : '%s channel configured with service status',
              attendanceNumber ?? 0
            )
          }}
        />
      </ChannelInfoContainer>
      <ButtonContainer>
        <CancelButton
          size="medium"
          variation="basic"
          onClick={onCancel}
          disabled={isLoading}
        >
          {__('Cancel')}
        </CancelButton>
        <SubmitButtonContainer>
          <SubmitButton
            size="medium"
            variation="primary"
            onClick={onSave}
            disabled={isLoading}
          >
            {__('Save settings')}
          </SubmitButton>
        </SubmitButtonContainer>
      </ButtonContainer>
    </FooterContainer>
  );
}
