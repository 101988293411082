import React from 'react';
import { Message } from '.';
import { act, fireEvent, render } from '../../../../../../../newRender';

describe('Given automatic message input component', () => {
  const mockedProps = {
    value: 'Test message',
    disabled: false,
    onChange: jest.fn(),
    ref: React.createRef(),
  };

  it('should be able to render message without props', () => {
    const { getByTestId } = render(<Message />);

    const textarea = getByTestId('AUTOMATIC_MESSAGE_TEXTAREA');

    expect(textarea).toBeTruthy();
  });

  it('should be able to render some message', () => {
    const { getByText } = render(<Message {...mockedProps} />);

    const message = getByText('Test message');

    expect(message).toBeTruthy();
  });

  it('should be able to change the message', () => {
    const { getByTestId } = render(<Message {...mockedProps} />);

    const textarea = getByTestId('AUTOMATIC_MESSAGE_TEXTAREA');
    fireEvent.change(textarea, { target: { value: 'New message' } });

    expect(mockedProps.onChange).toHaveBeenCalled();
  });

  it('should be able to disable the message using ref', () => {
    const { getByTestId } = render(<Message {...mockedProps} />);
    const textarea = getByTestId('AUTOMATIC_MESSAGE_TEXTAREA');

    act(() => {
      mockedProps.ref.current.changeDisabled(true);
    });

    expect(textarea).toBeDisabled();
  });

  it('should be able to set a new message using ref', () => {
    const { getByTestId } = render(<Message {...mockedProps} />);
    const textarea = getByTestId('AUTOMATIC_MESSAGE_TEXTAREA');

    act(() => {
      mockedProps.ref.current.setMessage('Hello');
    });

    expect(textarea.value).toBe('Hello');
  });

  it('should be able to get the current message using ref', () => {
    render(<Message {...mockedProps} />);

    act(() => {
      mockedProps.ref.current.setMessage('Hi');
    });

    const message = mockedProps.ref.current.getValue();
    expect(message).toBe('Hi');
  });
});
