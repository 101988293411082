import React from 'react';
import { render, fireEvent } from '../../../../../../../../newRender';
import { RadioListItem } from '.';

jest.useFakeTimers();

describe('given RadioListItem component', () => {
  const mocks = {
    option: {
      label: {
        text: 'test',
      },
      value: 'TEST',
    },
    onSelect: jest.fn((value) => value),
  };

  it('should be able to find some text in the component', () => {
    const { getByText } = render(<RadioListItem {...mocks} />);

    const text = getByText(mocks.option.label.text);

    expect(text).toBeVisible();
  });

  it('should be able to choose some text color', () => {
    const { getByText } = render(
      <RadioListItem
        {...mocks}
        option={{
          ...mocks.option,
          label: {
            ...mocks.option.label,
            textColor: 'red',
          },
        }}
      />,
    );

    const container = getByText(mocks.option.label.text);

    expect(container).toHaveStyle({ color: 'red' });
  });

  it('should be able to click the item of the list', () => {
    const { getByText } = render(<RadioListItem {...mocks} />);

    const text = getByText(mocks.option.label.text);
    fireEvent.click(text);

    expect(mocks.onSelect).toHaveBeenCalled();
  });

  it('should be able to click the radiobutton of the list', () => {
    const { queryByTestId } = render(<RadioListItem {...mocks} />);

    const checkbox = queryByTestId('radiolist-item-radiobutton');

    expect(checkbox).toBeDefined();
    fireEvent.click(checkbox);

    expect(mocks.onSelect).toHaveBeenCalled();
  });
});
