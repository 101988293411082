import React from 'react';
import { MagnifyingGlassLight } from '@classapp-tech/edna-icons';
import { Container } from './SearchInput.styles';

export function SearchInput(props) {
  const { placeholder, onChangeValue, debounceTime } = props;
  const [isFocused, setIsFocused] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    const timer = setTimeout(() => {
      onChangeValue(inputValue);
    }, debounceTime);

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  return (
    <Container
      data-testid="search-container"
      isFocused={isFocused}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <MagnifyingGlassLight fontSize={20} />
      <input
        type="text"
        data-testid="search-input"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={placeholder}
      />

      {inputValue.trim() !== '' && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <span onClick={() => {
          setIsFocused(false);
          setInputValue('');
        }}
        >
          <img src="/public/images/CircleXMarkSolid.svg" alt="clear input button" />
        </span>
      )}
    </Container>
  );
}
