
import React from 'react';
import { DefaultPlayer as Video } from 'react-html5video';
import { __ } from '../i18n';

class VideoPlayer extends React.Component {
  componentDidMount() {
    // Workaround for old version of React not supporting attributes "controlslist" and "disablepictureinpicture"
    if (this.props.hideButton) {
      this.ref.setAttribute('controlslist', 'nodownload noremoteplayback');
      this.ref.setAttribute('disablepictureinpicture', true);
    }
  }

  render() {
    const { src, name, thumbnail, loop = false, oldPlayer = false } = this.props;

    return !oldPlayer ? (
      <video
        ref={(ref) => { this.ref = ref; }}
        src={src}
        loop={loop}
        poster={thumbnail}
        style={{ width: '100%', height: '100%', background: 'black' }}
        controls
      >
        {__('Your browser does not support the video tag. You can download it and watch in your favorite video player.')}
        <a href={`/download_file?path=${src}&name=${name}`}>{__('Download the video here!')}</a>
      </video>
    ) : (
      <Video
        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
        poster={thumbnail}
        loop={loop}
        src={src}
      >
        {__('Your browser does not support the video tag. You can download it and watch in your favorite video player.')}
        <a href={src}>{__('Download the video here!')}</a>
      </Video>
    );
  }
}

// import { DefaultPlayer as Video } from 'react-html5video';

// <Video
//   controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
// >
//   <source src={media.preview} type={'video/mp4'} />
//   {__('Your browser does not support the video tag.')}
// </Video>

// <Video
// controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
// poster={media.thumbnail}
// >
// <source src={media.original} type={media.mimetype === 'video/quicktime' ? 'video/mp4' : media.mimetype} />
// {__('Your browser does not support the video tag. You can download it and watch in your favorite video player.')}
// <a href={media.original}>{__('Download the video here!')}</a>
// </Video>

// <Video
// controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
// >
// <source src={media.preview} type="video/mp4" />
// {__('Your browser does not support the video tag.')}
// </Video>


export default VideoPlayer;
