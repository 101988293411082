import React from 'react';

import Form from '../../../components/Form';
import { __ } from '../../../i18n';

/**
 * @param {{
 *  values: {
 *    name: string;
 *    checked: boolean;
 *    isNetworkTag: boolean;
 *  };
 *  errors: {
 *    name: string;
 *  };
 *  edit: boolean;
 *  isNetwork: boolean;
 *  onInputChange: (event: React.ChangeEvent<HTMLInputElement>, data: { name: string; value: string; checked: boolean }) => void;
 * }} props
 */
export function FormContent({
  values,
  errors,
  edit,
  isNetwork,
  onInputChange,
}) {
  return (
    <>
      <Form.Input
        label={__('Name')}
        name="name"
        value={values.name}
        onChange={onInputChange}
        error={errors.name}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Form.Checkbox
          label={__('Initially checked')}
          name="checked"
          disableWithoutBanIcon
          data-value={!!values.checked}
          checked={!!values.checked}
          disabled={isNetwork && edit}
          onClick={() =>
            onInputChange(null, {
              name: 'checked',
              checked: !values.checked,
            })
          }
        />
        {isNetwork && (
          <Form.Checkbox
            label={__('I want it to reflect on the units')}
            name="isNetworkTag"
            disableWithoutBanIcon
            data-value={!!values.isNetworkTag}
            checked={!!values.isNetworkTag}
            disabled={edit}
            onClick={() =>
              onInputChange(null, {
                name: 'isNetworkTag',
                checked: !values.isNetworkTag,
              })
            }
          />
        )}
      </div>
    </>
  );
}
