import React from 'react';
import { __ } from '../../../../../../../../i18n';
import SelectGroup from '../../../../../../../../components/ui/SelectGroup';

const styles = {
  subTitleText: {
    fontWeight: 'bold',
    fontSize: '1.286rem',
    color: '#000000'
  },
  subTitle: {
    marginBottom: '1.25rem'
  },
};

/**
* @typedef {{
*   id: number,
*   name: string,
*  }} SelectedGroup
* @param {{
*   selectedGroups: SelectedGroup[],
*   error: string,
*   visibility: string,
*   disabled: boolean,
*   currentEntityId: number,
*   onSubmit: (SelectedGroup[]) => void,
*   onDelete: (SelectedGroup[]) => void,
* }} param
* @returns {JSX.Element}
*/
export function SelectVisibility({
  selectedGroups,
  error,
  visibility,
  disabled,
  onChange,
  onSubmit,
  onDelete,
}) {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <div style={styles.subTitle}>
        <span style={styles.subTitleText}>{`${__('Visibility')} *`}</span>
      </div>
      <SelectGroup
        selectedItems={selectedGroups}
        error={error}
        visibility={visibility}
        disabled={disabled}
        visibilityFields
        onChange={onChange}
        onSubmit={onSubmit}
        onDelete={onDelete}
      />
    </div>
  );
}
