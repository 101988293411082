import React from 'react';
import { inject } from 'mobx-react';
import gql from 'graphql-tag';
import { Button, Dropdown, Label } from 'semantic-ui-react';

import Controller from './Controller';
import MultipleAutosuggestForm from './MultipleAutosuggestForm';

import { __ } from '../i18n';
import * as utils from '../utils';

const SUGGESTION_SIZE = 20;

const entityDataQuery = gql`
  query EntityDataQuery($id: ID!) {
  node (id: $id) {
    ... on Entity {
      id: dbId
      fullname
      picture {
        uri
        id: dbId
        key
      }
    }
  }
}`;
@inject('client', 'store')
export default class MultipleAutosuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      suggestions: props.requests.map(() => []),
      selected: props.requests.map(() => []),
      values: [],
      hasValueChange: false,
      tempValues: [],
      isLoading: false,
      open: false
    };
    this.lastRequestId = null;
  }

  componentDidMount() {
    const { client, ids, style } = this.props;

    if (ids) {
      this.setState({ isLoading: true });
      Promise.all(ids.map(id => client.query(({ query: entityDataQuery, variables: { id } })))).then((datas) => {
        const results = [[]];
        const values = [];
        datas.forEach((data) => {
          const { id, fullname, picture } = data.data.node;
          results[0].push({
            text: fullname,
            value: `${id} 0`,
            image: style[0].image && { avatar: true, src: (picture && picture.uri) || utils.asset(`/avatars/${utils.normalize(fullname[0].toLowerCase())}.svg`) },
            icon: !style[0].image && style[0].icon,
            'data-id': id
          });
          values.push(`${id} 0`);
        });

        this.setState({ isLoading: false, suggestions: results, selected: results, values });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { requestArguments } = nextProps;
    const { requestArguments: oldRequestArguments } = this.props;
    if (requestArguments[0].id !== oldRequestArguments[0].id) {
      this.setState({
        text: '',
        suggestions: nextProps.requests.map(() => []),
        selected: nextProps.requests.map(() => []),
        values: [],
        isLoading: false,
        open: false
      });
      this.lastRequestId = null;
    }

    const { client, ids, style, requests } = nextProps;
    if (nextProps.location.search !== this.props.location.search) {
      if (ids) {
        this.setState({ isLoading: true });
        Promise.all(ids.map(id => client.query(({ query: entityDataQuery, variables: { id } })))).then((datas) => {
          const results = [[]];
          const values = [];
          datas.forEach((data) => {
            const { id, fullname, picture } = data.data.node;
            results[0].push({
              text: fullname,
              value: `${id} 0`,
              image: style[0].image && { avatar: true, src: (picture && picture.uri) || utils.asset(`/avatars/${utils.normalize(fullname[0].toLowerCase())}.svg`) },
              icon: !style[0].image && style[0].icon,
              'data-id': id
            });
            values.push(`${id} 0`);
          });

          this.setState({ isLoading: false, suggestions: results, selected: results, values });
        });
      } else {
        // props.requests.map(() => [])
        this.setState({
          suggestions: requests.map(() => []),
          selected: requests.map(() => []),
          values: [],
        });
      }
    }
  }

  onSuggestionChange = (values) => {
    let finalSelected = JSON.parse(JSON.stringify(this.state.selected));
    if (values.length > this.state.values.length) {
      const value = values[values.length - 1].split(' '); //value contains id and key
      const id = parseInt(value[0], 10);
      const key = parseInt(value[1], 10);
      finalSelected[key] = this.state.selected[key].concat(this.state.suggestions[key].find(n => parseInt(n.value.replace(/ .$/, ''), 10) === id));
    } else {
      finalSelected = finalSelected.map(() => []);
      values.forEach((val) => {
        const [id, key] = val.split(' ').map(v => parseInt(v, 10));
        const selected = this.state.selected[key].slice();
        finalSelected[key].push(selected.find(n => parseInt(n.value.replace(/ .$/, ''), 10) === id));
      });
    }
    this.setState({ selected: finalSelected, values, hasValueChange: false }, () => this.onBlur());
    this.dropdown.clearSearchQuery();
  }

  onTextChange = (text) => {
    this.setState({ text, open: true }, () => this.startTimeout());
  }

  onBlur = () => {
    const idArray = this.state.selected.map(array =>
      array.map(item => parseInt(item.value.replace(/ .$/, ''), 10))
    );
    const hasChanges = !(JSON.stringify(idArray[0]) === JSON.stringify(this.props.ids));
    if (hasChanges) this.props.handleSelectedItems(idArray);
  }

  onValueChange = (tempValues) => {
    this.setState({
      hasValueChange: true,
      tempValues
    });
  }

  handleOpenModal = () => {
    const { style, apiExtract } = this.props;
    const { selected, values } = this.state;

    this.props.store.appends.push(
      <Controller
        id="searchForPeopleModal"
        confirm
        modal
        title={(__('Search for people...')).replace('...', '')}
        content={
          <MultipleAutosuggestForm
            style={style}
            apiExtract={apiExtract}
            selected={selected}
            values={values}
            onValueChange={this.onValueChange}
            location={this.props.location}
          />
        }
        actions={[
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Cancel')}
            onClick={() => {
              this.setState({ hasValueChange: false });
              this.props.store.appends.pop();
            }}
          />,
          <Button
            data-action="submit"
            key={1}
            primary
            content={__('Confirm')}
            onClick={() => {
              if (this.state.hasValueChange) this.onSuggestionChange(this.state.tempValues);
              this.props.store.appends.pop();
            }}
            disabled={this.props.loading}
            loading={this.state.buttonLoading}
          />
        ]}
      />
    );
  }

  startTimeout = () => {
    const { requests, requestArguments, apiExtract, resultExtract, style, client } = this.props;

    clearTimeout(this.lastRequestId);

    if (this.state.text !== '') {
      this.setState({ isLoading: true });
      this.lastRequestId = setTimeout(() => {
        const id = this.lastRequestId;

        for (let i = 0; i < requestArguments.length; i++) {
          requestArguments[i].search = this.state.text;
        }

        const compensateSelected = this.state.selected.reduce((compensate, sel) => compensate + this.searchFilter(sel, this.state.text).length, 0);
        const limit = SUGGESTION_SIZE + compensateSelected;

        Promise.all(requests.map((request, key) => client.query({ query: request, variables: { ...requestArguments[key], limit } }))).then((datas) => {
          if (id === this.lastRequestId && this.state.text !== '') {
            const final = requests.map(() => []);
            datas.forEach((data, key) => {
              const { edgeName, nodeName = 'node' } = resultExtract[key];
              const result = [];
              data.data[nodeName][edgeName].nodes.forEach((n) => {
                result.push({
                  text: n[apiExtract[key].name],
                  value: `${n.id} ${key}`,
                  image: style[key].image && { avatar: true, src: (n.picture && n.picture.uri) || utils.asset(`/avatars/${utils.normalize(n[apiExtract[key].name][0].toLowerCase())}.svg`) },
                  icon: !style[key].image && style[key].icon,
                  'data-id': n.id
                });
              });
              const newSuggestions = result.filter(suggestion => !this.state.selected[key].find(selected => selected.value === suggestion.value));
              final[key] = this.state.selected[key].concat(newSuggestions);
            });
            this.setState({ isLoading: false, suggestions: final });
          }
        });
      }, 400);
    }
  }

  searchFilter = (options, target) => {
    const newTarget = utils.normalize(target).toLowerCase();
    const newOptions = options.filter(option => utils.normalize(option.text).toLowerCase().indexOf(newTarget) !== -1);

    return newOptions;
  }

  renderLabel = (label, i) => {
    const { style, apiExtract, location } = this.props;
    const { values } = this.state;
    const key = parseInt(label.value.replace(/.* /, ''), 10);
    const maxSize = location.pathname.search('organizations') === -1 ? 5 : 21;
    if (i === 0) {
      return {
        color: style[key].color,
        content: utils.renderLongText(`${label.text}`, maxSize),
        image: style[key].image && { avatar: true, size: 'medium', src: label.image.src || utils.asset(`/avatars/${utils.normalize(label[apiExtract[key].name][0].toLowerCase())}.svg`) },
        icon: !style[key].image && { name: style[key].icon, size: 'large' },
        onClick: () => {
          if (values.length > 1) this.handleOpenModal();
        },
        style: { cursor: values.length > 1 ? 'pointer' : 'unset' }
      };
    }
    if (i === values.length - 1) {
      return (<Label
        className="image"
        size="tiny"
        style={{
          backgroundColor: '#fff',
          fontWeight: 'bold',
          padding: '.5833em .833em .5833em .833em',
          cursor: 'pointer'
        }}
        onClick={() => {
          this.handleOpenModal();
        }}
      >
        {`+${values.length - 1}`}
      </Label>);
    }
    return null;
  }

  render() {
    const { className, cssStyle, icon, placeholder } = this.props;
    const { isLoading, open, suggestions, text, values } = this.state;
    const options = [].concat(...suggestions);

    return (
      <Dropdown
        ref={(c) => { this.dropdown = c; }}
        upward={false}
        fluid multiple scrolling selection
        style={cssStyle}
        className={className}
        selectOnBlur={false}
        renderLabel={this.renderLabel}
        onChange={(e, { value }) => this.onSuggestionChange(value)}
        onClose={() => this.setState({ open: false, text: '' })}
        onFocus={this.props.onFocus}
        placeholder={placeholder}
        icon={icon || 'search'}
        onBlur={this.onBlur}
        open={open}
        noResultsMessage={__('No person was found')}
        loading={isLoading && text !== ''}
        options={options}
        text={text}
        onSearchChange={e => this.onTextChange(e.target.value)}
        minCharacters={0}
        value={values}
        search={this.searchFilter}
      />
    );
  }
}
