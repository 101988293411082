import React from 'react';
import { RenderSent } from '../RenderSent';
import { render } from '../../../../../newRender';

describe('RenderSent', () => {
  it('should render the component', () => {
    const message = {
      recipientsCount: 1,
      toEntity: {
        fullname: 'John Doe',
        status: 0,
      },
      deleted: false,
      unread: false,
    };

    const props = {
      message,
      isMobile: false,
    };

    const { container } = render(<RenderSent {...props} />);
    expect(container.firstChild).toBeInTheDocument();
  });
});
