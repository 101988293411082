import React from 'react';
import { Container } from 'semantic-ui-react';
import { NotificationItem } from '../../../../../../../components/ui/NotificationItem';
import ListView from '../../../../../../../components/ListView';
import { NotificationEmptyState } from '../NotificationEmptyState';
import Loading from '../../../../../../../components/ui/Loading';

export const DirectNotifications = ({
  notifications = [],
  pagination,
  onLoadMore,
  isLoading = false,
  locale,
  onClose,
}) => {
  return (
    <Container
      style={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        ...(notifications.length ? {} : { alignItems: 'center' }),
      }}
    >
      {!!notifications?.length && (
        <ListView
          segment={false}
          renderRow={(notifications, key) => (
            <NotificationItem
              key={key}
              notification={notifications}
              onClick={onClose}
              locale={locale}
              to={
                notifications?.toEntity?.id
                  ? `/entities/${notifications?.toEntity?.id}/chats`
                  : '/'
              }
            />
          )}
          source={notifications}
          pagination={pagination}
          onLoadMore={() => onLoadMore('directNotifications')}
        />
      )}

      {!notifications?.length && !isLoading && <NotificationEmptyState />}

      {isLoading && <Loading width={32} height={32} />}
    </Container>
  );
};
