import React from 'react';
import { fireEvent, render } from '../../../../newRender';

import { Tag } from '../Tag';

describe('<Tag />', () => {
  let props;

  beforeEach(() => {
    props = {
      id: 1,
      name: 'tag',
      onClick: jest.fn(),
      isAdmin: false,
      hasMaster: false,
      onDelete: jest.fn(),
    };
  });

  it('should render the component when props is provided', () => {
    const container = render(<Tag {...props} />);
    expect(container).toBeDefined();
  });

  it('should render the simple label when entity is not admin and user is not master', () => {
    const { getByText } = render(<Tag {...props} />);
    expect(getByText(props.name)).toBeInTheDocument();
  });

  it('should render the edit label when entity is admin and user is not master', () => {
    props.isAdmin = true;
    const { getByTestId } = render(<Tag {...props} />);
    const editButton = getByTestId('edit-tag-icon-1');

    fireEvent.click(editButton);

    expect(editButton).toBeInTheDocument();
    expect(props.onClick).toHaveBeenCalled();
  });

  it('should render the edit label with delete when entity is admin and user is master', () => {
    props.isAdmin = true;
    props.hasMaster = true;
    const { getByTestId } = render(<Tag {...props} />);
    const deleteButton = getByTestId('delete-tag-icon-1');

    fireEvent.click(deleteButton);

    expect(deleteButton).toBeInTheDocument();
    expect(props.onDelete).toHaveBeenCalled();
  });
});
