import React from 'react';
import { __ } from '../../../../../../../../i18n';
import SelectUser from '../../../../../../../../components/ui/SelectUser';

const styles = {
  subTitleText: {
    fontWeight: 'bold',
    fontSize: '1.286rem',
    color: '#000000'
  },
  subTitle: {
    marginBottom: '1.25rem'
  },
};

/**
  * @typedef {{
  *    address: string,
  *    original: boolean,
  *    tags: string[],
  *    type: string,
  *    user: {
  *      confirmEmail: boolean | null,
  *      confirmPhone: boolean | null,
  *      email: string,
  *      fullname: string,
  *      id: number,
  *      phone: string | null,
  *    },
  *  }} SelectedUser
  * @param {{
  *   selectedUsers: SelectedUser[],
  *   error: string,
  *   currentEntityId: number,
  *   onSubmit: (SelectedUser[]) => void,
  *   onDelete: (SelectedUser[]) => void,
  * }} param
  * @returns {JSX.Element}
  */
export function SelectResponsible({
  selectedUsers,
  error,
  currentEntityId,
  onSubmit,
  onDelete,
}) {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <div style={styles.subTitle}>
        <span style={styles.subTitleText}>{`${__('Responsible')} *`}</span>
      </div>
      <SelectUser
        selectedItems={selectedUsers}
        error={error}
        onSubmit={onSubmit}
        onDelete={onDelete}
        currentEntityId={currentEntityId}
      />
    </div>
  );
}
