import React from 'react';
import { toJS } from 'mobx';
import {
  Message, Loader, List, Button
} from 'semantic-ui-react';
import BetaButton from './ui/Button';
import Modal from './Modal';
import BetaModal from './ui/Modal';
import Responsive from './Responsive';
import { __ } from '../i18n';
import * as utils from '../utils';
import * as Analytics from '../lib/analytics';


export default class Controller extends Responsive {
  state = {
    loading: false,
    errors: {}
  }

  /**
   * @param {{ 
   * name?: string,
   * brand: string,
   * ccNumber: string,
   * cvv: string,
   * documentNumber: string,
   * email: string,
   * expiration: string,
   * phone?: string
   * }} values
   */
  onSubmit = async (values) => {
    const { store } = this.props;

    if (values.name) {
      values.name = values.name.trim();
    }
    if (!this.request) return;
    await this.setState({ loading: true });

    if (store.currentUser?.documentNumber !== values.documentNumber) {
      if (store.currentUser?.documentNumber === null || '') {
        Analytics.sendGoogleAnalyticsEvent({
          name: 'add_new_credit_card',
          category: 'user added a new CPF',
        }, {store})
      } else {
        Analytics.sendGoogleAnalyticsEvent({
          name: 'add_new_credit_card',
          category: 'user changed the CPF',
        }, {store})
      }
    }

    if (store.currentUser?.phone !== values.phone) {
      Analytics.sendGoogleAnalyticsEvent({
        name: 'add_new_credit_card',
        category: 'user changed the phone number',
      }, {store})
    }

    if (store.currentUser?.email !== values.email) {
      Analytics.sendGoogleAnalyticsEvent({
        name: 'add_new_credit_card',
        category: 'user changed the email',
      }, {store})
    }

    // @ts-ignore
    if (this.validate && !this.validate(values)) {
      this.setState({ loading: false });
      return;
    }
    await this.request(values).then(() => {
      this.setState({ loading: false });

      if (this.props.onClose) {
        this.props.onClose();
      }
    }).catch((errors) => {
      console.log('catch error', errors);
      if (this.props.store) {
        this.props.store.appends.push(
          // @ts-ignore TS expects prop store to be passed
          <BetaModal
            id="ErrorModal"
            onClose={() => this.props.store.appends.pop()}
            actions={[
              <BetaButton
                data-action="ok"
                round
                text={__('Ok')}
                onClick={() => this.props.store.appends.pop()}
              />
            ]}
            header={__('Error')}
            content={(
              <div>
                <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{utils.handleError(errors.graphQLErrors[0])}</span>
              </div>
            )}
          />
        );
      }
      this.setState({ loading: false, errors: { fullname: utils.handleError(errors.graphQLErrors[0]) } });
    });
  }

  /** @type {null | ((args: any) => Promise<void>)} */
  request = null

  render() {
    const { modalType } = this.props;
    const modal = !!this.props.modal;
    const modalProps = typeof this.props.modal === 'object' ? this.props.modal : {};
    if (this.props.edit && !this.props.values) {
      return (
        modal ? <Modal loading /> : <Loader />
      );
    }

    const submitButton = this.props.submitButton || (this.props.add && __('Add'))
      || (this.props.edit && __('Save'));
    const form = this.props.form
      && (
        <this.props.form
          modal={modal}
          add={this.props.add}
          edit={this.props.edit}
          accountManager={this.props.accountManager}
          toggleLoading={() => this.props.toggleLoading()}
          values={toJS(this.props.values) || this.props.values || (this.props.location && utils.parseQuery(this.props.location.query))}
          loading={this.props.loading}
          errors={this.props.errors}
          refreshRecipients={this?.props?.refreshRecipients}
          onSubmit={this.props.onSubmit}
          submitButton={submitButton}
          disableSave={this.props.disableSave}
          submitButtonIcon={this.props.submitButtonIcon}
          onClose={this.props.onClose}
          onCancel={this.props.onCancel}
          cancelButton={this.props.cancelButton}
          deleteButton={this.props.deleteButton}
          router={this.props.router}
          onFormValuesChange={this.props.onFormValuesChange}
          hasFormValuesChanges={this.props.hasFormValuesChanges}
          {...this.props.formProps}
        />
      );

    if (this.props.delete && this.props.object) {
      if (this.props.id === 'RoleDelete') {
        return (
          <Modal
            id={this.props.id}
            onClose={(this.props.onClose ? this.props.onClose : () => this.props.router.goBack())}
            header={this.props.plural ? __('Are you sure you want to remove these %s?', this.props.object)
              : __('Are you sure you want to remove this %s?', this.props.object)}
            content={<Message warning>{__('Once removed, it can’t be undone.')}</Message>}
            actions={[
              <Button
                data-action="cancel"
                key={0}
                basic
                floated="left"
                content={__('Cancel')}
                disabled={this.props.loading}
                onClick={(this.props.onCancel) ? this.props.onCancel : () => this.props.router.goBack()}
              />,
              <Button
                data-action="submit"
                key={1}
                negative
                content={__('Delete')}
                onClick={this.props.onSubmit}
                disabled={this.props.loading}
                loading={this.props.loading}
              />
            ]}
          />
        );
      }
      return (
        <Modal
          id={this.props.id}
          onClose={(this.props.onClose ? this.props.onClose : () => this.props.router.goBack())}
          header={this.props.userEntity ? __('Unlink User') : (this.props.plural ? __('Are you sure you want to delete these %s?', this.props.object)
            : __('Are you sure you want to delete this %s?', this.props.object))}
          content={(
            <Message warning>
              {
                this.props.userEntity
                  ? (
                    <div>
                      <span style={{ textAlign: 'justify' }}>
                        <span>{__('Are you sure you want to unlink this user? By doing so, the user will lose access to messages from this profile but will not be deleted. We recommend this action') + ' '}</span>
                        <span style={{ fontStyle: 'italic' }}>{__('only if that user can no longer have access to this profile.')}</span>
                      </span>
                      <div style={{ marginTop: '10px' }}>
                        <span>{__('If you wish:')}</span>
                        <List bulleted>
                          <List.Item style={{ marginBottom: '8px' }}>
                            <span>
                              {__('Change the registered data (email / mobile): only the user himself can change the information of his account. See how to do it ')}
                              <a href="https://ajuda.classapp.com.br/hc/pt-br/articles/115001012267-Como-trocar-meus-dados-">{__(' here.')}</a>
                            </span>
                          </List.Item>
                          <List.Item style={{ marginBottom: '8px' }}>
                            <span>
                              {__('Resend access invitation: You do not need to register again. Just send the store link to download the app. See below: ')}
                            </span>
                          </List.Item>
                          <List.Item>
                            <span>
                              {__('Submit the link to download the app: ')}
                              <a href="https://play.google.com/store/apps/details?id=br.com.classapp.ClassApp">{__('Play Store / ')}</a>
                              <a href="https://apps.apple.com/br/app/classapp/id918086112">{__('App Store')}</a>
                            </span>
                          </List.Item>
                        </List>
                      </div>
                    </div>
                  )
                  : __('Once deleted, it can’t be undone.')
              }
            </Message>
          )}
          actions={[
            <Button
              data-action="cancel"
              key={0}
              basic
              content={__('Cancel')}
              disabled={this.props.loading}
              onClick={(this.props.onCancel) ? this.props.onCancel : () => this.props.router.goBack()}
            />,
            <Button
              data-action="submit"
              key={1}
              negative
              content={__('Delete')}
              onClick={this.props.onSubmit}
              disabled={this.props.loading}
              loading={this.props.loading}
            />
          ]}
        />
      );
    } if (this.props.activate && this.props.object) {
      if (this.props.id === 'RoleActivate') {
        return (
          <Modal
            id={this.props.id}
            onClose={(this.props.onClose ? this.props.onClose : () => this.props.router.goBack())}
            header={this.props.plural ? __('Are you sure you want to activate these %s?', this.props.object)
              : __('Are you sure you want to activate this %s?', this.props.object)}
            content={<Message warning>{__('Once activated, it can’t be undone.')}</Message>}
            actions={[
              <Button
                data-action="cancel"
                key={0}
                basic
                floated="left"
                content={__('Cancel')}
                disabled={this.props.loading}
                onClick={(this.props.onCancel) ? this.props.onCancel : () => this.props.router.goBack()}
              />,
              <Button
                data-action="submit"
                key={1}
                negative
                content={__('Activate')}
                onClick={this.props.onSubmit}
                disabled={this.props.loading}
                loading={this.props.loading}
              />
            ]}
          />
        );
      }
      return (
        <Modal
          id={this.props.id}
          onClose={(this.props.onClose ? this.props.onClose : () => this.props.router.goBack())}
          header={this.props.userEntity ? __('Unlink User') : (this.props.plural ? __('Are you sure you want to activate these %s?', this.props.object)
            : __('Are you sure you want to activate this %s?', this.props.object))}
          content={(
            <Message warning>
              {
                this.props.userEntity
                  ? (
                    <div>
                      <span style={{ textAlign: 'justify' }}>
                        <span>{__('Are you sure you want to unlink this user? By doing so, the user will lose access to messages from this profile but will not be deleted. We recommend this action') + ' '}</span>
                        <span style={{ fontStyle: 'italic' }}>{__('only if that user can no longer have access to this profile.')}</span>
                      </span>
                      <div style={{ marginTop: '10px' }}>
                        <span>{__('If you wish:')}</span>
                        <List bulleted>
                          <List.Item style={{ marginBottom: '8px' }}>
                            <span>
                              {__('Change the registered data (email / mobile): only the user himself can change the information of his account. See how to do it ')}
                              <a href="https://ajuda.classapp.com.br/hc/pt-br/articles/115001012267-Como-trocar-meus-dados-">{__(' here.')}</a>
                            </span>
                          </List.Item>
                          <List.Item style={{ marginBottom: '8px' }}>
                            <span>
                              {__('Resend access invitation: You do not need to register again. Just send the store link to download the app. See below: ')}
                            </span>
                          </List.Item>
                          <List.Item>
                            <span>
                              {__('Submit the link to download the app: ')}
                              <a href="https://play.google.com/store/apps/details?id=br.com.classapp.ClassApp">{__('Play Store / ')}</a>
                              <a href="https://apps.apple.com/br/app/classapp/id918086112">{__('App Store')}</a>
                            </span>
                          </List.Item>
                        </List>
                      </div>
                    </div>
                  )
                  : __('Once activated, it can’t be undone.')
              }
            </Message>
          )}
          actions={[
            <Button
              data-action="cancel"
              key={0}
              basic
              content={__('Cancel')}
              disabled={this.props.loading}
              onClick={(this.props.onCancel) ? this.props.onCancel : () => this.props.router.goBack()}
            />,
            <Button
              data-action="submit"
              key={1}
              negative
              content={__('Activate')}
              onClick={this.props.onSubmit}
              disabled={this.props.loading}
              loading={this.props.loading}
            />
          ]}
        />
      );
    } if (this.props.delete) {
      return (
        <Modal
          id={this.props.id}
          onClose={(this.props.onClose ? this.props.onClose : () => this.props.router.goBack())}
          header={this.props.title}
          content={this.props.content}
          actions={this.props.actions || [
            <Button
              data-action="cancel"
              key={0}
              basic
              floated="left"
              content={__('Cancel')}
              disabled={this.props.disabled}
              onClick={(this.props.onCancel) ? this.props.onCancel : () => this.props.router.goBack()}
            />,
            <Button
              data-action="submit"
              key={1}
              negative
              content={this.props.submitButton || __('Delete')}
              onClick={this.props.onSubmit}
              disabled={this.props.disabled}
              loading={this.props.loading}
            />
          ]}
        />
      );
    } if (modal && modalType === 'beta') {
      return (
        <BetaModal
          id={this.props.id}
          header={this.props.title}
          actions={!(this.props.onCancel || this.props.cancelButton)
            ? (
              <BetaButton
                round
                transparent
                text={__('Cancel')}
                onClick={(this.props.onClose ? this.props.onClose : () => this.props.router.goBack())}
              />
            ) : true}
          content={form}
          onClose={(this.props.onClose ? this.props.onClose : () => this.props.router.goBack())}
          {...modalProps}
        />
      );
    }

    if (this.props.confirm) {
      return (
        <Modal
          id={this.props.id}
          header={this.props.title}
          content={this.props.content}
          actions={this.props.actions}
        />
      );
    }

    if (this.props.confirm) {
      return (
        <Modal
          id={this.props.id}
          header={this.props.title}
          content={this.props.content}
          actions={this.props.actions}
        />
      );
    }

    return (
      modal
        ? (
          <Modal
            id={this.props.id}
            header={this.props.title}
            actions={!(this.props.onCancel || this.props.cancelButton)
              ? (
                <Button
                  floated="left"
                  basic
                  transparent
                  content={__('Cancel')}
                  onClick={(this.props.onClose ? this.props.onClose : () => this.props.router.goBack())}
                />
              ) : true}
            content={form}
            onClose={(this.props.onClose ? this.props.onClose : () => this.props.router.goBack())}
            {...modalProps}
          />
        )
        : form
    );
  }
}
