import React from 'react';
import { Icon } from 'semantic-ui-react';
import '../assets/css/ui/NestedCheckbox.module.scss';
import ColoredCheckbox from './ColoredCheckbox';

/**
 * NestedCheckbox component
 * @property Object that may have the properties:
 *   {
 *     key: number | string,
 *     label: React Element,
 *     style: Object,
 *     onMainItemClick: Function,
 *   }
 *
 * @subItems Array of objects. These objects may have the properties
 *  {
 *    checked: boolean,
 *    disabled: boolean,
 *    key: number | string,
 *    label: React Element,
 *    style: Object,
 *    onSubItemClick: Function
 *  }
 */
export default class NestedCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false
    };
  }

  getMainItemCheckboxStatus = () => {
    const { subItems } = this.props;

    const checkedSubItemsAmount = subItems && subItems.filter(item => item.checked).length;
    const checked = subItems && subItems.length > 0 && subItems.length === checkedSubItemsAmount;
    const indeterminate = !checked && checkedSubItemsAmount > 0;
    const disabled = !subItems || !subItems.length;

    return { checked, indeterminate, disabled };
  }

  renderMainItem = () => {
    const { mainItem, subItems } = this.props;
    const { key, label, style, onMainItemClick, onDisabledClick } = mainItem;
    const { isDropdownOpen } = this.state;

    const { checked, indeterminate, disabled } = this.getMainItemCheckboxStatus();
    const hasBorderBottom = (subItems[0] && !subItems[0].checked) || !isDropdownOpen;
    const backgroundColor = subItems.length ? ((checked || indeterminate) ? '#F2F7FF' : '#FFF') : '#F0F0F0';

    const className = `
      mainItem
      ${checked ? 'checked' : ''}
      ${disabled ? 'disabled' : ''}
      ${hasBorderBottom ? 'border-bottom' : ''}
    `;
    const iconName = `angle ${isDropdownOpen ? 'up' : 'down'}`;

    return (
      <div
        key={key}
        className={className}
        style={{ backgroundColor, ...style }}
      >
        <div onClick={disabled ? () => onDisabledClick() : null}>
          <ColoredCheckbox
            checked={checked}
            indeterminate={indeterminate}
            disabled={disabled}
            disableWithoutBanIcon
            onClick={() => onMainItemClick({ checked, indeterminate })}
          />
          {label}
        </div>
        {subItems.length > 0 && <Icon name={iconName} size="large" onClick={() => this.setState(prevState => ({ isDropdownOpen: !prevState.isDropdownOpen }))} />}
      </div>
    );
  }

  renderSubItem = (item, index) => {
    const { checked, disabled, key, label, style, onSubItemClick } = item;
    const { subItems } = this.props;

    const hasBorderTop = index > 0 && !subItems[index - 1].checked;
    const hasBorderBottom = index === subItems.length - 1 || !subItems[index + 1].checked;
    const className = `
      subItem
      ${checked ? 'checked' : ''}
      ${disabled ? 'disabled' : ''}
      ${hasBorderTop ? 'border-top' : ''}
      ${hasBorderBottom ? 'border-bottom' : ''}
    `;

    return (
      <div key={key} className={className} style={style}>
        <ColoredCheckbox
          checked={checked}
          disabled={disabled}
          disableWithoutBanIcon
          onClick={onSubItemClick}
        />
        {label}
      </div>
    );
  };

  render() {
    const { subItems } = this.props;
    // const allSelected = subItems && subItems.every(item => item.checked);

    return (
      <div className="nestedCheckbox">
        {this.renderMainItem()}
        {this.state.isDropdownOpen &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {subItems.map((item, index) => this.renderSubItem(item, index))}
          </div>
        }
      </div>
    );
  }
}
