import React from 'react';
import {
  List, Loader, Message, Segment, Image
} from 'semantic-ui-react';
import omit from 'lodash/omit';

import Pagination from './Pagination';

import { __ } from '../i18n';
import * as utils from '../utils';

export default class ListView extends React.Component {
  render() {
    const {
      segment, source, pagination, renderRow, emptyMessage, onUpdate, size, onLoadMore, horizontal, id, appends, prepends, notCelled, classNameLabels,
      isMessageToApprove
    } = this.props;

    const newProps = omit(
      this.props,
      ['segment', 'source', 'pagination', 'renderRow', 'emptyMessage', 'onUpdate', 'size', 'onLoadMore', 'horizontal', 'id', 'appends', 'prepends', 'notCelled', 'noBorder']
    );

    if (!source) {
      return (<Loader active inline="centered" data-testid="loader" />);
    }

    if (source.length === 0 && emptyMessage !== '') {
      return (
        <Segment basic textAlign="center">
          <Image style={{ maxHeight: 200 }} src={utils.asset('/images/ilustracoes-30.png')} centered />
          <div style={{ color: '#9696a0' }}>{(emptyMessage !== undefined) ? emptyMessage : __('There are no items to display')}</div>
        </Segment>
      );
    }

    const items = source.map((item, key) => renderRow(item, key));

    const list = (
      <List data-testid="list" relaxed selection celled={!notCelled} horizontal={horizontal} verticalAlign="middle" size={size} {...newProps}>
        {prepends && prepends.length ? prepends : null}
        {items}
        {appends && appends.length ? appends : null}
        {
          pagination
          && (
          <Pagination
            isMessageToApprove={isMessageToApprove}
            source={source}
            onUpdate={onUpdate}
            pagination={pagination}
            emptyMessage={emptyMessage}
            onLoadMore={onLoadMore}
          />
          )
        }
        {this.props.children}
      </List>
    );

    if (segment === false) {
      return list;
    }

    return (
      <Segment className={classNameLabels ? `${classNameLabels}` : ''} id={id} style={{ padding: 0 }} {...(segment || {})}>
        {list}
      </Segment>
    );
  }
}
