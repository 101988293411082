import React from 'react';
import { render, fireEvent } from '../../../../../../newRender';
import { RadioButton } from '.';

jest.useFakeTimers();

describe('Given RadioButton Component', () => {
  const mocks = {
    selected: false,
    onChange: jest.fn((e) => e),
  };

  it('should be able to render RadioButton', () => {
    const radio = render(<RadioButton {...mocks} />);

    expect(radio).toBeDefined();
  });

  it('should not be able to press the radio button if it is disabled', () => {
    const { getByTestId } = render(
      <RadioButton
        data-testid="EDNA_RADIO_CONTAINTER_TEST_ID"
        disabled
        {...mocks}
      />,
    );

    const radio = getByTestId('EDNA_RADIO_CONTAINTER_TEST_ID');
    fireEvent.click(radio);

    expect(mocks.onChange).not.toHaveBeenCalled();
  });

  it('should be able to select the radio', async () => {
    const { findByTestId } = render(
      <RadioButton
        data-testid="EDNA_RADIO_CONTAINTER_TEST_ID"
        {...mocks}
        dotProps={{ testID: 'EDNA_RADIO_ICON_TEST_ID' }}
      />,
    );

    const radio = await findByTestId('EDNA_RADIO_CONTAINTER_TEST_ID');
    fireEvent.click(radio);

    const dot = await findByTestId('EDNA_RADIO_ICON_TEST_ID');

    expect(mocks.onChange).toHaveBeenCalled();
    expect(dot).toBeVisible();
  });

  it('should be able to select the radio', async () => {
    const { findByTestId } = render(
      <RadioButton
        data-testid="EDNA_RADIO_CONTAINTER_TEST_ID"
        {...mocks}
        dotProps={{ testID: 'EDNA_RADIO_ICON_TEST_ID' }}
      />,
    );

    const radio = await findByTestId('EDNA_RADIO_CONTAINTER_TEST_ID');
    fireEvent.click(radio);

    expect(mocks.onChange).toHaveBeenCalled();
  });

  it('should be able to use the radio ref', async () => {
    mocks.selected = false;
    const radioRef = React.createRef();

    const { findByTestId } = render(<RadioButton
      radioContainer={{ testID: 'EDNA_RADIO_CONTAINTER_TEST_ID' }}
      dotProps={{ testID: 'EDNA_RADIO_ICON_TEST_ID' }}
      ref={radioRef}
      {...mocks}
    />);

    const radio = await findByTestId('EDNA_RADIO_CONTAINTER_TEST_ID');
    fireEvent.click(radio);

    expect(radioRef.current?.isActive).toBeTruthy();
  });

  it('should be able to render some text', () => {
    const { getByText } = render(<RadioButton {...mocks} text="some text" />);

    const text = getByText('some text');

    expect(text).toBeVisible();
  });
});
