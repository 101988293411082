export function validateRangeTime(params) {
  if (!params?.open && !params?.close) return true;

  if ((params?.open && !params?.close) || (!params?.open && params?.close)) return false;

  const { open, close } = params;

  const openTimeSplit = open.split(':');
  const closeTimeSplit = close.split(':');

  const openDate = new Date(2022, 0, 1, openTimeSplit[0], openTimeSplit[1], 0).getTime();
  const closeDate = new Date(2022, 0, 1, closeTimeSplit[0], closeTimeSplit[1], 0).getTime();

  if (openDate >= closeDate) return false;

  return true;
}
