/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { render } from '../../../../../newRender';

import { Info } from '../Info';

describe('Info', () => {
  it('should render', () => {
    const props = {
      info: {
        style: {},
        name: 'name',
        icon: <div />,
        summary: 'summary',
      },
    };
    const { getByText } = render(<Info {...props} />);
    expect(getByText(props.info.name)).toBeInTheDocument();
  });
});
