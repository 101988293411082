import React from 'react';

export default class CustomLabel extends React.Component {
  render() {
    const { x, y, stroke, value, viewBox: { width } } = this.props;
    return (
      <text x={x} y={y} dx={width / 2} dy={-20} fill={stroke} fontSize={24} fontWeight="bold" textAnchor="middle">{value}</text>
    );
  }
}
