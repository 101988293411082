import React from 'react';

import { Popup, Icon, Dropdown } from 'semantic-ui-react';

import Responsive from './Responsive';

import { __ } from '../i18n';
import {
  formatBytes, getAttachmentIcon, isImageFile, isVideoFile
} from '../utils';

const styles = {
  container: {
    backgroundColor: '#f3f4f5',
    borderRadius: '12px',
    width: '45%',
    minWidth: 100,
    maxWidth: 156,
    margin: '0 12px 12px 0',
    flexShrink: 0,
    position: 'relative',
    padding: 0,
  },
  progress: {
    height: '100%',
    borderRadius: '0 12px 12px 0',
    backgroundColor: 'black',
    opacity: '.6',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  hoverReveal: {
    backgroundColor: '#f3f4f5',
    borderRadius: '12px',
    width: '100%',
    height: '100%',
    padding: 0
  },
  wordWrapped: {
    color: 'black',
    padding: 12,
    display: 'inline-flex'
  },
  attachmentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '0 12px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'black'
  }
};

const getThumb = (media) => {
  let thumb;
  if (
    isVideoFile(media.type, media.name)
  ) {
    thumb = (
      <div
        style={{
          height: '100%',
          width: '100%',
          opacity: media.loading ? 0.24 : 1,
          boxShadow: '0px 0px 2px rgb(0,0,0,0.2)'
        }}
      >
        <video
          style={{
            width: '100%', height: '100%', backgroundSize: 'cover', objectFit: 'cover', borderRadius: '12px'
          }}
          controls={false}
          preload="metadata"
        >
          <source src={`${media.preview}#t=0.1`} type="video/mp4" />
        </video>
      </div>
    );
  } else if (isImageFile(media.type, media.name)) {
    thumb = (
      <div
        data-testid="img-thumb"
        style={{
          height: '100%',
          width: '100%',
          background: 'url(' + (media.thumbnail || media.preview) + ')',
          backgroundSize: ' cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          borderRadius: '12px',
          opacity: media.loading ? 0.24 : 1,
          boxShadow: '0px 0px 2px rgb(0,0,0,0.2)',
        }}
      />
    );
  }

  return thumb;
};

const getTextColor = (media) => {
  if (
    isImageFile(media.type, media.name)
    || isVideoFile(media.type, media.name)
  ) { return 'white'; }
  return 'black';
};

const hasPreview = media => (
  isImageFile(media.type, media.name)
  || isVideoFile(media.type, media.name)
);

class AttachmentPreview extends Responsive {
  shouldHavePreview = (onPreview, onDelete) => {
    const { isMobile } = this.props;

    const removeIconStyle = isMobile
      ? {
        position: 'absolute',
        right: '5px',
        top: '16px'
      }
      : {
        position: 'absolute',
        right: '12px',
        bottom: '16px'
      };

    return (
      <span style={removeIconStyle}>
        <Dropdown icon={null} trigger={<Icon name="ellipsis v" title={__('Options')} />} upward={!isMobile}>
          <Dropdown.Menu className="bold">
            <Dropdown.Item
              data-action="preview"
              onClick={onPreview}
              style={{ cursor: 'pointer' }}
            >
              {__('Preview')}
            </Dropdown.Item>
            <Dropdown.Item data-action="delete" onClick={onDelete}>
              {__('Remove')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </span>
    );
  }

  shouldHaveNotPreview = (onDelete) => {
    const { isMobile } = this.props;

    const trashIconStyle = isMobile ? {
      position: 'absolute',
      right: '5px',
      top: '16px',
      cursor: 'pointer',
      color: 'rgba(0,0,0,.6)'
    } : {
      position: 'absolute',
      right: '12px',
      bottom: '16px',
      cursor: 'pointer',
      color: 'rgba(0,0,0,.6)'
    };

    return (
      <span
        data-action="delete"
        onClick={onDelete}
        style={trashIconStyle}
        title={__('Remove')}
      >
        <Icon name="trash" />
      </span>
    );
  }

  renderMobileLoading = () => {
    const { media, onDelete, onPreview } = this.props;
    const { progress, name, size } = media;
    const thumb = getThumb(media);

    const shouldHavePreview = onPreview && hasPreview(media);

    const trashIconStyle = shouldHavePreview ? { cursor: 'pointer', color: 'rgba(0,0,0,.6)' }
      : {
        cursor: 'pointer', color: 'rgba(0,0,0,.6)', position: 'absolute', right: '5px', bottom: '0px', top: '16px'
      };

    return (
      <div style={{ width: '100%', height: '100%' }}>
        {thumb || (
          <span className="wordWrapped" style={styles.wordWrapped}>
            {name.length > 20 ? (
              <Popup
                trigger={<p style={{ width: '75%' }}>{name.replace(/^(.{20}).+/, '$1...')}</p>}
                content={<span>{name}</span>}
              />
            ) : (<p style={{ width: '75%' }}>{name}</p>)}
          </span>
        )}
        <span data-action="delete" onClick={onDelete} title={__('Remove')} style={trashIconStyle}>
          <Icon name="trash" />
        </span>
        <div style={{ ...styles.progress, width: `${100 - progress}%` }} />
        <div style={styles.attachmentContainer}>
          <Icon name={getAttachmentIcon(media.type, media.name)} />
          <span style={{ marginRight: 'auto', marginLeft: 4, color: 'rgba(0,0,0,.6)' }}>
            {typeof size === 'number' && formatBytes(size, 1)}
          </span>
        </div>
      </div>
    );
  }

  renderLoading = () => {
    const { media, onDelete } = this.props;
    const { progress, name, size } = media;
    const thumb = getThumb(media);

    return (
      <div style={{ width: '100%', height: '100%' }} data-testid="attachment-loading">
        {thumb || (
          <span className="wordWrapped" style={styles.wordWrapped}>
            {name.length > 36 ? (
              <Popup
                trigger={<p style={{ width: '75%' }}>{name.replace(/^(.{20}).+/, '$1...')}</p>}
                content={<span>{name}</span>}
              />
            ) : (<p style={{ width: '75%' }}>{name}</p>)}
          </span>
        )}
        <div style={{ ...styles.progress, width: `${100 - progress}%` }} />
        <div style={styles.attachmentContainer}>
          <Icon name={getAttachmentIcon(media.type, media.name)} />
          <span style={{ marginRight: 'auto', marginLeft: 4, color: 'rgba(0,0,0,.6)' }}>
            {typeof size === 'number' && formatBytes(size, 1)}
          </span>
          <span data-action="delete" onClick={onDelete} title={__('Remove')} style={{ cursor: 'pointer', color: 'rgba(0,0,0,.6)' }}>
            <Icon name="trash" />
          </span>
        </div>
      </div>
    );
  }

  render() {
    const {
      media, insideReply, onDelete, onPreview, isMobile
    } = this.props;
    const {
      uniqueId, loading, name, size
    } = media;
    const height = insideReply ? 130 : 115;
    const thumb = getThumb(media);

    const shouldHavePreview = onPreview && hasPreview(media);

    if (loading) {
      return (
        <div key={uniqueId} style={{ ...styles.container, height, overflow: thumb && loading ? 'hidden' : 'initial' }}>
          {isMobile ? this.renderMobileLoading() : this.renderLoading()}
        </div>
      );
    }

    return (
      <div key={uniqueId} style={{ ...styles.container, height, overflow: thumb && loading ? 'hidden' : 'initial' }}>
        <div className="hoverReveal" style={styles.hoverReveal}>
          {thumb}
          <div
            className="wordWrapped"
            style={{
              width: '100%', height: '100%', padding: '12px', borderRadius: '12px', color: getTextColor(media)
            }}
          >
            {name.length > 20 ? (
              <Popup trigger={<p style={{ width: '75%' }}>{name.replace(/^(.{20}).+/, '$1...')}</p>} content={<span>{name}</span>} />
            ) : (<p style={{ width: '75%' }}>{name}</p>)}
            <span style={{ position: 'absolute', bottom: '16px', color: getTextColor(media) }}>
              <Icon name={getAttachmentIcon(media.type, media.name)} />
              {typeof size === 'number' && formatBytes(size, 1)}
            </span>

            {(shouldHavePreview) ? this.shouldHavePreview(onPreview, onDelete) : this.shouldHaveNotPreview(onDelete)}
          </div>
        </div>
      </div>
    );
  }
}

export default AttachmentPreview;
