import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 16px;

  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #fafafa;
  }

  tbody {
    tr {
      border-bottom: 1px solid #e5e5e5;
    }
  }
`;

export const StyledTableTh = styled.th`
  text-align: left;
  padding: 13.5px 16px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  height: 51px;
`;

export const StyledTableTdContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  gap: 16px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
`;

export const StyledTableTdContent = styled.span`
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export const NoDataFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  line-height: 160%;
  margin: 80px 0 144px;

  h3 {
    font-size: 18px;
    font-weight: 700;
  }

  p {
    color: #616161;
    font-size: 16px;
    font-weight: 450;
  }

  img {
    width: 326px;
    height: 190px;
  }
`;
