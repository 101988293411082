import React from 'react';
import { Popup } from 'semantic-ui-react';
import { CircleInfoLight, CircleExclamationSolid } from '@classapp-tech/edna-icons';

import {
  ErrorContainer, ErrorText
} from './SelectHours.styles';

import Form from '../../../../../../../../components/ui/Form';

import { __ } from '../../../../../../../../i18n';

const acceptedTimes = {
  hour: ['07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06'],
  minute: ['00', '15', '30', '45']
};

const timeOptions = (parentTestID) => acceptedTimes.hour
  .reduce((acc, hour) => {
    const newAcc = [...acc];
    acceptedTimes.minute.forEach(minute => newAcc.push(hour + ':' + minute));
    return newAcc;
  }, ['--:--'])
  .map(v => ({ text: v, value: v, 'data-testid': `${parentTestID}-${v}` }));

/**
 * @typedef {{
*  openTimeValue: string,
*  openTimeError?: string,
*  openTimeChange: (event: Event) => void
*  closeTimeValue: string,
*  closeTimeError?: string,
*  closeTimeChange: (event: Event) => void
* }} Props
*
* @param {Props} props
* @returns {JSX.Element}
*/

/**
 * @typedef {{
*  openTimeValue: string,
*  openTimeError?: string,
*  openTimeChange: (event: Event) => void
*  closeTimeValue: string,
*  closeTimeError?: string,
*  closeTimeChange: (event: Event) => void
* }} Props
*
* @param {Props} props
* @returns {JSX.Element}
*/

export function SelectHours({
  value,
  onTimeChange,
  error
}) {
  return (
    <div>
      <div style={{ marginBottom: '1.25rem', display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            fontWeight: 'bold',
            fontSize: '1.286rem',
            color: '#000000'
          }}
        >
          {__('Office Hours')}
        </span>
        <Popup
          trigger={<CircleInfoLight style={{ opacity: '.6', marginLeft: '5px' }} name="info circle" fill="grey" />}
          content={(
            <div>
              <span>{__('Select the service hours to be displayed. See how it works ')}</span>
              <a
                href="https://ajuda.classapp.com.br/hc/pt-br/articles/4407625235995"
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: 'pointer' }}
              >
                {__('clicking here.')}
              </a>
            </div>
          )}
          hideOnScroll
          basic
          mouseLeaveDelay={1000}
        />
      </div>
      <Form.Group
        widths="4"
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '0px',
          marginTop: '1rem'
        }}
      >
        <span style={{ marginRight: '0.5rem' }}>{__('from (time)')}</span>
        <Form.Dropdown
          data-testid="open-time-dropdown"
          className="form-dropdown"
          style={{ marginBottom: '0', border: error ? '2px solid #BF2600' : '1px solid #CCC' }}
          name="open"
          icon="chevron down"
          compact
          fluid
          selection
          placeholder="--:--"
          value={value?.open}
          options={timeOptions('open-time-dropdown')}
          onChange={(e, target) => (target?.value === '--:--'
            ? onTimeChange({ ...value, open: '' })
            : onTimeChange({ ...value, open: target?.value }))}
        />
        <span style={{ margin: '0rem 0.5rem' }}>{__('to (time)')}</span>
        <Form.Dropdown
          data-testid="close-time-dropdown"
          className="form-dropdown"
          name="close"
          style={{ border: error ? '2px solid #BF2600' : '1px solid #CCC' }}
          icon="chevron down"
          compact
          fluid
          selection
          placeholder="--:--"
          value={value?.close}
          options={timeOptions('close-time-dropdown')}
          onChange={(e, target) => (target?.value === '--:--'
            ? onTimeChange({ ...value, close: '' })
            : onTimeChange({ ...value, close: target?.value }))}
        />
      </Form.Group>
      <div>
        {error
          && (
            <ErrorContainer>
              <CircleExclamationSolid fill="#BF2600" />
              <ErrorText>{error}</ErrorText>
            </ErrorContainer>
          )}
      </div>
    </div>
  );
}
