import styled, { css } from 'styled-components';

const headerStyle = (isFull = false) => {
  if (isFull) {
    return css`
      width: 100%;
      text-align: center;
    `;
  }

  return '';
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Header = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const TabHeader = styled.li`
  font-family:
    Circular Std,
    sans-serif;
  font-size: 1.143rem;
  line-height: 2rem;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? '700' : '500')};
  box-shadow: ${({ isActive }) =>
    isActive ? '0px -4px 1px -1px #0069FF inset' : 'unset'};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.pen.main : theme.colors.pencil.bold};
  ${(props) => headerStyle(props.isFull)}
`;

export const TabContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
`;
