import React from 'react';
import {
  Menu, Icon, Button, Loader, Dropdown
} from 'semantic-ui-react';
import { Button as DSButton } from '@classapp-tech/edna';
import { uniqBy } from 'lodash';

import { __ } from '../i18n';

const options = [
  { text: '40', value: '40' },
  { text: '80', value: '80' },
  { text: '120', value: '120' }
];

export default class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getOptions = () => {
    const {
      totalCount, query, limit, noAllOption
    } = this.props.pagination;

    if (totalCount != null) {
      const value = totalCount.toString();
      const newOptions = noAllOption ? [] : [{ text: __('All'), value }];

      if (query && query.limit && query.limit !== value) {
        newOptions.push({ text: query.limit, value: query.limit });
      }

      if (limit) newOptions.push({ text: limit.toString(), value: limit.toString() });

      return uniqBy([...newOptions, ...options], 'value');
    }

    return [];
  }

  loadMore = (name, info, limit) => {
    const { pagination, onLoadMore } = this.props;

    if (name === 'loadMore') {
      onLoadMore(info);
    } else if (name === 'p') {
      const query = {
        ...pagination.query,
        limit,
        p: info
      };

      onLoadMore(query);
    } else if (name === 'limit') {
      const query = {
        ...pagination.query,
        limit: info,
        p: 1
      };

      onLoadMore(query);
    }
  }

  handleChange = (e, { value }) => {
    this.loadMore('limit', value);
  }

  renderButton = () => {
    const { pagination, isMessageToApprove } = this.props;
    const { loadingNext } = this.state;
    if (pagination.type === 'new') {
      return (
        <div
          className="defaultColor bold centeredItems"
          style={{ padding: '8px 16px', height: '44px', fontSize: '12px' }}
          loading={loadingNext}
          disabled={loadingNext}
          onClick={() => this.loadMore('loadMore', pagination)}
        >
          {__('See more').toUpperCase()}
        </div>
      );
    }

    if (isMessageToApprove) {
      return (
        <DSButton
          variation="basic"
          disabled={loadingNext}
          onClick={() => this.loadMore('loadMore', pagination)}
        >
          {__('Load more')}
        </DSButton>
      );
    }

    return (
      <Button
        basic
        loading={loadingNext}
        disabled={loadingNext}
        onClick={() => this.loadMore('loadMore', pagination)}
      >
        {__('Load more')}
      </Button>
    );
  }

  render() {
    const { pagination, loaderInModal } = this.props;

    if (pagination === null || (!pagination.hasNextPage && !pagination.query)) {
      return null;
    } if (!pagination.query && pagination.loading) {
      return (
        <Menu.Item style={{ textAlign: 'center' }}>
          {loaderInModal ? <Loader active className="workaround" inline="centered" data-testid="loader" />
            : <Loader active inline="centered" />}
        </Menu.Item>
      );
    } if (!pagination.query) {
      return (
        <Menu.Item style={{ textAlign: 'center', padding: '10px 0' }}>
          {(this.state.loadingNext)
            ? loaderInModal ? <Loader active className="workaround" inline="centered" />
              : <Loader active inline="centered" />
            : this.renderButton()}
        </Menu.Item>
      );
    }

    const {
      query, totalCount, loading, basic
    } = pagination;
    const limit = (query.limit && parseInt(query.limit, 10)) || pagination.limit || 40;
    const totalPages = Math.ceil(totalCount / limit);
    const currentPage = (query.p && parseInt(query.p, 10)) || 1;
    const prev = currentPage > 0 ? currentPage - 1 : null;
    const next = currentPage < totalPages ? currentPage + 1 : null;
    const pages = [];

    if (prev) {
      pages.push(
        <Menu.Item data-action="load-more" data-params={prev} key="prev" onClick={() => this.loadMore('p', prev, limit)} icon>
          <Icon name="left chevron" />
        </Menu.Item>
      );
    }

    if (totalPages < 10) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <Menu.Item
            data-action="load-more"
            data-params={i}
            key={i}
            active={i === currentPage}
            onClick={() => this.loadMore('p', i, limit)}
          >
            {
              i === currentPage && loading
                ? loaderInModal ? <Loader active className="workaround" inline="centered" />
                  : <Loader active />
                : i
            }
          </Menu.Item>
        );
      }
    } else {
      let current = 0;

      for (let i = 1; i <= 2 && i < currentPage; i++) {
        current = i;
        pages.push(
          <Menu.Item
            data-action="load-more"
            data-params={i}
            key={i}
            onClick={() => this.loadMore('p', i, limit)}
          >
            {i}
          </Menu.Item>
        );
      }

      if (current < currentPage - 3) pages.push(<Menu.Item disabled key="sep1">...</Menu.Item>);

      for (let i = Math.max(currentPage - 2, current + 1); i <= currentPage + 2; i++) {
        if (i <= totalPages && (i <= totalPages - 2 || i <= currentPage)) {
          current = i;
          pages.push(
            <Menu.Item
              data-action="load-more"
              data-params={i}
              key={i}
              active={i === currentPage}
              onClick={() => this.loadMore('p', i, limit)}
            >
              {
                i === currentPage && loading
                  ? loaderInModal ? <Loader active className="workaround" />
                    : <Loader active />
                  : i
              }
            </Menu.Item>
          );
        }
      }

      if (current < totalPages - 2) pages.push(<Menu.Item disabled key="sep2">...</Menu.Item>);

      for (let i = Math.max(current + 1, totalPages - 1); i <= totalPages; i++) {
        pages.push(
          <Menu.Item
            data-action="load-more"
            data-params={i}
            key={i}
            onClick={() => this.loadMore('p', i, limit)}
          >
            {i}
          </Menu.Item>
        );
      }
    }

    if (next) {
      pages.push(
        <Menu.Item
          data-action="load-more"
          data-params={next}
          key="next"
          onClick={() => this.loadMore('p', next, limit)}
          icon
        >
          <Icon name="right chevron" />
        </Menu.Item>
      );
    }

    if (basic && pages.length < 2) return null;

    return (
      <div style={basic ? { padding: '.8em', borderTop: '1px solid #eeeeee' } : {}}>
        {
          pages.length > 0
          && <Menu pagination>{pages}</Menu>
        }
        <Menu pagination floated="right">
          <Dropdown
            data-action="select-limit"
            compact
            upward
            selectOnBlur={false}
            selection
            placeholder={__('Choose number of items per page') + '...'}
            value={limit.toString()}
            options={this.getOptions()}
            onChange={this.handleChange}
          />
        </Menu>
      </div>
    );
  }
}
