import React from 'react';
import Banner from './Banner';
import { __ } from '../i18n';

export function OmieBanner() {
  const bannerTheme = {
  // this name will be used for cookies and Google Analytics
    name: 'OmieAlert',
    additionalClass: 'compact',
    content: {
      plainText: [
        <div
          key="text"
          dangerouslySetInnerHTML={{
            __html: __(
              'Important Notice! Your invoices have been sent to the registered email address. If you have not received them, click here for more details or request them via email at financeiro@classapp.com.br'
            )
            .replace(
              /click here|clique aqui/,
              `<a href="https://ajuda.classapp.com.br/hc/pt-br/articles/28543256038939-%C3%81rea-Financeira-para-parceiros-ClassApp" target="_blank" style="color: #0069FF; text-decoration: underline;">$&</a>`
            )
            .replace(
              /financeiro@classapp.com.br/,
              `<a href="mailto:financeiro@classapp.com.br" target="_blank" style="color: #0069FF; text-decoration: underline;">$&</a>`
            )
          }}
        />
      ],
      boldText: '',
      color: '#0069FF',
      fontSize: '14px',
      textAlign: 'center',
    },
    // actionButton: {
    //   text: __('Learn more'),
    //   color: '#FFFFFF',
    //   backgroundColor: '#0069FF',
    // },
    backgroundColor: ' #FFF08D',
    images: {
      left: '',
      right: '',
    },
    closeButton: {
      visible: true,
      color: '#0069FF',
    },
  };

  return (
    <Banner
      images={bannerTheme.images}
      additionalClass={bannerTheme.additionalClass}
      content={bannerTheme.content}
      // actionButton={bannerTheme.actionButton}
      closeButton={bannerTheme.closeButton}
      backgroundColor={bannerTheme.backgroundColor}
      name={bannerTheme.name}
      // onActionButtonClick={() => {
      //     window.open(
      //       'https://ajuda.classapp.com.br/hc/pt-br/articles/28543256038939-%C3%81rea-Financeira-para-parceiros-ClassApp',
      //       '_blank',
      //     );
      //   }
      // }
    />
  );
}
