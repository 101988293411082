/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { __ } from '../../../../i18n';

export function StatusIcon({ status, applicationName = null }) {
  if (status === 0) {
    return (
      <div data-testid="zero-status-container" style={{ display: 'flex' }}>
        <Icon name="check" />
        <span>{__('Sent')}</span>
      </div>
    );
  }

  if (status === 1) {
    return (
      <div style={{ display: 'flex' }}>
        <Icon color="black" name="check double" />
        <span>{__('Received')}</span>
      </div>
    );
  }

  if (status === 2) {
    const userReadBy =
      applicationName &&
      (applicationName === 'ClassApp Web' ||
        applicationName === 'ClassApp iOS' ||
        applicationName === 'ClassApp Android')
        ? 'ClassApp'
        : 'Email';
    return (
      <div style={{ display: 'flex' }}>
        <Icon name="check double" style={{ color: '#0080FF' }} />
        <span>{__('Read')}</span>
        {applicationName ? (
          <span
            style={{ marginLeft: '3px', opacity: '.5', fontWeight: 'bold' }}
          >
            ({userReadBy})
          </span>
        ) : null}
      </div>
    );
  }

  if (status === 3) {
    if (!applicationName) {
      return (
        <div style={{ display: 'flex' }}>
          <Icon color="black" name="check double" />
          <span>{__('Received')}</span>
        </div>
      );
    }

    const userReadBy =
      // eslint-disable-next-line sonarjs/no-gratuitous-expressions
      applicationName &&
      (applicationName === 'ClassApp Web' ||
        applicationName === 'ClassApp iOS' ||
        applicationName === 'ClassApp Android')
        ? 'ClassApp'
        : 'Email';

    return (
      <div style={{ display: 'flex' }}>
        <Icon name="check double" style={{ color: '#0080FF' }} />
        <span>{__('Read')}</span>
        {applicationName ? (
          <span
            style={{ marginLeft: '3px', opacity: '.5', fontWeight: 'bold' }}
          >
            ({userReadBy})
          </span>
        ) : null}
      </div>
    );
  }

  if (status === 4) {
    if (!applicationName) {
      return (
        <div style={{ display: 'flex' }}>
          <Icon color="black" name="check double" />
          <span>{__('Received')}</span>
        </div>
      );
    }

    const userReadBy =
      // eslint-disable-next-line sonarjs/no-gratuitous-expressions
      applicationName &&
      (applicationName === 'ClassApp Web' ||
        applicationName === 'ClassApp iOS' ||
        applicationName === 'ClassApp Android')
        ? 'ClassApp'
        : 'Email';
    return (
      <div style={{ display: 'flex' }}>
        <Icon name="check double" style={{ color: '#0080FF' }} />
        <span>{__('Read')}</span>
        {applicationName ? (
          <span
            style={{ marginLeft: '3px', opacity: '.5', fontWeight: 'bold' }}
          >
            ({userReadBy})
          </span>
        ) : null}
      </div>
    );
  }

  return null;
}
