import React, { Component } from 'react';
import {
  Button, Form, Portal, Popup, Message
} from 'semantic-ui-react';
import { omit, pick } from 'lodash';
import { toJS } from 'mobx';
import Switch from './Switch';
import ColoredCheckbox from './ColoredCheckbox';

import validate from '../lib/validate';
import { __ } from '../i18n';
import * as utils from '../utils';
import Responsive from './Responsive';
import SafeButton from './SafeButton';

export default class FormComponent extends Responsive {
  static Field = (props) => {
    const newProps = { ...props };

    const { error } = props;
    if (error) {
      newProps.error = true;
      newProps.action = (
        <Popup
          trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
          content={error}
        />
      );
    }

    return <Form.Field {...newProps} />;
  }

  static Checkbox = (props) => <ColoredCheckbox data-name={props.name} data-value={props.value} {...props} />;

  static Dropdown = (props) => <Form.Dropdown data-name={props.name} {...props} />;

  static Select = (props) => <Form.Select data-name={props.name} {...props} />;

  static Radio = (props) => <Form.Radio data-name={props.name} data-value={props.value} {...props} />;

  static Group = Form.Group;

  static TextArea = Form.TextArea;

  static Button = Form.Button;

  static Input = (props) => {
    const newProps = { ...props };

    const { error } = props;
    if (error) {
      newProps.error = true;
      newProps.action = (
        <Popup
          trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
          content={error}
        />
      );
    }

    return <Form.Input {...newProps} />;
  }

  static Switch = (props) => <Form.Field control={Switch} {...props} />

  constructor(props) {
    super(props);
    this.state = {
      values: props.values || {},
      errors: props.errors || {},
      activeIndex: 0,
      mounted: false
    };
  }

  UNSAFE_componentWillMount() {
    const defaultValues = Array.isArray(this.defaultValues) ? Object.assign({}, ...this.defaultValues) : this.defaultValues;
    this.setState({ values: { ...defaultValues, ...this.props.values } });
  }

  componentDidMount() {
    this.setMountNodeIfNecessary();
    this.setState({ mounted: true });
  }

  componentDidUpdate() {
    this.setMountNodeIfNecessary();
  }

  setMountNodeIfNecessary() {
    const { header, modal } = this.props;

    if (modal) {
      let mountNode = document.querySelectorAll('.modal .actions');
      if (mountNode && !!mountNode.length) {
        mountNode = mountNode[mountNode.length - 1];
      } else {
        mountNode = document.body;
      }
      if (!this.state.mountNode) this.setState({ mountNode });
    }

    if (header) {
      let mountNodeHeader = document.querySelectorAll('.modal .header');
      if (mountNodeHeader && !!mountNodeHeader.length) {
        mountNodeHeader = mountNodeHeader[mountNodeHeader.length - 1];
      } else {
        mountNodeHeader = document.body;
      }
      if (!this.state.mountNodeHeader) this.setState({ mountNodeHeader });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ errors: nextProps.errors });
  }

  // Event handlers
  onInputChange = (e, { name, value, checked }) => {
    this.setValue(name, value !== undefined ? value : checked);
  }

  onTextInputChange = (e, { name, value, maxDigits = null }) => {
    if (maxDigits) value = value.substring(0, maxDigits);
    this.setValue(name, value);
  }

  onItemChange = (index) => (e, { name, value, checked }) => {
    this.setItem(name, value !== undefined ? value : checked, index);
  }

  onSelectionChange = (e, { name, value }, cb) => {
    this.setValue(name, value, cb);
  }

  // Set values
  setItem = (name, value, index, cb) => {
    const { values } = this.state;
    values[name][index] = value;
    this.setState({ values }, cb);
  }

  setValue = (name, value, cb) => {
    const { values } = this.state;
    values[name] = value;
    this.setState({ values }, cb);
  }

  removeObservableArray = (value) => {
    Object.keys(value).forEach((key) => {
      if (value[key] && value[key].$mobx) {
        value[key] = this.removeObservableArray(toJS(value[key]));
      }
    });
    return value;
  };

  pushValue = (name, value, cb) => {
    const { values } = this.state;
    values[name].push(value);
    this.setState({ values }, cb);
  }

  popValue = (name, index, cb) => {
    const { values } = this.state;
    values[name].splice(index, 1);
    this.setState({ values }, cb);
  }

  replaceValue = (name, value, index, cb) => {
    value = this.removeObservableArray(value);
    const { values } = this.state;
    values[name][index] = value;
    this.setState({ values }, cb);
  }

  toggleValue = (name, value, cb) => {
    const index = this.state.values[name].indexOf(value);
    if (index > -1) {
      this.popValue(name, index, cb);
    } else {
      this.pushValue(name, value, cb);
    }
  }

  runActionAfterValidation = (action, values, rules, maybes) => {
    validate.run(values, rules, maybes)
      .then(action)
      .catch((err) => {
        console.error('error', err);
        const errors = err.errors && Object.keys(err.errors).reduce((prev, current) => {
          prev[current] = utils.handleError(err.errors[current].message);
          return prev;
        }, {});
        this.setState({ errors }, () => {
          if (this.onError) {
            this.onError({ ...this.state.errors });
          }
        });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    Promise.resolve(this.beforeSubmit({ ...this.state.values }))
      .then((values) => {
        const rules = Array.isArray(this.rules) ? Object.assign({}, ...this.rules) : this.rules;
        return this.runActionAfterValidation(async () => {
          if (this.beforeOnSubmit) this.beforeOnSubmit();
          await this.props.onSubmit(values);
          if (this.afterOnSubmit) this.afterOnSubmit();
        }, values, rules, this.maybes);
      });
  }

  beforeSubmit = (values) => values

  handleCancel = () => {
    Promise.resolve(this.beforeSubmit({ ...this.state.values }))
      .then((values) => this.props.onCancel(values));
  }

  handleClose = () => {
    Promise.resolve(this.beforeSubmit({ ...this.state.values }))
      .then((values) => this.props.onClose(values));
  }

  handleNext = (e) => {
    e.preventDefault();
    const { activeIndex } = this.state;
    const values = pick(this.state.values, Object.keys(this.defaultValues[activeIndex]));

    this.runActionAfterValidation(() => this.setState({ activeIndex: activeIndex + 1, errors: {} }), values, this.rules[activeIndex], this.maybes);
  }

  handleBack = () => {
    const { activeIndex } = this.state;

    this.setState({ activeIndex: activeIndex - 1 });
  }

  render() {
    const { errors, mounted } = this.state;
    const {
      modal, loading, onSubmit, submitButton, onCancel, nextSafeButton, safeNextButtonText, onPressNext,
      onPressBack, cancelButton, cancelButtonStyle, renderErrors, actionButtons,
      steps, stepsModal, tabs, renderNextButton, disableBackButton, disableSubmit
    } = this.props;
    const newProps = omit(
      this.props,
      ['modal', 'errors', 'onSubmit', 'submitButton', 'onCancel', 'cancelButton', 'cancelButtonStyle', 'store', 'values', 'loading', 'renderErrors', 'actionButtons', 'stepsModal']
    );

    const submitProps = (typeof submitButton === 'string') ? { content: submitButton } : submitButton;

    const renderedSubmitButton = (submitButton || onSubmit)
      ? (
        <Button
          data-action="submit"
          type="button"
          primary
          loading={loading}
          disabled={disableSubmit || loading}
          content={__('Submit')}
          onClick={onSubmit}
          {...submitProps}
        />
      ) : '';

    const renderedBackButton = onPressBack && this.props.add ? (
      <Button
        data-action="back"
        type="button"
        basic
        disabled={disableBackButton}
        content={__('Back')}
        onClick={onPressBack}
      />
    ) : '';

    const renderedNextButton = onPressNext
      ? (nextSafeButton
        ? (
          <SafeButton
            data-action="submit"
            type="button"
            primary
            loading={loading}
            disabled={loading}
            content={safeNextButtonText || __('Next')}
            onClick={onPressNext}
          />
        ) : (
          <Button
            data-action="submit"
            type="button"
            primary
            loading={loading}
            disabled={loading}
            content={__('Next')}
            onClick={onPressNext}
          />
        )) : '';

    const cancelProps = (typeof cancelButton === 'string') ? { content: cancelButton, style: cancelButtonStyle || {} } : cancelButton;

    const renderedCancelButton = (cancelButton || onCancel)
      ? (
        <Button
          data-action="cancel"
          type="button"
          floated="left"
          basic
          disabled={loading}
          content={__('Cancel')}
          onClick={onCancel}
          {...cancelProps}
        />
      ) : '';

    const actions = (
      <div>
        {renderedCancelButton}
        {actionButtons}
        {renderedBackButton}
        {(renderNextButton && this.props.add) ? renderedNextButton : renderedSubmitButton}
      </div>
    );

    const errorKeys = errors && Object.keys(errors);

    const allModals = document.querySelectorAll('.modal');
    const allMountNodes = document.querySelectorAll('.modal .actions');

    let mountNode = allMountNodes;

    if (mountNode && !!mountNode.length) {
      mountNode = mountNode[mountNode.length - 1];
    } else {
      mountNode = document.body;
    }

    const modalsInStoreAmount = (this.props.store && this.props.store.appends && this.props.store.appends.length) || 0;

    const isMountNodeStateEmpty = !this.state.mountNode;
    const hasMountNodesForModals = allMountNodes.length > 0;
    const isStoreAppendsEmpty = modalsInStoreAmount === 0;
    const modalsInStoreMatchesModalsInDocument = modalsInStoreAmount === allModals.length;

    if (isMountNodeStateEmpty && hasMountNodesForModals && (isStoreAppendsEmpty || modalsInStoreMatchesModalsInDocument)) this.setState({ mountNode });

    return (
      <div>
        {
          (steps && tabs)
            ? (
              <div style={this.props.add && { paddingBottom: stepsModal ? 23 : 70 }}>
                {this.props.add ? steps : tabs}
              </div>
            ) : ''
        }
        <Form
          //as="div"
          error={errorKeys && errorKeys.length > 0}
          warning
          {...newProps}
        >
          {
            renderErrors && errorKeys && errorKeys.length > 0
            && (
            <Message error>
              {errorKeys.map((key) => <p key={key}>{errors[key]}</p>)}
            </Message>
            )
          }
          {this.props.children}
          {
            (modal && (renderedSubmitButton || renderedCancelButton || actionButtons))
              ? (
                <Portal
                  open={mounted}
                  mountNode={this.state.mountNode || mountNode}
                >
                  {actions}
                </Portal>
              )
              : actions
          }
        </Form>
      </div>
    );
  }
}
