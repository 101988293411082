import styled from 'styled-components';
import { themes } from '@classapp-tech/edna-styles';
import { getBackgroundColor } from './helpers/getBackgroundColor';
import { getBorderColor } from './helpers/getBorderColor';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  border-radius: 100%;

  ${({ isActive = false, backgroundColor }) => getBackgroundColor({ isActive, backgroundColor })};
  ${({ isActive = false, backgroundColor }) => getBorderColor({ isActive, backgroundColor })};
  
  border-style: solid;
  overflow: hidden;

  margin-right: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export const RadioCircle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: ${6 / 2}px;

  background-color: ${({ dotColor }) => dotColor || themes.classapp.classappLight.colors.paper};
`;

export const Text = styled.span`
  font-size: 16px;
  color: ${() => themes.classapp.classappLight.colors.blackboard.main}
`;
