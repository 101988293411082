import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 8px;
  padding: 14px 16px;
  border-radius: 50px;
  border-radius: 200px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  outline: ${(props) => (props.isFocused ? '1px solid #000' : 'none')};

  * {
    box-sizing: border-box;
  }

  input {
    height: 100%;
    width: 100%;
    padding: 0;
    border: none;
    font-size: 16px;
    font-weight: 450;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
      font-size: 16px;
      font-weight: 450;
    }
  }

  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
`;
