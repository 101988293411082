import React from 'react';
import { CircleExclamationSolid } from '@classapp-tech/edna-icons';
import {
  InputText, Container, Label, ErrorContainer, ErrorText
} from './Input.styles';

/**
 * How to use Input component
 *
 * <Input
 *  style={{ width: '100%' }}
 *  placeholder='Placeholder for the input'
 *  label='Label for the input'
 * />
 *
*/

export const Input = React.forwardRef(({
  style, label, footer, error, inputStyle, onChange, ...rest
}, ref) => (
  <Container style={style}>
    <Label>{label}</Label>
    <InputText
      error={error}
      onChange={onChange}
      type="text"
      style={inputStyle}
      {...rest}
      ref={ref}
    />
    {footer}
    {error
      && (
        <ErrorContainer>
          <CircleExclamationSolid fill="#BF2600" />
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
  </Container>
));
