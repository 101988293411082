import React from 'react';

import '../assets/css/ui/RadioButtonList.module.scss';

/**
* @typedef {{
*  list: { value: string, label: string }[],
*  formName: string,
*  onChange: (e?: React.FormEvent) => void,
*  listTitle?: string,
*  defaultValueCheck?: string,
* }} Props
* @extends {React.Component<Props>}
*/
export default class RadioButtonList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.defaultValueCheck,
    };
  }

  render() {
    const { list, formName, onChange, listTitle } = this.props;
    const { selected } = this.state;

    const onChangeValue = (e) => {
      this.setState({ selected: e.target.value });
      onChange(e);
    };

    return (
      <div id="RadioButtonList">
        {listTitle && <h2 className="title">{listTitle}</h2>}

        {list?.map(radio => (
          <fieldset key={radio.value} name={formName}>
            <label className={`radio-label ${selected === radio.value ? 'selected' : ''}`} htmlFor={radio.value}>
              <input
                className="radio-button"
                name={formName}
                type="radio"
                id={radio.value}
                onChange={onChangeValue}
                value={radio.value}
                checked={selected === radio.value}
              />
              <span className="checkmark" />

              <span className="label-text">{radio.label}</span>
            </label>
          </fieldset>
        ))}
      </div>
    );
  }
}
