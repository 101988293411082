import React from 'react';
import getDataAttributeValue from './helpers/getDataAttributeValue';

import {
  StyledBasicButton,
  StyledDangerButton,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledSecondaryDangerButton,
  StyledSuccessButton,
} from './Button.styles';

const components = {
  primary: StyledPrimaryButton,
  basic: StyledBasicButton,
  danger: StyledDangerButton,
  secondary: StyledSecondaryButton,
  secondaryDanger: StyledSecondaryDangerButton,
  success: StyledSuccessButton,
};

function ButtonDefault({
  testID, children, size = 'medium', variation = 'primary', fluid, disabled, onClick,
}) {
  const StyledComponent = components[variation];

  return (
    <StyledComponent
      data-cy={getDataAttributeValue(variation, testID)}
      onClick={onClick}
      type="button"
      variation={variation}
      size={size}
      fluid={fluid}
      disabled={disabled}
    >
      {children}
    </StyledComponent>
  );
}

export default ButtonDefault;
