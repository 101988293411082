import styled from 'styled-components';

export const Container = styled.div`
  display: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.pencil.disabled};
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;
