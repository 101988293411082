import styled from 'styled-components';
import { tokens } from '@classapp-tech/edna-styles';

export const CloseButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const CloseButtonText = styled.span`
    color: ${tokens.colors.pen.bold};
`;
