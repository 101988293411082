import React from 'react';
import { Popup } from 'semantic-ui-react';
import { tokens } from '@classapp-tech/edna-styles';
import { CircleQuestionLight } from '@classapp-tech/edna-icons';

import { Label, LabelContainer, Dropdown } from './MarkersDropdown.styles';

import { __ } from '../../../../i18n';

// eslint-disable-next-line react/prop-types
export function MarkersDropdown({
  value,
  onChange,
  options,
  loading,
  error,
  onLoadMore,
  onSearchChange,
  ...rest
}) {
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    // eslint-disable-next-line prettier/prettier
    if (scrollTop === (scrollHeight - clientHeight)) {
      onLoadMore();
    }
  };

  return (
    <div>
      <LabelContainer>
        <Label>{`${__('Markers')} *`}</Label>
        <Popup
          content={__(
            'Markers make it possible to organize and categorize groups to facilitate sending communications',
          )}
          trigger={
            <CircleQuestionLight
              data-testid="info-icon"
              style={{
                marginBottom: '3px',
              }}
              fill={tokens.colors.pencil.main}
            />
          }
        />
      </LabelContainer>
      <Dropdown
        {...rest}
        onSearchChange={onSearchChange}
        noResultsMessage={__('Search...')}
        placeholder={__('Select one or more markers')}
        error={error}
        value={value}
        loading={loading}
        options={options}
        onChange={onChange}
        onScroll={onLoadMore ? handleScroll : undefined}
      />
    </div>
  );
}
