
import React from 'react';
import InputMask from 'react-input-mask';
import cep from 'cep-promise';
import Form from './Form';

const CepInput = ({
  fetching,
  mask = '99999-999',
  onChange,
  onResult,
  setFetching,
  shouldDisableOnFetch = true,
  shouldFetch = true,
  value,
  ...props
}) => {
  function handleChange(event) {
    onChange(event)

    const noMask = event.target.value.replace(/[_-]/g, '')
    if (noMask.length === 8 && !fetching && shouldFetch) handleSearch(noMask)
  }

  async function handleSearch(formattedValue) {
    setFetching(true)
    try {
      const result = await cep(formattedValue)
      onResult({ data: result })
    } catch (error) {
      console.error('ERROR GETTING CEP:')
      onResult({ error })
    }

    setFetching(false)
  }

  return (
    <InputMask
      alwaysShowMask={false}
      disabled={shouldDisableOnFetch && fetching}
      mask={mask}
      onChange={handleChange}
      value={value}
    >
      {
        inputProps =>
          <Form.Input
            {...inputProps}
            {...props}
          />
      }
    </InputMask>
  )
}

export default CepInput;