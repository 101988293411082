import React from 'react';
import { render } from '../../../../../newRender';
import { AvatarReceived } from '../AvatarReceived';

describe('AvatarReceived', () => {
  it('should render the component', () => {
    const message = {
      entity: {
        picture: {
          uri: 'https://example.com/picture.jpg',
        },
      },
    };
    const organization = {
      picture: {
        uri: 'https://example.com/picture.jpg',
      },
    };
    const { container } = render(
      <AvatarReceived
        message={message}
        organization={organization}
        isOrganizationPage={false}
      />,
    );
    expect(container).toBeDefined();
  });
});
