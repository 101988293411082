export function validateVisibility(params) {
  if (!params?.status) return false;

  const { status, selectedGroups } = params;

  if (status === 'public') return true;

  if (!selectedGroups.length) return false;

  return true;
}
