import React from 'react';
import { Input } from 'semantic-ui-react';
import omit from 'lodash/omit';

export default class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.value || props.initialValue || '',
      loading: false
    };
    this.lastRequestId = null;
  }

  onChange = (e, data) => {
    if (this.props.wait) {
      this.setState({ text: data.value }, () => this.startTimeout(e, data));
    } else {
      this.props.onChange(e, data);
    }
  }

  startTimeout = (e, data) => {
    clearTimeout(this.lastRequestId);
    this.setState({ loading: true });
    let id;

    this.lastRequestId = id = setTimeout(() => {
      if (id === this.lastRequestId) {
        setTimeout(() => this.setState({ loading: false }), 1200); //estimated time for request to finish

        data.value = this.state.text;
        this.props.onChange(e, data);
      }
    }, 400);
  }

  render() {
    return (<Input
      icon="search"
      loading={this.state.loading || this.props.loading}
      value={this.state.text}
      style={{ height: 38 }}
      {...omit(this.props, ['wait'])}
      onChange={(e, data) => this.onChange(e, data)}
    />);
  }
}
