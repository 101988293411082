import React from 'react';
import { fireEvent, render } from '../../../../newRender';

import { LabelItem } from '../LabelItem';

describe('Given a LabelItem component', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      label: {
        id: '1',
        title: 'label',
        color: 'color',
        type: 'type',
      },
      canUpdateOrganization: true,
      canCreateLabels: true,
      key: 'key',
      openLabelForm: jest.fn(),
      studentRepliesFeature: true,
      isMaster: false,
    };
  });

  it('should render the component when props is provided', () => {
    const container = render(<LabelItem {...props} />);
    expect(container).toBeDefined();
  });

  it('should render the component when type is INTEGRATION', () => {
    props.label.type = 'INTEGRATION';
    const container = render(<LabelItem {...props} />);
    expect(container).toBeDefined();
  });

  it('should render the component when canUpdateOrganization is false', () => {
    const container = render(
      <LabelItem {...props} canUpdateOrganization={false} />,
    );
    expect(container).toBeDefined();
  });

  it('should render the component when studentRepliesFeature is false', () => {
    const container = render(
      <LabelItem {...props} studentRepliesFeature={false} />,
    );
    expect(container).toBeDefined();
  });

  it('should call openLabelForm when label is clicked', async () => {
    const { getByText } = render(<LabelItem {...props} />);
    const label = await getByText('label');
    await fireEvent.click(label);
    expect(props.openLabelForm).toHaveBeenCalled();
  });

  it('should call openLabelForm when label is clicked and type is INTEGRATION', () => {
    props.label.type = 'INTEGRATION';
    props.isMaster = true;
    const { getByText } = render(<LabelItem {...props} />);
    getByText('label').click();
    expect(props.openLabelForm).toHaveBeenCalled();
  });

  it('should not call openLabelForm when label is clicked and type is INTEGRATION and canUpdateOrganization is false', () => {
    props.label.type = 'INTEGRATION';
    const { getByText } = render(
      <LabelItem {...props} canUpdateOrganization={false} />,
    );
    getByText('label').click();
    expect(props.openLabelForm).not.toHaveBeenCalled();
  });
});
