import React from 'react';
import { LoaderMessage } from '../LoaderMessage';
import { render } from '../../../../../newRender';

describe('LoaderMessage', () => {
  it('should render the component', () => {
    const { container } = render(<LoaderMessage />);
    expect(container).toBeDefined();
  });
});
