import React from 'react';
import { render } from '../../../../../newRender';

import { NetworkEntityName } from '../NetworkEntityName';

describe('NetworkEntityName', () => {
  let props;

  beforeEach(() => {
    jest.resetAllMocks();
    props = {
      message: {
        deleted: false,
        unread: false,
        entity: {
          fullname: 'Mr. Holmes',
        },
      },
    };
  });

  it('should not render the component when props is not provided', () => {
    const { container } = render(<NetworkEntityName />);
    expect(container.firstChild).toBeNull();
  });

  it('should render the component when props is provided', () => {
    const container = render(<NetworkEntityName {...props} />);
    expect(container).toBeDefined();
  });

  it('should render the trash icon when message is deleted', () => {
    props.message.deleted = true;
    const { getByTestId } = render(<NetworkEntityName {...props} />);
    const trashIcon = getByTestId('trash-icon');
    expect(trashIcon).toBeInTheDocument();
  });

  it('should render the unread icon when message is unread', () => {
    props.message.unread = true;
    const { getByTestId } = render(<NetworkEntityName {...props} />);
    const unreadIcon = getByTestId('unread-icon');
    expect(unreadIcon).toBeInTheDocument();
  });

  it('should render the read icon when message is read', () => {
    const { getByTestId } = render(<NetworkEntityName {...props} />);
    const readIcon = getByTestId('read-icon');
    expect(readIcon).toBeInTheDocument();
  });
});
