/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import cookie from 'react-cookie';
import * as utils from '../utils';

const responsive = {
  onlyMobile: { minWidth: 320, maxWidth: 767 },
  onlyTablet: { minWidth: 768, maxWidth: 991 },
  onlyComputer: { minWidth: 992 },
  onlyLargeScreen: { minWidth: 1200, maxWidth: 1919 },
  onlyWidescreen: { minWidth: 1920 }
};

export default class Responsive extends React.Component {
  static responsive = responsive;

  // @ts-ignore
  isMobile = () => utils.isMobileOrTablet() || cookie.load('isMobile') === true;
  // isMobile = () => window.innerWidth <= responsive.onlyComputer.minWidth
}
