/* eslint-disable react/jsx-key */
/* eslint-disable indent */

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { __ } from '../../../../i18n';
import { isNetwork } from '../../../../app/helpers/isNetwork';
import { hasCommunicationStatusFeedback } from '../../../../app/helpers/hasCommunicationStatusFeedback';

const styles = {
  defaultTextColor: {
    color: '#000000',
    opacity: '.8',
    display: 'flex',
  },
  typeIcon: {
    color: '#9696a0',
    marginRight: '0.4285712rem',
  },
};

/**
 * @typedef {{
 *   organization: string,
 *   recipientsCount: number,
 *   toEntity: {
 *     fullname: string,
 *     status: number
 *   },
 *   deleted: boolean,
 *   statusSend: string
 * }} MessageItem
 *
 * @typedef {{
 *   message: MessageItem,
 *   isMobile: () => boolean
 * }} Props
 * @param {Props} props
 * @returns {React.JSX.Element}
 */

export function RenderSent({ message, isMobile, organization }) {
  return (
    <div
      style={
        message.deleted ? { opacity: 0.65 } : { ...styles.defaultTextColor }
      }
    >
      {isNetwork(organization) &&
      hasCommunicationStatusFeedback(organization) ? (
        <div>
          {message.statusSend === 'DONE' || message.statusSend === null ? (
            <Icon
              data-name="users"
              name="paper plane"
              style={
                message.deleted ? { opacity: 0.65 } : { ...styles.typeIcon }
              }
            />
          ) : null}
        </div>
      ) : (
        <Icon
          data-name="users"
          name="paper plane"
          style={message.deleted ? { opacity: 0.65 } : { ...styles.typeIcon }}
        />
      )}
      <span
        data-name="recipients"
        data-value={`recipients-count-${message.recipientsCount || ''}`}
        style={
          isMobile
            ? { color: '#999999' }
            : message.deleted
              ? { opacity: 0.65 }
              : null
        }
      >
        {message.recipientsCount === 1 ? (
          [
            message.toEntity && message.toEntity.fullname,
            message.toEntity && (
              <Icon.Group>
                {message.toEntity.status === 0 && (
                  <Icon.Group
                    style={{
                      marginLeft: '4px',
                      opacity: message.deleted ? 0.65 : null,
                    }}
                  >
                    <Icon name="check" />
                  </Icon.Group>
                )}
                {message.toEntity.status > 0 && message.toEntity.status < 1 && (
                  <Icon.Group
                    style={{
                      marginLeft: '4px',
                      opacity: message.deleted ? 0.65 : null,
                    }}
                  >
                    <Icon color="black" name="check double" />
                  </Icon.Group>
                )}
                {message.toEntity.status > 1 && (
                  <Icon.Group
                    style={{
                      marginLeft: '4px',
                      opacity: message.deleted ? 0.65 : null,
                    }}
                  >
                    <Icon name="check double" style={{ color: '#0080FF' }} />
                  </Icon.Group>
                )}
              </Icon.Group>
            ),
          ]
        ) : isNetwork(organization) &&
          hasCommunicationStatusFeedback(organization) ? (
          <div>
            {message.statusSend === 'DONE' || message.statusSend === null ? (
              <span>
                {__('%s recipients', String(message.recipientsCount))}
              </span>
            ) : null}
          </div>
        ) : (
          <span>{__('%s recipients', String(message.recipientsCount))}</span>
        )}
      </span>
    </div>
  );
}
