import styled from 'styled-components';
import { Table as CHTable } from '../Table';

export const Table = styled(CHTable)`
  tbody td:first-child {
    max-width: 23rem !important;
    width: 23rem !important;
  }

  tbody td:nth-child(2) {
    width: fit-content !important;
    flex: 1;

    div span {
      max-width: initial;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  .automaticMessagesHeader {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2.5rem;

    h1 {
      font-size: 1.125rem;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.blackboard.main};
    }

    p {
      font-size: 1rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blackboard.main};
    }
  }
`;

export const Link = styled.a`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;
`;
