import React from 'react';
import { Input } from '../../../../../../components/Input';

import { __ } from '../../../../../../../../i18n';

/**
* @typedef {{
  *  text?: string,
  *  style?: React.CSSProperties
  * }} TError
**/

/**
* @typedef {{
*  value?: string;
*  onChange?: (event: Event) => void;
*  error?: TError | string;
* }} Props
*
* @param {Props} props
* @returns {JSX.Element}
*/
export const ChannelName = React.forwardRef((props, ref) => (
  <Input
    style={{ width: '50%', marginRight: '0.571rem' }}
    placeholder={__('Ex: Secretary')}
    label={`${__('Channel`s name')} *`}
    ref={ref}
    {...props}
  />
));
