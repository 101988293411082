// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`StatusIcon should render received status when status is 3 should render received status when applicationName is provided 1`] = `
<div>
  <div
    style="display: flex;"
  >
    <i
      aria-hidden="true"
      class="check double icon"
      style="color: rgb(0, 128, 255);"
    />
    <span>
      Read
    </span>
    <span
      style="margin-left: 3px; opacity: 0.5; font-weight: bold;"
    >
      (
      ClassApp
      )
    </span>
  </div>
</div>
`;

exports[`StatusIcon should render received status when status is 4 should render received status when applicationName is provided 1`] = `
<div>
  <div
    style="display: flex;"
  >
    <i
      aria-hidden="true"
      class="check double icon"
      style="color: rgb(0, 128, 255);"
    />
    <span>
      Read
    </span>
    <span
      style="margin-left: 3px; opacity: 0.5; font-weight: bold;"
    >
      (
      ClassApp
      )
    </span>
  </div>
</div>
`;
