import React from 'react';

import { __ } from '../../../../../../../../i18n';

import { InputText } from './ExternalID.styles';

/**
* @typedef {{
  *  text?: string,
  *  style?: React.CSSProperties
  * }} TError
**/

/**
* @typedef {{
*  value?: string;
*  onChange?: (event: Event) => void;
*  error?: TError | string;
* }} Props
*
* @param {Props} props
* @returns {JSX.Element}
*/
export const ExternalID = React.forwardRef((props, ref) => (
  <InputText
    placeholder={__('Optional')}
    label={`${__('External ID')}`}
    ref={ref}
    {...props}
  />
));
