import { useEffect, useState } from 'react';
import { themes } from '@classapp-tech/edna-styles';

export function useRadioButton(props) {
  const {
    selected = false,
    onChange,
    backgroundColor,
  } = props;

  const [isActive, setIsActive] = useState(selected);
  const defaultColor = themes.classapp.classappLight.colors.pen.main;
  const radioBackground = backgroundColor || defaultColor;

  const handleToogle = () => {
    let activeValue = false;
    setIsActive((state) => {
      activeValue = !state;
      return !state;
    });

    return activeValue;
  };

  useEffect(() => {
    setIsActive(selected);
  }, [selected]);

  const handleClick = () => {
    const newState = !isActive;
    if (onChange) {
      onChange(newState);
    }
    setIsActive(true);
  };

  return {
    handleToogle,
    handleClick,
    isActive,
    radioBackground,
  };
}
