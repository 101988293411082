import React from 'react';
import { AvatarSended } from '../AvatarSended';
import { render } from '../../../../../newRender';

describe('AvatarSended', () => {
  it('should render the component', () => {
    const message = {
      toEntity: {
        picture: {
          uri: 'https://example.com/picture.jpg',
        },
      },
    };

    const { container } = render(<AvatarSended message={message} />);
    expect(container).toBeDefined();
  });
});
