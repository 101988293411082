import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
    height: 25rem;
    width: 25rem;
`;

export const Title = styled.p`
    text-align: center;
    color: #252628;
    font-size: 20px;
    margin-bottom: 5px;
    line-height: 30.5px;
`;

export const Subtitle = styled.p`
    text-align: center;
    color: #848685;
    font-size: 15px;
    line-height: 30.5px;
`;
