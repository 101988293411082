/* eslint-disable prettier/prettier */
import React from 'react';
import { Popup, Label, Icon } from 'semantic-ui-react';

import { __ } from '../../../i18n';

/**
 * @param {{
 *  labels: {
 *    id: string | number;
 *    title: string;
 *    color: string;
 *    type: string;
 *  };
 *  canCreateLabels: boolean;
 *  key: string;
 *  openLabelForm: (action: 'edit' | 'add', label: any) => void;
 *  studentRepliesFeature: boolean;
 *  isMaster: boolean;
 * }} props
 */
export function LabelItem({
  label,
  canCreateLabels,
  key,
  openLabelForm,
  isMaster,
  studentRepliesFeature,
}) {
  if (label.type === 'INTEGRATION') {
    return (
      <Popup
        trigger={
          <Label
            size="large"
            style={{ marginRight: 5, marginBottom: 5 }}
            className={isMaster ? 'pointer' : null}
            key={`${key}-${label.title}`}
            onClick={() => (isMaster ? openLabelForm('edit', label) : null)}
          >
            <span id={label.title}>
              <Icon name={studentRepliesFeature ? 'circle' : 'square'} style={{ fontWeight: 'bold', color: `#${label.color}` }} />
              {label.title}
            </span>
            {isMaster ? <Icon data-action="edit-label" data-params={label.id} name="pencil" style={{ margin: '0px 0px 0px 18px' }} /> : null}
          </Label>
        }
        content={isMaster ? __('This label is linked to an integration module') : __('This label cannot be changed as it is linked to an integration module')}
      />
    );
  }

  return (
    <Label
      size="large"
      style={{ marginRight: 5, marginBottom: 5 }}
      className={canCreateLabels && 'pointer'}
      key={`${key}-${label.title}`}
      onClick={() => (canCreateLabels ? openLabelForm('edit', label) : null)}
    >
      <span id={label.title}>
        <Icon name={studentRepliesFeature ? 'circle' : 'square'} style={{ fontWeight: 'bold', color: `#${label.color}` }} />
        {label.title}
      </span>
      {
        canCreateLabels &&
            <Icon data-action="edit-label" data-params={label.id} name="pencil" style={{ margin: '0px 0px 0px 18px' }} />
      }
    </Label>
  );
}
