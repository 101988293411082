import React from 'react';
import { render, screen, fireEvent } from '../../../../newRender';
import { Input } from '.';

describe('<Input />', () => {
  const props = {
    label: 'Input label',
    placeholder: 'Input placeholder'
  };
  it('should to be in the document', () => {
    const { getByPlaceholderText, getByText } = render(<Input {...props} />);

    const input = getByPlaceholderText(/Input placeholder/);
    const label = getByText(/Input label/);

    expect(input).toBeInTheDocument();
    expect(label).toBeInTheDocument();
  });

  it('should be able to get channel error', () => {
    render(<Input error="Input Error" {...props} />);

    const error = screen.getByText(/Input Error/);
    expect(error).toBeInTheDocument();
  });

  it('should be able to change the input value', () => {
    const mockOnChange = jest.fn();
    const { getByPlaceholderText } = render(
      <Input value="" onChange={mockOnChange} {...props} />
    );

    const input = getByPlaceholderText(/Input placeholder/);

    fireEvent.change(input, { target: { value: 'New Channel' } });

    expect(mockOnChange).toHaveBeenCalled();
  });
});
