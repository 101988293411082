/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { render } from '../../../../../newRender';

import { StatusIcon } from '../StatusIcon';

describe('StatusIcon', () => {
  it('should render sent status when status is 0', () => {
    const { getByText } = render(<StatusIcon status={0} />);
    expect(getByText('Sent')).toBeInTheDocument();
  });

  it('should render sent status when status is 1', () => {
    const { getByText } = render(<StatusIcon status={1} />);
    expect(getByText('Received')).toBeInTheDocument();
  });

  describe('should render read status when status is 2', () => {
    it('should render read status when applicationName is ClassApp Web', () => {
      const { getByText } = render(
        <StatusIcon status={2} applicationName="ClassApp Web" />,
      );
      expect(getByText('Read')).toBeInTheDocument();
      expect(getByText('(ClassApp)')).toBeInTheDocument();
    });

    it('should render read status when applicationName is ClassApp iOS', () => {
      const { getByText } = render(
        <StatusIcon status={2} applicationName="ClassApp iOS" />,
      );
      expect(getByText('Read')).toBeInTheDocument();
      expect(getByText('(ClassApp)')).toBeInTheDocument();
    });

    it('should render read status when applicationName is ClassApp Android', () => {
      const { getByText } = render(
        <StatusIcon status={2} applicationName="ClassApp Android" />,
      );
      expect(getByText('Read')).toBeInTheDocument();
      expect(getByText('(ClassApp)')).toBeInTheDocument();
    });

    it('should render read status when applicationName is Email', () => {
      const { getByText, queryByText } = render(<StatusIcon status={2} />);
      expect(getByText('Read')).toBeInTheDocument();
      expect(queryByText('(Email)')).toBeNull();
    });
  });

  describe('should render received status when status is 3', () => {
    it('should render received status when applicationName is not provided', () => {
      const { getByText } = render(<StatusIcon status={3} />);
      expect(getByText('Received')).toBeInTheDocument();
    });

    it('should render received status when applicationName is provided', () => {
      const { container } = render(
        <StatusIcon status={3} applicationName="ClassApp Web" />,
      );
      expect(container).toMatchSnapshot();
    });
  });

  describe('should render received status when status is 4', () => {
    it('should render received status when applicationName is not provided', () => {
      const { getByText } = render(<StatusIcon status={4} />);
      expect(getByText('Received')).toBeInTheDocument();
    });

    it('should render received status when applicationName is provided', () => {
      const { container } = render(
        <StatusIcon status={4} applicationName="ClassApp Web" />,
      );
      expect(container).toMatchSnapshot();
    });
  });

  it('should return null when status is unknown', () => {
    const { container } = render(<StatusIcon status={5} />);
    expect(container).toBeEmptyDOMElement();
  });
});
