import React from 'react';
import { Divider, Label, Icon, Segment } from 'semantic-ui-react';

import { __ } from '../../../i18n';
import { LabelItem } from './LabelItem';

/**
 * @param {{
 *   labels: {
 *      id: string | number;
 *      title: string;
 *      color: string;
 *      type: string;
 *    }[];
 *    studentRepliesFeature: boolean;
 *    canCreateLabel: boolean;
 *    canUpdateOrganization: boolean;
 *    openLabelForm: (action: 'edit' | 'add', label: any) => void;
 *    divProps?: any;
 *    isMaster: boolean;
 * }} props
 */
export function LabelsSection({
  labels,
  studentRepliesFeature,
  canCreateLabel,
  canUpdateOrganization,
  openLabelForm,
  divProps = {},
  isMaster,
}) {
  return (
    <div {...divProps}>
      {studentRepliesFeature && <Divider section />}
      <Divider section horizontal>
        {__('Labels Configuration')}
      </Divider>
      {canCreateLabel && (
        <Label
          size="large"
          style={{ marginRight: 5, marginBottom: 5 }}
          className={`pointer add-button ${studentRepliesFeature ? 'new ' : ''}`}
          onClick={() => openLabelForm('add', null)}
        >
          <span>
            <Icon name="plus" />
            {__('Create new label')}
          </span>
        </Label>
      )}
      <Segment className={studentRepliesFeature ? 'new label-content' : ''}>
        {labels.map((label, key) => (
          <LabelItem
            key={key}
            label={label}
            canUpdateOrganization={canUpdateOrganization}
            openLabelForm={openLabelForm}
            studentRepliesFeature={studentRepliesFeature}
            canCreateLabels={canCreateLabel}
            isMaster={isMaster}
          />
        ))}
      </Segment>
    </div>
  );
}
