import React from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';

export function Info({ info, key }) {
  return (
    <Grid.Column key={key} style={info.style}>
      <Segment style={{ padding: '20px 0px', textAlign: 'center' }}>
        <div>
          <Header style={{ margin: 0 }} as="h5" disabled>
            {info.name}
          </Header>
          <div>
            {info.icon}
            <Header
              style={{ margin: 0, display: '-webkit-inline-box' }}
              as="h3"
            >
              <Header.Content>{info.summary}</Header.Content>
            </Header>
          </div>
        </div>
      </Segment>
    </Grid.Column>
  );
}
