import React from 'react';
import omit from 'lodash/omit';
import { inject, observer } from 'mobx-react';

import 'react-quill/dist/quill.snow.css';
import { __ } from '../i18n';

const Toolbar = props => (
  <div id="toolbar" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', ...props.style }}>
    <select className="ql-font ql-picker" defaultValue={''} onChange={e => e.persist()} title={__('Font')}>
      <option value="" selected />
      <option value="serif" />
      <option value="monospace" />
    </select>
    <button className="ql-header" value="1" title={__('Main title')} />
    <button className="ql-header" value="2" title={__('Secondary title')} />
    <button className="ql-italic" title={__('Italic')} />
    <button className="ql-bold" title={__('Bold')} />
    <button className="ql-strike" title={__('Strike')} />
    <button className="ql-underline" title={__('Underline')} />
    <select className="ql-align ql-picker" defaultValue={''} onChange={e => e.persist()} title={__('Alignment')}>
      <option className="ql-align" value="" title={__('Aligned to the left')} selected />
      <option className="ql-align" value="center" title={__('Centralized')} />
      <option className="ql-align" value="right" title={__('Aligned to the right')} />
      <option className="ql-align" value="justify" title={__('Justify')} />
    </select>
    <select className="ql-color" title={__('Text color')} />
    <select className="ql-background" title={__('Background color')} />
    <button className="ql-blockquote" title={__('Quote')} />
    <button className="ql-code-block" title={__('Format as code')} />
    <button className="ql-list" value="ordered" title={__('Ordered list')} />
    <button className="ql-list" value="bullet" title={__('Unordered list')} />
    <button className="ql-link" title={__('Insert link on the selected text')} />
    <button className="ql-clean" title={__('Clean formatting')} />
    <select className="ql-line-height" title={__('Line spacing')} defaultValue="1.38">
      <option value="1.2" /> {/* Line spacing equal 0 in Google Docs has 1.20 lineheight */}
      <option value="1.38" />
      <option value="1.8" />
      <option value="2.4" />
    </select>
    <select className="ql-margin-bottom" title={__('Paragraph spacing')} defaultValue="5pt">
      <option value="0pt" />
      <option value="5pt" />
      <option value="10pt" />
      <option value="15pt" />
      <option value="20pt" />
    </select>
  </div>
);

const quillConfig = {
  init(Quill) {
    this.Quill = Quill;
    this
      .initAlign()
      .initFont()
      .initLink()
      .initIcons()
      .initLineHeight() // Line height -> Line Spacing
      .initMarginBottom(); // Margin Bottom -> Paragraph Spacing
  },
  initAlign() {
    const AlignStyle = this.Quill.import('attributors/style/align');
    this.Quill.register(AlignStyle, true);
    return this;
  },
  initFont() {
    const FontStyle = this.Quill.import('attributors/style/font');
    this.Quill.register(FontStyle, true);
    return this;
  },
  initLink() {
    const Link = this.Quill.import('formats/link');
    Link.sanitize = (url) => {
      // modify url if desired
      if ((url.indexOf('http://') === -1) && (url.indexOf('https://') === -1) && (!url.includes('mailto:'))) return 'http://' + url;
      return url;
    };
    return this;
  },
  initIcons() {
    const icons = this.Quill.import('ui/icons');
    icons.bold = '<i class="icon bold" aria-hidden="true" />';
    icons.strike = '<i class="icon strikethrough" aria-hidden="true" />';
    icons.underline = '<i class="icon underline" aria-hidden="true" />';
    icons.italic = '<i class="icon italic" aria-hidden="true" />';
    icons.blockquote = '<i class="icon quote left" aria-hidden="true" />';
    icons.list.bullet = '<i class="icon list ul" aria-hidden="true" />';
    icons.list.ordered = '<i class="icon list ol" aria-hidden="true" />';
    icons.clean = '<i class="icon text slash" aria-hidden="true" />';
    icons.align.right = '<i class="icon align right" aria-hidden="true" />';
    icons.align.justify = '<i class="icon align justify" aria-hidden="true" />';
    icons.align.center = '<i class="icon align center" aria-hidden="true" />';
    icons.align[''] = '<i class="icon align left" aria-hidden="true" />';
    icons.header[1] = '<i class="icon h1" aria-hidden="true" />';
    icons.header[2] = '<i class="icon h2" aria-hidden="true" />';
    icons.color = '<i class="icon a bold-text" aria-hidden="true" />';
    icons.background = '<i class="icon square a duotone" aria-hidden="true" />';
    icons['code-block'] = '<i class="icon code" aria-hidden="true" />';
    icons.link = '<i class="icon link" aria-hidden="true" />';

    return this;
  },
  initLineHeight() {
    const Parchment = this.Quill.import('parchment');
    const lineHeightConfig = {
      scope: Parchment.Scope.BLOCK,
      whitelist: ['1.2', '1.38', '1.8', '2.4']
    };
    const lineHeightClass = new Parchment.Attributor.Class('line-height', 'ql-line-height', lineHeightConfig);
    const lineHeightStyle = new Parchment.Attributor.Style('line-height', 'line-height', lineHeightConfig);

    Parchment.register(lineHeightClass);
    Parchment.register(lineHeightStyle);
    return this;
  },
  initMarginBottom() {
    const Parchment = this.Quill.import('parchment');
    const marginBottomConfig = {
      scope: Parchment.Scope.BLOCK,
      whitelist: ['0pt', '5pt', '10pt', '15pt', '20pt']
    };
    const marginBottomClass = new Parchment.Attributor.Class('margin-bottom', 'ql-margin-bottom', marginBottomConfig);
    const marginBottomStyle = new Parchment.Attributor.Style('margin-bottom', 'margin-bottom', marginBottomConfig);

    Parchment.register(marginBottomClass);
    Parchment.register(marginBottomStyle);
    return this;
  }
};

const quillModulesConfig = {
  init() {
    const modules = {
      toolbar: {
        container: '#toolbar'
      },
      clipboard: {
        matchVisual: false,
        matchers: [
          [window.Node && window.Node.ELEMENT_NODE, this.lineSpacingMatcher], // Matches Line Spacing
          [window.Node && window.Node.ELEMENT_NODE, this.paragraphSpacingMatcher], // Matches Paragraph Spacing when copy - paste from Google Docs
          [window.Node && window.Node.ELEMENT_NODE, this.textDecorationLineMatcher] // Matches Underline when copy - paste from Google Docs
        ]
      }
    };
    return modules;
  },
  lineSpacingMatcher(node, delta) {
    const style = node.getAttribute('style');
    if (/line-height: *(\d+\.*\d*?);/.test(style)) {
      const Delta = require('react-quill').Quill.import('delta');
      const lineHeight = style.match(/line-height: *(\d+\.*\d*?);/)[1];
      if (lineHeight) {
        const allowedValues = [1.20, 1.38, 1.8, 2.4];
        const nearestLineHeight = allowedValues.reduce((nearest, val) =>
          (Math.abs(nearest - lineHeight) < Math.abs(val - lineHeight) ? nearest : val), Infinity);
        return delta.compose(new Delta().retain(delta.length(), { 'line-height': nearestLineHeight.toString() }));
      }
    } else if (node.nodeName === 'P') { // Includes default Line Spacing when it doesn't exist
      const Delta = require('react-quill').Quill.import('delta');
      return delta.compose(new Delta().retain(delta.length(), { 'line-height': '1.38' }));
    }
    return delta;
  },
  /**
   * @param {HTMLElement} node
   * @param {import('quill-delta')} delta
   */
  paragraphSpacingMatcher(node, delta) {
    const style = node.getAttribute('style');

    if (/margin-bottom/.test(style)) {
      /** @type {typeof import('quill-delta')} */
      const Delta = require('react-quill').Quill.import('delta');

      const marginBottomMatch = style.match(/margin-bottom: *(\d+?)([a-z]+);/);
      if (!marginBottomMatch) return delta;

      const [, marginBottom, unit] = marginBottomMatch;
      let marginBottomInPt;
      switch (unit) {
        case 'px':
          marginBottomInPt = marginBottom * (3 / 4);
          break;
        case '%':
          marginBottomInPt = marginBottom * (3 / 25);
          break;
        case 'em':
        case 'rem':
          marginBottomInPt = marginBottom * (12);
          break;
        case 'pt':
        default:
          marginBottomInPt = marginBottom;
      }
      marginBottomInPt = Math.round(marginBottomInPt / 5) * 5;

      // Google Docs use margin-bottom and, sometimes, padding-bottom for Paragraph Spacing - all in 'pt' unit
      if (marginBottomInPt > 20) marginBottomInPt = 20;
      else if (marginBottomInPt === 0 && /padding/.test(style)) { // When padding is used, margin-bottom is 0 in Google Docs
        const paddingBottomMatch = style.match(/padding: *\d+pt \d+pt (.*?)pt( \d+pt)*;/);
        if (paddingBottomMatch) {
          let paddingBottom = paddingBottomMatch[1];
          paddingBottom = Math.round(paddingBottom / 5) * 5;
          if (paddingBottom > 20) paddingBottom = 20;

          marginBottomInPt = paddingBottom;
        } else {
          marginBottomInPt = 0;
        }
      }

      if ([0, 5, 10, 15, 20].includes(marginBottomInPt)) {
        const newDelta = delta.compose(new Delta().retain(delta.length(), { 'margin-bottom': `${marginBottomInPt}pt` }));
        return newDelta;
      }
    } else if (node.nodeName === 'P') { // Includes default Paragraph Spacing when it doesn't exist
      /** @type {typeof import('quill-delta')} */
      const Delta = require('react-quill').Quill.import('delta');
      return delta.compose(new Delta().retain(delta.length(), { 'margin-bottom': '5pt' }));
    }
    return delta;
  },
  textDecorationLineMatcher(node, delta) {
    const style = node.getAttribute('style');

    if (/text-decoration-line/.test(style)) {
      const Delta = require('react-quill').Quill.import('delta');

      const textDecorationLine = style.match(/text-decoration-line: *(.*?);/)[1];
      if (textDecorationLine === 'underline') {
        const newDelta = delta.compose(new Delta().retain(delta.length(), { underline: true }));
        return newDelta;
      }
    }
    if (/text-decoration:/.test(style)) { // Fix for Safari and Firefox
      const Delta = require('react-quill').Quill.import('delta');

      const textDecoration = style.match(/text-decoration: *(.*?);/)[1];
      if (textDecoration.includes('underline')) {
        const newDelta = delta.compose(new Delta().retain(delta.length(), { underline: true }));
        return newDelta;
      }
    }
    return delta;
  }
};

const getFormattedText = (text) => {
  if (!text) return '';
  if (text.startsWith('<p>')) return text;
  return text.split('\n').reduce((acc, line) => `${acc}<p>${line || '<br />'}</p>`, '');
};

@inject('store') @observer
export default class QuillEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: getFormattedText(props.contentState) };
    this.reactQuill = require('react-quill');
    this.quill = require('react-quill').Quill;
    this.ref = React.createRef();
  }

  componentDidMount() {
    // Disable Quill spellcheck until the library fix the erasing problem
    if (this.ref.current) this.ref.current.editor.root.setAttribute('spellcheck', 'false');
  }

  handleChange = value => this.setState({ text: value }, () => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  })

  render() {
    const { store, hideToolbar } = this.props;
    const ReactQuill = this.reactQuill;
    const Quill = this.quill;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    quillConfig.init(Quill);
    const modules = quillModulesConfig.init();

    const newProps = omit(this.props, ['onChange']);

    return (<div className="quill-editor" style={{ backgroundColor: 'white' }}>
      {!hideToolbar && <Toolbar style={this.props.toolbarStyle} />}
      <ReactQuill
        ref={this.ref}
        className={(lang === 'pt' || lang === 'pt-BR') ? 'lang-pt' : 'lang-en'}
        value={this.state.text}
        onChange={this.handleChange}
        placeholder={this.props.placeholder}
        modules={{
          toolbar: !hideToolbar ? modules.toolbar : false,
          clipboard: modules.clipboard
        }}
        bounds=".quill-editor"
        {...newProps}
      />
    </div>);
  }
}
