import React from 'react';
import { render, screen } from '../../../../../../../../newRender';
import { Status } from '.';
import * as Utils from '../../../../../../../../utils';

import { __ } from '../../../../../../../../i18n';

describe('<Status />', () => {
  const props = {
    value: true,
    onChange: jest.fn(),
  };
  beforeEach(() => {
    jest.clearAllMocks();
  });

  it('should be defined', () => {
    const { container } = render(<Status />);
    expect(container).toBeDefined();
  });

  it('should be able to get status switch when the value is true', async () => {
    render(<Status {...props} />);

    const statusLabel = await screen.getByText(__('Enabled'));
    expect(statusLabel).toBeDefined();
  });

  it('should be able to get status switch when the value is false', async () => {
    props.value = false;
    render(<Status {...props} />);

    const statusLabel = await screen.getByText(__('Disabled'));
    expect(statusLabel).toBeDefined();
  });

  it('should be able to see the options in mobile', async () => {
    jest.spyOn(Utils, 'isMobileOrTablet').mockReturnValue(true);
    const { findByText } = render(<Status {...props} />);

    const statusLabel = await findByText(__('Enabled'));
    expect(statusLabel).toBeDefined();
  });
});
