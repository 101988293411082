import React from 'react';
import { Container } from './Switch.styles';

/**
 * @param {{
 *  checked?: boolean;
 *  onChange?: () => void;
 * }} props
 * @returns {React.Component}
 */
export const Switch = ({ checked = false, onChange }) => {
  const checkedId = checked ? 'CHECKED' : 'UNCHECKED';

  return (
    <Container
      data-testid={`CHANNEL_CONFIG_SWITCH_${checkedId}`}
      checked={checked}
      onChange={onChange}
    />
  );
};
