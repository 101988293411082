import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Container, InfoContainer } from './Trigger.styles';
import { Switch } from '../../Switch';

/**
 * @param {{
 * title: string;
 * description: string;
 * enabled?: boolean;
 * onChange?: (checked: boolean) => void;
 * }} props
 * @param {React.Ref<{ getChecked: () => boolean }>} ref
 * @returns {React.Component}
 */
const TriggerComponent = (
  { title, description, enabled = false, onChange },
  ref,
) => {
  const [checked, setChecked] = useState(enabled);

  useEffect(() => {
    setChecked(enabled);
  }, [enabled]);

  useImperativeHandle(ref, () => ({
    getChecked: () => checked,
  }));

  function handleChange() {
    setChecked((state) => {
      onChange?.(!state);
      return !state;
    });
  }

  return (
    <Container>
      <Switch checked={checked} onChange={handleChange} />

      <InfoContainer>
        <h3>{title}</h3>

        <p>{description}</p>
      </InfoContainer>
    </Container>
  );
};
export const Trigger = forwardRef(TriggerComponent);
Trigger.displayName = 'Trigger';
