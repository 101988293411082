import React from 'react';
import { browserHistory } from 'react-router';
import qs from 'query-string';

import Responsive from './Responsive';

export default class Page extends Responsive {
  onUniqueParameterChange = (name, value, type) => {
    const parameters = { ...this.props.location.query };

    if (type === 'array' && value.length <= 1) {
      parameters[name] = value[0];
    } else if (type === 'array') {
      parameters[name] = value.filter(o => parameters[name].indexOf(o) === -1)[0];
    } else if (value.length) {
      parameters[name] = value;
    } else {
      delete parameters[name];
    }

    parameters.p = 1;

    this.eventReload(parameters);
  }

  onMultipleParametersChange = (data) => {
    const parameters = { ...this.props.location.query };

    data.forEach(({ name, value, type }) => {
      if (type === 'array' && value.length <= 1) {
        parameters[name] = value[0];
      } else if (type === 'array') {
        parameters[name] = value.filter(o => parameters[name].indexOf(o) === -1)[0];
      } else if ((value && value.length) || value === true) {
        parameters[name] = value;
      } else {
        delete parameters[name];
      }
    });

    parameters.p = 1;

    this.eventReload(parameters);
  }

  onMultipleParameterChange = (name, value) => {
    const parameters = { ...this.props.location.query };

    if (value && value.length) {
      parameters[name] = JSON.stringify(value);
    } else {
      delete parameters[name];
    }

    parameters.p = 1;

    this.eventReload(parameters);
  }

  /*  This function refreshes the page, calling the new url if any parameters
  *   were changed */
  eventReload = (query = {}) => {
    const pathname = this.props.location.pathname;

    if (query.search) query.search = encodeURIComponent(query.search);

    const params = qs.stringify(query, { arrayFormat: 'brackets', encode: false });

    browserHistory.push(pathname + (params ? '?' + params : ''));
  }

  loadMore = (edgeName, nodeName = 'node', reversed = false) => {
    const { data } = this.props;

    if (!data[nodeName][edgeName].pageInfo.hasNextPage) return;

    data.fetchMore({
      variables: {
        offset: data[nodeName][edgeName].nodes.length
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // Don't do anything if there weren't any new items
        if (!fetchMoreResult || fetchMoreResult[nodeName][edgeName].nodes.length === 0) {
          return previousResult;
        }

        if (reversed) {
          fetchMoreResult[nodeName][edgeName].nodes =
            [...fetchMoreResult[nodeName][edgeName].nodes, ...previousResult[nodeName][edgeName].nodes];
        } else {
          fetchMoreResult[nodeName][edgeName].nodes =
            [...previousResult[nodeName][edgeName].nodes, ...fetchMoreResult[nodeName][edgeName].nodes];
        }

        return fetchMoreResult;
      },
    });
  }

  loadMoreWithoutEdge = (nodeName) => {
    const { data } = this.props;

    if (!data[nodeName].pageInfo.hasNextPage) return;

    data.fetchMore({
      variables: {
        offset: data[nodeName].nodes.length
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // Don't do anything if there weren't any new items
        if (!fetchMoreResult || fetchMoreResult[nodeName].nodes.length === 0) {
          return previousResult;
        }

        fetchMoreResult[nodeName].nodes =
          [...previousResult[nodeName].nodes, ...fetchMoreResult[nodeName].nodes];

        return fetchMoreResult;
      },
    });
  }

  loadMoreWithMoreEdges = (edgeName, secondaryEdgeName, nodeName = 'node', reversed = false) => {
    const { data } = this.props;

    if (!data[nodeName][edgeName][secondaryEdgeName].pageInfo.hasNextPage) return;

    data.fetchMore({
      variables: {
        offset: data[nodeName][edgeName][secondaryEdgeName].nodes.length
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // Don't do anything if there weren't any new items
        if (!fetchMoreResult || fetchMoreResult[nodeName][edgeName][secondaryEdgeName].nodes.length === 0) {
          return previousResult;
        }

        if (reversed) {
          fetchMoreResult[nodeName][edgeName][secondaryEdgeName].nodes =
            [...fetchMoreResult[nodeName][edgeName][secondaryEdgeName].nodes, ...previousResult[nodeName][edgeName][secondaryEdgeName].nodes];
        } else {
          fetchMoreResult[nodeName][edgeName][secondaryEdgeName].nodes =
            [...previousResult[nodeName][edgeName][secondaryEdgeName].nodes, ...fetchMoreResult[nodeName][edgeName][secondaryEdgeName].nodes];
        }

        return fetchMoreResult;
      },
    });
  }
}
