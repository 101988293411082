import React from 'react';
import { Header } from './index';
import { render, screen, fireEvent } from '../../../../newRender';

describe('Header', () => {
  it('should be defined', () => {
    expect(Header).toBeDefined();
  });

  it('should display the title', () => {
    render(<Header title="title" />);

    const title = screen.getByText('title');
    expect(title).toBeVisible();
  });

  it('should call onBack', () => {
    const onBack = jest.fn();
    render(<Header title="title" onBack={onBack} />);

    const icon = screen.getByTestId('onBack');
    fireEvent.click(icon);
    expect(onBack).toHaveBeenCalled();
  });
});
