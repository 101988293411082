import { useEffect } from 'react';

/**
 * @typedef {{
 *  element: HTMLElement,
 *  newClassName: string,
 * }} Props
 * @param {Props} props
 *
 * @returns {null}
 */
function ElementModifier({ element, newClassName }) {
  const alreadyHasClassName = [...element.classList].find(className => className === newClassName);

  /**
   * @param {string} className
   */
  const addClassName = className => element.classList.add(className);

  /**
   * @param {string} className
   */
  const removeClassName = className => element.classList.remove(className);

  useEffect(() => {
    if (!alreadyHasClassName && newClassName) addClassName(newClassName);
    return () => { if (!alreadyHasClassName && newClassName) removeClassName(newClassName); };
  }, []);

  return null;
}

export default ElementModifier;
