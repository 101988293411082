import React from 'react';
import '../../assets/css/kit/SearchDropdown.module.scss';

import Error from '../ui/Error';

/**
 * Search Dropdown component
 *
 * How to use it:
 *
 *  <SearchDropdown
      placeholder="Teste"
      renderOption={(option, __key) => <span>{option} - teste</span>}
      noFilter
      options={[
        'option 1',
        'option 2',
        'option 3'
      ]}
      onChange={(e, { value }) => console.log('option:', value)}
    />
 */

/**
 * @typedef {{
 *  icon?: string,
 *  placeholder: string,
 *  noFilter: boolean,
 *  renderOption?: React.ReactNode,
 *  style?: React.CSSProperties,
 *  options: Object[] myArray,
 *  onChange: function
 * }} Props
 * @extends {Component<Props>}
 */
export default class SearchDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      options: [],
      selectedOption: null,
      loading: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // eslint-disable-next-line no-undef
  handleClickOutside = (event) => {
    if (this.wrapperRef && this.state.isOpened && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpened: false });
    }
  }

  // eslint-disable-next-line no-undef
  handleOnClick = (e) => {
    e.preventDefault();
    this.setState({ isOpened: true });
  }

  // eslint-disable-next-line no-undef
  handleKeyUp = (e) => {
    e.preventDefault();
    this.props.onSearchQuery(e.target.value);
  }

  // eslint-disable-next-line no-undef
  handleOnFocus= (e) => {
    e.target.setAttribute('autocomplete', 'off');
  }

  // eslint-disable-next-line no-undef
  handleOnChange = (option) => {
    const selectedOption = { ...option };
    this.setState({ selectedOption, isOpened: false });
    this.props.onSearchQuery(option.value);
    this.props.onChange(selectedOption);
  }

  // eslint-disable-next-line no-undef
  handleOnClear = async (e) => {
    e.preventDefault();
    if (this.props.disabled) return;
    this.setState({ selectedOption: null, isOpened: false });
    this.props.onSearchQuery('');
    this.props.onChange(null);
  }

  // eslint-disable-next-line no-undef
  filterOptions = (options) => {
    const { searchQuery } = this.props;
    const filteredOptions = options.filter(option => option.toLowerCase().includes(searchQuery.toLowerCase()));
    return filteredOptions;
  }

  // eslint-disable-next-line no-undef
  renderOptions = (options) => {
    const { renderOption } = this.props;

    if (!options.length) {
      return (
        <div key={'dropdown-item-not-found'} style={{ padding: '.78571429rem 1.14285714rem', color: 'rgba(0,0,0,.4)', display: 'flex', alignItems: 'center', fontSize: '16px', height: '4rem' }}>Nenhum Resultado</div>
      );
    }

    if (renderOption) {
      return options.map((option, id) => (
        <div id={id} key={`dropdown-item-${id}`} className="dropdown-item" style={{ padding: '.78571429rem 1.14285714rem', height: '4rem' }} onClick={() => this.handleOnChange(option)}>
          {renderOption(option, id)}
        </div>
      ));
    }

    return options.map((option, id) => (
      <div id={id} key={`dropdown-item-${id}`} className="dropdown-item" style={{ padding: '.78571429rem 1.14285714rem', height: '4rem' }} onClick={() => this.handleOnChange(option)}>
        <span key={option.id} style={{ marginLeft: '7px' }}>{option.value}</span>
      </div>
    ));
  }

  render() {
    const { icon, placeholder, options, loading, noFilter, header, disabled, searchQuery, error, trigger } = this.props;
    const { isOpened } = this.state;

    const filteredOptions = noFilter ? options : this.filterOptions(options);

    return (
      <div style={{ ...this.props.style }} id="search-dropdown" ref={(el) => { this.wrapperRef = el; }}>
        <i className={`icon before ${icon || 'search'}`} />
        {trigger ?
          <div
            onClick={this.handleOnClick}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', paddingLeft: '3rem', border: '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '8px', height: '47px' }}
          >
            {trigger}
          </div>
          : <input disabled={disabled} type="text" style={error ? { borderColor: '#BF2600' } : {}} value={searchQuery} onClick={this.handleOnClick} onFocus={this.handleOnFocus} placeholder={placeholder} id="search-input" onChange={this.handleKeyUp} />
        }
        {error && !isOpened && <Error text={error} />}
        {loading && <i className={'icon loading  spinner'} />}
        {!loading && <i onClick={this.handleOnClear} className={'icon clearable times'} />}
        <div className={`dropdown-content ${isOpened ? 'show' : ''}`}>
          {header}
          {this.renderOptions(filteredOptions)}
        </div>
      </div>
    );
  }
}
