import React from 'react';
import { render } from '../../../../newRender';

import { LabelsSection } from '../LabelsSection';

describe('Given a LabelsSection component', () => {
  const props = {
    labels: [
      {
        id: '1',
        title: 'label',
        color: 'color',
        type: 'type',
      },
    ],
    studentRepliesFeature: true,
    canCreateLabel: true,
    canUpdateOrganization: true,
    openLabelForm: jest.fn(),
    divProps: {},
    isMaster: false,
  };

  beforeEach(() => {
    jest.clearAllMocks();
  });

  it('should render the component when props is provided', () => {
    const container = render(<LabelsSection {...props} />);
    expect(container).toBeDefined();
  });

  it('should render the component when studentRepliesFeature is false', () => {
    const container = render(
      <LabelsSection {...props} studentRepliesFeature={false} />,
    );
    expect(container).toBeDefined();
  });

  it('should render the component when canCreateLabel is false', () => {
    const container = render(
      <LabelsSection {...props} canCreateLabel={false} />,
    );
    expect(container).toBeDefined();
  });

  it('should call openLabelForm when label is clicked', () => {
    const { getByText } = render(<LabelsSection {...props} />);
    getByText('Create new label').click();
    expect(props.openLabelForm).toHaveBeenCalled();
  });
});
