import React from 'react';
import { render } from '../../../../../newRender';

import { PersonalizedFile } from '../PersonalizedFile';

describe('<PersonalizedFile />', () => {
  it('should render component when env is provided', () => {
    const { container } = render(<PersonalizedFile env="local" />);

    expect(container).not.toBeEmpty();
  });

  it('should render component with default env when prop is not provided', () => {
    const { container } = render(<PersonalizedFile />);

    expect(container).not.toBeEmpty();
  });
});
