import React from 'react';
import { Dropdown, Label, Icon } from 'semantic-ui-react';
import { inject } from 'mobx-react';

import Avatar from './Avatar';
import EntityItem from '../containers/Entity/Item';
import { __ } from '../i18n';
import * as utils from '../utils';
@inject('client')
export default class SingleAutosuggest extends React.Component {

  constructor() {
    super();
    this.state = {
      text: '',
      suggestions: [],
      defaultSuggestions: [],
      isLoading: false,
      showReplyTime: false,
    };
    this.lastRequestId = null;

  }

  onTextChanged = ({ searchQuery }) => {
    this.setState({ text: searchQuery }, () => this.startTimeout());
  }

  canShowReplyTime = (data) => {
    const canShow = !!data.find((item) => item && item.entity && item.entity.dailyMessagesMetrics.length > 0 && item.entity.dailyMessagesMetrics[0].directReplyTime > 0);
    return canShow
  }

  request = () => {
    const { add, request, requestArguments, client, resultExtract } = this.props;
    const { defaultSuggestions } = this.state
    if (defaultSuggestions.length > 0) {
      this.setState({ showReplyTime: this.canShowReplyTime(defaultSuggestions) });
      return;
    }


    this.setState({ isLoading: true });

    client.query({ query: request, variables: { ...requestArguments } })
      .then((data) => {
        const { edgeName, nodeName = 'node' } = resultExtract;
        const result = [];
        if (add) {
          result.push({
            text: `${this.state.text} (${__('New')})`,
            value: -1, //this is an 'add' item
            icon: { name: 'plus' }
          });
        }
        data.data[nodeName][edgeName].nodes.forEach((n) => {
          result.push(this.handleEntityObject(n));
        });

        this.setState({ isLoading: false, defaultSuggestions: result, showReplyTime: this.canShowReplyTime(result) });
      }).catch((e) => {
        console.log(e)
        this.setState({ isLoading: false })
      });
  }

  handleSuggestionSelect = (value) => {
    const { suggestions, defaultSuggestions } = this.state;
    const suggestion = suggestions.length > 0 ? suggestions.find(n => n.value === value) : defaultSuggestions.find(n => n.value === value);
    suggestion.id = suggestion.value;
    this.props.onSuggestionSelect(suggestion);
    this.setState({ text: '', showReplyTime: false });
  }

  handleEntityObject = (n) => {
    const { apiExtract, style } = this.props;
    const entityObject = {
      text: n[apiExtract.name],
      value: n.id,
      imageUri: style.image && { avatar: true, src: (n.picture && n.picture.uri) || `/public/avatars/${utils.normalize(n[apiExtract.name][0].toLowerCase())}.svg` },
      icon: !style.image && style.icon,
      'data-id': n.id,
      entity: n,
      content: <EntityItem isMobile={this.props.isMobile} wrapType id={`optionEntity${n.id}`} indicator="typeLabel" entity={n} />,
    };

    return entityObject;
  }

  startTimeout = () => {
    const { add, request, requestArguments, client, resultExtract } = this.props;
    const { text, defaultSuggestions } = this.state;
    clearTimeout(this.lastRequestId);
    text === '' && defaultSuggestions.length > 0 ? this.setState({ showReplyTime: this.canShowReplyTime(defaultSuggestions), suggestions: [] }) :
      this.setState({ suggestions: [] });

    if (text !== '') {
      this.setState({ isLoading: true });
      this.lastRequestId = setTimeout(() => {
        const id = this.lastRequestId;

        requestArguments.search = text;

        return client.query({ query: request, variables: { ...requestArguments } })
          .then((data) => {
            if (id === this.lastRequestId && text !== '') {
              const { edgeName, nodeName = 'node' } = resultExtract;
              const result = [];
              if (add) {
                result.push({
                  text: `${text} (${__('New')})`,
                  value: -1, //this is an 'add' item
                  icon: { name: 'plus' }
                });
              }
              data.data[nodeName][edgeName].nodes.forEach((n) => {
                result.push(this.handleEntityObject(n));
              });
              this.setState({ isLoading: false, suggestions: result, showReplyTime: this.canShowReplyTime(result) });
            }
          });
      }, 400);
    }
  }

  searchFilter = (options, target) => {
    const newTarget = utils.normalize(target).toLowerCase();
    const newOptions = options.filter(option => (utils.normalize(option.text).toLowerCase().indexOf(newTarget) !== -1 ||
      (option.entity.description ? utils.normalize(option.entity.description).toLowerCase().indexOf(newTarget) !== -1 : false)))
    return newOptions;
  }

  render() {
    const { placeholder, onSuggestionRemove, fluid, style, dropdownCssStyle, isMobile, labelCssStyle, rightView } = this.props;
    let { selected } = this.props;
    const { suggestions, defaultSuggestions, text, isLoading, showReplyTime } = this.state;

    if (selected && selected.imageUri === undefined) { selected = this.handleEntityObject(selected.entity); } // this if is true only when the user is editing their message
    const renderSelected = selected &&
      <div style={{ display: 'inline-block', paddingLeft: isMobile ? '12px' : '0px' }}>
        <Label className="image round" data-action="delete-suggestion" style={{ alignItems: 'center', display: 'flex', border: '1px solid #D6D6D6' }} size="tiny" onClick={() => onSuggestionRemove(selected.id)} >
          {
            selected.imageUri.avatar ?
              <Avatar avatar spaced="right" src={selected.imageUri.src} alt={selected.text} />
              :
              <Icon name={style.icon} />
          }
          {selected.text}
          <Icon name="delete" />
        </Label>
      </div>;

    return (
      <div className={rightView ? isMobile ? "entity-drop-down-mobile" : "entity-drop-down" : null} style={{
        display: 'flex', flex: 1, flexDirection: 'row',
        marginRight: isMobile ? -23 : 0, marginLeft: isMobile ? -24 : 0
      }}>
        {

          renderSelected ||
          <div style={{ flex: 1, }}>
            <Dropdown
              className={`no-search-padding${isMobile ? ' full-screen' : ''} show-more-suggestions`}
              fluid={fluid} scrolling selection style={dropdownCssStyle} selectOnBlur={false}
              onBlur={() => this.setState({ suggestions: [], text: '', showReplyTime: false })}
              placeholder={placeholder}
              icon={' '}
              noResultsMessage={!text && !selected ? __('Type to search...') : __('No results were found')}
              loading={isLoading && (text !== '' || !defaultSuggestions.length)}
              options={text !== '' ?
                suggestions : defaultSuggestions
              }
              text={text}
              onChange={(e, target) => this.handleSuggestionSelect(target.value)}
              onSearchChange={(e, target) => this.onTextChanged(target)}
              minCharacters={0}
              search={this.searchFilter}
              onFocus={this.request}
              selectOnNavigation={false}
            />
          </div>
        }

        <div style={{ width: '80px', display: 'flex', flexDirection: 'row' }}>
          {!renderSelected && showReplyTime ? rightView : null}
        </div>
      </div>
    );
  }
}
