import React from 'react';
import { Icon, Header, Segment, Popup, Divider } from 'semantic-ui-react';

export default class DashEntityCard extends React.Component {
  render() {
    const { title, icon, iconColor, info, description, filter, content, padded } = this.props;
    return (
      <Segment textAlign="center" style={{ border: 'none', padding: '30px' }}>
        {
          title ?
            <div>
              <div className="horizontallySpacedItems">
                <Header as="h5" style={{ textAlign: 'left', fontSize: '18px' }}>
                  <Header.Content>
                    {
                      icon ?
                        <Icon name={icon} color={iconColor} />
                        :
                        null
                    }
                    {title}
                    {
                      info ?
                        <Popup
                          trigger={<Icon style={{ opacity: '.6', marginLeft: '5px', fontSize: '18px' }} name="info circle" color="grey" />}
                          content={<span>{info}</span>}
                          hideOnScroll
                          basic
                        />
                        :
                        null
                    }
                    {
                      description ?
                        <Header.Subheader>
                          {description}
                        </Header.Subheader>
                        :
                        null
                    }
                  </Header.Content>
                </Header>
                {
                  filter ?
                    <div>{filter}</div>
                    :
                    null
                }
              </div>
              <Divider hidden />
            </div>
            :
            null
        }
        {
          content ?
            <div style={padded ? { padding: '0px 20px' } : {}}>{content}</div>
            :
            null
        }
      </Segment>
    );
  }
}
