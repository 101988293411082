import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router';

import { __ } from '../i18n';

const style = {
  display: 'inline-block',
  padding: '5px',
  color: '#999',
  fontSize: '0.9em'
};

// @inject('store')
// @observable
export default class Footer extends React.Component {
  footer = () => [
    { href: 'https://www.classapp.co/about', value: __('About') },
    { href: 'https://ajuda.classapp.com.br', value: __('Help') },
    // { href: 'https://www.classapp.co/brand', value: __('Brand') },
    { href: 'https://developer.classapp.co', value: __('API') },
    { target: '_blank', to: '/terms', value: __('Terms and Privacy') },
    { href: 'http://blog.classapp.com.br', value: __('Blog') },
    { href: 'https://www.classapp.com.br/carreira', value: __('Carrers') },
    { href: 'https://facebook.com/ClassAppBR', value: <Icon name="facebook brand" /> },
    { href: 'https://twitter.com/ClassApp', value: <Icon name="twitter brand" /> }
  ]

  render() {
    const { nodes } = this.props;
    const userTypes = nodes ? [...new Set(nodes.map(obj => obj.type))] : [];
    const params = (userTypes.length > 1) ? userTypes.join('+') : userTypes[0];
    return (
      <div className="footer">
        {this.footer().map((item, i) => {
          if (item.href === undefined && params) {
            if (item.target === undefined) return (<Link key={i} style={style} to={`${item.to}?type=${params.toLowerCase()}`}>{item.value}</Link>);
            return (<Link key={i} style={style} target={item.target} to={`${item.to}?type=${params.toLowerCase()}`}>{item.value}</Link>);
          } else if (item.href === undefined && params === undefined) {
            if (item.target === undefined) return (<Link key={i} style={style} to={`${item.to}?type=noentity`}>{item.value}</Link>);
            return (<Link key={i} style={style} target={item.target} to={`${item.to}?type=noentity`}>{item.value}</Link>);
          }
          return (<a target="_blank" rel="noreferrer noopener" key={i} style={style} href={item.href}>{item.value}</a>);
        }
        )}
      </div>
    );
  }
}
