import React from 'react';
import { inject, observer } from 'mobx-react';
import { Menu, Container } from 'semantic-ui-react';

import { __ } from '../i18n';
import { isEntityType } from '../utils';

@inject('store') @observer
export default class ExpiredLimitBanner extends React.Component {
  render() {
    const { onPress, isBanner, organization, store } = this.props;

    const monthlyMessagesCount = organization && organization.monthlyMessagesCount;
    const monthlyMessageQuota = organization && organization.monthlyMessageQuota;

    const availableMessages = (monthlyMessageQuota - monthlyMessagesCount) || 0;

    const organizationBanners = (organization && organization.organizationBanners && organization.organizationBanners.nodes) || [];
    const hasOrganizationBanners = organizationBanners && organizationBanners.find(__banner => __banner.bannerType === 'FINANCIAL_SUSPENDED');

    const isOverdue = hasOrganizationBanners && organization.permissions.hasAdmin;

    if (!monthlyMessageQuota || availableMessages > 0 || isOverdue) return null;

    if (isEntityType(store, 'STUDENT')) return null;

    if (isBanner) {
      return (
        <div>
          <div style={{ height: 55 }} />
          <Menu
            fixed="top"
            borderless
            style={{
              backgroundColor: '#FFFFF',
              marginTop: 64,
              zIndex: 3,
              border: 0
            }}
          >
            <Container style={{ justifyContent: 'center', alignItems: 'center' }}>
              <span style={{ color: '#DB2828' }}>
                {isEntityType(store, 'ADMIN') ?
                  <span>{__('The message limit has been exceeded, consult your dashboard and')}</span>
                  : __('The message limit has been exceeded, please contact your administrator.')}
                {' '}
                {<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onPress}>{__('Know More')}</span>}
              </span>
            </Container>
          </Menu>
        </div>);
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 48, padding: '0px 20px' }}>
        <span style={{ color: '#DB2828' }}>
          {isEntityType(store, 'ADMIN') ?
            <span>{__('The message limit has been exceeded, consult your dashboard and')}</span>
            : __('The message limit has been exceeded, please contact your administrator.')}
          {' '}
          {<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onPress}>{__('Know More')}</span>}
        </span>
        {isEntityType(store, 'ADMIN') && <span
          onClick={onPress}
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#0069FF', color: '#FFF', height: '30px', borderRadius: '20px', fontSize: '12px', cursor: 'pointer', padding: '0px 15px'
          }}
        >
          {__('Upgrade')}
        </span>}
      </div>
    );
  }
}
