import { validateSelectedUsers } from '.';

describe('given validateSelectedUsers function', () => {
  it('should be able to return false result when param is not provided', () => {
    const result = validateSelectedUsers(null);

    expect(result).toEqual(false);
  });

  it('should be able validate when selected users array is provided', () => {
    const result = validateSelectedUsers([
      { id: 1, fullname: 'Mr Holmes' },
      { id: 2, fullname: 'Nikita' },
    ]);

    expect(result).toEqual(true);
  });
});
