import React from 'react';
import { fireEvent, render } from '../../../../newRender';

import { TagsList } from '../TagsList';

describe('<TagsList />', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      hasStudentRepliesFeature: true,
      tags: [
        {
          id: 1,
          name: 'tag',
        },
      ],
      hasMaster: false,
      isAdmin: false,
      organization: {
        id: 1,
      },
      onClick: jest.fn(),
      onDelete: jest.fn(),
      browserHistory: {
        push: jest.fn(),
      },
    };
  });

  it('should render the component when props is provided', () => {
    const container = render(<TagsList {...props} />);
    expect(container).toBeDefined();
  });

  it('should render the default props when it is not provided', () => {
    const container = render(<TagsList />);
    expect(container).toBeDefined();
  });

  it('should render the empty tags message when tags is empty', () => {
    props.tags = [];
    const { getByText } = render(<TagsList {...props} />);
    expect(getByText('Your organization has no tags.')).toBeInTheDocument();
  });

  it('should render the add tag button when user is master', () => {
    props.hasMaster = true;
    const { getByText } = render(<TagsList {...props} />);
    expect(getByText('Add tag')).toBeInTheDocument();
  });

  it('should render the contact support message when user is not master', () => {
    const { getByText } = render(<TagsList {...props} />);
    expect(
      getByText('Contact our support to add or delete tags.'),
    ).toBeInTheDocument();
  });

  it('should render the tags when tags is not empty', () => {
    const { getByText } = render(<TagsList {...props} />);
    expect(getByText(props.tags[0].name)).toBeInTheDocument();
  });

  it('should call browser history when tags is not empty and click in delete icon', () => {
    props.isAdmin = true;
    props.hasMaster = true;
    const { getByTestId } = render(<TagsList {...props} />);
    const deleteIcon = getByTestId(`delete-tag-icon-${props.tags[0].id}`);
    fireEvent.click(deleteIcon);
    expect(props.browserHistory.push).toBeCalled();
  });

  it('should call browser history when tags is not empty and click in label', () => {
    props.isAdmin = true;
    props.hasMaster = true;
    const { getByTestId } = render(<TagsList {...props} />);
    const editIcon = getByTestId(`edit-tag-icon-${props.tags[0].id}`);
    fireEvent.click(editIcon);
    expect(props.browserHistory.push).toBeCalled();
  });

  it('should not add the label-content classname when hasStudentRepliesFeature is false and user is master', () => {
    props.hasStudentRepliesFeature = false;
    props.hasMaster = true;
    const container = render(<TagsList {...props} />);
    expect(container).toBeDefined();
  });
});
