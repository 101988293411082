import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import Cropper from 'react-cropper';

import 'cropperjs/dist/cropper.css';

import Modal from './Modal';

import { __ } from '../i18n';
import * as utils from '../utils';

export default class ImageCropper extends React.Component {
  constructor(props) {
    super();

    this.state = {
      medias: props.medias,
      position: 0
    };
  }

  handleTransition = (media) => {
    const dataUrl = this.cropper.getCroppedCanvas().toDataURL(media.type);

    const block = dataUrl.split(';');
    const contentType = block[0].split(':')[1];
    const realData = block[1].split(',')[1];
    const blob = utils.b64toBlob(realData, contentType);

    const { medias, position } = this.state;

    medias[position].crop = { dataUrl, blob };

    this.setState({ medias }, () => {
      if (position < medias.length - 1) {
        this.setState({ position: position + 1 });
      } else {
        this.props.onSubmit(this.state.medias);
      }
    });
  }

  render() {
    const { aspectRatio, circular } = this.props;
    const { medias, position } = this.state;
    const params = {};

    if (circular) {
      params.className = 'clsp-circular';
    }

    return (
      <Modal id={this.props.id || 'ImageCropper'} size="small" onClose={() => this.props.onClose()} closeOnRootNodeClick={false}>
        <Modal.Header>{__('Adjust image %s of %s', position + 1, medias.length)}</Modal.Header>
        <Modal.Content>
          <Cropper
            key={medias[position].preview}
            ref={(c) => this.cropper = c}
            src={medias[position].preview}
            aspectRatio={aspectRatio || 1}
            guides={false}
            style={{ maxHeight: 400 }}
            {...params}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-action="cancel"
            basic
            floated="left"
            content={position ? __('Back') : __('Cancel')}
            onClick={position ? () => this.setState({ position: position - 1 }) : this.props.onClose}
          />
          <Button
            data-action="submit"
            primary
            content={position < medias.length - 1 ? __('Next image') : __('Conclude')}
            onClick={() => this.handleTransition(medias[position])}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
