import React from 'react';
import { inject, observer } from 'mobx-react';
import { Notification } from 'react-notification';
import { Icon, Portal } from 'semantic-ui-react';
import { __ } from '../i18n';
import '../assets/css/ui/Snackbar.module.scss';

const DEFAULT_SNACKBAR = {
  active: false,
  message: '',
  success: false,
  dismissAfter: 2000
};
@inject('store') @observer
export default class Snackbar extends React.Component {
  state = {
    snackbar: DEFAULT_SNACKBAR,
    scheduledSnackbar: null
  }

  componentDidUpdate = () => {
    const { snackbar: prevSnackbar, snackbarInstance: prevSnackbarInstance } = this.state;
    const { scheduledSnackbar, scheduledInstance } = this.state;
    const { snackbar } = this.props.store;

    if (scheduledSnackbar) this.setState({ snackbar: { ...scheduledSnackbar }, snackbarInstance: scheduledInstance, scheduledSnackbar: null });
    else if (snackbar.active !== prevSnackbar.active) this.setState({ snackbar: { ...snackbar }, snackbarInstance: snackbar });
    else if (snackbar !== prevSnackbarInstance) this.setState({ scheduledSnackbar: { ...snackbar }, scheduledInstance: snackbar, snackbar: { ...DEFAULT_SNACKBAR } });
  }

  componentWillUnmount = () => {
    if (!this.props.noHideOnUnmount) this.hideSnackbar();
  }

  hideSnackbar = () => {
    this.props.store.snackbar = {
      active: false,
      cssTags: '',
      message: '',
      success: false,
      dismissAfter: 3000
    };
  };

  render() {
    const { store } = this.props;
    const dismissAfter = store.snackbar.dismissAfter || 3000;

    return (
      <Portal
        className="ui page modals dimmer transition visible active none unnoticed"
        style={{ position: 'initial' }}
        open={store.snackbar.active}
      >
        <Notification
          isActive={this.state.snackbar.active}
          style
          className={`snackbar-wrapper ${this.state.snackbar.cssTags} ${store.snackbar.success ? 'snackbar-wrapper-sucess' : 'snackbar-wrapper-error'} ${dismissAfter === 3000 && 'dismiss-after3000'} ${dismissAfter === 4000 && 'dismiss-after4000'} ${dismissAfter === 5000 && 'dismiss-after5000'} ${dismissAfter === 6000 && 'dismiss-after6000'} ${dismissAfter === 7000 && 'dismiss-after7000'} ${dismissAfter === 10000 && 'dismiss-after10000'}`}
          message={
            <div id="Snackbar">
              <div className="snackbar-icon">
                {
                  store.snackbar.success ? <Icon data-value="success" style={{ color: '#00A656', fontWeight: 900 }} name="circle check" size="large" />
                    : <Icon data-value="error" style={{ color: '#BF2600', fontWeight: 900 }} name="exclamation circle" size="large" />
                }
              </div>
              <div className="snackbar-message">
                <span style={{ marginLeft: '5px' }}>
                  {store.snackbar.message}
                  {store.snackbar.success ? ' 🎉' : ' 😞'}
                </span>
              </div>
            </div>
          }
          action={store.snackbar.action ? <div style={{ color: '#084FFF', font: '1rem "normal Roboto", sans-serif', textTransform: 'none', fontSize: '13px' }}>{store.snackbar.action}</div> : <Icon className="times" style={{ marginTop: '0.2rem', color: 'rgba(0, 0, 0, 0.6)' }} size="large" />}
          onClick={() => {
            if (store.snackbar.onClick) store.snackbar.onClick();
            this.hideSnackbar();
          }}
          dismissAfter={store.snackbar.dismissAfter || 2000}
          onDismiss={this.hideSnackbar}
          barStyle={{ textAlign: 'center' }}
        />
      </Portal>
    );
  }
}
