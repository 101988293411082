import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Table, Menu, Icon, Label, Popup } from 'semantic-ui-react';

import EventItem from '../containers/Event/Item';
import Responsive from './Responsive';

import { __ } from '../i18n';

const dayNames = () => [__('Su'), __('Mo'), __('Tu'), __('We'), __('Th'), __('Fr'), __('Sa')];
const monthNames = () => [__('January'), __('February'), __('March'), __('April'), __('May'), __('June'), __('July'), __('August'), __('September'), __('October'), __('November'), __('December')];
const date = new Date();

@inject('store') @observer
export default class EventCalendar extends Responsive {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      selectedDt: props.selectedDt || new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      firstOfMonth: null,
      daysInMonth: null
    };
  }

  UNSAFE_componentWillMount() {
    this.updateInfo(this.props.year, this.props.month);
  }

  onChangeDay = (year, month, day) => {
    this.props.onChangeDate(Object.assign({ year: null, month: null, day: null }, { year, month, day }));
    if (day !== undefined) {
      this.setState({ selectedDt: new Date(year, month, day) });
    } else {
      this.setState({ selectedDt: null });
    }
  }

  getPrev = () => {
    let year;
    let month;
    if (this.props.month > 0) {
      month = this.props.month - 1;
      year = this.props.year;
    } else {
      month = 11;
      year = this.props.year - 1;
    }

    this.updateInfo(year, month, () => {
      const { daysInMonth } = this.state;
      let day = this.props.day;

      if (day > daysInMonth) day = daysInMonth;

      this.onChangeDay(year, month, day);
    });
  }

  getNext = () => {
    let year;
    let month;
    if (this.props.month < 11) {
      month = this.props.month + 1;
      year = this.props.year;
    } else {
      month = 0;
      year = this.props.year + 1;
    }

    this.updateInfo(year, month, () => {
      const { daysInMonth } = this.state;
      let day = this.props.day;

      if (day > daysInMonth) day = daysInMonth;

      this.onChangeDay(year, month, day);
    });
  }

  updateInfo = (year, month, cb) => {
    this.setState({
      firstOfMonth: new Date(year, month, 1),
      daysInMonth: new Date(year, month + 1, 0).getDate()
    }, cb);
  }

  render() {
    const { events, store, router } = this.props;
    return (
      <div id="EventCalendar">
        {
          store.currentEntity.type !== 'STUDENT' && store.currentEntity.organization &&
          <Menu compact floated="right" style={{ marginBottom: 24, backgroundColor: '#084FFF', border: '0px', boxShadow: 'unset', borderRadius: '100px' }}>
            <Menu.Item style={{}} as={Link} to={`/entities/${store.currentEntity.id}/commitments/add`} icon="plus" id="NewEventButton">
              <Icon style={{ marginRight: '5px', color: '#fff' }} name="plus" />
              <span style={{ paddingLeft: '0px', color: '#FFF', fontSize: '16px' }}>{__('New Event')}</span>
            </Menu.Item>
          </Menu>
        }
        <Menu compact floated="left" style={{ marginBottom: 24, border: '0px', boxShadow: 'unset', borderRadius: '100px' }}>
          <Menu.Item style={{ padding: '0px' }} id="CalendarSpan">
            <h3>{__('Calendar')}</h3>
          </Menu.Item>
        </Menu>
        <Table className={'calendar-table'} unstackable celled style={{ tableLayout: 'fixed' }}>
          <Table.Header>
            <Header
              monthNames={monthNames()}
              month={this.props.month}
              year={this.props.year}
              onPrev={this.getPrev}
              onNext={this.getNext}
            />
            <WeekDays />
          </Table.Header>

          <MonthDates
            month={this.props.month}
            year={this.props.year}
            daysInMonth={this.state.daysInMonth}
            firstOfMonth={this.state.firstOfMonth}
            selectedDt={this.state.selectedDt}
            onSelect={this.onChangeDay}
            events={events}
            today={this.state.today}
            store={store}
            router={router}
            isMobile={this.isMobile()}
          />
        </Table>
      </div>
    );
  }
}

const Header = props => (
  <Table.Row>
    <Table.HeaderCell colSpan="7" className={'header'}>
      <div style={{ display: 'flex', backgroundColor: '#fff', justifyContent: 'space-around', border: '1px solid rgba(34, 36, 38, 0.1)', height: '43px' }}>
        <div className={'arrow'} style={{ display: 'flex', width: '33.33%', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => props.onPrev()} > <Icon style={{}} name="arrow left" /> </div>
        <div style={{ minWidth: '120px', width: '33.33%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRight: '1px solid rgba(34, 36, 38, 0.1)', borderLeft: '1px solid rgba(34, 36, 38, 0.1)' }}>
          <span style={{ fontWeight: '300' }}>{`${props.monthNames[props.month]} ${props.year}`}</span>
        </div>
        <div className={'arrow'} style={{ display: 'flex', width: '33.33%', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => props.onNext()}> <Icon style={{}} name="arrow right" /> </div>
      </div>
    </Table.HeaderCell>
  </Table.Row>
);

const WeekDays = () => {
  const haystack = [...Array(7).keys()];
  return (
    <Table.Row>
      {
        haystack.map((item, i) => <Table.HeaderCell
          textAlign="center"
          style={{ width: '14%', maxWidth: 100, backgroundColor: i === 0 || i === 6 ? '#f8f8f8' : '#e8e8e8' }}
          key={i}
        >
          {dayNames()[i % 7]}
        </Table.HeaderCell>
        )
      }
    </Table.Row>
  );
};

const Event = props => <div key={props.key} className="calendarEvent">
  {
    !props.isMobile ?
      <Popup
        trigger={<div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <Icon name="circle" className="bold" style={{ color: `#${props.commitment.color || '495057'}` }} />
          {props.commitment.name}
        </div>}
        content={<EventItem event={props.commitment} />}
        style={{ padding: 0, border: 0, zIndex: '4', maxWidth: 400 }}
        on="click"
        basic
      />
      :
      <Link style={{ textOverflow: 'ellipsis', overflow: 'hidden', color: '#000' }} to={`/entities/${props.store.currentEntity.id}/commitments/${props.commitment.id}`}>
        <Icon name="circle" className="bold" style={{ color: `#${props.commitment.color || '495057'}` }} />
        {props.commitment.name}
      </Link>
  }
</div>;

const MonthDates = (props) => {
  let day;
  let d;
  let current;
  let isDate;
  const weekStack = [...Array(7).keys()];
  const startDay = props.firstOfMonth.getUTCDay();
  const first = props.firstOfMonth.getDay();
  let rows = 5;
  const events = props.events;
  const isMobile = props.isMobile;

  if ((startDay === 5 && props.daysInMonth === 31) || (startDay === 6 && props.daysInMonth > 29)) {
    rows = 6;
  }

  const haystack = [...Array(rows).keys()];
  day = 1 - first;
  while (day > 1) {
    day -= 7;
  }
  day -= 1;


  return (
    <Table.Body>
      {
        haystack.map((item, i) => {
          d = day + (i * 7);

          return (<Table.Row key={i}>
            {
              weekStack.map((item, j) => {
                d += 1;
                isDate = d > 0 && d <= props.daysInMonth;

                if (isDate) {
                  current = new Date(props.year, props.month, d);
                  const currentDate = current.getDate();
                  const commitmentAvailable = events && events.filter(commitment => parseInt(commitment.date.substring(8, 10), 10) === currentDate);
                  const finalDay = d;
                  let styles;
                  let className = 'noneCalendar';
                  const selected = props.selectedDt && current.toString() === props.selectedDt.toString();

                  if (j === 0 || j === 6) {
                    className = 'weekendCalendar';
                  }

                  return (
                    <Table.Cell
                      data-action="select-day"
                      data-params={finalDay}
                      verticalAlign="top"
                      className={className + ' buttonHover'}
                      style={{ ...styles, height: 100 }}
                      key={j} role="button"
                      onClick={() => props.onSelect(props.year, props.month, finalDay)}
                    >
                      <Label
                        circular
                        style={selected ? { color: 'white', backgroundColor: '#0080ff' } : { backgroundColor: 'white' }}
                      >
                        {
                          !selected && props.today && props.today.toString() === current.toString() ?
                            <span style={{ color: '#0080ff' }}>{finalDay}</span>
                            :
                            finalDay
                        }
                      </Label>
                      {
                        commitmentAvailable.length < 4 ?
                          commitmentAvailable.map((commitment, key) => <Event key={key} commitment={commitment} store={props.store} isMobile={isMobile} />)
                          :
                          [
                            <Event key={0} commitment={commitmentAvailable[0]} store={props.store} isMobile={isMobile} />,
                            <Event key={1} commitment={commitmentAvailable[1]} store={props.store} isMobile={isMobile} />,
                            <Event key={2} commitment={commitmentAvailable[2]} store={props.store} isMobile={isMobile} />,
                            <span style={{ color: '#9696a0' }}>{`+${commitmentAvailable.length - 3}`}</span>
                          ]
                      }
                    </Table.Cell>
                  );
                }
                return <Table.Cell key={j} id={'ecBlank_' + i + '_' + j} style={{ height: 100 }} />;
              })
            }
          </Table.Row>);
        })
      }
    </Table.Body>
  );
};
