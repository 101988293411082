import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as utils from '../../client/utils';

const styles = {
  loved: {
    fontSize: 20,
    color: '#0080ff',
    fontWeight: 'bold'
  },
  notLoved: {
    fontSize: 20,
    color: '#9696a0'
  }
};

@inject('store')
@graphql(gql`mutation createReaction($createReactionMutation: CreateReactionInput!) {
  createReaction(input: $createReactionMutation) {
    clientMutationId
  }
}`, {
  name: 'createReaction',
  options: {
    refetchQueries: ['EntityMomentsQuery', 'MomentNodeQuery', 'MomentStatusQuery']
  }
})
@graphql(gql`mutation deleteReaction($deleteReactionMutation: DeleteReactionInput!) {
  deleteReaction(input: $deleteReactionMutation) {
    clientMutationId
  }
}`, {
  name: 'deleteReaction',
  options: {
    refetchQueries: ['EntityMomentsQuery', 'MomentNodeQuery', 'MomentStatusQuery']
  }
})
@observer
export default class extends React.Component {
  state = {
    isReacted: null
  }

  async submit(isReacted) {
    const { message, createReaction, deleteReaction, params } = this.props;

    try {
      if (isReacted) {
        await deleteReaction({
          variables: {
            deleteReactionMutation: {
              messageId: message.id,
              entityId: params.entity_id
            }
          }
        });
      } else {
        await createReaction({
          variables: {
            createReactionMutation: {
              messageId: message.id,
              entityId: params.entity_id,
              type: 'LIKE'
            }
          }
        });
      }
      this.setState({ isReacted: !isReacted });
    } catch (err) {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  }

  render() {
    const { message, canReact } = this.props;

    const isReacted = (this.state.isReacted === null ? message.reaction && message.reaction.type : this.state.isReacted);
    const className = (canReact && isReacted) ? 'active' : '';

    return (
      <Icon
        name="heart"
        style={canReact && isReacted ? styles.loved : styles.notLoved}
        className={canReact ? className + ' pointer' : className}
        onClick={canReact ? () => this.submit(isReacted) : () => { }}
      />
    );
  }
}
