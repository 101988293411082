/* eslint-disable no-console */
import React from 'react';
import { SearchInput } from './components/SearchInput';
import {
  Container,
  StyledTable,
  StyledTableTdContainer,
  StyledTableTdContent,
  StyledTableTh,
  NoDataFound,
} from './Table.styles';
import Loading from '../../../../../../components/ui/Loading';
import { __ } from '../../../../../../i18n';

export function Table({
  topColumns,
  rows,
  isLoading,
  onChangeSearchValue,
  debounceTime,
  inputPlaceholder,
  showInput = true,
  ...rest
}) {
  return (
    <Container>
      {showInput && (
        <SearchInput
          onChangeValue={onChangeSearchValue}
          debounceTime={debounceTime}
          placeholder={inputPlaceholder}
        />
      )}
      {isLoading ? (
        <Loading
          width={30}
          height={30}
          style={{
            margin: '0px',
          }}
        />
      ) : (
        <StyledTable data-testid="table" {...rest}>
          <thead>
            <tr>
              {topColumns.map(({ id, label }) => (
                <StyledTableTh key={id}>{label}</StyledTableTh>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 ? (
              <tr>
                <td colSpan={topColumns.length}>
                  <NoDataFound>
                    <img
                      src="/public/images/EmptyStateChannels.svg"
                      alt="No data found"
                    />
                    <h3>{__('No results found')}</h3>
                    <p>{__('Try a new search')}</p>
                  </NoDataFound>
                </td>
              </tr>
            ) : (
              rows.map(({ id, columns }) => (
                <tr key={id}>
                  {columns.map(({ id: columnId, icon, content }) => (
                    <td
                      key={columnId}
                      style={{
                        width: `${100 / topColumns.length}%`,
                      }}
                    >
                      <StyledTableTdContainer>
                        {icon}
                        <StyledTableTdContent>{content}</StyledTableTdContent>
                      </StyledTableTdContainer>
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </StyledTable>
      )}
    </Container>
  );
}
