/* eslint-disable react/prop-types */
import React from 'react';

import { Container, Header, TabContent, TabHeader } from './Tabs.styles';

import { useTabs } from './hooks/useTabs';

export function Tabs({
  children,
  value,
  onTabChange,
  isFullSize = false,
  ...rest
}) {
  if (!children) return null;
  const childrenToArray = React.Children.toArray(children);

  const { activeTab, changeTab } = useTabs({
    value,
    onTabChange,
    childrenToArray,
  });

  return (
    <Container {...rest}>
      <Header>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) return null;
          return (
            <TabHeader
              isActive={activeTab === child?.props?.value}
              onClick={() => changeTab(child?.props?.value)}
              key={`tabs-header-item-${child?.props?.value}`}
              data-testid={child?.props?.value}
              isFull={isFullSize}
            >
              {child?.props?.title}
            </TabHeader>
          );
        })}
      </Header>
      <TabContent>
        {childrenToArray.map((child) => {
          if (!React.isValidElement(child) || child.props?.value !== activeTab)
            return null;
          return child;
        })}
      </TabContent>
    </Container>
  );
}
