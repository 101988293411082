import React from 'react';
import Button from '../../../../../../../../components/ui/Button';

import {
  Container,
  SaveText,
  SubmitButtonContainer,
  CheckLightIcon
} from './Footer.styles';

/**
* @typedef {{
  *  text?: string,
  *  style?: React.CSSProperties
  * }} TError
**/

/**
* @typedef {{
*  value?: string;
*  onChange?: (event: Event) => void;
*  error?: TError | string;
* }} Props
*
* @param {Props} props
* @returns {JSX.Element}
*/
export function Footer({ onCancel, cancelText, submitText }) {
  return (
    <Container>
      <Button
        data-testid="on-cancel-footer-button"
        round
        transparent
        text={cancelText}
        onClick={onCancel}
      />
      <div style={{ marginLeft: '0.75rem' }}>
        <Button
          data-testid="submit-button"
          round
          submit
          text={(
            <SubmitButtonContainer>
              <CheckLightIcon fill="#FFF" />
              <SaveText>{submitText}</SaveText>
            </SubmitButtonContainer>
          )}
        />
      </div>
    </Container>
  );
}
