import React from 'react';

import {
  Container, Image, Title, Subtitle
} from './Fallback.styles';

export function Fallback({
  src, alt, title, subtitle
}) {
  return (
    <Container>
      <Image src={src} alt={alt} />
      <div>
        <Title>
          {title}
        </Title>
        <Subtitle>
          {subtitle}
        </Subtitle>
      </div>
    </Container>
  );
}
