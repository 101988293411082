import React from 'react';
import { Segment, Container } from 'semantic-ui-react';

export default class CustomTooltip extends React.Component {
  render() {
    const { active, valuePrefix } = this.props;

    if (active) {
      const { payload, label } = this.props;

      return (
        <Segment key={label} style={{ maxWidth: '160px' }}>
          <Container textAlign="left">
            <p><strong>{`${payload[0].payload.name}`}</strong></p>
            {
              Object.keys(payload[0].payload).map((key) => {
                if (['name', 'title', 'offset'].indexOf(key) > -1) return null;
                return (<p key={key}>
                  <span style={{ color: payload[0].color || '' }}>{`${payload[0].payload.title || key} : `}</span>
                  {valuePrefix && valuePrefix}
                  {`${payload[0].payload[key] - (payload[0].payload.offset || 0) || 0}`}
                </p>);
              })
            }
          </Container>
        </Segment>
      );
    }

    return null;
  }
}
