/* eslint-disable no-undef */
/* eslint-disable react/jsx-indent-props */
import React from 'react';
import * as utils from '../../utils';

/**
 * Avatar component
 *
 * How to use it:
 *
 *  <Avatar
      alt="Picture description"
      src="picture.com.br"
      style={{ backgroundColor: '#FF00FF' }}
      svgStyle={{ backgroundColor: '#0000FF' }}
    />
 */

const colors = [
  '#0BAE6D',
  '#00C7E6',
  '#ED1C25',
  '#FF7226',
  '#6555C0',
  '#ED335F',
  '#0069FF',
  '#FBB13C',
  '#108E82',
];

/**
 * @typedef {{
 *  src: string,
 *  alt: string,
 *  svgStyle?: React.CSSProperties,
 *  style?: React.CSSProperties
 * }} Props
 * @extends {Component<Props>}
 */
export default class Avatar extends React.Component {
  /**@param {Props} props*/
  constructor(props) {
    super(props);
    this.state = { urlError: false };
  }

  /**
   * @param {string} firstLetter
   * @param {string} lastLetter
   * @param {React.CSSProperties} svgStyle
  */
  createSVG = (firstLetter, lastLetter, svgStyle) => (
    <svg version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" className="clsp-svg" style={svgStyle} viewBox="0 0 400 400" enableBackground="new 0 0 400 400" xmlSpace="preserve">
      <g>
        <rect x={0} y={0} width="100%" height="100%" fill={svgStyle.backgroundColor} />
        <text x="50%" y="53%" alignmentBaseline="middle" dominantBaseline="middle" fontFamily="Circular" fontSize={200} fontWeight={100} fill="rgba(255,255,255,0.8)" textAnchor="middle" pointerEvents="auto">{firstLetter + lastLetter}</text>
      </g>
    </svg>
  )

  render() {
    const { urlError } = this.state;
    const { src } = this.props;
    let img = null;

    const name = this.props.alt;
    let firstL = 'a';
    let lastL = '';
    let nameColorIndex = 0;

    if (name) {
      const namesSplit = name.split(' ');
      firstL = utils.normalize(namesSplit[0].charAt(0).toLowerCase());
      lastL = (namesSplit.length > 1) ? utils.normalize(namesSplit[namesSplit.length - 1].charAt(0).toLowerCase()) : '';
      nameColorIndex = Math.floor(namesSplit[0].length % colors.length);
    }

    const styles = {
      backgroundColor: '#ffffff',
      width: '1.875rem',
      height: '1.875rem',
      borderRadius: '500rem'
    };

    if (this.props.width) styles.width = this.props.width;
    if (this.props.height) styles.height = this.props.height;

    const backgroundColor = colors[nameColorIndex];

    const firstLetterUpperCase = `${(firstL || '')}`.trim().toUpperCase();
    const lastLetterUpperCase = `${(lastL || '')}`.trim().toUpperCase();

    const svgStyle = {
      width: '100%', height: '100%', borderRadius: '50%', backgroundColor, ...this.props.svgStyle
    };

    if (!src || urlError) {
      img = this.createSVG(firstLetterUpperCase, lastLetterUpperCase, svgStyle);

      return (
        <div style={styles}>
          <div>{img}</div>
        </div>
      );
    }

    return (
      <div>
        <img
          alt={this.props.alt || ''}
          src={src}
          style={this.props.style ? { ...styles, ...this.props.style } : { ...styles }}
        />
      </div>
    );
  }
}
