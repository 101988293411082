import React from 'react';
import {
  Table, Loader, Message, Segment, Image
} from 'semantic-ui-react';
import omit from 'lodash/omit';

import { __ } from '../i18n';
import * as utils from '../utils';

import Pagination from './Pagination';

export default class TableView extends React.Component {
  render() {
    const {
      source, renderColumns, renderRowCells, renderRows, pagination, onUpdate, emptyMessage, onLimitChange, onPageChange, onLoadMore, renderHeader
    } = this.props;

    const newProps = omit(
      this.props,
      ['source', 'renderColumns', 'renderRowCells', 'renderRows', 'pagination', 'onUpdate', 'emptyMessage', 'onLimitChange', 'onPageChange', 'onLoadMore', 'renderHeader']
    );

    if (!source) {
      return (<Loader style={{ marginTop: 30 }} active inline="centered" />);
    }

    return (
      <Table unstackable {...newProps}>
        {
          renderHeader
            ? renderHeader()
            : (
              <Table.Header>
                <Table.Row>
                  {
                  renderColumns.map((column, i) => <Table.HeaderCell key={i}>{column}</Table.HeaderCell>)
                }
                </Table.Row>
              </Table.Header>
            )
        }

        <Table.Body>
          {
            source.length > 0
              ? source.map((row, i) => {
                if (renderRows) return renderRows(row, i);

                return (
                  <Table.Row key={i} data-id={row.id || row.code}>
                    {

                    renderRowCells(row, i, source.length).map((cell, j) => {
                      const rowSpan = (cell && typeof (cell) == 'object' && cell.props) ? { rowSpan: cell.props.rowSpan || null } : null;
                      return <Table.Cell {...rowSpan} key={j}>{cell}</Table.Cell>;
                    })
                  }
                  </Table.Row>
                );
              })
              : (
                <Table.Row>
                  <Table.Cell colSpan={8}>
                    <Segment basic textAlign="center">
                      <Image style={{ maxHeight: 200 }} src={utils.asset('/images/ilustracoes-30.png')} centered />
                      <div style={{ color: '#9696a0' }}>{(emptyMessage !== undefined) ? emptyMessage : __('There are no items to display')}</div>
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )
          }
        </Table.Body>
        {
          pagination && (!pagination.graphql || pagination.hasNextPage)
          && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={renderColumns.length} style={pagination.basic ? { padding: 0, border: 'none' } : {}}>
                <Pagination
                  source={source}
                  pagination={pagination}
                  onUpdate={onUpdate}
                  onLimitChange={onLimitChange}
                  onPageChange={onPageChange}
                  onLoadMore={onLoadMore}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
          )
        }
      </Table>
    );
  }
}
