import React from 'react';
import { CloseButtonContainer, CloseButtonText } from './UndoButton.styles';

export function UndoButton({ onClick, text }) {
  return (
    <CloseButtonContainer>
      <CloseButtonText onClick={onClick}>{text}</CloseButtonText>
    </CloseButtonContainer>
  );
}
