import React from 'react';
import omit from 'lodash/omit';

import SortableItem from './SortableItem';

export default class SortableList extends React.Component {

  state = {
    draggingIndex: null,
    data: this.props.data
  };

  updateState = (obj) => {
    this.setState(obj, () => {
      this.props.onSort(this.state.data);
    });
  }

  render() {
    const { draggingIndex, data: { items } } = this.state;
    const listItems = items.map((item, i) => (
      <SortableItem
        key={i}
        updateState={this.updateState}
        items={items}
        draggingIndex={draggingIndex}
        sortId={i}
        outline="list"
      >{item.content}</SortableItem>
      ));
    const newProps = omit(this.props, ['onSort', 'data']);

    return (
      <div {...newProps}>{listItems}</div>
    );
  }
}
