import React from 'react';
import { Icon, Form } from 'semantic-ui-react';

import Responsive from './Responsive';

const styles = {
  action: {
    padding: '.78571429em .78571429em .78571429em',
    border: '1px solid #e0b4b4',
    borderLeft: '0px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: '#fff7f6'
  }
};
export default class Dropdown extends Responsive {
  render() {
    const newProps = { ...this.props };
    let errorText = null;

    if (newProps.error && !this.isMobile() && newProps.label) {
      newProps.error = true;
      newProps.label += ` - ${this.props.error}`;
    } else if (newProps.error) {
      newProps.error = true;
      newProps.action = (<span style={styles.action}><Icon name="exclamation triangle" color="red" /></span>);
      errorText = <div className="clsp-errorText">{this.props.error}</div>;
    }

    return (<Form.Field className={newProps.error && 'clsp-error'} style={{ display: 'flex', flexFlow: 'column' }}>
      <Form.Dropdown data-name={newProps.name} {...newProps} />
      {errorText}
    </Form.Field>);
  }
}
