import React from 'react';
import { Icon } from 'semantic-ui-react';

const styles = {
  typeIcon: {
    color: '#9696a0',
    marginRight: '0.4285712rem',
  },
  defaultTextColor: {
    color: '#000000',
    opacity: '.8',
    display: 'flex',
  },
};

/**
 * @param {{
 *  message: {
 *   deleted: boolean,
 *   unread: boolean,
 *   entity: {
 *    fullname: string
 *   }
 *  }
 * }} params
 */
export function NetworkEntityName({ message }) {
  if (!message) return null;
  const isDeleted = message?.deleted;
  const isUnread = message?.unread;
  const entity = message?.entity;

  return (
    <div style={styles.defaultTextColor}>
      {isDeleted ? (
        <Icon
          data-testid="trash-icon"
          data-name="trash-alt"
          name="trash"
          style={styles.typeIcon}
        />
      ) : isUnread ? (
        <Icon
          data-testid="unread-icon"
          data-name="unread-mail"
          name="envelope"
          style={styles.typeIcon}
        />
      ) : (
        <Icon
          data-testid="read-icon"
          name="envelope open outline"
          style={styles.typeIcon}
        />
      )}
      <span style={isDeleted ? { opacity: 0.65, fontStyle: 'italic' } : null}>
        {entity?.fullname}
      </span>
    </div>
  );
}
