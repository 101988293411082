import React from 'react';
import { RemoteMountedComponent } from '@classapp/sebastiao-orchestrator';

import { createUrlWithHash } from '../../../../utils';
import { Loading } from '@classapp-tech/edna';

import config from '../../../../../../config/server.config';

/**
 * @param {{
 *  env: 'local' | 'development' | 'stage' | 'internal' | 'production';
 * }}
 */
export function PersonalizedFile({ env = 'local' }) {
  return (
    <RemoteMountedComponent
      url={createUrlWithHash(
        `${config.microfrontends.sully[env]}/remoteEntry.js`,
      )}
      name="sully"
      noStyle
      moduleName="./PersonalizedFile"
      fallbackComponent={null}
      loadingComponent={<Loading variation="basic" size={20} />}
    />
  );
}
