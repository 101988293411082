import React from 'react';
import { render, screen } from '../../../../../../../../newRender';
import { ManagePermissions } from '.';

import { __ } from '../../../../../../../../i18n';

describe('<ManagePermissions />', () => {
  const props = {
    scope: {},
    policy: ['NEED_APPROVAL_MESSAGE'],
    hasMessageApprove: true,
    entity: {
      id: 1,
      fullname: 'Mr. Holmes',
      type: 'ADMIN',
      eid: '',
      seeAll: false,
      invisible: true,
      disabled: false,
      groups: [{
        id: 1,
        name: 'Grupo 1',
      }],
      addresses: [{
        address: '19990909090',
        type: 'PHONE',
        tags: [{
          id: 1,
          name: 'Tag 1',
        }],
        user: { id: 1 },
      }],
      scope: {},
      policy: ['NEED_APPROVAL_MESSAGE']
    },
    onSubmit: jest.fn(),
  };
  it('should be defined', () => {
    const { container } = render(<ManagePermissions {...props} />);
    expect(container).toBeDefined();
  });

  it('should be able to get message approve button with unleash', async () => {
    render(<ManagePermissions {...props} hasMenuConversations />);

    const button = await screen.getByText(__('Communication approval'));
    expect(button).toBeDefined();
  });

  it('should be able to get message approve button withou unleash', async () => {
    render(<ManagePermissions {...props} hasMenuConversations={false} />);

    const button = await screen.getByText(__('Message approval'));
    expect(button).toBeDefined();
  });

  it('should be able to get manage permissions button', () => {
    render(<ManagePermissions {...props} />);

    const button = screen.getByText(__('Manage Permissions'));
    expect(button).toBeVisible();
  });

  it('should be able to get label of communication approval with unleash', () => {
    render(<ManagePermissions {...props} hasMenuConversations />);

    const label = screen.getByText(__('Communication approval permissions only apply to communications sent to the inbox.'));
    expect(label).toBeVisible();
  });
});
