import React from 'react';
import { inject } from 'mobx-react';
import gql from 'graphql-tag';
import { Popup } from 'semantic-ui-react';

import { __ } from '../i18n';
import { sendGoogleAnalyticsEvent } from '../lib/analytics';
import Button from './ui/Button';

const MAX_TITLE_SIZE = 18;
const MAX_TIME_OUT_IN_MILLISECONDS = 10000;

const reopenURL = (portalLoggedURL) => {
  if (portalLoggedURL) {
    window.open(`${portalLoggedURL}`, '_blank');
  }
};

const __makeStorageKey = async (linkId, entityId, userId) => `_classapp_portal_logged.${linkId}-${entityId}-${userId}`;

const __handleClearStorage = async (linkId, entityId, userId) => {
  const storageKey = await __makeStorageKey(linkId, entityId, userId);
  localStorage.removeItem(storageKey);
  window.location.reload();
};

const CardContainer = ({ link, children, style, entityId, userId }) => (
  <div style={{ ...style, width: '20rem', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '1.714rem', margin: '0.714rem', padding: '1.714rem' }}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <i style={{ fontSize: '2.143rem' }} className={`icon ${link.icon}`} />
      <Popup
        trigger={
          <div>
            <Button
              transparent
              round
              icon={{ name: 'redo alt', style: { fontWeight: 300, margin: 0 } }}
              onClick={() => __handleClearStorage(link.id, entityId, userId)}
            />
          </div>
        }
        content={__('Update cache')}
        hideOnScroll
        basic
      />
    </div>
    <div>
      {(link.title && link.title.length > MAX_TITLE_SIZE) ?
        <Popup content={link.title} trigger={<span style={{ fontSize: '20px', color: '#000000', fontWeight: 600 }}>{`${link.title.substr(0, MAX_TITLE_SIZE)}...`}</span>} /> :
        <span style={{ fontSize: '20px', color: '#000000', fontWeight: 600 }}>{link.title}</span>
      }
    </div>
    {children}
  </div>
);

const AUTHENTICATE_PORTAL_LOGGED = gql`
  mutation authenticatePortalLogged($portalLoggedInput: portalLoggedInput!) {
    portalLogged(input: $portalLoggedInput) {
      url
      token
      expiration
    }
  }
`;

@inject('store', 'client')
export default class AccessPortalCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: false,
      portalLoggedURL: null
    };
  }

  __renderLoadingFooter = () => (
    <div>
      <div style={{ height: '1.875rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.714rem' }}>
          <div style={{ width: '87%', display: 'flex', justifyContent: 'center' }}>
            <i style={{ fontSize: '0.857rem', color: '#0069FF' }} className={'icon arrow right to bracket'} />
            <span style={{ color: '#0069FF', fontWeight: 700, fontSize: '1rem' }}>{__('Accessing system')}</span>
          </div>
          <div style={{ width: '12%', marginLeft: '1%', display: 'flex' }}>
            <i style={{ fontSize: '0.857rem', color: '#C4C4C4' }} className={'icon check'} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', height: '0.313rem', backgroundColor: '#0069FF', width: '87%', borderRadius: '12px' }} />
          <div style={{ display: 'flex', height: '0.313rem', backgroundColor: '#C4C4C4', width: '12%', borderRadius: '12px', marginLeft: '1%' }} />
        </div>
      </div>
      <div style={{ height: '1.875rem', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '1rem', marginTop: '1.8rem' }}>
        <i className="icon loading circle notch" style={{ fontWeight: 600, fontSize: '1.3rem', color: '#999999' }} />
        <span style={{ color: '#999999' }}>{__('Wait a few seconds')}</span>
      </div>
    </div>
  );

  __renderSuccessFooter = portalLoggedURL => (
    <div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.714rem' }}>
          <div style={{ width: '12%', marginLeft: '1%', display: 'flex', alignItems: 'center' }}>
            <i style={{ fontSize: '0.857rem', color: '#C2DBFF' }} className={'icon arrow right to bracket'} />
          </div>
          <div style={{ width: '87%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <i style={{ fontSize: '0.857rem', color: '#00A656', marginBottom: '4px' }} className={'icon check'} />
            <span style={{ color: '#00A656', fontWeight: 700, fontSize: '1rem' }}>{__('Access made')}</span>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', height: '0.313rem', backgroundColor: '#C2DBFF', width: '12%', borderRadius: '12px' }} />
          <div style={{ display: 'flex', height: '0.313rem', backgroundColor: '#00A656', width: '87%', borderRadius: '12px', marginLeft: '1%' }} />
        </div>
      </div>
      <div style={{ height: '1.875rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', padding: '1rem', marginTop: '1.8rem' }}>
        <span style={{ color: '#999999' }}>{__('You will be redirected')}</span>
        <span style={{ color: '#084FFF', fontWeight: 700, cursor: 'pointer' }} onClick={() => { reopenURL(portalLoggedURL); }}>{__('Click here to access')}</span>
      </div>
    </div>
  );

  __renderErrorFooter = (error, link, currentUserId) => (
    <div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.714rem' }}>
          <div style={{ width: '12%', marginLeft: '1%', display: 'flex', alignItems: 'center' }}>
            <i style={{ fontSize: '0.857rem', color: '#C2DBFF' }} className={'icon arrow right to bracket'} />
          </div>
          <div style={{ width: '87%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <i style={{ fontSize: '1rem', color: '#ED335F', marginBottom: '4px' }} className={'icon circle exclamation'} />
            <span style={{ color: '#ED335F', fontWeight: 700, fontSize: '1rem' }}>{__('Error found')}</span>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', height: '0.313rem', backgroundColor: '#C2DBFF', width: '12%', borderRadius: '12px' }} />
          <div style={{ display: 'flex', height: '0.313rem', backgroundColor: '#ED335F', width: '87%', borderRadius: '12px', marginLeft: '1%' }} />
        </div>
      </div>
      <div style={{ height: '1.875rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', padding: '0.8rem', marginTop: '1.8rem' }}>
        {error && (
          error.message.includes('Network error') ? (
          <>
            <span style={{ color: '#999999' }}>{__('Missing internet')}</span>
            <span onClick={() => { this.__handleOnClick(link, currentUserId); }} style={{ color: '#084FFF', fontWeight: 700, cursor: 'pointer' }}>{__('Try again')}</span>
          </>
          ) : (
            <span style={{ color: '#999999' }}>{error.message}</span>
          )
        )}
      </div>
    </div>
  );

  __handleOnClick = async (link, userId) => {
    let abortController = null;
    const { getEntityIdFromURL, store } = this.props;
    const makeStorageKey = await __makeStorageKey(link.id, getEntityIdFromURL, userId);
    const getStoragePortal = JSON.parse(localStorage.getItem(makeStorageKey));
    const hasTokenExpired = (getStoragePortal && getStoragePortal.expiration && new Date(getStoragePortal.expiration) < Date.now());

    const getCurrentEntityType = store && store.currentEntity && store.currentEntity.type;
    const getOrganizationId = store && store.currentOrganization && store.currentOrganization.id;

    const expirationTimeInMilliseconds = (link && link.application && link.application.expiresIn) || MAX_TIME_OUT_IN_MILLISECONDS;

    sendGoogleAnalyticsEvent({
      name: 'External Link',
      category: `Open portal access link by ${getCurrentEntityType}`,
      label: `OrganizationID: ${getOrganizationId}`,
    }, { store });
    this.setState({ loading: true });

    if (getStoragePortal && !hasTokenExpired) {
      window.open(`${getStoragePortal.url}`, '_blank');
      this.setState({ success: true, portalLoggedURL: getStoragePortal.url, loading: false });
    } else {
      abortController = new AbortController();
      this.props.client.mutate({
        mutation: AUTHENTICATE_PORTAL_LOGGED,
        variables: {
          portalLoggedInput: {
            linkId: link && link.id,
            entityId: getEntityIdFromURL
          }
        },
        context: {
          fetchOptions: {
            signal: abortController.signal
          }
        }
      }).then(({ data }) => {
        const portalLoggedURL = data.portalLogged.url;
        const portalLoggedExpiration = data.portalLogged.expiration;
        localStorage.setItem(makeStorageKey, JSON.stringify({ url: portalLoggedURL, expiration: portalLoggedExpiration }));
        window.open(`${portalLoggedURL}`, '_blank');
        this.setState({ success: true, portalLoggedURL, loading: false });
      }).catch((e) => {
        const error = e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message;
        this.setState({ error: error ? JSON.parse(error) : e, loading: false });
      });
    }

    setTimeout(() => {
      if (this.state.loading && abortController) {
        abortController.abort();
        this.setState({ error: { type: 3, code: 300 }, loading: false });
      }

      if (this.state.success) {
        this.setState({ success: false, loading: false });
      }
    }, expirationTimeInMilliseconds);
  }

  render() {
    const { link, store, getEntityIdFromURL } = this.props;
    const { loading, success, error, portalLoggedURL } = this.state;
    const currentUserId = store && store.currentUser && store.currentUser.id;

    if (loading) {
      return (
        <CardContainer userId={currentUserId} entityId={getEntityIdFromURL} style={{ height: '18.036rem' }} link={link}>
          {this.__renderLoadingFooter(portalLoggedURL)}
        </CardContainer>
      );
    }

    if (success) {
      return (
        <CardContainer userId={currentUserId} entityId={getEntityIdFromURL} style={{ height: '18.036rem' }} link={link}>
          {this.__renderSuccessFooter(portalLoggedURL)}
        </CardContainer>
      );
    }

    if (error) {
      return (
        <CardContainer userId={currentUserId} entityId={getEntityIdFromURL} style={{ height: '18.036rem' }} link={link}>
          {this.__renderErrorFooter(error, link, currentUserId)}
        </CardContainer>
      );
    }

    return (
      <CardContainer userId={currentUserId} entityId={getEntityIdFromURL} style={{ height: '14.286rem' }} link={link}>
        <Button primary round icon={{ name: 'arrow right to bracket' }} text={__('Access')} onClick={() => { this.__handleOnClick(link, currentUserId); }} loading={false} disabled={false} />
      </CardContainer>
    );
  }
}
