import React from 'react';
import { Icon } from 'semantic-ui-react';

export default class Head extends React.Component {
  render() {
    const { pages, style, router } = this.props;
    return (<div style={{ display: 'flex', ...style }}>
      {pages.map((p, i) =>
      (<div style={{ display: 'flex' }}>
        <div onClick={() => { router.push(p.to); router.push(p.to); }} style={{ cursor: p.to ? 'pointer' : 'default' }}>
          {p.name}
        </div>
        {i !== pages.length - 1 &&
          <div>
            <Icon name={'angle right'} style={{ fontSize: '16px', marginLeft: '5px' }} />
          </div>
        }
      </div>))}
    </div>);
  }
}
