import styled from 'styled-components';
import { RadioButton } from '../../../RadioButton';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  &:active {
    opacity: 0.7;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ItemRadioButton = styled(RadioButton)``;

export const StatusText = styled.span`
  color: ${({ color, theme }) => color || theme.colors.blackboard.main};
  font-size: 16px;
  font-weight: 400;
`;

export const StatusTextContainer = styled.div``;
