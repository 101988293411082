import React from 'react';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';  
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Icon, Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import Controller from './Controller';
import BetaModal from './ui/Modal';
import BetaButton from './ui/Button';

import ArrivedForm from '../containers/Arrived/Form';

import i18n, { __ } from '../i18n';
import * as utils from '../utils';

const PENDING_ARRIVAL_ERROR_MESSAGE = 'GraphQL error: Arrived configuration can not be changed, exist arrivals in progress yet.';
const DISABLED_ARRIVAL_ERROR_MESSAGE = 'GraphQL error: Arrived is disabled in this organization';

export default class ArrivedSettingsItem extends Controller {
  constructor(props) {
    super(props);
    this.state = {
      hasFormValuesChanges: false,
      routeLeaveHookCleaner: null,
      hasPendingArrival: true
    };
  }

  request = ({ enabled, selectedTags, staffResponsible, concierges }) => {
    const { createArrivedConfiguration, updateArrivedConfiguration, store, data } = this.props;
    const { arrivedConfiguration } = data.node;
    const organizedConcierges = {
      createConcierges: [],
      updateConcierges: [],
      deleteConcierges: []
    };

    concierges.forEach((conciergeAfter) => {
      const { id, name, address, groups } = conciergeAfter;
      const formattedConcierge = {
        name,
        address,
        groupIds: groups.map(group => group.id),
        organizationId: store.currentOrganization.id
      };
      if (id) {
        const conciergeBefore = arrivedConfiguration.concierges.nodes.find(conc => conc.id === id);
        const diff = this.findConciergesDifference(conciergeBefore, conciergeAfter);
        if (Object.keys(diff).length > 0) {
          organizedConcierges.updateConcierges.push({ id, ...formattedConcierge });
        }
      } else {
        organizedConcierges.createConcierges.push({ ...formattedConcierge });
      }
    });

    if (arrivedConfiguration && arrivedConfiguration.concierges) {
      const remainingIds = concierges.filter(c => c.id).map(c => c.id);
      const originalIds = arrivedConfiguration.concierges.nodes.map(c => c.id);
      const missingIds = originalIds.filter(conciergeId => !remainingIds.includes(conciergeId));
      organizedConcierges.deleteConcierges = missingIds;
    }

    Object.keys(organizedConcierges).forEach((key) => {
      if (organizedConcierges[key].length === 0) delete organizedConcierges[key];
    });

    let mutate;
    const variables = {};

    if (arrivedConfiguration) {
      mutate = updateArrivedConfiguration;
      variables.input = {
        organizationId: store.currentOrganization.id,
        tagsIds: selectedTags,
        usersIds: [...new Set(staffResponsible.map(({ user }) => user.id))],
        enabled,
        ...organizedConcierges
      };
    } else {
      mutate = createArrivedConfiguration;
      variables.input = {
        organizationId: store.currentOrganization.id,
        tagsIds: selectedTags,
        usersIds: [...new Set(staffResponsible.map(({ user }) => user.id))],
        enabled,
        ...organizedConcierges
      };
    }

    return mutate({
      variables
    }).then(() => {
      utils.clearSnackbar(this.props.store);
      store.snackbar = { active: true, message: __('“Arrived” successfully configured'), success: true, dismissAfter: 3000 };
    }).catch((err) => {
      console.error(err);

      if (err.message === PENDING_ARRIVAL_ERROR_MESSAGE) {
        const { client, params } = this.props;

        client.writeQuery({
          query: gql` query OrganizationArrivedQuery($id: Int!) {
            node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
              ... on Organization {
                id: dbId
                arrivedConfiguration {
                  id: dbId
                  canEditArrived
                }
              }
            }
          }`,
          data: {
            node: {
              __typename: 'Organization',
              id: params.organization_id,
              arrivedConfiguration: {
                __typename: 'ArrivedConfiguration',
                id: null,
                canEditArrived: false
              }
            },
          },
          variables: {
            id: params.organization_id
          }
        });

        this.renderErrorsModal({
          header: __('Could not disable the feature'),
          content: __('The "Arrived!" feature is in use. Finish all pending request before disabling it')
        });
      } else if (err.message === DISABLED_ARRIVAL_ERROR_MESSAGE) {
        const { client, params, router } = this.props;

        this.state.routeLeaveHookCleaner();
        router.push(`/organizations/${params.organization_id}`);
        
        // TODO: Remove this query, is reached in Entity/Node.js and Organization/Node.js can be accessed by store.currentOrganization
        const query = gql` query OrganizationNodeIdQuery($id: Int!) {
          node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
            ... on Organization {
              id: dbId
              productFeatures {
                totalCount
                nodes {
                  id
                  identifier
                  plan
                  paid
                  live
                  isEnabled(organizationId: $id)
                }
              }
            }
          }
        }`;

        const { productFeatures, totalCount } = store.currentOrganization;

        const newData = {
          node: {
            __typename: 'Organization',
            id: params.organization_id,
            productFeatures: {
              __typename: 'OrganizationProductFeatureConnection',
              totalCount: totalCount - 1,
              nodes: productFeatures.nodes.filter(pf => pf.identifier !== 'arrived')
            }
          },
        };

        client.writeQuery({ query, data: newData, variables: { id: params.organization_id } });
      }

      utils.clearSnackbar(this.props.store);
      store.snackbar = { active: true, message: __('Error occurred while saving your settings'), success: false, dismissAfter: 3000 };
    });
  }

  findConciergesDifference = (conciergeBefore, conciergeAfter) => Object.keys(conciergeBefore).reduce((diff, key) => {
    if (['id', 'groupIds', '__typename'].includes(key)) return diff;
    if (['name', 'address'].includes(key)) {
      if (conciergeBefore[key] !== conciergeAfter[key]) {
        return { ...diff, [key]: conciergeAfter[key] };
      }
      return diff;
    }
    if (!isEqual(conciergeBefore.groups.nodes, conciergeAfter.groups)) {
      return { ...diff, groups: conciergeAfter.groups };
    }
    return diff;
  }, {});

  handleChange = (hasChanges) => {
    this.setState((prevState) => {
      if (hasChanges && !prevState.routeLeaveHookCleaner) {
        const routeLeaveHookCleaner = this.props.router.setRouteLeaveHook(this.props.route, () =>
          __('Your settings are not saved! If you leave this screen, you will lose the changes you made to the settings. Are you sure you want to exit?')
        );
        return { hasFormValuesChanges: hasChanges, routeLeaveHookCleaner };
      } else if (!hasChanges && prevState.routeLeaveHookCleaner) {
        prevState.routeLeaveHookCleaner();
        return { hasFormValuesChanges: hasChanges, routeLeaveHookCleaner: null };
      }
      return {};
    });
  }

  componentDidUpdate() {
    const { data } = this.props;
    const arrivedConfiguration = data && data.node && data.node.arrivedConfiguration;
    const users = arrivedConfiguration && arrivedConfiguration.entity && arrivedConfiguration.entity.users;
    const totalCount = users && users.totalCount;
    const nodes = users && users.nodes;
    const hasLoadedAllUsers = totalCount === (nodes && nodes.length);

    if (!hasLoadedAllUsers && totalCount > 0) {
      data.fetchMore({
        variables: {
          offset: nodes.length,
          limit: totalCount - nodes.length,
          organizationId: this.props.params.organization_id,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.node.arrivedConfiguration.entity.users.nodes.length === 0) return previousResult;
          const { arrivedConfiguration } = fetchMoreResult.node;
          const { nodes } = arrivedConfiguration.entity.users;

          const newUsers = [...previousResult.node.arrivedConfiguration.entity.users.nodes, ...nodes];
          const newResult = {...previousResult};
          newResult.node.arrivedConfiguration.entity.users.nodes = newUsers;

          return newResult;
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (!data || data.loading) return;

    const { hasPendingArrival } = this.state;
    const { arrivedConfiguration } = data.node;

    if (!arrivedConfiguration || (arrivedConfiguration.canEditArrived && this.state.hasPendingArrival)) {
      this.setState({ hasPendingArrival: false });
    } else if (!arrivedConfiguration.canEditArrived && !hasPendingArrival) {
      this.setState({ hasPendingArrival: true });
    }
  }

  beforeSubmit = (values) => {
    this.setState({ errors: null });
    if (!this.validate(values)) {
      return this.renderErrorsModal({
        header: __('Incomplete configuration'),
        content: __('To use "Arrived!" it is necessary to inform the tags that will have access to the resource, indicate the responsible employees and create at least one gate')
      });
    }

    if (this.shouldShowConfirmSubmitModal(values)) {
      this.props.store.appends.push(
        <BetaModal
          id="ArrivedConfirmSubmitModal"
          onClose={() => this.props.store.appends.pop()}
          actions={[
            <BetaButton
              data-action="just_submit"
              key={0}
              round
              transparent
              text={__('Just save')}
              onClick={() => {
                this.props.store.appends.pop();
                this.onSubmit(values);
              }}
            />,
            <BetaButton
              data-action="submit_and_activate"
              key={1}
              round
              text={__('Activate')}
              onClick={() => {
                this.props.store.appends.pop();
                this.onSubmit({ ...values, enabled: true });
              }}
            />
          ]}
          header={__('Do you want to activate "Arrived"?')}
          content={
            <div>
              <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{__('Do you want to save the changes you have made, or save and activate the feature?')}</span>
            </div>
          }
        />
      );
    } else {
      this.onSubmit(values);
    }
  }

  shouldShowConfirmSubmitModal = values => !values.enabled // if it's enabled the content and sendTo has already been verified, so, just save
    && !this.willOnlyDisable(values)

  willOnlyDisable = (values) => {
    const { arrivedConfiguration } = this.props.data.node;

    let selectedTags = (arrivedConfiguration && arrivedConfiguration.tags && [...arrivedConfiguration.tags.nodes]) || [];
    if (selectedTags.length > 0) {
      selectedTags = selectedTags.map(t => t.id);
    }

    let concierges = (arrivedConfiguration && arrivedConfiguration.concierges && [...arrivedConfiguration.concierges.nodes]) || [];
    if (concierges.length > 0) {
      concierges = concierges.map(concierge => ({ ...concierge, groups: concierge.groups.nodes }));
    }

    let staffResponsible = (arrivedConfiguration && arrivedConfiguration.entity && arrivedConfiguration.entity.users && arrivedConfiguration.entity.users.nodes) || [];
    if (staffResponsible.length > 0) {
      staffResponsible = staffResponsible.map((user) => {
        const formattedUser = {
          address: user.email || user.phone,
          user: {
            id: user.id,
            fullname: user.fullname,
            confirmEmail: user.confirmEmail,
            email: user.email,
            confirmPhone: user.confirmPhone,
            phone: user.phone
          },
        };
        return formattedUser;
      });
    }

    const initialValues = {
      selectedTags,
      staffResponsible,
      concierges
    };

    const willOnlyDisable = isEqual(initialValues, omit(values, 'enabled'));
    return willOnlyDisable;
  }

  validate = (values) => {
    const errors = {};
    if (!values.selectedTags || values.selectedTags.length === 0) {
      errors.selectedTags = true;
    }
    if (!values.staffResponsible || values.staffResponsible.length === 0) {
      errors.staffResponsible = true;
    }
    if (!values.concierges || values.concierges.length === 0) {
      errors.concierges = true;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }
    return true;
  }

  tagIsStudent = (tag) => {
    const regExp = /student|aluno|estudiante|学生/gi;
    return regExp.test(tag.name);
  }

  renderErrorsModal = ({ header, content }) => {
    this.props.store.appends.push(
      <BetaModal
        id="ErrorModal"
        onClose={() => this.props.store.appends.pop()}
        actions={[
          <BetaButton
            data-action="ok"
            round
            text={__('Ok')}
            style={{ width: '94px', height: '40px' }}
            onClick={() => this.props.store.appends.pop()}
          />
        ]}
        header={header}
        content={
          <div>
            <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{content}</span>
          </div>
        }
      />
    );
  }

  render() {
    const { data, store } = this.props;
    const { hasPendingArrival } = this.state;

    if (!data || data.loading) return <Loader active />;

    const { arrivedConfiguration, confArrived } = data.node;

    let selectedTags = (arrivedConfiguration && arrivedConfiguration.tags && [...arrivedConfiguration.tags.nodes]) || [];
    if (selectedTags.length > 0) {
      selectedTags = selectedTags.map(t => t.id);
    }

    let concierges = (arrivedConfiguration && arrivedConfiguration.concierges && [...arrivedConfiguration.concierges.nodes]) || [];
    if (concierges.length > 0) {
      concierges = concierges.map(concierge => ({ ...concierge, groups: concierge.groups.nodes }));
    }

    let staffResponsible = (arrivedConfiguration && arrivedConfiguration.entity && arrivedConfiguration.entity.users && arrivedConfiguration.entity.users.nodes) || [];
    if (staffResponsible.length > 0) {
      staffResponsible = staffResponsible.map((user) => {
        const formattedUser = {
          address: user.email || user.phone,
          user: {
            id: user.id,
            fullname: user.fullname,
            confirmEmail: user.confirmEmail,
            email: user.email,
            confirmPhone: user.confirmPhone,
            phone: user.phone
          },
        };
        return formattedUser;
      });
    }

    const initialValues = {
      enabled: confArrived || false,
      selectedTags,
      staffResponsible,
      concierges
    };

    const tags = [...store.currentOrganization.tags.nodes].sort((tagA, tagB) => Number(this.tagIsStudent(tagA)) - Number(this.tagIsStudent(tagB)));
    if (initialValues.selectedTags.length === 0) tags.forEach((tag) => { if (!this.tagIsStudent(tag)) initialValues.selectedTags.push(tag.id); });

    const { errors, hasFormValuesChanges, loading } = this.state;

    return (
      <div>
        {hasPendingArrival &&
          <div className="warning-box">
            <div>
              <Icon data-value="error" name="exclamation circle" />
              <span>{__('You cannot change your settings while there are open requests. Complete them through the app and reload the page')}</span>
            </div>
            <button onClick={() => document.location.reload(true)}>{__('Reload')}</button>
          </div>}
        <Controller
          id="ArrivedEdit"
          edit
          title={__('Edit account')}
          form={ArrivedForm}
          values={initialValues}
          loading={loading}
          errors={errors}
          onSubmit={this.beforeSubmit}
          submitButton={{ className: 'round', content: __('Save') }}
          onFormValuesChange={this.handleChange}
          hasFormValuesChanges={hasFormValuesChanges}
          formProps={{
            tags,
            hasPendingArrival,
            hiddenEntities: (arrivedConfiguration && arrivedConfiguration.entity && [arrivedConfiguration.entity.id]) || []
          }}
          {...this.props}
        />
      </div>
    );
  }
}