import styled from 'styled-components';
import Button from '../../../../components/Button';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ChannelInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ButtonContainer = styled.div``;

export const CancelButton = styled(Button)``;

export const SubmitButtonContainer = styled.div`
  display: inline;
  margin-left: 1rem;
`;

export const SubmitButton = styled(Button)``;
