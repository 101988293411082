import React from 'react';
import SimplePictureInput from '../../../../../../../../components/ui/PictureInput';
import { __ } from '../../../../../../../../i18n';
// import { asset, b64toBlob, hasEntityInCurrentOrganization } from '../../../../../../utils';

/**
 * @typedef {{
*  value?: { id: number; uri: string; } | Blob;
*  fullname?: string;
*  onSubmit?: (picture) => void;
*  onDelete?: () => void;
*  onSetDefault?: (name) => void;
*  canAddPicture?: boolean;
* }} Props
*
* @param {Props} props
* @returns {JSX.Element}
*/
export function PictureInput({
  value, fullname, onSubmit, onDelete, onSetDefault, canAddPicture
}) {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{canAddPicture ? __('Add a picture or choose one bolow:') : __('Appearance')}</span>
      <div style={{ marginTop: '1rem' }}>
        <SimplePictureInput
          // entityId={value.id}
          // hideEdit={!(hasEntityInCurrentOrganization(store, ['ADMIN']) || store.currentUser.isMaster || (store.currentEntity && store.currentEntity.id === values.id))}
          picture={(value && ((value.id && value.uri) || value instanceof Blob)) ? value : null}
          fullname={fullname}
          icons={[
            { name: 'graduation cap', backgroundColor: '#016EEA', onClick: () => onSetDefault('graduation-cap') },
            { name: 'calculator', backgroundColor: '#E75353', onClick: () => onSetDefault('calculator-icon') },
            { name: 'user tie', backgroundColor: '#108E82', onClick: () => onSetDefault('user-tie') }
          ]}
          onSubmit={picture => onSubmit(picture)}
          onDelete={() => onDelete(null)}
        />
      </div>
    </div>
  );
}
