import styled from 'styled-components';

export const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0.714rem 0;
`;

export const ErrorText = styled.span`
    color: #BF2600;
    margin-left: 0.5rem;
`;
