import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Loader } from 'semantic-ui-react';
import omit from 'lodash/omit';

import ElementModifier from './ElementModifier';

@inject('store') @observer
export default class ModalComponent extends React.Component {
  static Header = Modal.Header;
  static Content = Modal.Content;
  static Actions = Modal.Actions;

  onMount = () => {
    this.props.store.modals.push('');
    this.mounted = true;
  }

  onUnmount = () => {
    const modals = this.props.store.modals;
    const l = modals.length;

    if (l) {
      document.body.classList.add('dimmable', 'dimmed', 'scrolling');
      if (this.props.dimmer) document.body.classList.add(this.props.dimmer);
    }
    if (this.mounted) {
      this.props.store.modals.pop();
      this.mounted = false;
    }
  }

  // Prevent multiple unmount (weird semantic bug)
  mounted = false;

  render() {
    const { header, content, actions, trigger, loading, scrolling } = this.props;

    const newProps = omit(this.props,
      ['header', 'content', 'actions', 'store', 'loading', 'scrolling']);

    if (loading) {
      return (<Modal
        open
        onMount={this.onMount}
        onUnmount={this.onUnmount}
        {...newProps}
      >
        <Loader active />
        {this.props.children}
      </Modal>);
    }

    return (
      <Modal
        size="tiny"
        id={this.props.id}
        open={(!trigger) ? true : undefined}
        onMount={this.onMount}
        onUnmount={this.onUnmount}
        closeIcon={this.props.onClose && this.props.closeIcon && 'times close'}
        closeOnDimmerClick={false}
        {...newProps}
      >
        {!!header && <Modal.Header>{header}</Modal.Header> }
        {!!content && <Modal.Content className={scrolling ? 'scrolling' : ''}>{content}</Modal.Content>}
        { actions === true ? <Modal.Actions /> :
          !!actions && <Modal.Actions>{actions}</Modal.Actions>
        }
        {this.props.children}
        <ElementModifier element={document.body} newClassName="paddingOnModalDimmer" />
      </Modal>
    );
  }
}
