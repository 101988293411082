import React from 'react';
import { Input, Dropdown } from 'semantic-ui-react';
import omit from 'lodash/omit';
import PhoneNumber from 'awesome-phonenumber';
import { allCountries } from '../lib/countries';
import { __ } from '../i18n';

export default class PhoneInput extends React.Component {
  constructor(props) {
    super(props);

    let country = props.country || '+55';

    // autoDetectCountry flag makes component select country code based on browser's language
    if (props.autoDetectCountry) {
      const navigatorCountries = navigator.languages.filter(c => c.indexOf('-') === 2).map(c => c.substring(3, 5).toLowerCase());
      const supportedCountries = allCountries().map(c => c.code);

      let navigatorCountry = null;
      for (const c of navigatorCountries) {
        const countryIndex = supportedCountries.indexOf(c);
        if (countryIndex >= 0) {
          navigatorCountry = allCountries()[countryIndex].value;
          break;
        }
      }

      country = navigatorCountry || country;
    }

    let phone = props.phone || '';


    if (props.defaultValue) {
      const pn = new PhoneNumber('+' + props.defaultValue, 'ZZ');
      country = '+' + PhoneNumber.getCountryCodeForRegionCode(pn.getRegionCode());
      phone = pn.getNumber('significant');
    }

    this.state = {
      country,
      phone,
      valid: true
    };
  }

  onPhoneBlur = pn => (e) => {
    const { onPhoneBlur, onBlur, optional } = this.props;

    this.setState({ valid: pn.isMobile() || (!!optional && e.target.value === ''), phone: e.target.value }, () => {
      if (onPhoneBlur) onPhoneBlur(this.state.phone);
      if (onBlur) onBlur(this.state.country + this.state.phone);
    });
  }

  onPhoneChange = (phone) => {
    const { onPhoneChange, onChange } = this.props;

    this.setState({ phone: phone.replace(/[^0-9 ()+-]+$/, '') }, () => {
      if (onPhoneChange) onPhoneChange(this.state.phone);
      if (onChange) onChange(new PhoneNumber(this.state.country + this.state.phone, 'ZZ').getNumber('international'));
    });
  }

  onCountryChange = (country) => {
    const { onCountryChange, onChange, dontCountryChange } = this.props;

    this.setState({ country }, () => {
      if (onCountryChange) onCountryChange(this.state.country);
      if (onChange && !dontCountryChange) onChange(this.state.country + this.state.phone);
    });
  }

  countries = allCountries().map((c, key) => ({
    key,
    text: `${this.props.flag ? c.flag : ''} ${c.text}`,
    value: `${c.value}`,
    'data-code': `${c.value}`,
    self: c,
  }))

  render() {
    const newProps = omit(this.props,
      ['defaultValue', 'country', 'phone', 'onCountryChange', 'onPhoneChange', 'onChange', 'onPhoneBlur', 'onBlur', 'hasError']);

    const pn = new PhoneNumber(this.state.country + this.state.phone, 'ZZ');

    const selectedCountry = this.countries.find(country => country.value === this.state.country);

    return (
      <Input
        label={
          <Dropdown
            scrolling
            value={this.state.country}
            options={this.countries}
            trigger={this.props.flag ? <span>{`${selectedCountry.self.flag} ${selectedCountry.value}`}</span> : undefined}
            onChange={(e, { value }) => this.onCountryChange(value)
            }
            style={{ border: this.props.hasError ? '1px solid' : '1px solid rgba(34,36,38,.15)', backgroundColor: '#FFF', textAlign: 'center' }}
          />
        }
        value={this.state.phone}
        placeholder={this.props.placeholder || __('Phone')}
        onChange={(e, { value }) => this.onPhoneChange(value)}
        onBlur={this.onPhoneBlur(pn)}
        error={!this.state.valid}
        {...newProps}
      />
    );
  }
}
