
import React from 'react';
import moment from 'moment';


function humanDate(stringDate, locale) {
  moment.locale(locale);
  return moment(stringDate).calendar();
}


export default class FriendlyDate extends React.Component {
  render() {
    const { date, locale, style } = this.props;

    const dict = {
      pt: 'pt-BR',
      en: 'en',
      es: 'es'
    };

    return (
      <div style={style}>
        {humanDate(date, dict[locale || 'en'])}
      </div>
    );
  }
}
