import React from 'react';
import { Menu, Icon, Button, Loader, Dropdown } from 'semantic-ui-react';
import { uniqBy } from 'lodash';
import '../assets/css/ui/Paginator.module.scss';

import { __ } from '../i18n';

const options = [
  { text: '40', value: '40' },
  { text: '80', value: '80' },
  { text: '120', value: '120' },
  { text: '200', value: '200' }
];

const Page = ({ index, currentPage, limit, loading, onClick }) => (
  <Menu.Item
    data-action="load-more"
    data-params={index}
    key={index}
    style={currentPage === index ? { color: '#084FFF', backGroundColor: '#F2F7FF' } : {}}
    active={currentPage === index}
    onClick={() => onClick('p', index, limit)}
  >
    {
      index === currentPage && loading ? <Loader active /> : index
    }
  </Menu.Item>
);

export default class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getOptions = () => {
    const { totalCount } = this.props.pagination;

    if (totalCount != null) {
      return uniqBy(options, 'value');
    }
  }

  /* getOptions = () => {
    const { totalCount, query, limit } = this.props.pagination;
    
    if (totalCount != null) {
      const value = totalCount.toString();
      const newOptions = [{ text: __('All'), value }];

      if (query && query.limit && query.limit !== value) {
        newOptions.push({ text: query.limit, value: query.limit });
      }

      if (limit) newOptions.push({ text: limit.toString(), value: limit.toString() });

      return uniqBy([...newOptions, ...options], 'value');
    }

    return [];
  } */

  loadMore = (name, info, limit) => {
    const { pagination, onLoadMore } = this.props;

    if (name === 'loadMore') {
      onLoadMore(info);
    } else if (name === 'p') {
      const query = {
        ...pagination.query,
        limit,
        p: info
      };

      onLoadMore(query);
    } else if (name === 'limit') {
      const query = {
        ...pagination.query,
        limit: info,
        p: 1
      };

      onLoadMore(query);
    }
  }

  handleChange = (e, { value }) => {
    this.loadMore('limit', value);
  }

  renderButton = () => {
    const { pagination } = this.props;
    if (pagination.type === 'new') {
      return (<div
        className="defaultColor bold centeredItems"
        style={{ padding: '8px 16px', height: '44px', fontSize: '12px' }}
        loading={this.state.loadingNext}
        disabled={this.state.loadingNext}
        onClick={() => this.loadMore('loadMore', pagination)}
      >
        {__('See more').toUpperCase()}
      </div>);
    }

    return (<Button
      basic
      loading={this.state.loadingNext}
      disabled={this.state.loadingNext}
      onClick={() => this.loadMore('loadMore', pagination)}
    >
      {__('Load more')}
    </Button>);
  }

  render() {
    const { pagination, isMobile } = this.props;

    if (pagination === null || (!pagination.hasNextPage && !pagination.query)) {
      return null;
    } else if (!pagination.query && pagination.loading) {
      return (
        <Menu.Item style={{ textAlign: 'center' }}>
          <Loader active inline="centered" />
        </Menu.Item>
      );
    } else if (!pagination.query) {
      return (
        <Menu.Item style={{ textAlign: 'center' }}>
          {(this.state.loadingNext) ?
            <Loader active inline="centered" /> :
            this.renderButton()}
        </Menu.Item>
      );
    }

    const { query, totalCount, loading, basic } = pagination;
    const limit = (query.limit && parseInt(query.limit, 10)) || pagination.limit || 40;
    const totalPages = Math.ceil(totalCount / limit);
    const currentPage = (query.p && parseInt(query.p, 10)) || 1;
    const prev = currentPage > 0 ? currentPage - 1 : null;
    const next = currentPage < totalPages ? currentPage + 1 : null;
    const pages = [];

    pages.push(
      <Menu.Item data-action="load-more" data-params={prev} disabled={!prev} key={'prev'} onClick={() => this.loadMore('p', prev, limit)} icon>
        <Icon name="left chevron" />
      </Menu.Item>
    );


    if (isMobile && isMobile()) {
      pages.push(
        <Page
          index={currentPage !== totalPages ? currentPage : 1}
          limit={limit}
          currentPage={currentPage}
          loading={loading}
          onClick={this.loadMore}
        />
      );

      if (totalPages > 2) pages.push(<Menu.Item disabled key={'sep2'}>...</Menu.Item>);

      if (totalPages > 1)
        pages.push(
          <Page
            index={totalPages}
            limit={limit}
            currentPage={currentPage}
            loading={loading}
            onClick={this.loadMore}
          />
        );
    } else if (totalPages < 10) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <Page
            index={i}
            limit={limit}
            currentPage={currentPage}
            loading={loading}
            onClick={this.loadMore}
          />
        );
      }
    } else {
      let current = 0;
      for (let i = 1; i <= 2 && i < currentPage; i++) {
        current = i;
        pages.push(
          <Menu.Item
            data-action="load-more"
            data-params={i}
            key={i}
            onClick={() => this.loadMore('p', i, limit)}
          >
            {i}
          </Menu.Item>
        );
      }


      if (current < currentPage - 3) pages.push(<Menu.Item disabled key={'sep1'}>...</Menu.Item>);

      for (let i = Math.max(currentPage - 2, current + 1); i <= currentPage + 2; i++) {
        if (i <= totalPages && (i <= totalPages - 2 || i <= currentPage)) {
          current = i;
          pages.push(
            <Page
              index={i}
              limit={limit}
              currentPage={currentPage}
              loading={loading}
              onClick={this.loadMore}
            />
          );
        }
      }

      if (current < totalPages - 2) pages.push(<Menu.Item disabled key={'sep2'}>...</Menu.Item>);

      for (let i = Math.max(current + 1, totalPages - 1); i <= totalPages; i++) {
        pages.push(
          <Menu.Item
            data-action="load-more"
            data-params={i}
            key={i}
            onClick={() => this.loadMore('p', i, limit)}
          >
            {i}
          </Menu.Item>
        );
      }
    }

    pages.push(
      <Menu.Item
        data-action="load-more"
        data-params={next}
        key={'next'}
        disabled={!next}
        onClick={() => this.loadMore('p', next, limit)}
        icon
      >
        <Icon name="right chevron" />
      </Menu.Item>
    );

    if (basic && pages.length < 2) return null;

    return (
      <div style={{ display: 'flex', marginTop: 50, justifyContent: 'space-between' }}>
        <div style={{ width: 62 }} />
        <div>
          {
            pages.length > 0 &&
            <Menu secondary className="beta-paginator" pagination>{pages}</Menu>
          }
        </div>
        <div style={{}}>
          <Menu className="beta-paginator" secondary pagination floated="right">
            <Dropdown
              data-action="select-limit"
              compact
              upward
              selectOnBlur={false}
              selection
              placeholder={__('Choose number of items per page') + '...'}
              value={limit.toString()}
              options={this.getOptions()}
              onChange={this.handleChange}
            />
          </Menu>
        </div>
      </div>


    );
  }
}
