import React, { useRef } from 'react';
import {
  Container, ItemRadioButton, StatusText, StatusTextContainer
} from './RadioListItem.styles';

export function RadioListItem(props) {
  const {
    onSelect,
    option,
    isSelected,
    ...rest
  } = props;
  const checkboxRef = useRef(null);

  function handleSelect(value, id) {
    return onSelect(value, id);
  }

  return (
    <Container onClick={() => handleSelect(option.value, option.id)} {...rest}>
      <ItemRadioButton
        data-testid="radiolist-item-radiobutton"
        ref={checkboxRef}
        selected={isSelected}
        {...option.radioProps}
      />
      <StatusTextContainer
        data-testid="radiolist-item-label"
        {...option.label.containerStyle}
        style={{
          backgroundColor: option?.label?.backgroundColor || 'transparent',
        }}
      >
        <StatusText
          {...option.label.textStyle}
          color={option.label.textColor}
        >
          {option.label.text}
        </StatusText>
      </StatusTextContainer>
    </Container>
  );
}
