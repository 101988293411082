import React from 'react';
import moment from 'moment';
import { Table, Menu } from 'semantic-ui-react';
import { __ } from '../i18n';

import '../assets/css/ui/Calendar.module.scss';

const date = new Date();

export default class Calendar extends React.Component {
  dayNames = [__('Su'), __('Mo'), __('Tu'), __('We'), __('Th'), __('Fr'), __('Sa')];

  monthNames = [__('January'), __('February'), __('March'), __('April'), __('May'), __('June'), __('July'), __('August'), __('September'), __('October'), __('November'), __('December')];

  constructor(props) {
    super(props);

    const year = (props.selectedDt && props.selectedDt.getFullYear())
      || (props.selectedStartDt && props.selectedStartDt.getFullYear())
      || date.getFullYear();
    const month = (props.selectedDt && typeof props.selectedDt.getMonth() === 'number')
      ? props.selectedDt.getMonth()
      : ((props.selectedStartDt && props.selectedStartDt.getMonth()) || date.getMonth());

    this.state = {
      year,
      month,
      selectedDt: props.selectedDt,
      selectedStartDt: props.selectedStartDt,
      selectedEndDt: props.selectedEndDt,
      firstOfMonth: null,
      daysInMonth: null
    };
  }

  UNSAFE_componentWillMount() {
    const { month, year } = this.state;
    this.setState(this.calc(year, month));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      has_date,
      range,
      selectedEndDt
    } = this.props;
    const { selectedStartDt, selectedDt } = this.state;

    if (has_date && !nextProps.has_date) {
      this.unselectDate();
    }

    if (range) {
      const hasStartDtChanges = this.hasDateChanges({ nextProps, propertyName: 'selectedStartDt' });

      let hasEndDtChanges;
      if (!hasStartDtChanges && selectedEndDt) {
        hasEndDtChanges = (!selectedEndDt == Boolean(nextProps.selectedEndDt)) || this.hasDateChanges({ nextProps, propertyName: 'selectedEndDt' });
      }

      if (hasStartDtChanges || hasEndDtChanges) {
        this.setState(
          { selectedStartDt: nextProps.selectedStartDt, selectedEndDt: nextProps.selectedEndDt },
          () => {
            const year = selectedStartDt.getFullYear();
            const month = selectedStartDt.getMonth();

            this.setState({ year, month, ...this.calc(year, month) });
          }
        );
      }
    } else {
      const hasChanges = this.hasDateChanges({ nextProps, propertyName: 'selectedDt' });

      if (hasChanges) {
        this.setState(
          { selectedDt: nextProps.selectedDt },
          () => {
            const year = selectedDt.getFullYear();
            const month = selectedDt.getMonth();

            this.setState({ year, month, ...this.calc(year, month) });
          }
        );
      }
    }
  }

  getPrev = () => {
    const { month, year } = this.state;
    const newState = {};
    if (month > 0) {
      newState.month = month - 1;
      newState.year = year;
    } else {
      newState.month = 11;
      newState.year = year - 1;
    }
    Object.assign(newState, this.calc(newState.year, newState.month));
    this.setState(newState);
  }

  setNext = () => {
    const state = this.getNext();

    Object.assign(state, this.calc(state.year, state.month));
    this.setState(state);
  }

  getNext = () => {
    const { month, year } = this.state;
    const newState = {};
    if (month < 11) {
      newState.month = month + 1;
      newState.year = year;
    } else {
      newState.month = 0;
      newState.year = year + 1;
    }

    return newState;
  }

  hasDateChanges = ({ nextProps, propertyName }) => nextProps[propertyName].getFullYear() !== { ...this.props }[propertyName].getFullYear()
    || nextProps[propertyName].getMonth() !== { ...this.props }[propertyName].getMonth()
    || nextProps[propertyName].getDate() !== { ...this.props }[propertyName].getDate()

  unselectDate = () => {
    const { unselectDate } = this.props;
    this.setState({
      selectedDt: undefined
    });
    unselectDate();
  }

  selectDate = (year, month, day) => {
    const {
      onSelect,
      range
    } = this.props;
    const { month: stateMonth, year: stateYear } = this.state;
    let newState;
    if (range) {
      const { selectedStartDt, selectedEndDt } = this.state;
      if (selectedEndDt) { // when a range is selected, a new selection triggers the start date
        newState = {
          selectedStartDt: new Date(year, month, day, 12, 0),
          selectedEndDt: null
        };
      } else {
        const current = new Date(year, month, day);
        const isBeforeStartDt = moment(current).isBefore(selectedStartDt);
        if (isBeforeStartDt) {
          newState = {
            selectedStartDt: new Date(year, month, day, 12, 0),
            selectedEndDt: null
          };
        } else {
          const isBeforeTwoMonths = moment(current).isSameOrBefore(moment(selectedStartDt).add(2, 'months'));
          if (isBeforeTwoMonths) {
            newState = {
              selectedEndDt: new Date(year, month, day, 12, 0)
            };
          } else {
            newState = {
              selectedStartDt: new Date(year, month, day, 12, 0),
              selectedEndDt: null
            };
          }
        }
      }
    } else {
      newState = { selectedDt: new Date(year, month, day, 12, 0) };
    }
    this.setState(newState, () => {
      this.setState({ year, month, ...this.calc(stateYear, stateMonth) });
      onSelect(this.state);
    });
  }

  calc(year, month) {
    return {
      firstOfMonth: new Date(year, month, 1),
      daysInMonth: new Date(year, month + 1, 0).getDate()
    };
  }

  render() {
    const {
      calendarStyles,
      double,
      headerAlwaysOn,
      maxDate,
      minDate,
      range,
      selectedColor,
      withBorder,
      withPadding
    } = this.props;
    const {
      daysInMonth,
      firstOfMonth,
      month,
      selectedDt,
      selectedEndDt,
      selectedStartDt,
      year
    } = this.state;

    return (
      <Table className={`${withBorder ? 'with-border' : ''}${withPadding ? ' with-padding' : ''}`} unstackable celled size="small" id="calendarView">
        <Table.Header>
          <Header
            monthNames={this.monthNames}
            month={month}
            year={year}
            onPrev={this.getPrev}
            onNext={this.setNext}
            double={double}
            getNext={this.getNext}
            calendarStyles={calendarStyles}
            headerAlwaysOn={headerAlwaysOn}
          />
          <WeekDays
            dayNames={this.dayNames}
            double={double}
          />
        </Table.Header>

        <MonthDates
          month={month}
          year={year}
          daysInMonth={daysInMonth}
          firstOfMonth={firstOfMonth}
          selectedDt={selectedDt}
          selectedStartDt={selectedStartDt}
          selectedEndDt={selectedEndDt}
          onSelect={this.selectDate}
          selectedColor={selectedColor}
          range={range}
          maxDate={maxDate}
          minDate={minDate}
          double={double}
          getNext={this.getNext}
          calc={this.calc}
        />
      </Table>
    );
  }
}

function Header(props) {
  const {
    double,
    getNext,
    headerAlwaysOn,
    month,
    monthNames,
    onNext,
    onPrev,
    year,
  } = props;

  let colSpan = '7';
  let widths = 3;
  let nextMonth = null;

  if (double) {
    const nextMonthData = getNext();

    colSpan = '14';
    widths = 4;
    nextMonth = <Menu.Item className="title">{`${monthNames[nextMonthData.month]} ${nextMonthData.year}`}</Menu.Item>;
  }

  return (
    <Table.Row>
      <Table.HeaderCell className="header" colSpan={colSpan}>
        <Menu widths={widths} style={headerAlwaysOn ? { display: 'flex' } : {}}>
          <Menu.Item className="previous-month" data-action="previous-month" data-testid="action-previousMonth-calendar" onClick={() => onPrev()} icon="arrow left" />
          <Menu.Item className="title" style={{ minWidth: '120px' }}>{`${monthNames[month]} ${year}`}</Menu.Item>
          {nextMonth}
          <Menu.Item className="next-month" data-action="next-month" data-testid="action-nextMonth-calendar" onClick={() => onNext()} icon="arrow right" />
        </Menu>
      </Table.HeaderCell>
    </Table.Row>
  );
}

function WeekDays({ double, dayNames }) {
  const haystack = [...Array(7).keys()];
  return (
    <Table.Row className="weekdays">
      {
        haystack.map((item, i) => <Table.HeaderCell key={i}>{dayNames[i % 7]}</Table.HeaderCell>)
      }
      {
        double ? haystack.map((item, i) => <Table.HeaderCell key={'2_' + i}>{dayNames[i % 7]}</Table.HeaderCell>) : null
      }
    </Table.Row>
  );
}

function MonthDates(props) {
  const {
    calc,
    daysInMonth,
    double,
    firstOfMonth,
    getNext,
    maxDate,
    minDate,
    month,
    onSelect,
    range,
    selectedColor,
    selectedDt,
    selectedEndDt,
    selectedStartDt,
    year
  } = props;

  let day;
  let d;
  let current;
  let isDate;
  let nextMonth;
  const weekStack = [...Array(7).keys()];
  const startDay = firstOfMonth.getUTCDay();
  const first = firstOfMonth.getDay();
  let rows = 5;

  if ((startDay === 5 && daysInMonth === 31) || (startDay === 6 && daysInMonth > 29)) {
    rows = 6;
  }

  if (double) {
    const nextMonthData = getNext();
    nextMonth = { ...calc(nextMonthData.year, nextMonthData.month), ...nextMonthData };

    nextMonth.startDay = nextMonth.firstOfMonth.getUTCDay();
    nextMonth.first = nextMonth.firstOfMonth.getDay();

    nextMonth.day = 1 - nextMonth.first;
    while (nextMonth.day > 1) {
      nextMonth.day -= 7;
    }
    nextMonth.day -= 1;

    if (rows !== 6 && ((nextMonth.startDay === 5 && nextMonth.daysInMonth === 31) || (nextMonth.startDay === 6 && nextMonth.daysInMonth > 29))) {
      rows = 6;
    }
  }

  const haystack = [...Array(rows).keys()];
  day = 1 - first;
  while (day > 1) {
    day -= 7;
  }
  day -= 1;

  const formatDate = (unformattedDate) => unformattedDate.toString().replace(/..:..:../, '00:00:00');

  return (
    <Table.Body>
      {
        haystack.map((item, i) => {
          d = day + (i * 7);

          if (nextMonth) {
            nextMonth.d = nextMonth.day + (i * 7);
          }

          return (
            <Table.Row key={i}>
              {
              weekStack.map((_item, j) => {
                d += 1;
                isDate = d > 0 && d <= daysInMonth;

                if (isDate) {
                  current = new Date(year, month, d);
                  const yesterday = new Date(date.getTime() - (24 * 60 * 60 * 1000));

                  const finalDay = d;
                  const after = moment(current).isAfter(minDate || yesterday);
                  const beforeMaxDate = maxDate ? moment(current).isSameOrBefore(maxDate) : true;

                  const isSelectedDt = (selectedDt && (formatDate(current) === formatDate(selectedDt)));
                  const isSelectedStartDt = (selectedStartDt && (formatDate(current) === formatDate(selectedStartDt)));
                  const isSelectedEndDt = (selectedEndDt && (formatDate(current) === formatDate(selectedEndDt)));
                  const selected = isSelectedDt || isSelectedStartDt || isSelectedEndDt;

                  const isToday = formatDate(current) === formatDate(new Date());

                  let style = { backgroundColor: '#ffffff', cursor: 'pointer' };
                  let className = 'available';
                  let dataStatus = 'clickable';
                  if (!(after && beforeMaxDate)) {
                    style = { color: 'rgba(0, 0, 0, 0.16)' };
                    className = 'unavailable';
                    dataStatus = 'disabled';
                  } else if (selected) {
                    style = {
                      backgroundColor: selectedColor || '#084FFF',
                      color: '#ffffff',
                    };
                    if (isToday) style.fontWeight = 700;
                    dataStatus = 'selected';
                  } else if (isToday) {
                    style = {
                      color: '#084FFF',
                      fontWeight: 700,
                      cursor: 'pointer'
                    };
                  }

                  let rangeStyle;
                  let dateStyle;
                  const isBetweenRange = moment(current).isAfter(selectedStartDt) && moment(current).isBefore(selectedEndDt);
                  if (range) {
                    if (selectedEndDt) {
                      if (isSelectedStartDt) {
                        dateStyle = { ...style, cursor: 'pointer' };
                        rangeStyle = {
                          backgroundColor: '#C2DBFF',
                          borderRadius: '100% 0 0 100%'
                        };
                      } else if (isSelectedEndDt) {
                        dateStyle = { ...style, cursor: 'pointer' };
                        rangeStyle = {
                          backgroundColor: '#C2DBFF',
                          borderRadius: '0 100% 100% 0'
                        };
                      } else if (isBetweenRange) {
                        dateStyle = { ...style, backgroundColor: null };
                        rangeStyle = {
                          backgroundColor: '#C2DBFF',
                          borderRadius: '0'
                        };
                      }
                    } else {
                      rangeStyle = {
                        borderRadius: '0',
                        backgroundColor: '#ffffff'
                      };
                      if (selected) dateStyle = style;
                      else dateStyle = { ...style, backgroundColor: null };
                    }
                  }

                  return (
                    <Table.Cell
                      data-action="select-day"
                      data-cy={`day-${dataStatus}-calendar`}
                      data-params={finalDay}
                      textAlign="center"
                      style={rangeStyle || style}
                      className={className}
                      key={'day_' + finalDay}
                      role="button"
                      onClick={after && beforeMaxDate ? (e) => onSelect(year, month, finalDay, e) : () => { }}
                      content={(
                        <span
                          style={dateStyle ? {
                            borderRadius: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            ...dateStyle
                          } : null}
                        >
                          {finalDay}
                        </span>
                      )}
                    />
                  );
                }
                return <Table.Cell key={'cBlank_' + j} id={'cBlank_' + i + '_' + j} />;
              })
            }
              {
              nextMonth
                ? weekStack.map((_item, j) => {
                  nextMonth.d += 1;
                  isDate = nextMonth.d > 0 && nextMonth.d <= nextMonth.daysInMonth;

                  if (isDate) {
                    current = new Date(nextMonth.year, nextMonth.month, nextMonth.d);
                    const yesterday = new Date(date.getTime() - (24 * 60 * 60 * 1000));

                    const finalDay = nextMonth.d;
                    const after = moment(current).isAfter(minDate || yesterday);
                    const beforeMaxDate = maxDate ? moment(current).isSameOrBefore(maxDate) : true;

                    const selected = selectedDt && (formatDate(current) === formatDate(selectedDt));

                    let style = { backgroundColor: '#ffffff', cursor: 'pointer' };
                    if (!(after && beforeMaxDate)) {
                      style = { backgroundColor: '#e4e4e4', color: '#a8a8a8' };
                    } else if (selected) {
                      style = { backgroundColor: selectedColor || '#084FFF' };
                    }

                    return (
                      <Table.Cell
                        data-action="select-day"
                        data-params={finalDay}
                        textAlign="center"
                        style={style}
                        key={'nextMonthday_' + finalDay}
                        role="button"
                        onClick={after && beforeMaxDate ? (e) => onSelect(nextMonth.year, nextMonth.month, finalDay, e) : () => { }}
                        content={finalDay}
                      />
                    );
                  }
                  return <Table.Cell key={'cBlankNextMonth_' + j} id={'cBlank_NextMonth' + i + '_' + j} />;
                })
                : null
            }
            </Table.Row>
          );
        })
      }
    </Table.Body>
  );
}
