import styled from 'styled-components';

import MultipleDropdown from '../../../../components/ui/MultipleDropdown';

export const Label = styled.label`
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

export const Dropdown = styled(MultipleDropdown)`
  padding: 12px 0 12px 6px !important;

  input {
    padding: 0.2rem 0 !important;
    margin: 0.2em 0em 0em 0.6em !important;
  }
`;
