/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import { Label, Header, Segment, Icon, Popup } from 'semantic-ui-react';
import gql from 'graphql-tag';

import Avatar from './Avatar';
import Responsive from './Responsive';
import { __ } from '../i18n';

@inject('store') @observer
export default class TopCover extends Responsive {
  static fragments = {
    organization: gql`
      fragment TopCover on Organization {
        id: dbId
        fullname
        color
        link
        logo {
          id: dbId
          uri
        }
        cover {
          id: dbId
          original: uri(size: "w1240")
        }
      }`,
  }

  constructor(props) {
    super(props);
    this.state = {
      expanded: !this.isMobile(),
      completelyExpanded: !this.isMobile()
    };
  }

  async componentDidMount() {
    const { store } = this.props;
    const { isCoverExpanded } = store;

    if (!isCoverExpanded) {
      this.setState({ expanded: isCoverExpanded });
    }
  }

  render() {
    let { organization, isAdmin, isPublic, store } = this.props;
    const { expanded, completelyExpanded } = this.state;

    let iconStyle = {
      display: 'flex',
      width: '32px',
      height: '32px',
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.24)',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    };

    let divStyle = {
      width: '100%',
      height: expanded ? 230 : 0,
      position: 'relative',
      transition: '200ms ease'
    };
    let divClassName = '';

    if (organization && organization.cover && organization.cover.original) {
      divStyle.background = 'url(' + organization.cover.original + ')';
      divClassName = 'coverImage';
    } else {
      divStyle.backgroundColor = '#' + ((organization && organization.color) || this.props.store.app.color);
    }

    const link = (organization.link && organization.link.search('://') === -1) ?
      'http://' + organization.link : organization.link;

    const logo = organization.logo && organization.logo.uri;

    return (
      <div>
        {
          isPublic &&
          <Segment inverted textAlign="center" style={{ margin: 0 }}>
            {__('%s is on ClassApp.', organization.fullname)}
          </Segment>
        }
        <div style={divStyle} className={divClassName}>
          {
            !expanded ?

              <Popup
                trigger={
                  <div
                    style={{ ...iconStyle, margin: 16, right: 0, top: 5, marginBottom: 16 }}
                    onClick={() => {
                      store.isCoverExpanded = !expanded;
                      this.setState({ expanded: true }, () => setTimeout(() => this.setState({ completelyExpanded: true }), 200));
                    }}
                  >
                    <Icon className="expand alt" style={{ margin: 0, position: 'absolute', top: '6.5px', color: '#FFFFFF', fontSize: 16, fontWeight: 'bold' }} />
                  </div>
                }
                basic
                position="left center"
                content={__('Expand cover')}
              />
              :
              <div
                style={{
                  background: logo && 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.3))',
                  height: '100%',
                  textAlign: 'center'
                }}
              >
                {
                  isAdmin ?
                    <Label
                      id="ChangeOrganizationCover"
                      style={{ margin: 20, opacity: '0.7', position: 'absolute', right: 0, top: 0 }}
                      color="black"
                      size="mini"
                      as={Link}
                      to={`/organizations/${organization.id}/edit/cover`}
                      icon="image"
                      content={__('Change image')}
                    />
                    :
                    (isPublic ? null : <div style={{ height: 10 }} />)
                }
                {
                  completelyExpanded &&
                  <div>
                    <div style={{ paddingTop: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div>
                        {logo ?
                          <Avatar
                            size="tiny"
                            src={logo}
                            alt={organization.fullname}
                            circular
                            centered
                            style={{ boxShadow: '0 2px 10px rgba(0,0,0,0.1)', height: '140px', width: '140px', display: 'block' }}
                          />
                          :
                          isAdmin ?
                            <div style={{ display: 'flex', borderRadius: '200px', backgroundColor: '#FFFFFF', width: 140, height: 140, boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)', alignItems: 'center', justifyContent: 'center' }}>
                              <div style={{ marginLeft: 10, marginRight: 10, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{organization.fullname}</div>
                            </div>
                            :
                            null

                        }
                      </div>
                      {isAdmin &&
                        <div style={{ height: '140px', width: '140px', position: 'absolute' }}>
                          <Icon
                            className="plus"
                            style={{ ...iconStyle, top: '108px', left: '108px', margin: 0, color: '#FFFFFF', fontSize: 16 }}
                            onClick={() => browserHistory.push(`/organizations/${organization.id}/edit/logo`)}
                          />
                        </div>
                      }
                    </div>

                    <div style={{ marginTop: '5px' }}>

                      {organization.fullname && logo &&
                        <h3
                          style={{ color: '#ffffff' }}
                        >
                          {organization.fullname}
                        </h3>}

                      {organization.link && logo &&
                        <a
                          href={link}
                          ref={link}
                          style={{ color: '#ffffff' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {organization.link}
                        </a>}
                    </div>

                  </div>
                }
                <Popup
                  trigger={
                    <div
                      style={{ ...iconStyle, margin: 16, right: 0, bottom: 0 }}
                      onClick={() => {
                        store.isCoverExpanded = !expanded;
                        this.setState({ expanded: false, completelyExpanded: false });
                      }}
                    >
                      <Icon className="compress alt" style={{ margin: 0, position: 'absolute', top: '6.5px', color: '#FFFFFF', fontSize: 16, fontWeight: 'bold' }} />
                    </div>
                  }
                  basic
                  position="left center"
                  content={__('Minimize cover')}
                />
              </div>
          }
        </div>
      </div>
    );
  }
}
