import React, { useMemo } from 'react';
import { Popup } from 'semantic-ui-react';
import { CircleInfoLight } from '@classapp-tech/edna-icons';
import Form from '../../../../../../../../components/ui/Form';
import * as utils from '../../../../../../../../utils';

import { __ } from '../../../../../../../../i18n';

import Input from '../../../../../../../../components/ui/Input';

import { OptionContainer, Option, Icon } from './ChannelType.styles';

const MOBILE_ITEM_STYLE = {
  width: 'fit-content',
};

const styles = {
  label: {
    fontSize: '1.143rem',
    fontWeight: 'bold',
    marginTop: '1rem'
  },
};

/**
* @typedef {{
  *  text?: string,
  *  style?: React.CSSProperties
  * }} TError
**/

/**
* @typedef {{
*  value?: string;
*  onChange?: (event: Event) => void;
*  error?: TError | string;
* }} Props
*
* @param {Props} props
* @returns {JSX.Element}
*/
export function ChannelType({ value, onChange }) {
  const isMobile = useMemo(() => window.innerWidth <= 768, [window.innerWidth]);

  const DEFAULT_TYPE_OPTIONS = [
    {
      value: 'WITH_STATUS',
      testId: 'with-status-option',
      content: __('The attendances created have status (pending, in progress and completed) for facilitate conversation management, and generate data such as average response time which can be accessed on the channel dashboard'),
      direction: 'bottom left',
      text: __('With attendance status'),
    },
    {
      value: 'WITHOUT_STATUS',
      testId: 'without-status-option',
      content: __('The attendances created have a simple conversation structure, without status and do not generate any type of data.'),
      direction: 'bottom right',
      text: isMobile ? __('No attendance status') : __('Simple conversation, no attendance status'),
    }
  ];

  const TYPE_OPTIONS = useMemo(() => {
    if (isMobile) {
      return DEFAULT_TYPE_OPTIONS.map((option) => ({
        ...option,
        style: MOBILE_ITEM_STYLE
      }));
    }

    return DEFAULT_TYPE_OPTIONS;
  }, [
    window.innerWidth
  ]);

  return (
    <div style={{ margin: '1.25rem 0' }}>
      <div style={{ marginBottom: '1.25rem' }}>
        <span style={{
          fontWeight: 'bold',
          fontSize: '1.286rem',
          color: '#000000'
        }}
        >
          {__('Channel Type')}
        </span>
      </div>
      <OptionContainer isMobile={isMobile}>
        {TYPE_OPTIONS.map(option => (
          <Option isSelected={value === option.value}>
            <Input
              data-testid={option.testId}
              key={`input-radio-${option.value}`}
              type="radio"
              id={`input-radio-${option.value}`}
              name={option.value}
              value={option.text}
              selected={value === option.value}
              onChange={e => onChange(e.target.name)}
            />
            <Popup
              trigger={<CircleInfoLight style={{ opacity: '.6', marginLeft: '5px' }} name="info circle" fill="grey" />}
              content={(
                <div>
                  <span>{option?.content}</span>
                </div>
              )}
              position={option?.position}
              hideOnScroll
              basic
            />
          </Option>
        ))}
      </OptionContainer>
      <p style={styles.label}>{__('All messages exchanged within Channels can be monitored by the organization`s admin.')}</p>
    </div>
  );
}
