import React from 'react';
import { Icon } from 'semantic-ui-react';
import omit from 'lodash/omit';

export default class ColoredCheckbox extends React.Component {
  render() {
    const {
      checked,
      label,
      color = '#0080ff',
      radio,
      disabled,
      disableWithoutBanIcon,
      indeterminate,
      radioChecked,
      lighter,
      bold = true,
      className,
      background,
      style,
      iconStyle
    } = this.props;

    const newProps = omit(this.props,
      ['checked', 'label', 'color', 'radio', 'disabled', 'indeterminate', 'radioChecked', 'style', 'className']);

    if (disabled) {
      newProps.onClick = null;
      newProps.style = {
        opacity: '.3',
        cursor: 'default',
        ...style,
        ...newProps.style
      };
    }

    if (checked) {
      return (
        <span
          className={`checkbox checked pointer ${className}`}
          style={{ backgroundColor: background ? '#F2F7FF' : '', ...style }}
          {...newProps}
        >
          <Icon name={radio ? (radioChecked ? 'check circle' : 'dot circle') : 'check square'} style={{ color, margin: label ? null : '0px', fontWeight: lighter ? 'lighter' : 'bold', cursor: disabled ? undefined : 'pointer', ...iconStyle }} size="large" />
          {
            label ?
              ((radio && bold) ? <strong>{label}</strong> : label)
              :
              null
          }
        </span>
      );
    } else if (indeterminate) {
      return (
        <span
          className={`checkbox pointer ${className}`}
          style={{ ...style }}
          {...newProps}
        >
          <Icon name={radio ? 'minus circle' : 'minus square'} style={{ color, margin: label ? null : '0px', cursor: disabled ? 'default' : 'pointer', ...iconStyle }} size="large" />
          {
            label ?
              ((radio && bold) ? <strong>{label}</strong> : label)
              :
              null
          }
        </span>
      );
    }

    return (
      <span
        className={`checkbox pointer ${className}`}
        style={{ ...style }}
        {...newProps}
      >
        <Icon name={radio ? 'circle' : disabled && !disableWithoutBanIcon ? 'ban' : 'square'} style={{ color: disabled ? '#CCCCCC' : '#c8c8c8', margin: label ? null : '0px', cursor: disabled ? 'default' : 'pointer', fontWeight: 300, ...iconStyle }} size="large" />
        {label}
      </span>
    );
  }
}
