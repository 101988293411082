import styled from 'styled-components';

export const Subtitle = styled.span`
    font-weight: bold;
    font-size: 1.286rem;
    color: #000000;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const DiscardText = styled.span`
    font-size: 1.143rem;
    font-family: Circular,"Helvetica Neue",Arial,Helvetica,sans-serif;
    font-style: normal;
    font-weight: 500;
`;

export const SaveText = styled.span`
    font-size: 1.143rem;
    font-family: Circular,"Helvetica Neue",Arial,Helvetica,sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-left: 0.357rem;
`;

export const Container = styled.div`
    margin: 2rem 0 0 2rem;
`;

export const SubtitleContainer = styled.div`
    margin-bottom: 1.25rem;
`;

export const FirstRow = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const Row = styled.div`
    margin-top: 1rem;
`;

export const AdvancedLabelContainer = styled.div`
    margin: 1.5rem 0;
`;

export const AdvancedLabelText = styled.span`
    font-weight: bold;
    font-size: 1.286rem;
    color: #000;
`;
