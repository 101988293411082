import React from 'react';
import { Image } from 'semantic-ui-react';

import * as utils from '../utils';

const colors = [
  '#2882FF',
  '#0BAE6D',
  '#00C7E6',
  '#FBB13C',
  '#ED3360',
  '#ED1C25',
  '#FF7226',
  '#6555C0',
  '#00875A',
  '#025EDB'
];

export default class Avatar extends React.Component {
  constructor(params) {
    super(params);
    this.state = { urlError: false };
  }

  createSVG = (firstLetter, lastLetter, backgroundColor) => (
    <svg
      version="1.1"
      id="layer"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      className="clsp-svg"
      style={this.props.svgStyle ? {
        width: '100%', height: '100%', backgroundColor, ...this.props.svgStyle
      } : { width: '100%', height: '100%', backgroundColor }}
      viewBox="0 0 400 400"
      enableBackground="new 0 0 400 400"
      xmlSpace="preserve"
    >
      <g>
        <rect x={0} y={0} width="100%" height="100%" fill={backgroundColor} />
        <text x="50%" y="53%" alignmentBaseline="middle" dominantBaseline="middle" fontFamily="Circular" fontSize={200} fontWeight={100} fill="rgba(255,255,255,0.8)" textAnchor="middle" pointerEvents="auto">{firstLetter + lastLetter}</text>
      </g>
    </svg>
  )

  render() {
    const { urlError } = this.state;
    const style = { backgroundColor: '#ffffff' };
    const { src } = this.props;
    let img = null;

    const name = this.props.alt;
    let firstL = 'a';
    let lastL = '';

    if (name) {
      const names = name.split(' ');
      const l = names.length;
      firstL = utils.normalize(names[0].charAt(0).toLowerCase());
      lastL = (l > 1) ? utils.normalize(names[l - 1].charAt(0).toLowerCase()) : '';
    }

    const colorString = (firstL + (lastL || '')).replace(/./g, (text) => text.charCodeAt().toString(10));
    const backgroundColor = colors[colorString.charAt(colorString.length - 1)];

    const firstLetterUpperCase = `${(firstL || '')}`.trim().toUpperCase();
    const lastLetterUpperCase = `${(lastL || '')}`.trim().toUpperCase();

    if (!src || urlError) {
      img = this.createSVG(firstLetterUpperCase, lastLetterUpperCase, backgroundColor);
    }

    return (
      <Image
        {...this.props}
        src={src}
        style={this.props.style ? { ...style, ...this.props.style } : { ...style }}
        onError={() => this.setState({ urlError: true })}
      >
        {img}
      </Image>
    );
  }
}
