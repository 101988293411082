import styled from 'styled-components';

export const AlertContainer = styled.div`
  width: 100%;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'transparent'};
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const DropdownContainer = styled.div`
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
`;
