import React from 'react';
import { Container, Title, Icon } from './Header.styles';

/**
 * How to use Header component
 *
 * <Header
 *  onBack={() => {}}
 *  title="The header title"
 * />
 *
*/

export function Header({ title, onBack }) {
  return (
    <Container>
      {onBack && <Icon data-testid="onBack" onClick={onBack} />}
      <Title>{title}</Title>
    </Container>
  );
}
