import React from 'react';
import { Switch } from './';
import { fireEvent, render } from '../../../../../../newRender';

describe('given Switch channels component', () => {
  it('should be able to see the switch as false by default', () => {
    const { queryByTestId } = render(<Switch />);

    const result = queryByTestId('CHANNEL_CONFIG_SWITCH_UNCHECKED');

    expect(result).toBeTruthy();
  });

  it('should be able to see switch as checked', () => {
    const { queryByTestId } = render(<Switch checked />);

    const result = queryByTestId('CHANNEL_CONFIG_SWITCH_CHECKED');

    expect(result).toBeTruthy();
  });

  it('should be able to click the switch and call onChange', () => {
    const onChange = jest.fn();
    const { getByTestId } = render(<Switch onChange={onChange} />);

    const result = getByTestId('CHANNEL_CONFIG_SWITCH_UNCHECKED');

    fireEvent.click(result);

    expect(onChange).toHaveBeenCalledTimes(1);
  });
});
