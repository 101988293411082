import React from 'react';
import { __ } from '../i18n';

export default class OverdueBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { isOverdue, isMobile } = this.props;

    if (!isOverdue || isMobile) return null;

    return (
      <div style={{ position: 'fixed', top: 0, backgroundColor: '#FFE75B', zIndex: 3, marginTop: '64px', width: '100%', height: '55px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <div>
          <i className="icon exclamation triangle" style={{ fontSize: '28px', fontWeight: '300', margin: '9px 9px 0 0' }} />
        </div>
        <div style={{ wordBreak: 'break-all', maxWidth: '770px', lineHeight: '17px', fontSize: '15px' }}>
          {__('We have identified one or more overdue charges.')}
          <b>{` ${__('Your system will be suspended after 30 days of delay')}`}</b> - {`${__('to ensure this does not happen, please contact')} `}
          <span style={{ color: '#0069FF' }}>financeiro@classapp.com.br</span>
          {` ${__('immediately.')}`}
        </div>
      </div>
    );
  }
}
