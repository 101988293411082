import React from 'react';
import { inject, observer } from 'mobx-react';
import { Image, Icon } from 'semantic-ui-react';
import utils from '../utils';

@inject('store') @observer
export default class SideBanner extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { url, disclaimer, handleCloseClick, handleImageClick } = this.props;
    return(
      <div className='arrived-banner'>
        <div className='close-banner' onClick={handleCloseClick}>
          <Icon
            size='large'
            name='times'
          />
        </div>
        <Image className='banner' src={url} width="150px" onClick={handleImageClick}/>
        <p className='disclaimer'>
          {disclaimer}
        </p>
      </div>
    );
  }
}