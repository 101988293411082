import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

/** @type {{[key:string]: React.CSSProperties}} */
const styles = {
  countdownBox: {
    display: 'flex',
    justifyContent: 'space-around',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    padding: '16px 10px',
    minWidth: 110
  },
  inlineCountdown: {
    display: 'inline'
  }
};

const milissecondsToHMS = (ms) => {
  const h = ms / 3.6e6 | 0;
  const m = (ms % 3.6e6) / 6e4 | 0;
  const s = (ms % 6e4) / 1e3 | 0;

  return `${('' + h).padStart(2, '0')}:${('' + m).padStart(2, '0')}:${('' + s).padStart(2, '0')}`;
};

/**
 * @typedef {{
 *  finalDate: Date;
 *  onFinish: () => any;
 *  inline: boolean;
 * }} Props
 * @extends {Component<Props>}
 */
export default class Countdown extends Component {
  /** @param {Props} props */
  constructor(props) {
    super(props);

    this.state = {
      counter: props.finalDate.getTime() - new Date().getTime()
    };
  }

  componentDidMount() {
    if (this.state.counter > 0) this.updateCounter();
    else this.props.onFinish();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState || this.state.counter != prevState.counter) {
      if (this.state.counter > 0) this.updateCounter();
      else this.props.onFinish();
    }
  }

  updateCounter() {
    const { finalDate } = this.props;

    const timerInterval = setInterval(() => {
      this.setState({ counter: finalDate.getTime() - new Date().getTime() });
      clearInterval(timerInterval);
    }, 1000);
  }

  render() {
    const { inline } = this.props;
    const { counter } = this.state;

    return (
      <div data-testid="countdown" style={inline ? styles.inlineCountdown : styles.countdownBox}>
        {!inline && <Icon name="clock" style={{ verticalAlign: 'top' }} />}
        {milissecondsToHMS(counter)}
      </div>
    );
  }
}
