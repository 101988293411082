/* Extern */
import React from 'react';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';

import {
  Icon,
  Label,
  Image
} from 'semantic-ui-react';

import BetaModal from './ui/Modal';
import BetaButton from './ui/Button';
import { asset } from '../utils';
import { sendGoogleAnalyticsEvent } from '../lib/analytics';
import { __ } from '../i18n';

/* Intern */

const styles = {
  menu: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0px',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 16px',
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.08)',
    margin: '16px'
  },
  itemIcon: {
    fontSize: '1rem',
    fontWeight: 300
  },
  itemText: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginLeft: '4px'
  }
};

@inject('store')
@observer
export default class SideMenuMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }


  setCookies = (cookieItem) => {
    const { store } = this.props;
    const menuWarnModal = cookie.load('menuWarnModal') || [];
    menuWarnModal.push(cookieItem);
    cookie.save('menuWarnModal', menuWarnModal, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 30 });

  }

  handleOpenLink = (to) => {
    const { router, store } = this.props;
    store.appends.pop();
    router.push(to);
  }

  checkAndRenderWarnModal = (to) => {
    const menuWarnModal = cookie.load('menuWarnModal') || [];
    let cookieItem = '';
    if (to.includes('dashboard')) cookieItem = 'dashboard';
    else if (to.includes('groups')) cookieItem = 'groups';
    else if (to.includes('reports')) cookieItem = 'reports';
    else if (to.includes('payments')) cookieItem = 'payments';
    else if (to.includes('forms')) cookieItem = 'forms';
    else if (to.includes('integrations')) cookieItem = 'integrations';
    else if (to.includes('accesses')) cookieItem = 'accesses';
    else if (to.includes('edit')) cookieItem = 'edit';

    if (menuWarnModal.includes(cookieItem)) this.handleOpenLink(to);
    else {
      this.props.store.appends.push(<BetaModal
        id="OptionsToast"
        toast
        invertCloseButton
        onClickTimesClose={() => {
          this.setCookies(cookieItem); this.handleOpenLink(to); this.props.store.appends.pop();
        }}
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick
        header={__('Warning')}
        spaceFooter
        content={this.renderMenuWarning(to, cookieItem)}
      />);
    }
  };

  __handleOnClick = (menuItem) => {
    const { store } = this.props;
    const pathname = store.currentLocation.pathname;
    const isOrganizationSideMenu = /organization/g.test(pathname);
    const organizationId = store.currentOrganization && store.currentOrganization.id;
    const entityType = store.currentEntity && store.currentEntity.type;
    const menuItemUpperCase = menuItem && menuItem.id && menuItem.id.toUpperCase();

    if (menuItem.openPaywall) {
      if (isOrganizationSideMenu) {
        sendGoogleAnalyticsEvent({
          name: 'Sidebar',
          category: `Sidebar click by ${entityType} - ${menuItemUpperCase} - Paywall`,
          label: `OrganizationId: ${organizationId}`,
        }, { store });
      }
      menuItem.openPaywall();
    } else {
      if (isOrganizationSideMenu) {
        sendGoogleAnalyticsEvent({
          name: 'Sidebar',
          category: `Sidebar click by ${entityType} - ${menuItemUpperCase}`,
          label: `OrganizationId: ${organizationId}`,
        }, { store });
      }
      this.checkAndRenderWarnModal(menuItem.to);
    }
  }

  _renderItem = (menuItem) => {
    const { store } = this.props;
    const pathname = store.currentLocation.pathname;

    let isActive = (menuItem.to && pathname.includes(menuItem.to));

    if (menuItem.to && menuItem.to.includes('dashboard') && pathname.includes('dashboard')) {
      isActive = true;
    }

    if (store.currentOrganization) {
      if ((pathname === `/organizations/${store.currentOrganization.id}/` || pathname === `/organizations/${store.currentOrganization.id}`) && menuItem.to.includes('entities')) {
        isActive = true;
      }
      if ((pathname.includes(`/organizations/${store.currentOrganization.id}/accounts`) || pathname.includes(`/organizations/${store.currentOrganization.id}/charges`) || pathname.includes(`/organizations/${store.currentOrganization.id}/paymentTerms`)) && menuItem.to.includes('payments')) {
        isActive = true;
      }
    }

    if (store.currentEntity) {
      if ((pathname === `/entities/${store.currentEntity.id}/` || pathname === `/entities/${store.currentEntity.id}`) && menuItem.to.includes('messages')) {
        isActive = true;
      }
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        onClick={() => this.__handleOnClick(menuItem)}
        key={`side-menu-item-${menuItem.to}`}
        data-action={menuItem['data-action']}
        style={styles.menuItem}
      >
        <Icon style={{ ...styles.itemIcon, color: isActive ? '#084FFF' : '#000000' }} disabled={menuItem.openPaywall} className={`${menuItem.icon} ${isActive ? 'duotone' : ''}`} />
        {(menuItem.name) &&
          <p style={{ ...styles.itemText, color: menuItem.openPaywall ? '#a6a6a6' : '#000' }}>{menuItem.name}</p>
        }
        {menuItem.rightIcon &&
          <Icon name={menuItem.rightIcon} style={{ margin: '0px 0px 0px 8px', color: '#A5A5A5' }} />
        }
        {
          menuItem.quantity > 0 &&
          <div>
            <Label circular size="small" color="red" key={'label_' + menuItem.name} style={{ margin: '0px 0px 0px 8px' }}>{menuItem.quantity}</Label>
          </div>
        }
      </div>
    );
  }

  renderMenuWarning = (to, cookieItem) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ height: '122px', width: '149px', marginBottom: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Image src={asset('/images/classapp-web.png')} size="medium" style={{ display: 'flex' }} />
      </div>
      <div style={{ marginBottom: '18px', width: '80%', color: 'rgba(29, 29, 29, 1)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>{__('Have a better experience using a computer to access')}</div>
      <div style={{ marginBottom: '16px', width: '80%', color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px', textAlign: 'center' }}>{__('Switch to computer to better view your institution\'s data')}</div>
      <BetaButton
        data-action="ok"
        round
        text={__('Ok, I got it')}
        onClick={() => { this.setCookies(cookieItem); this.handleOpenLink(to); this.props.store.appends.pop(); }}
        style={{ marginBottom: '23.5px', width: '90%', height: '47px' }}
      />
    </div>
  );

  render() {
    const { items } = this.props;

    // filter items that are already on the menu
    const filteredItems = items.filter(item => !['people', 'channels', 'messages', 'moments'].includes(item.id));

    return (
      <BetaModal
        id="side-menu-mobile"
        hasDivider
        fullScreen
        onClose={() => {
          this.props.store.appends.pop();
        }}
        invertCloseButton
        headerItem
        content={
          <div style={styles.menu}>
            {filteredItems.map(item => this._renderItem(item))}
          </div>
        }
      />
    );
  }
}
