/* eslint-disable quotes */
import { __ } from '../../../../../../../i18n';

export const DefaultAutomaticMessages = () => [
  {
    id: '1',
    type: 'FIRST_MESSAGE',
    message: __(
      "Hello, thank you for contacting us. Please send us all the necessary information so that we can speed up the process. We'll get back to you shortly!",
    ),
    enabled: false,
  },
  {
    id: '2',
    type: 'OUT_WORKING_HOURS',
    message: __(
      "Hello, we are currently outside service hours. Please leave a message with all the necessary information, and we'll get back to you as soon as possible. Thank you for your understanding!",
    ),
    enabled: false,
  },
];
