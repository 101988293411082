import React from 'react';
import { Icon } from 'semantic-ui-react';

export default class Tag extends React.Component {
  render() {
    const { name, onDelete, loading } = this.props;
    return (
      <div className="global-tag-groups">
        <span>{name}</span>

        {onDelete && <Icon className="times circle duotone" onClick={loading ? null : onDelete} />}
      </div>
    );
  }
}
