import React, { useMemo } from 'react';
import Form from '../../../../../../../../components/ui/Form';
import { __ } from '../../../../../../../../i18n';
import { isMobileOrTablet } from '../../../../../../../../utils';

import Input from '../../../../../../../../components/ui/Input';

const MOBILE_ITEM_STYLE = {
  width: 'fit-content',
};

/**
 * @typedef {{
*  value?: string,
*  onChange?: (event: Event) => void
* }} Props
*
* @param {Props} props
* @returns {JSX.Element}
*/
export function Status({ value, onChange }) {
  const isMobile = useMemo(isMobileOrTablet, [window.innerWidth]);

  const DEFAULT_TYPE_OPTIONS = [
    {
      value: 'ENABLED',
      testId: 'enabled-status-option',
      text: __('Enabled'),
      style: {
        width: '21.429rem'
      }
    },
    {
      value: 'DISABLED',
      testId: 'without-status-option',
      text: __('Disabled'),
      style: {
        width: '28.571rem'
      }
    }
  ];

  const TYPE_OPTIONS = useMemo(() => {
    if (isMobile) {
      return DEFAULT_TYPE_OPTIONS.map((option) => ({
        ...option,
        style: MOBILE_ITEM_STYLE
      }));
    }

    return DEFAULT_TYPE_OPTIONS;
  }, [
    window.innerWidth
  ]);

  return (
    <div>
      <div style={{ marginBottom: '1.25rem' }}>
        <span style={{
          fontWeight: 'bold',
          fontSize: '1.286rem',
          color: '#000000'
        }}
        >
          {__('Status')}
        </span>
      </div>
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: isMobile ? '90%' : '100%' }}>
        {TYPE_OPTIONS.map(option => (
          <Input
            key={`input-radio-${option.value}`}
            type="radio"
            id={`input-radio-${option.value}`}
            name={option.value}
            value={option.text}
            selected={value === option.value}
            onChange={e => onChange(e.target.name)}
          />
        ))}
      </div>
    </div>
  );
}
