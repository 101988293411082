import React from 'react';
import { render, screen } from '../../../../../../../../newRender';
import { PictureInput } from '.';

import { __ } from '../../../../../../../../i18n';

describe('<PictureInput />', () => {
  const props = {
    value: {
      id: 1,
      uri: 'http://example.com/picture'
    },
    fullname: 'Mr. holmes',
    canAddPicture: true
  };
  it('should be defined', () => {
    const { container } = render(<PictureInput />);
    expect(container).toBeDefined();
  });

  it('should be testing canAddPicture field when it is true', () => {
    render(<PictureInput {...props} />);
    const title = screen.getByText(__('Add a picture or choose one bolow:'));
    expect(title).toBeInTheDocument();
  });

  it('should be testing canAddPicture field when it is false', () => {
    props.canAddPicture = false;
    render(<PictureInput {...props} />);
    const title = screen.getByText(__('Appearance'));
    expect(title).toBeInTheDocument();
  });
});
