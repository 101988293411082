import { validateRangeTime } from '.';

describe('given validateRangeTime function', () => {
  it('should be able to return false result when param is not provided', () => {
    const result = validateRangeTime(null);

    expect(result).toEqual(true);
  });

  it('should be able validate the range time when is correct', () => {
    const result = validateRangeTime({
      open: '10:00',
      close: '14:00'
    });

    expect(result).toEqual(true);
  });

  it('should be able validate the range time when is incorrect', () => {
    const result = validateRangeTime({
      open: '13:00',
      close: '08:00'
    });

    expect(result).toEqual(false);
  });

  it('should be able validate the range time when open time is correct and close time is wrong', () => {
    const result = validateRangeTime({
      open: '',
      close: '14:00'
    });

    expect(result).toEqual(false);
  });

  it('should be able validate the range time when open time is wrong and close time is correct', () => {
    const result = validateRangeTime({
      open: '14:00',
      close: ''
    });

    expect(result).toEqual(false);
  });
});
