import React from 'react';

import {
  StyledContainer,
} from './Container.styles';

export function Container({ testID }) {
  return (
    <StyledContainer
      testID={testID}
      closeOnClick={false}
    />
  );
}
