import React from 'react';
import { screen, render } from '../../../../../newRender';
import { UserNameHeader } from '../UserNameHeader';

describe('<UserNameHeader />', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      user: {
        fullname: 'John Doe',
      },
      tags: {
        nodes: [
          {
            id: 1,
            name: 'tag1',
          },
          {
            id: 2,
            name: 'tag2',
          },
        ],
      },
    };
  });

  it('should be able to return null when tags and user name is not provided', () => {
    props.user = undefined;
    props.tags = undefined;

    render(<UserNameHeader {...props} />);
    expect(screen.queryByTestId('header-container')).toBeNull();
  });

  it('should be able to render component when user and tags is provided', () => {
    render(<UserNameHeader {...props} />);
    expect(screen.getByText(props.user.fullname)).toBeInTheDocument();
  });

  it('should be able to render component when only tags is not provided', () => {
    props.tags = undefined;
    render(<UserNameHeader {...props} />);
    expect(screen.getByText(props.user.fullname)).toBeInTheDocument();
  });
});
