import React from 'react';
import { Popup } from 'semantic-ui-react';
import { inject } from 'mobx-react';

import { sendGoogleAnalyticsEvent } from '../lib/analytics';
import { __ } from '../i18n';
import Button from './ui/Button';

const MAX_TITLE_SIZE = 18;

@inject('store')
export default class AccessCard extends React.Component {
  __handleTrackingEvent = () => {
    const { store } = this.props;
    const getOrganizationId = store && store.currentOrganization && store.currentOrganization.id;

    sendGoogleAnalyticsEvent({
      name: 'External Link',
      category: `OrganizationId: ${getOrganizationId}`
    }, { store });
  }

  render() {
    const { link, eid, externalData } = this.props;

    let newLink = link.url;

    if (link.type === 'INTEGRATION') {
      const external_data = {
        ...externalData,
        eid
      };

      Object.keys(link.replaces).forEach((key) => {
        const regex = new RegExp(key);
        newLink = newLink.replace(regex, external_data[link.replaces[key]]);
      });
    }

    return (
      <a onClick={() => this.__handleTrackingEvent()} style={{ width: '20rem', height: '14.286rem', margin: '0.714rem' }} href={newLink} target="_blank" rel="noopener noreferrer">
        <div style={{ width: '20rem', height: '14.286rem', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '1.714rem', padding: '1.714rem' }}>
          <i style={{ fontSize: '2.143rem', color: '#000000' }} className={`icon ${link.icon || 'globe'}`} />
          <div>
            {(link.title && link.title.length > MAX_TITLE_SIZE) ?
              <Popup content={link.title} trigger={<span style={{ fontSize: '20px', color: '#000000', fontWeight: 600 }}>{`${link.title.substr(0, MAX_TITLE_SIZE)}...`}</span>} /> :
              <span style={{ fontSize: '20px', color: '#000000', fontWeight: 600 }}>{link.title}</span>
            }
          </div>
          {
            link.type === 'URL' ? <Button transparent round icon={{ name: 'external link' }} text={__('Visit website')} />
              : <Button transparent round icon={{ name: 'arrow right to bracket' }} text={__('Access')} />
          }
        </div>
      </a>
    );
  }
}
