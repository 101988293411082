import Switch from 'react-switch';
import styled from 'styled-components';

export const Container = styled(Switch).attrs({
  onColor: '#084FFF',
  onHandleColor: '#FFFFFF',
  offColor: '#E7E7E7',
  handleDiameter: 24,
  uncheckedIcon: false,
  checkedIcon: false,
  width: 54,
  height: 28,
})``;
