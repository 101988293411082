import getDataAttributeValue from '../getDataAttributeValue';

describe('getDataAttributeValue function', () => {
  it('should return button attribute value when param is provided', () => {
    const variation = 'primary';
    const testID = 'testing-padding-function';

    const result = getDataAttributeValue(variation, testID);

    expect(result).toBe(`btn-${variation}-${testID}`);
  });

  it('should return undefined if param is not provided', () => {
    const result = getDataAttributeValue();

    expect(result).toBe(undefined);
  });

  // Add more test cases as needed
});
