import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
`;

export const InfoContainer = styled.div`
  color: ${({ theme }) => theme.colors.blackboard.main};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h3 {
    font-size: 1rem;
    font-weight: 700;
  }

  p {
    font-weight: 400;
    word-break: break-word;
  }
`;
