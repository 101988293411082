import React from 'react';
import { render, fireEvent } from '../../../../../../../../newRender';
import { SelectHours } from '.';

import { __ } from '../../../../../../../../i18n';

describe('<SelectHours />', () => {
  it('should to be in the document', () => {
    const { getByText, getByTestId } = render(
      <SelectHours
        data-testid="hours-controller"
        value={{
          open: '',
          close: ''
        }}
        error=""
        onTimeChange={() => { }}
      />
    );

    const officeHoursText = getByText(__('Office Hours'));
    const openTimeDropdown = getByTestId('open-time-dropdown');
    const closeTimeDropdown = getByTestId('close-time-dropdown');

    expect(officeHoursText).toBeInTheDocument();
    expect(openTimeDropdown).toBeInTheDocument();
    expect(closeTimeDropdown).toBeInTheDocument();
  });

  test('should be able to change the open time dropdown', () => {
    const mockTimeChange = jest.fn();

    const { getByTestId } = render(
      <SelectHours
        data-testid="hours-controller"
        value={{
          open: '',
          close: ''
        }}
        error=""
        onTimeChange={mockTimeChange}
      />
    );

    const openTimeDropdown = getByTestId('open-time-dropdown');

    fireEvent.click(openTimeDropdown);
    const openTimeOption = getByTestId('open-time-dropdown-08:00');
    fireEvent.click(openTimeOption);

    expect(mockTimeChange).toHaveBeenCalledWith({ close: '', open: '08:00' });
  });

  test('should be able to change the open time dropdown', () => {
    const mockTimeChange = jest.fn();

    const { getByTestId } = render(
      <SelectHours
        data-testid="hours-controller"
        value={{
          open: '',
          close: ''
        }}
        error=""
        onTimeChange={mockTimeChange}
      />
    );

    const closeTimeDropdown = getByTestId('close-time-dropdown');

    fireEvent.click(closeTimeDropdown);
    const closeTimeOption = getByTestId('close-time-dropdown-17:00');
    fireEvent.click(closeTimeOption);

    expect(mockTimeChange).toHaveBeenCalledWith({ close: '17:00', open: '' });
  });
});
