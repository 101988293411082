import React from 'react';

export default class ArrivedTermsAndPrivacy extends React.Component {
  render() {
    const { entityTypes } = this.props;
    const isStudent = entityTypes.includes('noentity') || entityTypes.includes('student');
    const isAdminOrStaff = entityTypes.includes('staff') || entityTypes.includes('admin');
    const paddingTable = { paddingLeft: '10px', paddingRight: '10px' };
    return (
      <div className="terms">
        {
          isStudent
           && (
           <section>
             {!this.props.noHeader && <h2 id="terms-responsible" className="avoid-header-overlapping" style={{ marginBottom: '38px' }}>Cheguei - Termos de Uso e Privacidade</h2>}

             <ol type="1">
               <li>
                 <strong>O que é?</strong>
                 <ol type="1">
                   <li>O serviço opcional Cheguei foi criado com o objetivo de auxiliar os responsáveis dos alunos no horário de saída das escolas, tornando a atividade mais prática e segura para o responsável e para o colégio.</li>
                   <li>Com essa funcionalidade, o responsável, ao chegar na escola, notificará a instituição de forma simples e prática através do aplicativo, enquanto a portaria acompanha a informação e realiza a logística para levar a criança até a saída.</li>
                 </ol>
               </li>

               <li>
                 <strong>Como Aderir?</strong>
                 <ol type="1">
                   <li>Caso a instituição tenha aderido esta nova funcionalidade, você será notificado no aplicativo ClassApp sobre a possibilidade de utilização do Cheguei.</li>
                   <li>Com o recebimento do comunicado informando que a instituição já está usando esta nova funcionalidade, você poderá realizar o primeiro acesso e iniciar a utilização do Cheguei.</li>
                 </ol>
               </li>

               <li>
                 <strong>Como Funciona?</strong>
                 <ol type="1">
                   <li style={{ color: 'gray' }}>
                     <i>Cadastro</i>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>
                         Em seu primeiro acesso, você deverá preencher os seguintes dados:

                         <ul>
                           <li>Transporte: Veículo ou a pé;</li>
                           <li>Placa do Veículo;</li>
                           <li>Marca;</li>
                           <li>Modelo; e</li>
                           <li>Cor.</li>
                         </ul>
                       </li>
                       <li>Posteriormente, caso seja necessário, você poderá editar essas informações.</li>
                     </ol>
                   </li>

                   <li style={{ color: 'gray' }}>
                     <i>Veículos</i>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>Durante o cadastro, você poderá cadastrar mais de 1 (um) veículo. Entretanto, durante a utilização da funcionalidade para comunicar a chegada no colégio, você deverá selecionar o veículo que utilizará.</li>
                     </ol>
                   </li>

                   <li style={{ color: 'gray' }}>
                     <i>Diferentes Escolas</i>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>Se você tiver filhos em diferentes colégios, não se preocupe. O seu acesso permitirá visualizar e informar a sua chegada, desde que o colégio utilize o ClassApp e tenha aderido esta nova funcionalidade.</li>
                     </ol>
                   </li>

                   <li style={{ color: 'gray' }}>
                     <i>Cheguei</i>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>Ao chegar na portaria do colégio, você deverá selecionar o botão “cheguei” disponível e, logo em seguida, demonstrará em qual portaria está e qual veículo está utilizando.</li>
                       <li>Caso a portaria em que você se encontre não esteja disponível no aplicativo, significa que você está na portaria errada, pois a escola determinará qual é a portaria correta para cada turma do colégio. Dirija-se a portaria disponível no aplicativo.</li>
                       <li>Após a solicitação, o pedido estará “aguardando confirmação” até o funcionário do colégio visualizar o chamado.</li>
                       <li>Em seguida, o funcionário encaminhará o aluno até a respectiva portaria, ficando o chamado em “a caminho”.</li>
                       <li>Com a entrega do aluno a você, o chamado será finalizado e aparecerá como “entregue” em seu aplicativo.</li>
                     </ol>
                   </li>

                   <li style={{ color: 'gray' }}>
                     <i>Cancelamento</i>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>Sendo necessário, é possível o cancelamento do chamado após selecionar a opção “cheguei”.</li>
                       <li>Outros responsáveis do mesmo aluno que também são usuários do aplicativo poderão acompanhar o status da solicitação, mas não poderão realizar o cancelamento.</li>
                     </ol>
                   </li>
                 </ol>
               </li>

               <li>
                 <strong>E os dados informados?</strong>
                 <ol type="1">
                   <li>
                     Todas as nossas funcionalidades opcionais seguem a Política de Privacidade (acesse
                     {' '}
                     <a href="https://www.classapp.com.br/privacy" target="_blank" rel="noopener noreferrer">aqui</a>
                     ), mantendo a devida segurança com os dados inseridos na plataforma. (É o que se espera de todo aplicativo sério como o nosso!)
                   </li>
                   <li>
                     Para a utilização da funcionalidade Cheguei, conforme já mencionado, você precisará, além dos dados já cadastrados no aplicativo ClassApp, fornecer as seguintes informações:
                     <ul>
                       <li>Transporte: Veículo ou a pé;</li>
                       <li>Placa do Veículo;</li>
                       <li>Marca;</li>
                       <li>Modelo; e</li>
                       <li>Cor.</li>
                     </ul>
                   </li>
                   <li>Destacamos que os dados informados serão utilizados com a única finalidade de facilitar a entrega do aluno pelo colégio a você, de forma que não utilizaremos os mencionados dados para outras finalidades.</li>
                 </ol>
               </li>

               <li>
                 <strong>Quais as nossas responsabilidades?</strong>
                 <ol type="1">
                   <li>Somos responsáveis em disponibilizar a funcionalidade Cheguei aos responsáveis e à instituição, auxiliando na organização da instituição, além de facilitar a retirada dos alunos pelos seus responsáveis.</li>
                   <li>Ressaltamos que a funcionalidade foi criada com a intenção de facilitar a entrega do aluno pelo colégio a você, de forma que todos os responsáveis terão um meio mais fácil de comunicar ao colégio que chegaram para buscar o aluno, bem como os funcionários terão uma nova ferramenta para controle de entrada e saída dos alunos.</li>
                   <li>Considerando que somos apenas facilitadores, não somos responsáveis por qualquer evento externo à utilização do aplicativo, como acidentes automobilísticos, equívoco na entrega dos alunos pela instituição, conflitos com os funcionários do colégio etc.</li>
                   <li>Sabemos que eventos inesperados podem ocorrer, de forma que estaremos sempre dispostos a ajudar da melhor forma possível.</li>
                 </ol>
               </li>

               <li>
                 <strong>Termos e Condições Gerais do Aplicativo</strong>
                 <ol type="1">
                   <li>Como o Cheguei é uma funcionalidade adicional do aplicativo ClassApp, todos os termos e condições gerais do aplicativo são aplicáveis nesta funcionalidade.</li>
                   <li>
                     Assim, existindo algum ponto que não foi abordado nestes termos e condições da funcionalidade Cheguei, será aplicado o que está disposto nos termos e condições gerais da ClassApp, podendo ser acessado
                     {' '}
                     <a href="https://www.classapp.com.br/terms?type=student" target="_blank" rel="noopener noreferrer">aqui</a>
                     .
                   </li>
                 </ol>
               </li>
             </ol>
           </section>
           )
        }
        {
           isAdminOrStaff && !isStudent
           && (
           <section>
             {!this.props.noHeader && <h2 id="terms-staff" className="avoid-header-overlapping" style={{ marginBottom: '38px' }}>Cheguei - Termos de Uso e Privacidade</h2>}
             <ol type="1">
               <li>
                 <strong>O que é?</strong>
                 <ol type="1">
                   <li>
                     O serviço opcional Cheguei foi criado com o objetivo de ajudar as escolas na organização
                     do horário de saída dos alunos de forma prática e segura.

                   </li>
                   <li>Com essa funcionalidade, a escola poderá organizar o fluxo de saídas conforme a ordem de chegada dos responsáveis que, ao chegarem na escola, notificarão a instituição de forma simples e prática através do aplicativo, enquanto a portaria acompanha a informação e faz a logística para levar a criança até a saída.</li>
                 </ol>
               </li>

               <li>
                 <strong>Como Aderir?</strong>
                 <ol type="1">
                   <li>
                     Basta a Instituição possuir uma conta de acesso vigente ao ClassApp, em quaisquer das modalidades, ficando esta funcionalidade disponível, após a contratação, no site
                     {' '}
                     <a href="https://www.classapp.com.br/saida-da-escola" target="blank">(https://www.classapp.com.br/saida-da-escola).</a>
                     {' '}

                   </li>
                   <li>
                     Com a liberação, a instituição terá acesso à nova funcionalidade através do site e do
                     aplicativo, existindo algumas diferenças na utilização em cada meio:
                     <ul>
                       <li>Site: Configuração da funcionalidade Cheguei e acesso aos históricos;</li>
                       <li>Aplicativo: Apenas para uso diário com a lista de alunos.</li>
                     </ul>
                   </li>
                 </ol>
               </li>

               <li>
                 <strong>Qual a taxa cobrada?</strong>
                 <ol type="1">
                   <li>
                     É cobrada a taxa de serviço de acordo com a quantidade de alunos da escola, seguindo os valores da tabela a seguir.

                     <table border="1" style={{ marginTop: '12px', borderCollapse: 'collapse', }}>
                       <tr>
                         <th style={paddingTable}>Quantidade de Alunos</th>
                         <th style={paddingTable}>Valor Mensal</th>
                       </tr>
                       <tr>
                         <td style={paddingTable}>1 a 400</td>
                         <td style={paddingTable}>R$ 100,00</td>
                       </tr>
                       <tr>
                         <td style={paddingTable}>401 a 1000</td>
                         <td style={paddingTable}>R$ 150,00</td>
                       </tr>
                       <tr>
                         <td style={paddingTable}>1001 a 2000</td>
                         <td style={paddingTable}>R$ 200,00</td>
                       </tr>
                       <tr>
                         <td style={paddingTable}>Mais de 2001</td>
                         <td style={paddingTable}>R$ 250,00</td>
                       </tr>
                     </table>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>
                         A taxa se integrará ao valor das mensalidades do serviço ClassApp, sendo que para cada unidade organizacional que a Contratante ativar o serviço opcional Cheguei, será cobrada uma taxa fixa.
                       </li>
                       <li>
                         A ClassApp oferece à Contratante a possibilidade de contratação de outros módulos conjuntamente com o serviço opcional Cheguei (“combos”), de forma que o valor total a ser pago poderá sofrer alterações decorrentes desta contratação conjunta, propiciando um benefício à Contratante.
                       </li>
                     </ol>

                   </li>

                   <li>
                     Nas contratações com pagamento mensal do serviço, ocorrerá a renovação automática junto com o Contrato da ClassApp. O serviço será renovado com atualização monetária conforme índice informado no Contrato de Prestação de Serviços da ClassApp.
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>No caso da Contratante ter adquirido o serviço opcional Cheguei juntamente com outros Módulos adicionais, o reajuste ocorrerá no valor total do “combo” e não de maneira específica no serviço.</li>

                     </ol>
                   </li>
                   <li>
                     A contratação do serviço opcional Cheguei de forma anual faz com que o serviço seja licenciado pelo prazo de 12 meses.
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>

                       <li>
                         Nas contratações anuais, ocorrerá a renovação automática a cada 12 meses da data de contratação do serviço. O licenciamento do serviço será renovado com atualização monetária pelo IGP-M ou outro índice que venha o substituir, desde que positivos.
                       </li>
                     </ol>

                   </li>

                   <li>Na hipótese da variação do mencionado índice ser negativa no período de 12 meses, não haverá reajuste no valor do serviço.</li>

                 </ol>
               </li>

               <li>
                 <strong>Como Funciona?</strong>
                 <ol type="1">
                   <li style={{ color: 'gray' }}>
                     <i>Ativação</i>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>
                         Após a liberação, a instituição estará autorizada a realizar configurações da
                         funcionalidade pelo site. Apenas com a finalização de todas as configurações é que a
                         funcionalidade será ativada automaticamente pela ClassApp.

                       </li>
                       <li>
                         Para ativar a funcionalidade, devem ser configurados os seguintes pontos:
                         <ul>
                           <li>
                             Tags, Concessão de acesso às pessoas (ex: Pai, Mãe, Responsável
                             Pedagógico, Responsável Financeiro, etc.);

                          </li>
                           <li>
                             Portarias e seus endereços, com a inclusão de grupos de alunos por
                             portaria (ex: Portaria 1 – Ensino Fundamental 1);

                          </li>
                           <li>Funcionários Responsáveis;</li>
                         </ul>
                       </li>
                       <li>Com os pontos acima finalizados, a funcionalidade estará disponível para uso.</li>
                     </ol>
                   </li>

                   <li style={{ color: 'gray' }}>
                     <i>Utilização</i>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>
                         Pelo site, a instituição poderá:
                         <ul>
                           <li>
                             Visualizar um relatório dos chamados de saídas por aluno, dias e horário de saída, quem buscou, tempo de espera e qual portaria.

                          </li>
                         </ul>
                       </li>
                       <li>
                         Pelo aplicativo, a instituição poderá:
                         <ul>
                           <li>Acompanhar a lista de chamados de saída de alunos; e</li>
                           <li>Enviar mensagem diretamente ao responsável do aluno.</li>
                         </ul>
                       </li>
                       <li>
                         Esta lista será responsável pela organização da instituição, de forma que o
                         funcionário poderá verificar quais alunos devem ser direcionados à retirada dos
                         responsáveis. A mencionada lista conterá um card do aluno com:
                         <ul>
                           <li>Nome;</li>
                           <li>Turma;</li>
                           <li>Nome do responsável que comunicou a chegada;</li>
                           <li>Informações do carro; e</li>
                           <li>Tempo/Horário</li>
                         </ul>
                       </li>
                       <li>Com a saída do aluno, o funcionário deverá marcar o card como “entregue”.</li>
                       <li>
                         Caso nenhuma ação seja tomada pelo funcionário, o card será inativado após 4
                         horas, podendo ser acessada a lista pela instituição de cards expirados apenas pelo
                         site.

                       </li>
                     </ol>
                   </li>

                   <li style={{ color: 'gray' }}>
                     <i>Relatórios de entrega dos alunos</i>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>
                         Pelo site, a Instituição poderá ter acesso, através de seu administrador, do relatório de saídas, sendo possível realizar a busca por:
                         <ul>
                           <li>Aluno;</li>
                           <li>Dias e horário de saída;</li>
                           <li>Quem buscou;</li>
                           <li>Tempo de Espera; e</li>
                           <li>Qual portaria.</li>
                         </ul>
                       </li>
                       <li>
                         Caso não seja encontrado o que a instituição estava buscando, é possível que o
                         card do aluno esteja expirado (após 7 dias da retirada) ou cancelado.

                       </li>
                       <li>
                         Para que sejam pesquisados os cards expirados e cancelados, a instituição deverá
                         buscar através do filtro disponibilizado na plataforma, inserindo a informação de
                         “expirado” e “cancelado” na coluna de “entregas”.

                       </li>
                     </ol>
                   </li>

                   <li style={{ color: 'gray' }}>
                     <i>Desativação e Cancelamento</i>
                     <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                       <li>
                         Perdendo o interesse na utilização da funcionalidade, a instituição poderá desativá-la. O cancelamento pode ocorrer a qualquer momento, sem implicação de multa.

                       </li>
                       <li>
                         Nas contratações com pagamento mensal, no mês seguinte do pedido de cancelamento não será mais cobrado o valor do serviço, ficando disponível até o encerramento do mês correspondente à solicitação de cancelamento.

                       </li>
                       <li>
                         Nas contratações anuais, a solicitação de cancelamento não fará com que as parcelas deixem de ser cobradas. Elas continuarão até o fim, de acordo com o período que foi contratado.
                         <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                           <li>
                             Desta forma, os valores já pagos à ClassApp no momento da solicitação de cancelamento também não serão reembolsados.
                          </li>
                           <li>
                             Nas contratações anuais, o serviço ficará disponível até o encerramento do período contratado.

                          </li>
                         </ol>
                       </li>

                       <li>
                         Nas contratações anuais, inexistindo interesse na renovação automática por uma das partes, a comunicação deve ser feita em até 30 dias após a data da renovação.
                         <ol type="1" style={{ color: 'rgba(0,0,0,.87)' }}>
                           <li>
                             A recomendação é que, nesses casos, seja realizada comunicação prévia de 30 dias por escrito ao e-mail financeiro@classapp.com.br

                          </li>
                         </ol>
                       </li>

                       <li>
                         Existindo algum chamado em progresso, o usuário será comunicado pelo aplicativo
                         que será necessário finalizar ou cancelar o chamado.

                       </li>
                       <li>
                         Após a desativação, todas as informações da escola e dos responsáveis estarão
                         salvas para a instituição.

                       </li>
                     </ol>
                   </li>
                 </ol>
               </li>

               <li>
                 <strong>E os dados?</strong>
                 <ol type="1">
                   <li>
                     Todas as nossas funcionalidades opcionais seguem a Política de Privacidade (acesse
                     {' '}
                     <a href="https://www.classapp.com.br/privacy" target="_blank" rel="noopener noreferrer">aqui</a>
                     ), mantendo a devida segurança com os dados inseridos na plataforma. (É o que se espera de
                     todo aplicativo sério como o nosso!)

                   </li>
                   <li>
                     Para a utilização da funcionalidade Cheguei, os responsáveis precisarão inserir, além dos
                     dados já cadastrados no aplicativo ClassApp, as seguintes informações:
                     <ul>
                       <li>Transporte: Veículo ou a pé;</li>
                       <li>Placa do Veículo;</li>
                       <li>Marca;</li>
                       <li>Modelo; e</li>
                       <li>Cor.</li>
                     </ul>
                   </li>
                   <li>
                     Destacamos, mais uma vez, que a ClassApp não realiza qualquer controle ou possui
                     autonomia para decidir sobre os conteúdos que são inseridos pelos usuários na plataforma, não
                     sendo responsável, portanto, pelos mencionados conteúdos ou pelos danos que vierem a ser
                     causados por estes conteúdos.

                   </li>
                   <li>
                     Assim, a Instituição deverá zelar ainda mais pela utilização e divulgação dos dados
                     coletados no aplicativo, sendo a Instituição a única responsável por decidir sobre a utilização das
                     informações coletadas.

                   </li>
                 </ol>
               </li>

               <li>
                 <strong>Quais as nossas responsabilidades?</strong>
                 <ol type="1">
                   <li>
                     Somos responsáveis em disponibilizar a funcionalidade Cheguei aos responsáveis e à
                     instituição, auxiliando na organização da instituição, além de facilitar a retirada dos alunos pelos
                     seus responsáveis.

                   </li>
                   <li>
                     Ressaltamos que a funcionalidade foi criada com a intenção de facilitar a organização da escola, de forma que os funcionários terão uma nova ferramenta para controle de saída dos alunos.

                   </li>
                   <li>
                     Considerando que somos apenas facilitadores, não somos responsáveis por qualquer
                     evento externo à utilização do aplicativo, como acidentes automobilísticos, equívoco na entrega
                     dos alunos pela instituição, conflitos com os responsáveis etc.

                   </li>
                   <li>
                     Sabemos que eventos inesperados podem ocorrer, de forma que estaremos sempre
                     dispostos a ajudar da melhor forma possível.

                   </li>
                 </ol>
               </li>

               <li>
                 <strong>Termos e Condições Gerais do Aplicativo</strong>
                 <ol type="1">
                   <li>
                     Como o Cheguei é uma funcionalidade adicional do aplicativo ClassApp, todos os termos e
                     condições gerais do aplicativo são aplicáveis nesta funcionalidade.

                   </li>
                   <li>
                     Assim, existindo algum ponto que não foi abordado nestes termos e condições da
                     funcionalidade Cheguei, será aplicado o que está disposto nos termos e condições gerais da
                     ClassApp, podendo ser acessado
                     {' '}
                     <a href="https://www.classapp.com.br/terms?type=admin+staff+student" target="_blank" rel="noopener noreferrer">aqui</a>
                     .

                   </li>
                 </ol>
               </li>
             </ol>
           </section>
           )
        }

      </div>
    );
  }
}
