import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Container, Table, Link } from './AutomaticMessages.styles';
import { __ } from '../../../../../../i18n';
import { formatAutomaticMessages } from '../../../../../helpers/formatAutomaticMessages';
import { DefaultAutomaticMessages } from './constants/default-automatic-messages';
import { ResponsiveMessage } from './ResponsiveMessage';
import { useScreenSize } from '../../../../../hooks/useScreenSize';
import { CircleInfoLight } from '@classapp-tech/edna-icons';
import { Popup } from 'semantic-ui-react';

/**
 * @param {{
 *  ref?: React.RefObject<{
 *     getMessages: () => {
 *      [key: string]: {
 *        enabled: boolean;
 *        message: string;
 *      };
 *     };
 *  }>;
 * messages?: any[];
 * attendanceEnabled?: boolean;
 * onResponsivityChange?: (isMobile: boolean) => void;
 * }} props
 */
const AutomaticMessagesComponent = (
  {
    messages = DefaultAutomaticMessages(),
    attendanceEnabled = false,
    popupEnabled = false,
    onResponsivityChange,
  },
  ref,
) => {
  const triggersRef = useRef([]);
  const messagesRef = useRef([]);
  const screenSize = useScreenSize();
  const [showTable, setShowTable] = useState(true);
  const messagesToShow = useMemo(() => {
    if (attendanceEnabled) {
      return messages;
    }

    return messages.filter((message) => message.type !== 'FIRST_MESSAGE');
  }, [messages, attendanceEnabled]);

  const defaultMessages = formatAutomaticMessages({
    messages: messagesToShow,
    messagesRef,
    triggersRef,
  });

  const isMobile = useMemo(() => {
    return screenSize.width < 768;
  }, [screenSize.width]);

  useEffect(() => {
    if (onResponsivityChange) {
      onResponsivityChange(isMobile);
      setShowTable(!isMobile);
    }
  }, [isMobile]);

  function handleGetValues() {
    let automaticMessages = {};

    for (const [index, message] of defaultMessages.entries()) {
      const trigger = triggersRef?.current[index].getChecked();
      const msg = messagesRef?.current[index].getValue();
      const isEnabled = !!msg.length && trigger;

      automaticMessages[message.type] = {
        enabled: isEnabled,
        message: msg,
      };
    }

    return automaticMessages;
  }

  useImperativeHandle(ref, () => ({
    getMessages: handleGetValues,
  }));

  return (
    <Container>
      <div className="automaticMessagesHeader">
        <h1>
          {__('Automatic messages')}
          {popupEnabled && (
            <Popup
              trigger={
                <CircleInfoLight
                  style={{
                    opacity: '.6',
                    marginLeft: '10px',
                    marginTop: '5px',
                  }}
                  name="info circle"
                  fill="grey"
                />
              }
              content={
                <div>
                  <span>
                    {__('Find out more about automatic messages')}
                    <Link href="#">{__('here')}</Link>
                  </span>
                </div>
              }
              position="top right"
              hideOnScroll
              basic
            />
          )}
        </h1>
        <p>
          {__(
            'Automatic messages are sent as channel messages and can be activated and customized independently as required.',
          )}
        </p>
      </div>

      {showTable && (
        <Table
          topColumns={[
            {
              id: 'trigger',
              label: __('Moment of chat'),
            },
            {
              id: 'message',
              label: __('Automatic messages'),
            },
          ]}
          rows={defaultMessages}
          debounceTime={800}
          showInput={false}
        />
      )}

      {!showTable &&
        defaultMessages?.map((message) => (
          <ResponsiveMessage key={message?.id} columns={message?.columns} />
        ))}
    </Container>
  );
};

export const AutomaticMessages = forwardRef(AutomaticMessagesComponent);
AutomaticMessages.displayName = 'AutomaticMessages';
