import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.span`
    font-size: 1.143rem;
    margin-bottom: 0.75rem;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 0.214rem;
`;

export const FooterText = styled.span`
    color: #000000;
`;

export const TextArea = styled.textarea`
    resize: none;
    font-family: Circular,'Helvetica Neue',Arial,Helvetica,sans-serif;
    height: 5rem;
`;
