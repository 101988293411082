import React from 'react';
import { inject, observer } from 'mobx-react';
import { Image, Icon, Button } from 'semantic-ui-react';
import { __ } from '../i18n';
import BetaModal from './ui/Modal';
import Modal from './Modal';
import PaywallModal from '../containers/Trial/PaywallModal';
import Responsive from './Responsive';
import { asset, isEntityType, parseFormattedTranslation } from '../utils';
import TranslatedImage from '../containers/Arrived/TranslatedImage';

@inject('store')
@observer
export default class FeaturesModal extends Responsive {
  getFeatureContent = (feature, organization) => {
    const featuresList =
    {
      MESSAGE_MANAGER: {
        title: __('Communications Manager'),
        subtitle: <span>
          {__('See all sent communications in one place. This feature is available in') + ' '}
          <span style={{ color: '#0069FF' }}>{__('Premium Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/messageManager.png'),
        width: this.isMobile() ? 380 : 500,
        height: this.isMobile() ? 240 : 332
      },
      VIDEO_MOMENTS: {
        title: __('Video in moments'),
        subtitle: <span>
          {__('Engage and bring emotions to your communication through videos. This feature is available in')}
          {' '} <span style={{ color: '#0069FF' }}>{__('Premium Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/videoMoments.png'),
        width: 300,
        height: 280
      },
      VIDEO_MESSAGE: {
        title: __('Video in menssage and reply'),
        subtitle: <span>
          {__('Engage and bring emotions to your communication through videos. This feature is available in')}
          {' '} <span style={{ color: '#0069FF' }}>{__('Premium Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/videoMessage.png'),
        width: 300,
        height: 280
      },
      REPORTS: {
        title: __('Use Reports'),
        subtitle: <span>
          {__('Students routine in real time. Personalize your daily reports, homework, attendance and much more. This feature is available from')}
          {' '} <span style={{ color: '#0069FF' }}>{__('Standard Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/reports.png'),
        width: 300,
        height: 280
      },
      INTEGRATION: {
        title: __('API for integration'),
        subtitle: <span>
          {__('Open API to develop integrations. This feature is available from')}
          {' '} <span style={{ color: '#0069FF' }}>{__('Standard Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/api.png'),
        width: 500,
        height: 332
      },
      MORE_FILES: {
        title: __('Send more files'),
        subtitle: <span>
          {__('Engage students and parents with dynamic contents. Send documents, presentations, sheets and others file formats. This feature is available from')}
          {' '} <span style={{ color: '#0069FF' }}>{__('Standard Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/moreFiles.png'),
        width: 300,
        height: 260
      },
      ADMINISTRATION: {
        title: __('Manage Permissions'),
        subtitle: <span>
          {__('Allow admins tasks to your staff: give permission to see messages but not to add people and others tasks. This feature is available from')}
          {' '} <span style={{ color: '#0069FF' }}>{__('Standard Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/administration.png'),
        width: 500,
      },
      LABELS: {
        title: __('Use Labels'),
        subtitle: <span>
          {__('Tag message subjects to organize filters and find them. This feature is available from')}
          {' '} <span style={{ color: '#0069FF' }}>{organization.plan === 'STANDARD' ? __('Premium Plan') : __('Standard Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/labels.png'),
        width: 300,
        height: 232
      },
      DELETE_MESSAGE: {
        title: __('Delete and edit'),
        subtitle: <span>
          {__('Missing information? Wrong recipients? Edit and delete messages quickly. This feature is available from')}
          {' '} <span style={{ color: '#0069FF' }}>{__('Standard Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/deleteMessage.png'),
        width: 300,
        height: 260
      },
      AUDIOS: {
        title: __('Send audios'),
        subtitle: <span>
          {__('Engage and bring emotions to your communication sending audios. Quickly answer the families. This feature is available from')}
          {' '} <span style={{ color: '#0069FF' }}>{organization.plan === 'STANDARD' ? __('Premium Plan') : __('Standard Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/audios.png'),
        width: 330,
        height: 286
      },
      FORMS: {
        title: __('Send forms'),
        subtitle: <span>
          {__('Create templates with list of questions and surveys to send health forms, assignments, update data and much more. This feature is available in')}
          {' '} <span style={{ color: '#0069FF' }}>{__('Premium Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/forms.png'),
        width: 400,
      },
      ACCESSES: {
        title: __('Use External Accesses'),
        subtitle: <span>
          {__('Turn ClassApp into a unified experience for your students. Release easy-to-access links and integrate with your systems so your students and family can access everything directly in ClassApp. This functionality is available from the ')}
          {' '} <span style={{ color: '#0069FF' }}>{__('Standard Plan')}.</span>
        </span>,
        img: asset('/images/featuresPaywall/accesses.png'),
        width: 400,
      },
      ARRIVED: {
        title: __('Arrived!'),
        subtitle: <div className="arrived-paywall-content">
          <p>
            {__('A practical innovation designed to bring security, organise and facilitate the departure of students')}
          </p>
          <br />
          <ul>
            <li>{' '}{__('Departure history')}</li>
            <li>{' '}{__('Organising student withdrawal')}</li>
            <li>{' '}{__('Agility in the child exit process')}</li>
          </ul>
          <br />
          {
            parseFormattedTranslation({
              text: __('This functionality is available **in the Premium Plan.**'),
              props: {
                style: {
                  color: '#0069FF'
                }
              }
            })
          }
        </div>,
        img: <TranslatedImage text={__('Arrived')} style={this.isMobile() ? { width: 320, height: 165 } : { width: 429, height: 221 }} />,
        imgClassName: 'arrived-paywall-image',
      },
    };

    return featuresList[feature];
  }

  renderButton = () => {
    const { store, feature } = this.props;

    if (isEntityType(store, 'ADMIN')) {
      return <Button style={{ backgroundColor: '#084FFF', borderRadius: '40px', color: '#FFF', padding: '15px' }} onClick={() => { this.props.store.appends.push(<PaywallModal feature={feature} />); }}> {__('Learn more about plans')} </Button>;
    }
    return <Button style={{ backgroundColor: '#084FFF', borderRadius: '40px', color: '#FFF', padding: '15px 40px' }} onClick={() => { this.props.store.appends.pop(); }}>{__('Ok')}</Button>;
  }

  render() {
    const { feature, store } = this.props;
    if (!feature) return null;
    const organization = store && store.currentOrganization;
    const { title, subtitle, img, width, height, imgClassName } = this.getFeatureContent(feature, organization);

    return (
      this.isMobile() ?
        <BetaModal
          id={'recipientAttachmentForm'}
          size="tiny"
          fullScreen
          header={__('Update now')}
          scrolling
          invertCloseButton
          onClose={() => this.props.store.appends.pop()}
          content={
            <div style={{ padding: '15px 20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              {
                typeof img === 'string' ? <Image className={imgClassName} alt={title} src={img} style={{ width, height }} />
                  : img
              }
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '15px 0px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <Icon name="stars" size="large" style={{ color: '#A5A5A5' }} />
                  <span style={{ fontWeight: 'bold', fontSize: this.isMobile() ? '22px' : '30px', lineHeight: '40px', color: '#000' }}>{title}</span>
                </div>
                <span style={{ fontSize: '14px', color: '#1D1D1D', textAlign: 'center', marginTop: '6px' }}>{subtitle}</span>
              </div>
              {isEntityType(store, 'STAFF') ? <span style={{ color: '#084FFF', marginBottom: '15px' }}>{__('Contact your institution`s administrator for more information.')}.</span> : null}
              {this.renderButton()}
            </div>
          }
        />
        :
        <Modal
          size="tiny"
          closeIcon={'times close inside'}
          onClose={() => this.props.store.appends.pop()}
          content={
            <div className={imgClassName} style={{ padding: '15px 20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              {
                typeof img === 'string' ? <Image alt={title} src={img} style={{ width, height }} />
                  : img
              }
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '15px 0px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <Icon name="stars" size="large" style={{ color: '#A5A5A5' }} />
                  <span style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '40px', color: '#000' }}>{title}</span>
                </div>
                <span style={{ fontSize: '14px', color: '#1D1D1D', textAlign: 'center', marginTop: '6px' }}>{subtitle}</span>
              </div>
              {isEntityType(store, 'STAFF') ? <span style={{ color: '#084FFF', marginBottom: '15px' }}>{__('Contact your institution`s administrator for more information.')}.</span> : null}
              {this.renderButton()}
            </div>
          }
        />
    );
  }
}
