import React from 'react';
import { Link } from 'react-router';

import { __ } from '../../../../i18n';

import { getEntityLink } from '../helpers/getEntityLink';

import { UserNameHeader } from './UserNameHeader';

/**
 * @param {{
 * entity: {
 *  fullname: string,
 * },
 * store: {
 *  currentUser: {
 *     isMaster: boolean;
 *  }
 * },
 * params: {
 *  organization_id: number | string;
 * },
 * entityType: 'ADMIN' | 'STAFF' | 'STUDENT',
 * organizationId: number | string
 * message: {
 *  entity: {
 *    organizationId: number | string;
 *  },
 *  user: {
 *   fullname: string;
 *  },
 * tags: {
 *   nodes: {
 *    id: number | string;
 *    name: string;
 *   }[];
 * }
 * isMessageFromNetwork: boolean
 * }} options
 */
export function EntityNameHeader({
  entity,
  store,
  params,
  entityType,
  organizationId,
  message,
  isMessageFromNetwork,
}) {
  if (!entity) {
    return null;
  }

  if (isMessageFromNetwork) {
    return (
      <div data-testid="header-network-container">
        <span style={{ color: '#000', fontSize: '1rem' }}>{__('from')}</span>
        <span style={{ fontSize: '1rem', paddingLeft: '5px', color: '#000' }}>
          {entity && entity.fullname}
        </span>
      </div>
    );
  }

  const entityLink = getEntityLink({
    entity,
    store,
    params,
    entityType,
    organizationId,
    message,
  });

  return (
    <span
      data-testid="header-container"
      style={{ display: 'flex', alignItems: 'baseline' }}
    >
      <span>{__('from')}</span>
      {entityLink ? (
        <Link
          data-testid="header-link"
          to={entityLink}
          style={{ color: '#007fff', fontSize: '16px', paddingLeft: '5px' }}
        >
          {entity && entity.fullname}
        </Link>
      ) : (
        <span style={{ fontSize: '16px', paddingLeft: '5px' }}>
          {entity && entity.fullname}
        </span>
      )}
      <UserNameHeader tags={message?.tags} user={message?.user} />
    </span>
  );
}
