import styled from 'styled-components';
import { LoaderLight } from '@classapp-tech/edna-icons';

export const LoadingContainer = styled(LoaderLight)`
  animation: rotate 1s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
