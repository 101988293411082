export const directNotificationsMock = [
  {
    fromEntity: {
      fullname: 'John Doe',
      picture: {
        uri: 'http://placehold.it/350x150',
      },
    },
    toEntity: {
      id: 73,
      fullname: 'Mr. Holmes',
      picture: {
        uri: 'http://placehold.it/350x150',
      },
    },
    messageId: 1,
    created: '2018-01-01',
    notificationType: 'ATTENDANCE_CREATED',
  },
];
