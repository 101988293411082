import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Form, Popup, Container, Icon } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../i18n';
import parse from '../lib/parse';
import * as utils from '../utils';

import PhoneInput from './PhoneInput';
import Modal from './Modal';
import Responsive from './Responsive';

const errorMap = {
  email: __('Email address is not valid: check domain name and if it contains @ and .'),
  phone: __('Phone number is not valid: check area code and number length')
};

@inject('store')
@graphql(gql`mutation findUser($email: String, $phone: String) {
  findUser (email: $email, phone: $phone, recoverPassword: true, isNewCode:true, source: WEB) {
    ... on User {
      id:dbId
      fullname
    }
  }
}`)
@observer
export default class RecoverPassword extends Responsive {
  state = {
    formData: {},
    errors: {}
  }

  onRecoverPassword = (address, type) => this.props.mutate({
    variables: {
      [type]: address
    }
  }).then(() => {
    this.props.store.appends.pop();
    this.props.store.snackbar = {
      active: true,
      message: __('The recovery code was sent successfully. Check your inbox for it.'),
      success: true
    };
  }).catch((err) => { this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false }; })

  validate = () => {
    const { email, phone } = this.state.formData;

    if (!(email || phone)) {
      this.setState({ errors: { both: __('Please, provide at least 1 address') } });
      return;
    }

    const type = email ? 'email' : 'phone';
    const valid = type === 'email' ? parse.validateEmail(email) :
      parse.validatePhone('', phone);

    if (!valid) {
      this.setState({ errors: { [type]: errorMap[type] } });
    } else {
      this.setState({ errors: {} }, () => {
        this.onRecoverPassword(email || phone.replace(/[^0-9]+/g, ''), type);
      });
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <Modal id="UserRecoverPassword" size="small" open dimmer onClose={() => this.props.store.appends.pop()}>
        <Modal.Header>
          {__('Recover Password')}
        </Modal.Header>
        <Modal.Content>
          <Container textAlign="center">
            <Form style={this.isMobile() ? { } : { display: '-webkit-inline-box', webkitBoxAlign: 'baseline' }}>
              <Form.Field
                name="phone"
                type="text"
                placeholder={__('Area Code + Phone')}
                action={errors.phone && <Popup
                  trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
                  content={errors.phone}
                />}
                control={PhoneInput}
                onChange={value => this.setState({ formData: { ...this.state.formData, phone: value } })}
                autoFocus
                optional
              />
              <h5 style={{ margin: '1em' }}>{__('or').toUpperCase()}</h5>
              <Form.Input
                name="email"
                placeholder={__('Email')}
                icon="envelope"
                iconPosition="left"
                action={errors.email && <Popup
                  trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
                  content={errors.email}
                />}
                onChange={(e, data) => this.setState({ formData: { ...this.state.formData, email: data.value } })}
              />
            </Form>
            {
              errors && errors.both ?
                <div style={{ marginBottom: 14 }}>
                  <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                  {errors.both}
                </div>
                :
                null
            }
            <p style={{ marginTop: 15 }}>
              {__('A recovery code will be sent to your email address or by SMS to your phone. Check your inbox for it.')}
            </p>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button data-action="cancel" basic floated="left" content={__('Cancel')} onClick={() => this.props.store.appends.pop()} />
          <Button data-action="submit" primary content={__('Recover')} onClick={this.validate} />
        </Modal.Actions>
      </Modal>
    );
  }
}
