import styled from 'styled-components';
import { CheckLight } from '@classapp-tech/edna-icons';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    border-top: 1px solid #E5E5E5;
    padding-top: 1.5rem;
`;

export const SaveText = styled.span`
    font-size: 1.143rem;
    font-family: Circular,"Helvetica Neue",Arial,Helvetica,sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-left: 0.357rem;
`;

export const SubmitButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const CheckLightIcon = styled(CheckLight)`
    margin-right: 0.357rem;
`;
