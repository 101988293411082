import React from 'react';
import { render, fireEvent } from '../../../../../../../../newRender';
import { Description } from '.';

describe('<Description />', () => {
  it('should to be in the document', () => {
    const { getByPlaceholderText, getByText } = render(
      <Description
        value=""
        error=""
        placeholder="Testing placeholder"
        label="Testing Label"
        onChange={() => { }}
      />
    );

    const input = getByPlaceholderText('Testing placeholder');
    const label = getByText('Testing Label');

    expect(input).toBeInTheDocument();
    expect(label).toBeInTheDocument();
  });

  it('should be able to change the input value', async () => {
    const mockOnChange = jest.fn();
    const { getByPlaceholderText } = render(
      <Description
        value=""
        error=""
        placeholder="Testing placeholder"
        onChange={mockOnChange}
      />
    );

    const input = getByPlaceholderText('Testing placeholder');

    fireEvent.change(input, { target: { value: 'New Channel description' } });

    expect(mockOnChange).toHaveBeenCalled();
  });
});
