/* eslint-disable quotes */
import React from 'react';
import { AutomaticMessages } from '.';
import { act, fireEvent, render } from '../../../../../../newRender';
import { DefaultAutomaticMessages } from './constants/default-automatic-messages';
import * as ScreenSize from '../../../../../hooks/useScreenSize';

describe('given AutomaticMessages component', () => {
  const mockedProps = {
    messages: DefaultAutomaticMessages(),
    attendanceEnabled: true,
    ref: React.createRef(),
    onResponsivityChange: jest.fn(),
  };

  it('should be able to render automatic messages', () => {
    const { getAllByText } = render(<AutomaticMessages />);

    const text = getAllByText('Automatic Messages', { exact: false });

    expect(text).toBeTruthy();
  });

  it('should be able to render automatic messages with props', () => {
    const { getAllByText } = render(<AutomaticMessages {...mockedProps} />);

    const text = getAllByText('Automatic Messages', { exact: false });

    expect(text).toBeTruthy();
  });

  it('should be able to enable some message trigger', async () => {
    const { getAllByTestId, findByTestId } = render(
      <AutomaticMessages {...mockedProps} />,
    );

    const trigger = getAllByTestId('CHANNEL_CONFIG_SWITCH_UNCHECKED');

    act(() => {
      fireEvent.click(trigger[0]);
    });

    const checkedTrigger = await findByTestId('CHANNEL_CONFIG_SWITCH_CHECKED');

    expect(checkedTrigger).toBeTruthy();
  });

  it('should be able to enable and edit some automatic message', async () => {
    const { getAllByTestId, findByTestId } = render(
      <AutomaticMessages {...mockedProps} />,
    );

    const trigger = getAllByTestId('CHANNEL_CONFIG_SWITCH_UNCHECKED');

    act(() => {
      fireEvent.click(trigger[0]);
    });

    const checkedTrigger = await findByTestId('CHANNEL_CONFIG_SWITCH_CHECKED');

    expect(checkedTrigger).toBeTruthy();

    const message = getAllByTestId('AUTOMATIC_MESSAGE_TEXTAREA');

    act(() => {
      fireEvent.change(message[0], { target: { value: 'Test message' } });
    });

    expect(message[0].value).toBe('Test message');
  });

  it('should be able to get all messages using ref', () => {
    render(<AutomaticMessages {...mockedProps} />);

    const messages = mockedProps.ref.current.getMessages();

    expect(messages).toStrictEqual({
      FIRST_MESSAGE: {
        enabled: false,
        message:
          "Hello, thank you for contacting us. Please send us all the necessary information so that we can speed up the process. We'll get back to you shortly!",
      },
      OUT_WORKING_HOURS: {
        enabled: false,
        message:
          "Hello, we are currently outside service hours. Please leave a message with all the necessary information, and we'll get back to you as soon as possible. Thank you for your understanding!",
      },
    });
  });

  it('should be able to render the mobile version', () => {
    jest.spyOn(ScreenSize, 'useScreenSize').mockReturnValue({
      width: 767,
      height: 1024,
    });

    const { getByText } = render(<AutomaticMessages {...mockedProps} />);

    const text = getByText('Automatic messages');

    expect(text).toBeTruthy();
  });
});
