import React from 'react';
import { render, fireEvent } from '../../../../../../../../newRender';
import { Footer } from '.';

describe('<Footer />', () => {
  it('should to be in the document', () => {
    const { getByText } = render(
      <Footer
        cancelText="Cancel"
        submitText="Save"
      />
    );

    const cancelButton = getByText('Cancel');
    const saveButton = getByText('Save');

    expect(cancelButton).toBeInTheDocument();
    expect(saveButton).toBeInTheDocument();
  });
});
