import { act, renderHook } from '@testing-library/react-hooks/dom';
import { useRadioList } from '../useRadioList';

describe('given useRadioList hook', () => {
  const mocks = {
    onSelect: jest.fn((value) => value),
    value: 'value',
  };

  it('should return handleSelectOption and selectedOptions', () => {
    const { result } = renderHook(() => useRadioList({
      onSelect: mocks.onSelect,
      selectedFilter: mocks.value,
    }));

    act(() => {
      result.current.handleSelectOption(mocks.value);
    });

    expect(result.current.handleSelectOption).toBeDefined();
    expect(result.current.selectedOption).toEqual(mocks.value);
  });

  it('should be able to add some different value', () => {
    const { result } = renderHook(() => useRadioList(mocks));

    act(() => {
      result.current.handleSelectOption(mocks.value);
    });
    expect(result.current.selectedOption).toEqual(mocks.value);
  });
});
