import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

/**
 * @param {{
 *  id: number | string;
 *  name: string;
 *  onClick: (event: React.MouseEvent<HTMLSpanElement>) => void;
 *  isAdmin: boolean;
 *  hasMaster: boolean;
 *  onDelete: (event: React.MouseEvent<HTMLSpanElement>) => void;
 *  icon: React.ReactNode;
 * }} props
 */
export function Tag({ id, name, onClick, isAdmin, hasMaster, onDelete, icon }) {
  if (isAdmin) {
    return (
      <Label
        size="large"
        style={{ marginRight: 5, marginBottom: 5 }}
        key={name}
        as={'a'}
      >
        <span
          data-testid={`edit-tag-icon-${id}`}
          type="button"
          id={name}
          onClick={onClick}
        >
          <Icon data-action="edit-tag" data-params={id} name="pencil" />
          {name}
        </span>
        {hasMaster && (
          <Icon
            data-action="delete-tag"
            data-testid={`delete-tag-icon-${id}`}
            style={{ marginLeft: '10px' }}
            id={`delete-${name}`}
            data-params={id}
            name="delete"
            onClick={onDelete}
          />
        )}
      </Label>
    );
  }

  return (
    <Label
      size="large"
      style={{
        marginRight: 5,
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
      }}
      key={name}
    >
      {icon}
      <span id={name}>{name}</span>
    </Label>
  );
}
