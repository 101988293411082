import styled from 'styled-components';
import { themes } from '@classapp-tech/edna-styles';
import getButtonPadding from './helpers/getButtonPadding';

export const StyledButtonDefault = styled.button`
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  padding: ${({ size }) => getButtonPadding(themes.classapp.classappLight.spacings, size)};
  border-radius: ${themes.classapp.classappLight.radii.circle}px;

  &:hover:not(:disabled) {
    cursor: pointer;
  }
`;
export const StyledPrimaryButton = styled(StyledButtonDefault)`
  color: ${themes.classapp.classappLight.colors.paper};
  background: ${themes.classapp.classappLight.colors.pen.main};
  border: none;

  &:hover:not(:disabled) {
    background: ${themes.classapp.classappLight.colors.pen.bold};
  }

  &:disabled {
    background: ${themes.classapp.classappLight.colors.pen.disabled};
  }
`;
export const StyledBasicButton = styled(StyledButtonDefault)`
  color: ${themes.classapp.classappLight.colors.blackboard.main};
  background: ${themes.classapp.classappLight.colors.paper};
  border: ${themes.classapp.classappLight.borders.small}px solid ${themes.classapp.classappLight.colors.pencil.clear};

  &:hover:not(:disabled) {
    background: ${themes.classapp.classappLight.colors.pencil.disabled};
    border: ${themes.classapp.classappLight.borders.small}px solid ${themes.classapp.classappLight.colors.pencil.clear};
    color: ${themes.classapp.classappLight.colors.blackboard.main};
  }

  &:disabled {
    color: ${themes.classapp.classappLight.colors.pencil.main};
    background: ${themes.classapp.classappLight.colors.paper};
    border: ${themes.classapp.classappLight.borders.small}px solid ${themes.classapp.classappLight.colors.pencil.disabled};
  }
`;
export const StyledDangerButton = styled(StyledButtonDefault)`
  color: ${themes.classapp.classappLight.colors.paper};
  background: ${themes.classapp.classappLight.colors.warning.main};
  border: none;

  &:hover:not(:disabled) {
    background: ${themes.classapp.classappLight.colors.warning.bold};
  }

  &:disabled {
    background: ${themes.classapp.classappLight.colors.warning.disabled};
  }
`;
export const StyledSuccessButton = styled(StyledButtonDefault)`
  color: ${themes.classapp.classappLight.colors.paper};
  background: ${themes.classapp.classappLight.colors.approved.main};
  border: none;

  &:hover:not(:disabled) {
    background: ${themes.classapp.classappLight.colors.approved.bold};
  }

  &:disabled {
    background: ${themes.classapp.classappLight.colors.approved.disabled};
  }
`;
export const StyledSecondaryButton = styled(StyledButtonDefault)`
  color: ${themes.classapp.classappLight.colors.pen.main};
  background: ${themes.classapp.classappLight.colors.paper};
  border: ${themes.classapp.classappLight.borders.small}px solid ${themes.classapp.classappLight.colors.pen.main};

  &:hover:not(:disabled) {
    color: ${themes.classapp.classappLight.colors.pen.main};
    background: ${themes.classapp.classappLight.colors.pen.clear};
    border: ${themes.classapp.classappLight.borders.small}px solid ${themes.classapp.classappLight.colors.pen.main};
  }

  &:disabled {
    color: ${themes.classapp.classappLight.colors.pen.disabled};
    border: ${themes.classapp.classappLight.borders.small}px solid ${themes.classapp.classappLight.colors.pen.disabled};
  }
`;
export const StyledSecondaryDangerButton = styled(StyledButtonDefault)`
  color: ${themes.classapp.classappLight.colors.warning.main};
  background: ${themes.classapp.classappLight.colors.paper};
  border: ${themes.classapp.classappLight.borders.small}px solid ${themes.classapp.classappLight.colors.warning.main};

  &:hover:not(:disabled) {
    border: ${themes.classapp.classappLight.borders.small}px solid ${themes.classapp.classappLight.colors.warning.main};
    color:  ${themes.classapp.classappLight.colors.warning.main};
    background: ${themes.classapp.classappLight.colors.warning.clear};
  }

  &:disabled {
    border: ${themes.classapp.classappLight.borders.small}px solid ${themes.classapp.classappLight.colors.warning.disabled};
    color:  ${themes.classapp.classappLight.colors.warning.disabled};
    background: ${themes.classapp.classappLight.colors.paper};
  }
`;
