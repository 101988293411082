import React from 'react';
import { screen, render } from '../../../../../newRender';
import { EntityNameHeader } from '../EntityNameHeader';

describe('<EntityNameHeader />', () => {
  let props;

  beforeEach(() => {
    props = {
      entity: {
        fullname: 'Test',
        organizationId: 1,
        isChannel: false,
      },
      store: {
        currentUser: {
          isMaster: true,
        },
      },
      params: {
        organization_id: 1,
      },
      entityType: 'ADMIN',
      organizationId: 1,
      message: {
        entity: {
          organizationId: 1,
        },
      },
      isMessageFromNetwork: false,
    };
  });

  it('should be able to return null when entity is not provided', () => {
    props.entity = null;
    render(<EntityNameHeader {...props} />);
    expect(screen.queryByTestId('header-container')).toBeNull();
  });

  it('should be able to render component when message is from a network child', () => {
    props.isMessageFromNetwork = true;
    render(<EntityNameHeader {...props} />);
    expect(screen.getByTestId('header-network-container')).toBeInTheDocument();
  });

  it('should be able to render component when props is provided', () => {
    render(<EntityNameHeader {...props} />);
    expect(screen.getByTestId('header-container')).toBeInTheDocument();
  });

  it('should be able to render link when it has a entity link', () => {
    render(<EntityNameHeader {...props} />);
    expect(screen.getByTestId('header-link')).toBeInTheDocument();
  });

  it('should be able to render only text when it has not a entity link', () => {
    props.entity.organizationId = null;
    render(<EntityNameHeader {...props} />);
    expect(screen.getByText(props.entity.fullname)).toBeInTheDocument();
  });
});
