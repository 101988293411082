export const otherNotificationsMock = [
  {
    toEntity: {
      id: 533105,
      fullname: 'Marcos Kevin Felipe Dias',
      picture: null,
      __typename: 'Entity',
    },
    fromEntity: {
      fullname: 'Lightning Serum',
      picture: null,
      __typename: 'Entity',
    },
    messageId: 500438,
    message: {
      id: 500438,
      moment: true,
      __typename: 'Message',
    },
    notificationType: 'MOMENT',
    created: '2024-02-26T19:26:28.000Z',
    __typename: 'Notification',
  },
];
