import { validateVisibility } from '.';

describe('given validateVisibility function', () => {
  it('should be able to return false result when param is not provided', () => {
    const result = validateVisibility(null);

    expect(result).toEqual(false);
  });

  it('should be able validate when status is public', () => {
    const result = validateVisibility({
      status: 'public',
      selectedGroups: []
    });

    expect(result).toEqual(true);
  });

  it('should be able validate when status is custom', () => {
    const result = validateVisibility({
      status: 'custom',
      selectedGroups: [
        { id: 1, name: 'Group 1' },
        { id: 2, name: 'Group 2' },
      ]
    });

    expect(result).toEqual(true);
  });

  it('should be able validate when status is custom and is not selected groups', () => {
    const result = validateVisibility({
      status: 'custom',
      selectedGroups: []
    });

    expect(result).toEqual(false);
  });
});
