import styled from 'styled-components';
import { CircleInfoLight } from '@classapp-tech/edna-icons';

export const OptionContainer = styled.div`
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    width: ${({ isMobile }) => (isMobile ? '90%' : '70%')}
`;

export const Option = styled.div`
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    width: 100%;
    background-color: ${({ isSelected }) => (isSelected ? '#F2F7FF' : '#FFFFFF')};
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    margin: 5px 0px;
`;

export const Icon = styled(CircleInfoLight)`
    opacity: .6;
    margin-left: 5px;
    margin-top: 1px;
`;
