import React from 'react';
import { Popup } from 'semantic-ui-react';
import _ from 'lodash';

// const defaultQuantity = 5;
const defaultActive = 0;

const stepStyle = {
  width: '1em',
  height: '1em',
  textAlign: 'center',
  lineHeight: '1em',
  borderRadius: '1em',
  background: 'dodgerblue',
  // margin: '0 2em',
  display: 'inline-block',
  color: 'white',
  position: 'relative',
  cursor: 'default'
};

const stepActiveStyle = {
  background: 'dodgerblue',
};

const stepNotActiveStyle = {
  background: '#e9ecef',
};

export default class StepsModal extends React.Component {
  render() {
    const { items, activeItem, color, size = 1, style, generalOnClick, checkedIcon, circleStyle } = this.props;
    let updatedStepStyle = stepStyle;

    if (size >= 1 && size <= 4) {
      updatedStepStyle = _.assignIn(stepStyle, {
        width: `${size}em`,
        height: `${size}em`,
        lineHeight: `${size}em`,
        borderRadius: `${size}em`
      });
    }

    const active = activeItem || defaultActive;

    const steps = [];
    items.forEach((item, i) => {
      let activeStyle = _.assignIn({}, updatedStepStyle);
      let cssClassName = 'step-modal';
      const isClickable = item.onClick || generalOnClick;

      if (i > active) {
        activeStyle = _.assignIn(activeStyle, stepNotActiveStyle);
      } else if (i < active || i === items.length - 1) {
        activeStyle = _.assignIn(activeStyle, color ? { background: color } : stepActiveStyle);
        cssClassName = 'step-modal-active';
      } else {
        activeStyle = _.assignIn(activeStyle, color ? { background: color } : stepActiveStyle);
        cssClassName = 'step-modal-last-active';
      }

      steps.push(
        <span
          key={`modal-li-${i}`}
          className={cssClassName}
          id={`modal-${i}`}
          style={{ width: `${100 / items.length}%`, display: 'flex', height: '2em' }}
        >
          <div style={{ margin: 'auto', zIndex: '2' }}>
            {
              item.popupContent ?
                <Popup
                  basic
                  trigger={
                    <li
                      style={{ ...activeStyle, ...(isClickable && { cursor: 'pointer' }) }}
                      onClick={() => (item.onClick ? item.onClick() : generalOnClick ? generalOnClick(i) : null)}
                    >
                      {
                        (i < activeItem && checkedIcon) ?
                          checkedIcon && checkedIcon
                          :
                          item.icon && item.icon
                      }
                    </li>
                  }
                  content={item.popupContent}
                />
                :
                <li
                  style={{ ...activeStyle, ...(isClickable && { cursor: 'pointer' }), ...(i > activeItem ? circleStyle : null) }}
                  onClick={() => (item.onClick ? item.onClick() : generalOnClick ? generalOnClick(i) : null)}
                >
                  {
                    ((i < activeItem || i === (items.length - 1)) && checkedIcon) ?
                      checkedIcon && checkedIcon
                      :
                      item.icon && item.icon
                  }
                </li>
            }
          </div>
        </span>
      );
    });

    return (
      <div style={{ ...style, flexDirection: 'column' }}>
        <div style={{ width: '100%', display: 'flex' }}>
          {items.map(item => <div style={{ width: `${(100 / items.length)}%`, textAlign: 'center', margin: 'auto' }} >{item.title}</div>)}
        </div>
        <ul id={'ul-modal'} style={{ display: 'flex', width: '100%', padding: '0px' }}>
          {steps}
        </ul>
      </div>
    );
  }
}
