import styled from 'styled-components';
import { AngleLeftSolid } from '@classapp-tech/edna-icons';

export const Container = styled.div`
    display: flex;
    height: 40px;
    align-items: center;
`;

export const Title = styled.div`
    font-family: Circular,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 32px;
    font-weight: 700;
    margin-left: 10px;
`;

export const Icon = styled(AngleLeftSolid)`
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
`;
