import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Container, Textarea } from './Message.styles';
import { __ } from '../../../../../../../i18n';

const MAX_LENGTH = 300;

/**
 * @param {{
 *  value?: string;
 *  onChange?: (value: string) => void;
 *  disabled?: boolean;
 *  ref?: React.RefObject<{
 *    getValue: () => string;
 *  }>;
 * }} props
 * @returns {React.Component}
 */
const MessageComponent = ({ onChange, value = '', disabled = false }, ref) => {
  const [message, setMessage] = useState(value);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setMessage(value);
  }, [value]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  function handleChange(e) {
    setMessage(e.target.value);
    onChange?.(e.target.value);
  }

  useImperativeHandle(ref, () => ({
    getValue: () => message,
    setMessage: (value) => {
      setMessage(value);
    },
    changeDisabled: (value) => {
      setIsDisabled(value);
    },
  }));

  return (
    <Container disabled={isDisabled}>
      <Textarea
        ref={ref}
        data-testid={'AUTOMATIC_MESSAGE_TEXTAREA'}
        className="channelConfig"
        value={message}
        onChange={handleChange}
        maxLength={MAX_LENGTH}
        disabled={isDisabled}
        placeholder={__('No message registered')}
      />

      <span>
        {message?.length || 0}/{MAX_LENGTH}
      </span>
    </Container>
  );
};
export const Message = forwardRef(MessageComponent);
Message.displayName = 'Message';
