import { forwardRef } from 'react';
import { RadioButtonComponent } from './RadioButton';

/**
 * It's the radio component, with this component you can render a radio an get the state of it
 *
 * Follow an example of how to use this component
 *
 * @example
 * import { RadioButton } from '@classapp-tech/edna-native';
 *
 * export function App() {
 *  const [radioState, setRadioButtonState] = useState(false);
 *
 * return (
 *  <RadioButton selected={radioState} />
 * )
 */
export const RadioButton = forwardRef(
  RadioButtonComponent
);
