import React from 'react';
import { inject, observer } from 'mobx-react';

import Modal from './Modal';
import ListView from './ListView';

import { __ } from '../i18n';

@inject('store') @observer
export default class History extends React.Component {

  render() {
    return (
      <Modal id={this.props.id} open size="small" style={{ borderRadius: '4px' }} closeIcon={'times close inside'} onClose={() => this.props.store.appends.pop()} closeOnRootNodeClick={false}>
        <Modal.Header style={{ padding: '16px' }}>
          {this.props.header}
        </Modal.Header>
        <Modal.Content>
          <ListView
            renderRow={this.props.renderRow}
            source={this.props.source}
            segment={false}
          />
        </Modal.Content>
      </Modal>
    );
  }
}
