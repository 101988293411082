import { css } from 'styled-components';

export const getBorderColor = ({ isActive = false, backgroundColor }) => {
  if (isActive) {
    return css`
      border-color: ${backgroundColor};
    `;
  }
  return css`
    border-color: ${({ theme }) => theme.colors.pencil.clear}
  `;
};
