import React from 'react';
import { render, screen, fireEvent } from '@testing-library/react';

import { Footer } from '.';
import { __ } from '../../../../../../i18n';

describe('Footer', () => {
  let props;

  beforeEach(() => {
    props = {
      chatNumber: 5,
      attendanceNumber: 10,
      onSave: jest.fn(),
      onCancel: jest.fn(),
    };
  });

  it('should render simple talk text with the correct number', () => {
    const { rerender } = render(<Footer {...props} />);
    const simpleTalkText = screen.getByText('simple talk');
    expect(simpleTalkText).toBeInTheDocument();
    expect(screen.getByText('5')).toBeInTheDocument();

    props.chatNumber = 15;
    rerender(<Footer {...props} />);
    expect(screen.getByText('15')).toBeInTheDocument();
  });

  it('should render service status text with the correct number', () => {
    const { rerender } = render(<Footer {...props} />);
    const serviceStatusText = screen.getByText('service status');
    expect(serviceStatusText).toBeInTheDocument();
    expect(screen.getByText('10')).toBeInTheDocument();

    props.attendanceNumber = 20;
    rerender(<Footer {...props} />);
    expect(screen.getByText('20')).toBeInTheDocument();
  });

  it('should call onSave when save settings button is clicked', () => {
    render(<Footer {...props} />);
    const saveSettingsButton = screen.getByText(__('Save settings'));
    fireEvent.click(saveSettingsButton);
    expect(props.onSave).toHaveBeenCalled();
  });

  it('should call onCancel when cancel button is clicked', () => {
    render(<Footer {...props} />);
    const cancelButton = screen.getByText(__('Cancel'));
    fireEvent.click(cancelButton);
    expect(props.onCancel).toHaveBeenCalled();
  });

  it('should display the number 0 when chatNumber or attendanceNumber is not passed', () => {
    props.chatNumber = undefined;
    props.attendanceNumber = undefined;
    render(<Footer {...props} />);
    const numbers = screen.getAllByText('0');
    expect(numbers).toHaveLength(2);
  });
});
