import React from 'react';
import { Image, Segment } from 'semantic-ui-react';
import { __ } from '../../../../../../../i18n';

export const NotificationEmptyState = () => {
  return (
    <Segment basic textAlign="center">
      <Image
        /* src={utils.asset('/images/deletedMessage.png')} */ height="200"
        src="https://walle-media.s3-us-west-2.amazonaws.com/images/deletedMessage.png"
        centered
      />
      <div
        style={{
          fontSize: 19,
          fontWeight: 'bold',
          margin: '32px 0px 8px 0px',
        }}
      >
        {__('No notifications yet')}
      </div>
      <div style={{ color: '#6C6C6C' }}>
        {__('Nothing to read for now. Take a break!')}
      </div>
    </Segment>
  );
};
