import React from 'react';
import { Button } from 'semantic-ui-react';

import { __ } from '../i18n';

export default class Timer extends React.Component {
  state = {
    elapsed: 0,
    date: Date.now()
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, 50);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick = () => {
    const elapsed = new Date() - (this.props.start || this.state.date);

    this.setState({ elapsed }, () => {
      if ((elapsed / 1000) >= this.props.interval) {
        clearInterval(this.timer);
      }
    });
  }

  render() {
    const { onClick, content, interval, onFinishTimer, textColor, postContent } = this.props;
    const elapsed = Math.round(this.state.elapsed / 100);
    const seconds = (elapsed / 10).toFixed(1);

    if (seconds >= interval) {
      if (onFinishTimer) onFinishTimer();
      return (
        <span
          style={{ color: (textColor || '#0080ff') }}
          className="pointer"
          onClick={() => {
            onClick();
            this.setState({ date: new Date() }, () => {
              this.timer = setInterval(this.tick, 50);
            });
          }}
        >
          {content}
        </span>
      );
    }

    return (
      <span style={{ color: (textColor || '#0080ff'), opacity: '.6' }} disabled>
        {content} <b>{(interval - seconds).toFixed(0)}</b> {__('seconds')} {postContent}
      </span>
    );
  }
}
