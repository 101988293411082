import React from 'react';
import { inject, observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';
import cookie from 'react-cookie';

import { __ } from '../i18n';

@inject('store')
export default class PopupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: '',
      top: null,
      left: null,
      currentUserId: 0
    };
  }

  componentDidMount() {
    const { name, showOnStart, delay = 1000 } = this.props;

    if (showOnStart && this.state[name + 'Open'] === undefined) setTimeout(() => this.onPopupOpen(name), delay);
  }

  componentDidUpdate() {
    const { name, showOnStart, store } = this.props;
    const { currentUserId } = this.state;
    if (showOnStart && (this.state[name + 'Open'] === undefined || currentUserId !== store.currentUser.id)) this.onPopupOpen(name);
  }

  componentWillUnmount() {
    const { timer } = this.state;
    if (timer) clearTimeout(timer);
  }

  onPopupOpen(popupName) {
    const { store } = this.props;
    if (!this.hasSeenPopup(popupName)) {
      const newPopupTimer = setTimeout(() => this.closePopup(popupName), 10000);

      this.setState(prevState => ({
        ...prevState,
        currentUserId: store.currentUser.id,
        [popupName + 'Open']: true,
        timer: newPopupTimer
      }));
    } else {
      this.setState({ [popupName + 'Open']: false, currentUserId: store.currentUser.id, });
    }
  }

  closePopup(popupName) {
    this.setState({ [popupName + 'Open']: false });

    const { currentUser, popups } = this.props.store;

    const currentUserId = currentUser.id;
    const usersList = popups && popups[`hasSeen${popupName}Popup`];

    let newPopupData;

    if (!usersList) newPopupData = [currentUserId];
    else if (currentUserId && !usersList.includes(currentUserId)) newPopupData = [...usersList, currentUserId];

    if (newPopupData) this.saveData(popupName, newPopupData);
  }

  saveData(popupName, newPopupData) {
    const { app, popups } = this.props.store;

    cookie.save(
      `hasSeen${popupName}Popup`,
      newPopupData,
      {
        path: '/',
        domain: app.host.replace(/www|beta|https:\/\/|:5400/g, ''),
        maxAge: 60 * 60 * 24 * 30
      }
    );
    if (popups) popups[`hasSeen${popupName}Popup`] = newPopupData;
    else this.props.store.popups = { [`hasSeen${popupName}Popup`]: newPopupData };
  }

  hasSeenPopup(popupName) {
    const { currentUser, popups } = this.props.store;
    const currentUserId = currentUser.id;
    const usersList = popups && popups[`hasSeen${popupName}Popup`];
    return usersList && usersList.length && usersList.includes(currentUserId);
  }

  getPopupClassName = (position) => {
    const availablePositions = {
      'top left': (() => {
        let popupClassName = 'custom-popup-top-left';
        if (!this.propsisMobile) popupClassName += ' popup-desktop';
        return popupClassName;
      })(),
      'right center': 'custom-popup-right-center popup-desktop'
    };
    return availablePositions[position] || availablePositions['top left'];
  }

  render() {
    const { children, name, text, title, position } = this.props;

    return (
      <Popup
        open={this.state[name + 'Open']}
        header={
          <h3
            style={{
              lineHeight: '1,0625rem', // 17px
              fontWeight: 'bold',
              marginBottom: '0.5rem' // 8px
            }}
          >{title}</h3>
        }
        content={[
          <span
            key="1"
            style={{
              fontSize: '0.875rem', // 14px
              lineHeight: '1.125rem', // 18px
              color: 'rgba(0, 0, 0, 0.6)'
            }}
          >{text}</span>,
          <br key="2" />,
          <button
            key="3"
            onClick={() => {
              this.closePopup(name);
              clearTimeout(this.state.timer);
            }}
            style={{
              color: '#0069FF',
              border: 'none',
              background: 'transparent',
              padding: '0',
              marginTop: '0.6rem',
              lineHeight: '1.3rem',
              cursor: 'pointer'
            }}
          >Ok</button>]
        }
        on="focus"
        style={{
          minWidth: '16rem', // 256px
          padding: '0.9375rem 0.9375rem 1.125rem 0.875rem', // 15px 15px 18px 14px
          fontSize: '0.8125rem', // 13px
          zIndex: '2',
          borderRadius: '0.5rem' // 8px
        }}
        position={position}
        trigger={children}
      />
    );
  }
}
