import React from 'react';
import { render } from '../../../../../../../../newRender';
import { SelectResponsible } from '.';

describe('<SelectResponsible />', () => {
  const props = {
    selectedUsers: [],
    error: '',
    currentEntityId: 1,
    onSubmit: jest.fn(),
    onDelete: jest.fn(),
  };

  it('should be defined', () => {
    const { container } = render(<SelectResponsible {...props} />);
    expect(container).toBeDefined();
  });
});
