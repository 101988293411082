import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.span`
    font-size: 1.143rem;
    margin-bottom: 0.75rem;
`;

export const InputText = styled.input`
    border-radius: 0.5rem;
    border: ${({ error }) => (error ? '2px solid #BF2600 !important' : '1px solid rgba(0, 0, 0, 0.16)')};
    background: #FFF;
    padding: 1rem;
`;

export const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0.714rem 0;
`;

export const ErrorText = styled.span`
    color: #BF2600;
    margin-left: 0.5rem;
`;
