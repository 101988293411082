import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject('store') @observer
export default class OptionsToast extends React.Component {
    menuContent = () => {
      const { menu } = this.props;
      return (
        <div className="options-toast" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
          {menu.map((i, index) => <Button
            data-action={i.dataAction}
            key={index}
            onClick={() => { i.closeOnClick && this.props.store.appends.pop(); i.onClick && i.onClick(); }}
          >
            <span style={{ display: 'flex', fontSize: '16px', color: i.color }}>
              <Icon name={i.icon} style={{ marginRight: '16px' }} />
              {i.text}
            </span>
          </Button>)
          }
        </div>
      );
    };
    render() {
      return (
        this.menuContent()
      );
    }
}
