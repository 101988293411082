import React from 'react';
import { render, fireEvent } from '../../../../../../newRender';
import { RadioList } from '.';

jest.useFakeTimers();

describe('given RadioList component', () => {
  const mocks = {
    onSelect: jest.fn((value) => value),
    options: [
      {
        label: {
          text: 'OPTION 1',
        },
        value: 'OPTION_1',
      },
      {
        label: {
          text: 'OPTION 2',
        },
        value: 'OPTION_2',
      },
    ],
  };

  it('should be able to find some option in the list', () => {
    const { getByText } = render(<RadioList {...mocks} />);

    const text = mocks.options[0] ? mocks.options[0].label.text : '';
    const option = getByText(text);

    expect(option).toBeVisible();
  });

  it('should be able to choose some option', () => {
    const { getByText } = render(<RadioList {...mocks} />);

    const text = mocks.options[0] ? mocks.options[0].label.text : '';
    const option = getByText(text);
    fireEvent.click(option);

    expect(mocks.onSelect).toHaveBeenCalled();
  });
});
