import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import { __ } from '../i18n';

@inject('store') @observer
export default class FeatureToogleBanner extends React.Component {
  /**
   * @param {{
   * iconColor?: string,
   * iconName?: string,
   * backgroundColor?: string,
   * acceptText?: string,
   * declineText?: string,
   * mainText: string,
   * mainTextColor?: string,
   * optionTextColor?: string,
   * handleAccept?: void,
   * handleDecline?: void
   * }} props
   */
  constructor(props) {
    super(props);

    this.state = {
      showBanner: true,
    }
  }

  closeBanner() {
    this.setState({showBanner: false})
  }

  render() {
    const { iconColor, iconName, iconClassName, backgroundColor, acceptText, declineText, mainText, mainTextColor, optionTextColor, handleAccept, handleDecline } = this.props;
    const { showBanner } = this.state;

    if (!showBanner) {
      return (<div></div>);
    }

    return (
      <div className='feature-toogle-container' style={{backgroundColor}}>
        <Icon name={iconName} size='big' className={`icon ${iconClassName}`} style={{color: iconColor || 'black'}} />
        <p className='text' style={{color: mainTextColor || 'black'}}>
          {mainText}
        </p>
        <div className='button-container' style={{color: optionTextColor || 'black'}}>
          <p onClick={() => { if(handleAccept) handleAccept(); this.closeBanner() } }>{acceptText}</p>
          <p onClick={() => { if(handleDecline) handleDecline(); this.closeBanner() }}>{declineText}</p>
        </div>
      </div>
    );
  }
}
