import React from 'react';
import {
  Message,
  Divider,
  Button,
  Segment,
  Container,
} from 'semantic-ui-react';

import { Link } from 'react-router';

import { __ } from '../../../i18n';

import { Tag } from './Tag';

import { TagLight } from '@classapp-tech/edna-icons';

/**
 * @param {{
 *  hasStudentRepliesFeature: boolean;
 *  tags: {
 *   id: number | string;
 *   name: string;
 *  }[];
 *  hasMaster: boolean;
 *  isAdmin: boolean;
 *  organization: {
 *    id: number | string;
 *  };
 * browserHistory: {
 *  push: (path: string) => void;
 * };
 * }} props
 */
export function TagsList({
  hasStudentRepliesFeature = false,
  tags = [],
  hasMaster = false,
  isAdmin = false,
  organization,
  browserHistory,
  isNetworkChild,
}) {
  if (isNetworkChild) {
    return (
      <div>
        <Divider style={{ marginBottom: '1.25rem' }} section horizontal>
          {__('Tags')}
        </Divider>
        {!tags.length ? (
          <span
            style={{
              marginLeft: '1rem',
              color: '#616161',
              fontSize: '1rem',
            }}
          >
            {__('Your organization has no tags.')}
          </span>
        ) : null}
        <div style={{ margin: '1.25rem 0 0 1rem' }}>
          <Message warning>{__('Only network can modify tags')}</Message>
        </div>

        {tags.length ? (
          <Segment
            className={hasStudentRepliesFeature ? 'new label-content' : ''}
          >
            {tags.map((tag) => (
              <Tag
                key={tag.id}
                id={tag.id}
                name={tag.name}
                icon={<TagLight />}
              />
            ))}
          </Segment>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <Divider style={{ marginBottom: '1.25rem' }} section horizontal>
        {__('Tags')}
      </Divider>
      {!tags.length ? (
        <span
          style={{
            marginLeft: '1rem',
            color: '#616161',
            fontSize: '1rem',
          }}
        >
          {__('Your organization has no tags.')}
        </span>
      ) : null}
      {hasMaster ? (
        <Container
          textAlign="center"
          className={hasStudentRepliesFeature ? 'new ' : ''}
        >
          <Button
            icon="plus"
            style={{
              transform: 'translateY(-40px)',
            }}
            as={Link}
            content={__('Add tag')}
            to={`/organizations/${organization.id}/edit/tags/add`}
          />
        </Container>
      ) : (
        <div style={{ margin: '1.25rem 0 0 1rem' }}>
          <Message warning>
            {__('Contact our support to add or delete tags.')}
          </Message>
        </div>
      )}

      {tags.length ? (
        <Segment
          className={hasStudentRepliesFeature ? 'new label-content' : ''}
        >
          {tags.map((tag) => (
            <Tag
              isAdmin={isAdmin}
              key={tag.id}
              id={tag.id}
              name={tag.name}
              onClick={() =>
                browserHistory.push(
                  `organizations/${organization.id}/edit/tags/${tag.id}`,
                )
              }
              hasMaster={hasMaster}
              onDelete={() =>
                browserHistory.push(
                  `organizations/${organization.id}/edit/tags/${tag.id}/delete`,
                )
              }
            />
          ))}
        </Segment>
      ) : null}
    </div>
  );
}
