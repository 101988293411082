import React from 'react';
import { Popup } from 'semantic-ui-react';
import _ from 'lodash';

// const defaultQuantity = 5;
const defaultActive = 0;

const stepStyle = {
  width: '1em',
  height: '1em',
  textAlign: 'center',
  // lineHeight: '1em',
  borderRadius: '1em',
  background: 'dodgerblue',
  // margin: '0 2em',
  display: 'inline-block',
  color: 'white',
  position: 'relative',
  cursor: 'default'
};

const stepBeforeStyle = {
  content: '',
  position: 'absolute',
  top: '.4em',
  left: '-4em',
  width: '4em',
  height: '.2em',
  background: 'dodgerblue',
};

const firstStepBeforeStyle = {
  display: 'none',
};

const stepActiveStyle = {
  background: 'dodgerblue',
};

const stepNotActiveStyle = {
  background: '#e9ecef',
};

const stepNotActiveBeforeStyle = {
  background: '#e9ecef',
};

export default class Steps extends React.Component {
  render() {
    const { items, activeItem, color, connected, name, size = 10, spacing = 3, style, generalOnClick, checkedIcon } = this.props;
    let s = stepStyle;
    let bs = stepBeforeStyle;

    if (size >= 1 && size <= 4) {
      s = _.assignIn(stepStyle, {
        width: `${size / 1.1425}rem`,
        height: `${size / 1.1425}rem`,
        lineHeight: `${size / 1.1425}rem`,
        borderRadius: `${size / 1.1425}rem`
      });
      bs = _.assignIn(stepBeforeStyle, {
        top: `${(0.4 * size) / 1.1425}rem`,
        height: `${(0.2 * size) / 1.1425}rem`,
      });
    }

    if (spacing >= 1 && spacing <= 7) {
      s = _.assignIn(stepStyle, {
        margin: `0 ${spacing * 20}px 0 0`,
      });
      bs = _.assignIn(stepBeforeStyle, {
        left: `-${spacing * 20}px`,
        width: `${spacing * 20}px`,
      });
    }

    const active = activeItem || defaultActive;

    const newName = name || 'generic';

    const steps = [];
    items.forEach((item, i) => {
      if (i === (items.length - 1)) {
        s = { ...s, margin: '0' };
      }

      let before = _.assignIn({}, bs);
      let activeStyle = _.assignIn({}, s);
      let cssClassName = `step-${newName}`;
      const isClickable = item.onClick || generalOnClick;

      if (i > active) {
        before = _.assignIn(before, stepNotActiveBeforeStyle);
        activeStyle = _.assignIn(activeStyle, stepNotActiveStyle);
      } else {
        before = color ? _.assignIn(before, { background: color }) : before;
        activeStyle = _.assignIn(activeStyle, color ? { background: color } : stepActiveStyle);
        cssClassName = `step-${newName}-active`;
      }

      if (i === 0) {
        before = _.assignIn(before, firstStepBeforeStyle);
        cssClassName = `step-${newName}-first`;
      }

      const beforeStyle = '{' + _.join(Object.keys(before).map(key => `${key}: ${before[key] === '' ? '\'\'' : before[key]};`), ' ') + '}';
      steps.push(
        <span key={`${newName}-li-${i}`}>
          { cssClassName === `step-${newName}-first` || connected === false ? '' : <style>{`#${newName}-${i}.${cssClassName}`}:before {beforeStyle}</style> }
          {
            item.popupContent ?
              <Popup
                basic
                trigger={
                  <li
                    className={cssClassName}
                    id={`${newName}-${i}`}
                    style={{ ...activeStyle, ...(isClickable && { cursor: 'pointer' }) }}
                    onClick={() => (item.onClick ? item.onClick() : generalOnClick ? generalOnClick(i) : null)}
                  >
                    {
                      (i < activeItem && checkedIcon) ?
                        checkedIcon && checkedIcon
                        :
                        item.icon && item.icon
                    }
                  </li>
                }
                content={item.popupContent}
              />
              :
              <li
                className={cssClassName}
                id={`${newName}-${i}`}
                style={{ ...activeStyle, ...(isClickable && { cursor: 'pointer' }) }}
                onClick={() => (item.onClick ? item.onClick() : generalOnClick ? generalOnClick(i) : null)}
              >
                {
                  (i < activeItem && checkedIcon) ?
                    checkedIcon && checkedIcon
                    :
                    item.icon && item.icon
                }
              </li>
          }
        </span>
      );
    });

    return (
      <div style={{ ...style }}>
        <ul style={{ padding: 0 }} id={`ul-${newName}`}>
          {steps}
        </ul>
        {
          items[activeItem] && !!items[activeItem].label && items[activeItem].label
        }
      </div>
    );
  }
}
