import React from 'react';
import { XmarkLight } from '@classapp-tech/edna-icons';

const icons = {
  error: {
    icon: 'circle exclamation',
    color: 'red',
    code: '#BF2600'
  },
  warning: {
    icon: 'exclamation triangle',
    color: 'yellow',
    code: '#B4B400'
  },
  success: {
    icon: 'circle check',
    color: 'green',
    code: '#00A656'
  },
  info: {
    icon: 'circle info',
    color: 'gray',
    code: 'rgba(0, 0, 0, 0.6)'
  }
};

/**
 * @typedef {{
 *  type: 'error' | 'warning' | 'success' | 'info'
 *  text: string,
 *  style?: React.CSSProperties,
 *  visibleTime: number,
 *  visible?: boolean,
 *  close?: boolean,
 *  onClose?: () => null
 * }} Props
 * @extends {React.Component<Props>}
 */
export default class Alert extends React.Component {
  /**@param {Props} props*/
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible ? props.visible : true
    };
  }

  /**@param {Props} prevProps*/
  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    // handle when fathers component update
    if (prevProps.visible !== visible) {
      this.setState({ visible });
    }
  }

  close = () => {
    const { onClose } = this.props;

    this.setState({ visible: false }, () => { if (onClose) onClose(); });
  }

  render() {
    const {
      text, type, style, visibleTime, close
    } = this.props;
    const { visible } = this.state;
    const icon = icons[type] || {};

    if (visibleTime && visibleTime > 0 && visible) setTimeout(this.close, visibleTime);

    return (
      <div className={visible ? 'alert visible' : 'alert invisible'} data-testid={`${type}-alert`}>
        <div className={`alert background ${icon.color}`} style={style} role="alert" aria-label={`${type}-alert`}>
          <i className={`icon ${icon.icon}`} style={{ color: icon.code }} />
          <span>{text}</span>
          {close && <XmarkLight onClick={this.close} role="button" aria-label="alert-close-button" />}
        </div>
      </div>
    );
  }
}
