import React from 'react';
import Switch from 'react-switch';

import { __ } from '../../../i18n';

/**
 * @param {{
 *  isArrivedEnabled: boolean;
 *  onChange: (checked: boolean) => void;
 *  hasStudentRepliesFeature: boolean;
 * }} props
 */
export function ArrivedSwitch({
  isArrivedEnabled,
  onChange,
  hasStudentRepliesFeature,
}) {
  return (
    <div
      data-testId="arrived-switch-container"
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: hasStudentRepliesFeature ? '' : '1rem',
      }}
    >
      <Switch
        checked={isArrivedEnabled}
        onChange={onChange}
        onColor="#084FFF"
        onHandleColor="#FFFFFF"
        offColor="#E7E7E7"
        handleDiameter={29}
        uncheckedIcon={false}
        checkedIcon={false}
        height={36}
        width={67}
      />
      <div
        style={{ display: 'flex', flexDirection: 'column', marginLeft: '9px' }}
      >
        <span>{__('Arrived')}</span>
        <span style={{ color: '#666666' }}>
          {isArrivedEnabled ? __('Enabled') : __('Disabled')}
        </span>
      </div>
    </div>
  );
}
