import React from 'react';
import { screen, render, fireEvent } from '@testing-library/react';
import { Icon } from 'semantic-ui-react';
import { Alert } from '.';

describe('Alert Component', () => {
  let props;

  beforeEach(() => {
    props = {
      icon: <Icon data-testid="leftIcon" name="question" size="big" />,
      title: 'Alert channel',
      isExpanded: true,
      onExpand: jest.fn(),
    };
  });

  it('should display left icon', () => {
    render(<Alert {...props} />);
    expect(screen.getByTestId('leftIcon')).toBeInTheDocument();
  });

  it('should display title', () => {
    render(<Alert {...props} />);
    expect(screen.getByText('Alert channel')).toBeInTheDocument();
  });

  it('should call onExpand when click on dropdown icon', () => {
    render(<Alert {...props} />);
    const dropdown = screen.getByTestId('dropdownIcon');
    expect(props.onExpand).not.toHaveBeenCalled();
    fireEvent.click(dropdown);
    expect(props.onExpand).toHaveBeenCalled();
  });

  it('should display children only when the component is expanded', () => {
    const { rerender } = render(<Alert {...props}><div>Children Component</div></Alert>);
    expect(screen.queryByText('Children Component')).toBeInTheDocument();
    rerender(<Alert {...props} isExpanded={false}><div>Children Component</div></Alert>);
    expect(screen.queryByText('Children Component')).not.toBeInTheDocument();
  });
});
