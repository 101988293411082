import React from 'react';
import { Popup } from 'semantic-ui-react';

export function GroupName({ name = '', key = null }) {
  if (name.length <= 10) return <span key={key || 0}>{name}</span>;

  return (
    <Popup
      basic
      hideOnScroll
      key={key || 0}
      content={<div>{name}</div>}
      trigger={<span>{name.substring(0, 10).trim()}...</span>}
    />
  );
}
