/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { render } from '../../../../../newRender';

import { GroupName } from '../GroupName';

describe('GroupName', () => {
  it('should render only text when size is lower than 10 characters', () => {
    const { getByText } = render(<GroupName name="test" />);
    expect(getByText('test')).toBeInTheDocument();
  });

  it('should render a popup with full text when size is greater than 10 characters', () => {
    const { getByText } = render(<GroupName name="testtesttest" />);
    expect(getByText('testtestte...')).toBeInTheDocument();
  });
});
